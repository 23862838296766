<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huishuju')"
           class="can">慧数据--></div>
      <div>党建地图</div>
    </div>

    <div class="page_body flex">
      <div id="map"
           ref="myChart"></div>
      <div class="pr data_body">
        <div class="data">
          <div class="cell1">
            <text>支部名称：{{ info.name }}</text>
          </div>
          <div class="cell2 tar">
            <text>
              成立时间：<span>{{ info.creattime }}</span>
            </text>
          </div>
          <div class="cell3">
            <text>支部位置：{{ info.address }}</text>
          </div>
          <div class="cell4 tar">
            <text>
              参与人数：<span>{{ info.joinNum }}</span>
            </text>
          </div>
          <!-- <div class="cell5">
            <text>支部书记：{{ info.branchLeader }}</text>
          </div>
          <div class="cell6 tar">
            <text>
              活动次数：<span>{{ info.activityNum }}</span>
            </text>
          </div> -->
          <div class="cell7">
            <text>支部成员：{{ info.branchMember }}</text>
          </div>
          <div class="cell8">
            <div>
              <text>支部建设：</text>
              <div v-html="info.details"></div>
            </div>
          </div>
        </div>
        <img class="slash"
             src="@/assets/image/slash.png"
             alt="" />
      </div>
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
import * as echarts from "echarts";
import "@/utils/taizhou.js";
export default {
  data() {
    return {
      list: [],
      pageSize: 100,
      page: 1,
      info: {}, // 当前支部的信息
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getBranchList();
    this.$nextTick(() => {
      this.getEchartMap();
    });
  },
  methods: {
    onmessage() {},
    toPage(e) {
      this.$router.push(e);
    },
    async getEchartMap() {
      let that = this;
      const res = await Service.getApi({
        do: "intell",
        op: "branchList",
        data: {
          page: this.page,
          count: this.pageSize,
        },
      });
      var data = res.data,
        dataList = [];
      for (const [index, item] of data.entries()) {
        dataList.push({
          name: item.name,
          coord: [item.lng * 1, item.lat * 1],
          data: item,
        });
      }
      console.log("dataList", dataList);
      let myChart = echarts.init(this.$refs.myChart);

      myChart.setOption({
        title: {
          text: "",
          left: "center",
        },
        series: [
          {
            type: "map",
            // silent: true,
            map: "taizhou",
            zoom: 1.2,
            roam: true, // 是否开启缩放
            label: {
              // 默认文本标签样式
              // normal: {
              //   show: true,
              //   textStyle: {
              //     color: "#fff",
              //     textShadowOffsetX: 0,
              //     textShadowOffsetY: 0,
              //     textShadowBlur: 5,
              //     textShadowColor: "#000",
              //   },
              // },
              // // 高亮文本标签样式
              // emphasis: {
              //   color: "yellow",
              //   fontSize: 22,
              //   fontWeight: "bold",
              // },
              normal: {
                show: true,
                textStyle: {
                  color: "yellow",
                  fontWeight: "bold",
                  fontSize: 18,
                  textShadowOffsetX: 0,
                  textShadowOffsetY: 0,
                  textShadowBlur: 5,
                  textShadowColor: "#000",
                },
              },
              emphasis: {
                color: "yellow",
                fontSize: 22,
                fontWeight: "bold",
              },
            },
            itemStyle: {
              // 默认区域样式
              // normal: {
              //   areaColor: "#FB8225",
              //   borderColor: "#000",
              //   borderWidth: 1,
              // },
              // // 高亮区域样式
              // emphasis: {
              //   // 高亮区域背景色
              //   areaColor: "#D51F1C",
              // },
              normal: {
                areaColor: "#D51F1C",
                borderColor: "#000",
                borderWidth: 1,
              },
              emphasis: {
                areaColor: "#D51F1C",
                borderColor: "#000",
                borderWidth: 1,
              },
            },
            markPoint: {
              symbol:
                "image://" + require("../assets/image/map_point_select.png"), // 自定义图片路径
              symbolSize: [50, 50], // 图片大小
              label: {
                position: "top",
                color: "#fff",
              },
              // coord数组存放地址坐标
              data: dataList,
            },
          },
        ],
      });
      myChart.on("click", function (params) {
        console.log("params", params.data.data);
        that.info = params.data.data;
        return false;
      });
      myChart.on("mouseover", function (params) {
        myChart.dispatchAction({
          type: "map",
        });
      });
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    async getBranchList() {
      const res = await Service.getApi({
        do: "intell",
        op: "branchList",
        data: {
          page: this.page,
          count: this.pageSize,
        },
      });
      this.list = res.data;
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

.page_body {
  width: 1678px;
  height: 728px;
  margin-top: 20px;
}

.page_bottom {
  margin-top: 27px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}

.slash {
  width: 711px;
  height: 749px;
}

#map {
  width: 730px;
  height: 800px;
}

.data_body {
  width: calc(100% - 730px);
}

.data {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  padding-top: 120px;
}

.data > div {
  margin-top: 20px;
  font-weight: bold;
}

.data > div > text {
  max-width: 300px;
}

.data > div > text > span {
  font-size: 20px;
  color: #d51f1c;
}

.cell1 {
  padding-left: calc(640px - 140px);
}
.cell2 {
  padding-right: calc(460px + 140px);
}
.cell3 {
  padding-left: calc(510px - 120px);
}
.cell4 {
  padding-right: calc(580px + 140px);
}
.cell5 {
  padding-left: calc(405px - 140px);
}
.cell6 {
  padding-right: calc(660px + 140px);
}
.cell7 {
  padding-left: calc(320px - 10px);
}

.cell8 {
  margin-top: 100px !important;
  padding-left: 220px;
}

.cell8 > div {
  background-color: #fff;
  box-sizing: border-box;
  padding: 5px;
}
</style>
