<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huihudong')"
           class="can">慧互动--></div>
      <div>绘声绘影</div>
    </div>

    <div class="page_body flex at_c jc_c">
      <div v-loading="!currentInfo.url">
        <iframe class="iframe"
                :src="currentInfo.url"
                frameborder="0"></iframe>
      </div>
      <div class="lists">
        <div class="fz24 mb20 fb">设备列表:</div>
        <div @click="changeCurrent(index)"
             class="list_cell can"
             :class="{ active: currentIndex == index }"
             v-for="(item, index) in list"
             :key="index">
          {{ index + 1 }}、{{ item.deviceSerial }}---状态：{{
            item.status == 1 ? "在线" : "离线"
          }}
        </div>
      </div>
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
export default {
  data() {
    return {
      list: [],
      currentIndex: 0,
      currentInfo: {},
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getDeviceList();
  },
  methods: {
    onmessage() {},
    toPage(e) {
      this.$router.push(e);
    },
    async getDeviceList() {
      const res = await Service.getApi({
        do: "device",
        op: "list",
        data: {},
      });
      this.list = res.data;
      this.currentInfo = this.list[this.currentIndex];
      console.log("res", res.data);
    },
    changeCurrent(index) {
      this.currentIndex = index;
      this.currentInfo = {};
      setTimeout(() => {
        this.currentInfo = this.list[index];
      }, 1000);
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

.page_body {
  width: 1678px;
  height: 728px;
  margin-top: 20px;
}

.page_bottom {
  margin-top: 27px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}

.iframe {
  width: 975px;
  height: 650px;
}

.lists {
  width: 350px;
  height: 650px;
  margin-left: 30px;
  background-color: #fce085;
  box-sizing: border-box;
  padding: 30px;
}

.active {
  color: red;
}
</style>
