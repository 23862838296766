<template>
  <div class="bg">
    <div class="head">
      <img class="img1"
           src="@/assets/image/img1.png"
           alt="" />
      <img class="head_img"
           src="@/assets/image/head_huidangwu.png"
           alt="" />
    </div>

    <div class="page_body">
      <div @click="toPage('/zhidu?type=1')">
        <img src="@/assets/image/dangwu1.png"
             alt="" />
        <div>党内法规</div>
      </div>
      <div @click="toPage('/zhidu?type=2')">
        <img src="@/assets/image/dangwu2.png"
             alt="" />
        <div>控股制度</div>
      </div>
      <div @click="toPage('/zhidu?type=3')">
        <img src="@/assets/image/dangwu3.png"
             alt="" />
        <div>公司制度</div>
      </div>
      <div @click="toPage('/xingjikaoping')">
        <img src="@/assets/image/dangwu4.png"
             alt="" />
        <div>星级考评</div>
      </div>
      <!-- <div>
        <img src="@/assets/image/dangwu5.png"
             alt="" />
        <div>标准化台账</div>
      </div> -->
      <div @click="open('http://xfh.jchc.cn/sjkg/manager/login.htm')">
        <img src="@/assets/image/dangwu6.png"
             alt="" />
        <div>苏交控·先锋荟</div>
      </div>
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
  },
  methods: {
    onmessage() {},
    open(e) {
      window.open(e);
    },
    toPage(e) {
      this.$router.push(e);
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  margin: 162px 0 23px;
  display: flex;
  align-items: center;
}

.img1 {
  width: 84px;
  height: 167px;
}

.head_img {
  width: 781px;
  height: 199px;
}

.page_body {
  width: 1880px;
  height: 416px;
  background-image: url("../assets/image/dangwu_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: space-between;
}

.page_body > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 355px;
  margin-top: 70px;
  cursor: pointer;
}

.page_body > div > img {
  width: 143px;
  height: 143px;
  margin-bottom: 24px;
}

.page_body > div > div {
  font-size: 32px;
  font-weight: 400;
  color: #fff;
  line-height: 32px;
}

.page_bottom {
  margin-top: 47px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}
</style>
