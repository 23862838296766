<template>
  <div>
    <div class="title mb30">假人管理</div>
    <!-- <div class="head bb p30 flex fw_w">
      <div>
        <div>关键词</div>
        <div class="w300">
          <el-input
            clearable
            size="large"
            v-model="search"
            placeholder="请输入昵称/手机号/真实姓名"
          ></el-input>
        </div>
      </div>
      <div>
        <div>时间</div>
        <div>
          <el-date-picker
            format="YYYY-MM-DD"
            value-format="x"
            size="large"
            v-model="time_range"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div>
        <div>等级</div>
        <div>
          <el-select
            filterable
            size="large"
            v-model="level"
            placeholder="请选择"
          >
            <el-option
              v-for="item in levelList"
              :key="item.id"
              :label="item.label"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <div>归属</div>
        <div>
          <el-select
            filterable
            size="large"
            v-model="service_id"
            placeholder="请选择"
          >
            <el-option
              v-for="item in serviceArr"
              :key="item.id"
              :label="item.nickname"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div>
        <el-button @click="retrieval" type="primary">搜 索</el-button>
      </div>
    </div> -->
    <div class="bb pl30 pr30">
      <div class="mb20">
        <el-button type="primary"
                   @click="addUserFormShow = true">添加假人</el-button>
      </div>
      <div v-loading="userListLoading">
        <div class="mb20">
          <el-table :data="tableData"
                    height="60vh"
                    style="width: 100%">
            <el-table-column show-overflow-tooltip
                             label="昵称"
                             width="220"
                             prop="nickname">
            </el-table-column>
            <el-table-column label="头像"
                             width="120">
              <template #default="scope">
                <img class="list_avatar"
                     :src="scope.row.avatar"
                     alt="" />
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             width="300"
                             align="center">
              <template #default="scope">
                <el-button type="primary"
                           size="mini"
                           @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                <el-button type="danger"
                           size="mini"
                           @click="handleDelete(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-pagination @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-size="15"
                         layout="total, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 添加假人 -->
    <el-dialog width="420px"
               v-model="addUserFormShow"
               title="添加假人">
      <el-form :model="addUserForm"
               ref="addUserForm"
               :rules="addUserRules">
        <el-form-item class="w222"
                      size="large"
                      label="昵称"
                      :label-width="formLabelWidth"
                      prop="nickname">
          <el-input v-model="addUserForm.nickname"
                    autocomplete="off" />
        </el-form-item>
        <el-form-item class="w222"
                      size="large"
                      label="头像"
                      :label-width="formLabelWidth">
          <el-upload class="avatar-uploader"
                     action="https://api.i-vce.com/teacher/common/upload"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess1">
            <div class="avatar-uploader_div"
                 v-if="addUserForm.avatar">
              <img :src="addUserForm.avatar"
                   class="avatar" />
              <div class="zhezhao">
                <el-icon @click.stop="handleRemove1"
                         :size="24">
                  <Delete color="#ffffff" />
                </el-icon>
              </div>
            </div>
            <el-icon v-else
                     class="avatar-uploader-icon">
              <Plus />
            </el-icon>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addUserFormShow = false">取消</el-button>
          <el-button type="primary"
                     @click="submitForm('addUserForm')">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑资料 -->
    <el-dialog width="680px"
               v-model="editFormShow"
               title="编辑资料">
      <el-form :model="editForm"
               ref="editForm">
        <div>
          <div class="form_cell_title">基础信息</div>
          <div class="form_cell">
            <el-form-item size="large"
                          label="昵称"
                          :label-width="formLabelWidth1">
              <el-input v-model="editForm.nickname"
                        autocomplete="off" />
            </el-form-item>
          </div>
          <div class="form_cell">
            <el-form-item size="large"
                          label="头像"
                          :label-width="formLabelWidth1">
              <el-upload class="avatar-uploader"
                         action="https://api.i-vce.com/teacher/common/upload"
                         :show-file-list="false"
                         :on-success="handleAvatarSuccess">
                <div class="avatar-uploader_div"
                     v-if="editForm.avatar">
                  <img :src="editForm.avatar"
                       class="avatar" />
                  <div class="zhezhao">
                    <el-icon @click.stop="handleRemove"
                             :size="24">
                      <Delete color="#ffffff" />
                    </el-icon>
                  </div>
                </div>
                <el-icon v-else
                         class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editFormShow = false">取消</el-button>
          <el-button type="primary"
                     @click="submitForm('editForm')">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 圈子管理 -->
    <el-dialog width="500px"
               v-model="circleShow"
               title="圈子管理">
      <div class="flex jc_b">
        <div>
          <div>
            <div class="h500"
                 v-loading="tableLoading">
              <el-table row-key="id"
                        :data="circleList"
                        @selection-change="handleSelectionChange"
                        ref="multipleTable">
                <!-- <el-table-column type="selection"
                                 width="40"
                                 reserve-selection /> -->
                <el-table-column align="center"
                                 label="头像"
                                 width="80">
                  <template #default="scope">
                    <div class="flex at_c jc_c">
                      <img class="avatar"
                           :src="scope.row.avatar"
                           alt="" />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="title"
                                 label="名称"
                                 width="240"
                                 show-overflow-tooltip />
                <el-table-column align="center"
                                 label="状态"
                                 width="140">
                  <template #default="scope">
                    <div class="flex at_c jc_c">
                      <el-button @click="
                          changeCircleStatus(
                            scope.row.teacher_id,
                            scope.row.status
                          )
                        "
                                 v-if="scope.row.status"
                                 type="primary">已加入</el-button>
                      <el-button @click="
                          changeCircleStatus(
                            scope.row.teacher_id,
                            scope.row.status
                          )
                        "
                                 v-else
                                 type="danger">未加入</el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- <div class="mt10">
            <el-pagination @current-change="handleCurrentChange"
                           v-model:current-page="currentPage"
                           layout="prev, pager, next, jumper"
                           :page-size="15"
                           :total="total">
            </el-pagination>
          </div> -->
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/dummy";
// import { Delete, Plus } from "@element-plus/icons-vue";
// import * as ServiceIndex from "../api/index";
// import { useStore } from 'vuex'
export default {
  // components: {
  //   Plus,
  //   Delete,
  // },
  data() {
    return {
      search: "", // 关键词
      time_range: [], // 时间范围
      level: 0, // 0全部 1会员 2免费会员
      levelList: [
        {
          id: 0,
          label: "全部",
        },
        {
          id: 1,
          label: "会员",
        },
        {
          id: 2,
          label: "免费会员",
        },
      ],
      service_id: 0, // 0全部客服
      serviceArr: [
        {
          id: 0,
          nickname: "全部",
        },
      ],
      value4: "",
      userListLoading: false, // 会员列表加载状态
      tableData: [],
      page: 1,
      total: 0,
      addUserFormShow: false, // 添加假人表单显示状态
      addUserForm: {
        nickname: "",
        avatar: "",
      }, // 添加假人表单
      addUserRules: {
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        avatar: [{ required: true, message: "请上传头像", trigger: "blur" }],
      }, // 添加假人表单验证规则
      editFormShow: false, // 编辑资料表单显示状态
      editForm: {
        id: null,
        nickname: "",
        avatar: "",
      }, // 编辑资料表单
      formLabelWidth: "60px",
      formLabelWidth1: "100px",
      teacherList: [], // 研究员列表
      serviceList: [], // 客服列表
      is_service_admin: localStorage.getItem("is_service_admin"), // 0非管理员1管理员
      circleShow: false,
      circleList: [],
      currentUserId: null, // 当前会员id
      tableLoading: false,
      serviceId: null, // 当前账号客服id
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    // const store = useStore()
    // store.state.ws.onmessage = this.onmessage
    // $socketIo.connectSocketInit({
    //   room_id: 1,
    //   type: "join_room_send",
    // });
    // this.getBaseInfo();
    // this.getUserInfo();
    this.getUserList(1);
  },
  methods: {
    handleRemove() {
      console.log("@删除图片");
      this.editForm.avatar = "";
    },
    handleAvatarSuccess(res, file) {
      this.editForm.avatar = res.data.fullurl;
    },
    handleRemove1() {
      console.log("@删除图片");
      this.addUserForm.avatar = "";
    },
    handleAvatarSuccess1(res, file) {
      this.addUserForm.avatar = res.data.fullurl;
    },
    onmessage(e) {
      // console.log('myclient-onmessage',JSON.parse(e.data))
    },
    // 修改会员V券
    handleMoney(index, row) {
      let that = this;
      that
        .$prompt("请输入变动值（需要带加减号）", "V券变动", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          // inputPattern: /^(([1-9][0-9]*|0)|(([0]\\.\\d{1,2}|[1-9][0-9]*\\.\\d{1,2})))$/,
          // inputErrorMessage: '变动值不合法'
        })
        .then(({ value }) => {
          that.changeMoney(row, value);
        });
    },
    async changeMoney(row, money) {
      const res = await Service.changeMoney({
        id: row.id,
        money,
        remark: "客服修改V券",
      });
      // console.log("客服修改V券", res);
      this.$message.success(res.msg);
      this.getUserList(this.page);
    },
    // 搜索操作
    retrieval() {
      this.getUserList(1);
    },
    // 获取基础信息
    async getBaseInfo() {
      const res = await ServiceIndex.baseInfo();
      // console.log("获取基础信息", res);
      this.teacherList = res.data.teacherList;
      this.serviceList = res.data.serviceList;
      this.serviceArr = this.serviceArr.concat(res.data.serviceList);
    },
    // 获取账号信息
    async getUserInfo() {
      const res = await ServiceIndex.getServiceIndex();
      this.serviceId = res.data.userinfo.user_id;
      console.log("serviceId", res.data.userinfo.user_id);
      console.log("serviceId", this.serviceId);
    },
    // 获取我的会员列表
    async getUserList(page) {
      this.userListLoading = true;
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      const res = await Service.getUserList({
        page,
        // search: this.search,
        // time_range: time_range0,
        // level: this.level,
        // service_id: this.service_id,
      });
      // console.log("我的会员列表", res);
      this.page = page;
      this.total = res.data.total;
      this.tableData = res.data.data;
      this.userListLoading = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "addUserForm":
              this.addUser();
              break;
            case "editForm":
              this.profile();
              break;
          }
        }
      });
    },
    // 添加假人
    async addUser() {
      var addUserForm = this.addUserForm;
      const res = await Service.fillUser({
        nickname: addUserForm.nickname,
        avatar: addUserForm.avatar,
      });
      // console.log("添加假人", res);
      this.$message.success(res.msg);
      this.addUserFormShow = false;
      this.getUserList(this.page);
      this.addUserForm = {
        nickname: "",
        avatar: "",
      };
      this.$refs["addUserForm"].resetFields();
    },
    // 修改会员信息
    async profile() {
      var editForm = this.editForm;
      var param = {
        id: editForm.id,
        nickname: editForm.nickname,
        avatar: editForm.avatar,
      };
      const res = await Service.profile(param);
      // console.log("修改会员信息", res);
      this.$message.success(res.msg);
      this.editFormShow = false;
      this.$refs["editForm"].resetFields();
      this.getUserList(this.page);
    },
    handleEdit(index, row) {
      this.editFormShow = true;
      console.log("handleEdit", row.service_id);
      this.editForm = {
        id: row.id,
        nickname: row.nickname,
        avatar: row.avatar,
      };
    },
    handleDelete(index, row) {
      this.$confirm("确认删除该假人吗吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.handleDeleteApi(index, row);
      });
      // console.log(index, row);
    },
    async handleDeleteApi(index, row) {
      const res = await Service.delDummyUser({
        id: row.id,
      });
      this.$message.success(res.msg);
      this.getUserList(this.page);
    },
    handleCurrentChange(val) {
      this.getUserList(val);
      // console.log(`当前页: ${val}`);
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 20px;
  font-weight: bold;
  padding: 15px 30px 0;
}

.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}

.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.w202 /deep/.el-input__inner {
  width: 202px !important;
}

.w202 .date /deep/.el-input__suffix {
  right: 33px !important;
}

.list_avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.avatar-uploader_div {
  width: 178px;
  height: 178px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
