<template>
  <div class="sidebar">
    <el-menu
      :default-active="onRoutes"
      class="el-menu-vertical-demo"
      :collapse="collapse"
      background-color="#26292C"
      active-text-color="#5188F4"
      router
    >
      <div class="cell">
        <img class="head_img" src="../assets/img/menu/head_img.png" alt="" />
      </div>
      <!-- <el-menu-item
        :index="item.index"
        v-for="(item, index) in items"
        :key="index"
      >
        <img
          class="menu_icon"
          :src="onRoutes == item.index ? item.icon_active : item.icon"
          alt=""
        />
        <template #title>{{ item.name }}</template>
      </el-menu-item> -->
      <el-menu-item
        index=""
        v-for="(item, index) in items"
        :key="index"
        :class="{ a_active: onRoutes == item.index }"
      >
        <a :href="base_url + item.index" target="_blank">
          <img
            class="menu_icon"
            :src="onRoutes == item.index ? item.icon_active : item.icon"
            alt=""
          />
        </a>
        <template #title>{{ item.name }}</template>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      collapse: true,
      items: [
        {
          index: "chat",
          icon: require("../assets/img/menu/icon_chat.png"),
          icon_active: require("../assets/img/menu/icon_chat_active.png"),
          name: "聊天",
        },
        {
          index: "circleManage",
          icon: require("../assets/img/menu/icon_wodekehu.png"),
          icon_active: require("../assets/img/menu/icon_wodekehu_active.png"),
          name: "圈子管理",
        },
        {
          index: "statistics",
          icon: require("../assets/img/menu/icon_data.png"),
          icon_active: require("../assets/img/menu/icon_data_active.png"),
          name: "统计数据",
        },
        {
          index: "businessDynamic",
          icon: require("../assets/img/menu/icon_jiaoyidongtai.png"),
          icon_active: require("../assets/img/menu/icon_jiaoyidongtai_active.png"),
          name: "交易动态",
        },
        {
          index: "articleTemplate",
          icon: require("../assets/img/menu/icon_wenzhangmuban.png"),
          icon_active: require("../assets/img/menu/icon_wenzhangmuban_active.png"),
          name: "文章模板",
        },
        {
          index: "marketEditor",
          icon: require("../assets/img/menu/icon_hangqing.png"),
          icon_active: require("../assets/img/menu/icon_hangqing_active.png"),
          name: "行情编辑",
        },
        {
          index: "enterpriseWechat",
          icon: require("../assets/img/menu/icon_qiyeweixin.png"),
          icon_active: require("../assets/img/menu/icon_qiyeweixin_active.png"),
          name: "企业微信",
        },
        {
          index: "pushMessage",
          icon: require("../assets/img/menu/icon_liaotianjilv.png"),
          icon_active: require("../assets/img/menu/icon_liaotianjilv_active.png"),
          name: "推送消息",
        },
      ],
      base_url: window.location.origin + "/#/",
    };
  },
  computed: {
    onRoutes() {
      return this.$route.path.replace("/", "");
    },
  },
  created() {},
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 62px;
  overflow-y: scroll;
}
.cell {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.head_img {
  width: 46px;
  height: 46px;
}
.menu_icon {
  width: 32px;
  height: 32px;
  position: relative;
  left: -5.5px;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
}
.el-menu--collapse {
  width: 100%;
}
.el-menu-item * {
  vertical-align: middle !important;
}
.el-menu-item.is-active {
  background-color: #5188f4;
}
.a_active {
  background-color: #5188f4;
}
</style>
