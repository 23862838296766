<template>
  <div class="emoji_body overflow_y">
    <div v-for="(item, index) in emojiList" :key="index">
      <img
        @click="selectEmoji(item.value)"
        class="icon_emoji"
        :src="item.url"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import emojiList from "../utils/emoji";
export default {
  data() {
    return {
      emojiList,
    };
  },
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  methods: {
    selectEmoji(val) {
      if (this.id == "public") {
        this.$emit("changeInput", val);
      } else if (this.id == "private") {
        this.$emit("changeTextarea", val);
      }
    },
  },
};
</script>

<style scoped>
.emoji_body {
  width: 420px;
  height: 240px;
  position: absolute;
  box-shadow: #cacaca 0px 0px 2px 1px;
  background-color: #fff;
  top: -240px;
  left: 0;
  z-index: 99;
  display: flex;
  flex-wrap: wrap;
}

.overflow_y {
  overflow-y: auto;
}

.overflow_y::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.overflow_y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  background: rgba(0, 0, 0, 0.2);
}

.overflow_y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.emoji_body > div {
  box-sizing: border-box;
  padding: 10px;
}

.icon_emoji {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
</style>
