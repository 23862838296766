<template>
  <div class="bg">
    <!-- 头部 -->
    <div class="head">
      <div class="flex at_c">
        <img class="img1"
             src="@/assets/image/img1.png"
             alt="" />
        <img class="img2"
             src="@/assets/image/img2.png"
             alt="" />
      </div>
      <div class="times">
        <div class="flex fd_c at_c">
          <div class="week">星期{{ day }}</div>
          <div class="date">{{ currentDate }}</div>
        </div>
        <div class="time">{{ currentTime }}</div>
      </div>
    </div>

    <div class="bg2">
      <marquee behavior=""
               direction="">高度重视信息化发展对党的建设的影响，做到网络发展到哪里党的工作就覆盖到哪里</marquee>
    </div>

    <div class="flex at_c mb30">
      <div class="bg3 pr can"
           @click="toPage('/huidangwu')">
        <div class="name yellow">
          <img src="@/assets/image/icon3.png"
               alt="" />
          <div>慧党务</div>
        </div>
      </div>
      <div>
        <div class="flex">
          <div class="bg4 pr can"
               @click="toPage('/huishuju')">
            <div class="name white">
              <img src="@/assets/image/icon4.png"
                   alt="" />
              <div>慧数据</div>
            </div>
          </div>
          <div class="bg5 pr can"
               @click="toPage('/huihudong')">
            <div class="name white">
              <img src="@/assets/image/icon5.png"
                   alt="" />
              <div>慧互动</div>
            </div>
          </div>
        </div>
        <div class="bg6 pr can"
             @click="toPage('/huixuexi')">
          <div class="name yellow">
            <img src="@/assets/image/icon6.png"
                 alt="" />
            <div>慧学习</div>
          </div>
        </div>
      </div>
      <div class="bg7 pr can"
           @click="toPage('/huihuodong')">
        <div class="name yellow">
          <img src="@/assets/image/icon7.png"
               alt="" />
          <div>慧活动</div>
        </div>
      </div>
    </div>

    <div class="bg8">
      <div class="cell1 can"
           @click="toPage('/zhidu?type=1')">
        <img src="@/assets/image/icon8.png"
             alt="" />
        <div>党内法规</div>
      </div>
      <div class="cell1 can"
           @click="open('http://xfh.jchc.cn/sjkg/manager/login.htm')">
        <img src="@/assets/image/icon9.png"
             alt="" />
        <div>先锋荟</div>
      </div>
      <div class="cell1 can"
           @click="toPage('/library?type=1')">
        <img src="@/assets/image/icon10.png"
             alt="" />
        <div>学习视频</div>
      </div>
      <div class="cell1 can"
           @click="toPage('/xingjikaoping')">
        <img src="@/assets/image/icon11.png"
             alt="" />
        <div>星级考评</div>
      </div>
      <div @click="toPage('/Answer')"
           class="cell1 can">
        <img src="@/assets/image/icon12.png"
             alt="" />
        <div>PK答题</div>
      </div>
      <div class="cell1 can">
        <img src="@/assets/image/icon13.png"
             alt="" />
        <div>VR学党史</div>
      </div>
      <div class="cell2">
        <img @click="toPage('/library?type=4')"
             class="icon14 can"
             src="@/assets/image/icon14.png"
             alt="" />
        <img @click="toPage('/TrueToLike')"
             class="icon16 can"
             src="@/assets/image/icon16.png"
             alt="" />
      </div>
      <div class="cell2">
        <img class="icon15 can"
             @click="toPage('/partyMap2')"
             src="@/assets/image/icon15.png"
             alt="" />
        <img class="icon17 can"
             @click="toEchart"
             src="@/assets/image/icon17.png"
             alt="" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentDate: "",
      currentTime: "",
      day: "",
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.showRealTime();
  },
  methods: {
    onmessage() {},
    open(e) {
      window.open(e);
    },
    toPage(e) {
      this.$router.push(e);
    },
    //当前日期时间
    showRealTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      var date = d.getDate();
      var days = new Array("日", "一", "二", "三", "四", "五", "六");
      var day = d.getDay();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "-" + month + "-" + date;
      var cori_time = hour + ":" + min;
      this.currentDate = cori_date;
      this.currentTime = cori_time;
      this.day = days[day];
      setTimeout(() => {
        this.showRealTime();
      }, 1000);
    },
    toEchart() {
      window.location.href =
        "http://fishlion.ryze.club/app/index.php?i=2&c=entry&do=echart&m=vlinke_cparty";
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "方正黑体简体";
  src: url("../assets/font/方正黑体简体.TTF");
}

.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  width: 1678px;
  margin-top: 77px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.img1 {
  width: 72px;
  margin-right: 24px;
  position: relative;
  top: 16px;
}

.img2 {
  width: 836px;
}

.times {
  color: #c30515;
  font-weight: bold;
  display: flex;
  padding-top: 8px;
}

.week {
  font-size: 36px;
}

.date {
  font-size: 25px;
}

.time {
  font-size: 57px;
  margin-left: 64px;
}

.bg2 {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin-bottom: 15px;
  font-family: "方正大标宋简体";
  display: flex;
  align-items: center;
}

.bg3 {
  width: 673px;
  height: 505px;
  background-image: url("../assets/image/bg3.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-right: 17px;
}

.bg4 {
  width: 320px;
  height: 245px;
  background-image: url("../assets/image/bg4.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-right: 13px;
}

.bg5 {
  width: 321px;
  height: 245px;
  background-image: url("../assets/image/bg5.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.bg6 {
  width: 655px;
  height: 248px;
  background-image: url("../assets/image/bg6.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-top: 16px;
}

.bg7 {
  width: 320px;
  height: 503px;
  background-image: url("../assets/image/bg7.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: 14px;
}

.bg8 {
  width: 1678px;
  height: 170px;
  background-image: url("../assets/image/bg8.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
}

.name {
  position: absolute;
  left: 39px;
  bottom: 39px;
  display: flex;
  flex-direction: column;
  font-size: 46px;
  font-family: "方正大标宋简体";
}

.name img {
  width: 70px;
  height: 70px;
  margin-bottom: 15px;
}

.yellow {
  color: #ffcd00;
}

.white {
  color: #fff;
}

.cell1 {
  font-size: 32px;
  line-height: 32px;
  color: #dd121a;
  /* width: 11.2%; */
  width: 188px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cell1 img {
  width: 70px;
  height: 70px;
  margin-bottom: 23px;
}

.icon14 {
  width: 227px;
  height: 81px;
}

.icon15 {
  width: 182px;
  height: 87px;
}

.icon16 {
  width: 201px;
  height: 66px;
}

.icon17 {
  width: 180px;
  height: 90px;
}

.cell2 {
  width: 276px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
