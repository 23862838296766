import request from "../utils/request";
//获取基础信息
export const baseInfo = (query) => {
  return request({
    url: "/teacher/policy_notice/getBaseInfo",
    method: "POST",
    data: query,
  });
};
//获取行情
export const viewpointList = (query) => {
  return request({
    url: "/teacher/view_point_teacher/viewpointList",
    method: "POST",
    data: query,
  });
};
//删除行情
export const delViewpoint = (query) => {
  return request({
    url: "/teacher/view_point_teacher/delViewpoint",
    method: "POST",
    data: query,
  });
};
//增加行情
export const addViewpoint = (query) => {
  return request({
    url: "/teacher/view_point_teacher/addViewpoint",
    method: "POST",
    data: query,
  });
};
//获取交易所列表
export const getExchangeArr = (query) => {
  return request({
    url: "/teacher/view_point_teacher/getBaseInfo",
    method: "POST",
    data: query,
  });
};
