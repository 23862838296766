<template>
  <div class="bb p30">
    <div class="mb20">
      <el-button type="primary" @click="modifyTopicFormShow = true"
        >添加话题</el-button
      >
    </div>
    <!-- 增加修改话题 -->
    <el-dialog
      width="420px"
      v-model="modifyTopicFormShow"
      :title="modifyTopicForm.id ? '修改话题' : '添加话题'"
    >
      <el-form
        :model="modifyTopicForm"
        ref="modifyTopicForm"
        :rules="modifyTopicRules"
      >
        <el-form-item
          class="w222"
          size="large"
          label="话题"
          :label-width="formLabelWidth"
          prop="nickname"
        >
          <el-input v-model="modifyTopicForm.name" autocomplete="off" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="modifyTopicFormShow = false">取消</el-button>
          <el-button type="primary" @click="submitForm('modifyTopicForm')"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>
    <div v-loading="topicArrLoading">
      <div class="mb20">
        <el-table :data="topicArr" height="60vh" style="width: 100%">
          <el-table-column label="ID" width="120" prop="id"></el-table-column>
          <el-table-column
            show-overflow-tooltip
            label="话题"
            width="220"
            prop="name"
          >
          </el-table-column>
          <el-table-column show-overflow-tooltip label="是否置顶" width="110">
            <template #default="scope">
              <el-tooltip
                :content="'置顶:' + (scope.row.is_top == 1 ? '是' : '否')"
                placement="top"
              >
                <el-switch
                  @click="changeIsTop(scope.$index, scope.row)"
                  :value="scope.row.is_top"
                  :disabled="scope.row.isTopDisabled"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                >
                </el-switch>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300" align="center">
            <template #default="scope">
              <el-button
                type="primary"
                size="large"
                @click="handleEditTopic(scope.$index, scope.row)"
                >编辑</el-button
              >
              <el-button
                type="danger"
                size="large"
                @click="handleDeleteTopic(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination
          @current-change="handleCurrentChangeTopic"
          :current-page="pageTopic"
          :page-size="15"
          layout="total, prev, pager, next, jumper"
          :total="totalTopic"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/circleManage";
export default {
  data() {
    return {
      pageTopic: 1, // 话题页码
      totalTopic: 0, // 话题总数
      topicArr: [], // 话题列表数组
      topicArrLoading: false, // 话题列表加载状态
      modifyTopicFormShow: false, // 话题表单展示状态
      modifyTopicForm: {
        id: null,
        name: "",
      }, // 话题表单
      modifyTopicRules: {
        name: [{ required: true, message: "请输入话题", trigger: "blur" }],
      }, // 话题表单验证规则
    };
  },
  methods: {
    // 获取我的话题列表
    async getTopicList(page) {
      this.topicArrLoading = true;
      const res = await Service.topicList({
        page,
        is_paginate: 1,
      });
      var topicArr = res.data.data;
      for (const item of topicArr) {
        item.isTopDisabled = false;
      }
      this.topicArr = topicArr;
      this.totalTopic = res.data.total;
      this.pageTopic = page;
      this.topicArrLoading = false;
    },
    // 增加修改话题
    async modifyTopic() {
      var res = null,
        modifyTopicForm = this.modifyTopicForm;
      if (modifyTopicForm.id) {
        res = await Service.editTopic(modifyTopicForm);
      } else {
        res = await Service.addTopic(modifyTopicForm);
      }
      this.$message.success(res.msg);
      this.modifyTopicFormShow = false;
      this.getTopicList(this.page);
      this.modifyTopicForm = {
        id: null,
        name: "",
      };
      this.$refs["modifyTopicForm"].resetFields();
    },
    // 编辑话题
    handleEditTopic(index, row) {
      this.modifyTopicForm.id = row.id;
      this.modifyTopicForm.name = row.name;
      this.modifyTopicFormShow = true;
    },
    // 删除话题
    handleDeleteTopic(index, row) {
      this.$confirm("确认删除该话题吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.handleDeleteTopicApi(index, row);
      });
      // console.log(index, row);
    },
    async handleDeleteTopicApi(index, row) {
      const res = await Service.deleteTopic({
        id: row.id,
      });
      this.$message.success(res.msg);
      this.getTopicList(this.page);
    },
    handleCurrentChangeTopic(val) {
      this.getTopicList(val);
    },
    // 修改置顶
    changeIsTop(index, row) {
      if (!row.isTopDisabled) {
        this.changeIsTopApi(index, row);
      }
      this.topicArr[index].isTopDisabled = true;
      setTimeout(() => {
        this.topicArr[index].isTopDisabled = false;
      }, 800);
    },
    async changeIsTopApi(index, row) {
      const res = await Service.topTopic({
        id: row.id,
      });
      this.getTopicList(this.page);
      this.$message.success(res.msg);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "addUserForm":
              this.addUser();
              break;
            case "editForm":
              this.profile();
              break;
            case "circleInfoForm":
              this.changeCircleNotice();
              break;
            case "modifyTopicForm":
              this.modifyTopic();
              break;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}
</style>
