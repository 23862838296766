import request from "../utils/request";
//研究员socket绑定用户
export const bindUser = (query) => {
  return request({
    url: "/teacher/chat/bindUser",
    method: "POST",
    data: query,
  });
};
//获取在线会员
export const getOnLineCustomerList = (query) => {
  return request({
    url: "/teacher/customer/getOnLineCustomerList",
    method: "POST",
    data: query,
  });
};
//获取我的会员
export const getCustomerList = (query) => {
  return request({
    url: "/teacher/customer/getCustomerList",
    method: "POST",
    data: query,
  });
};
//获取聊天记录
export const getChatList = (query) => {
  return request({
    url: "/teacher/chat/getChatList",
    method: "POST",
    data: query,
  });
};
//获取会员游客信息
export const getUserInfo = (query) => {
  return request({
    url: "/teacher/customer/getUserInfo",
    method: "POST",
    data: query,
  });
};
//研究员中心
export const getTeacherInfo = (query) => {
  return request({
    url: "/teacher/user/index",
    method: "POST",
    data: query,
  });
};
//多人群发
export const sendMassMessage = (query) => {
  return request({
    url: "/teacher/chat/sendMassMessage",
    method: "POST",
    data: query,
  });
};
//获取弹窗统计信息
export const getUserData = (query) => {
  return request({
    url: "/teacher/statistics/getUserData",
    method: "POST",
    data: query,
  });
};
// 获取圈子人员列表
export const getCircleUserList = (query) => {
  return request({
    url: "/api/chat/getCircleUserList",
    method: "POST",
    data: query,
  });
};
// 获取圈子配置
export const getCircleConfig = (query) => {
  return request({
    url: "/teacher/chat/getCircleConfig",
    method: "POST",
    data: query,
  });
};
// 修改圈子发言状态
export const changeCircleChatStatus = (query) => {
  return request({
    url: "/teacher/chat/changeCircleChatStatus",
    method: "POST",
    data: query,
  });
};
// 修改圈子会员发言状态
export const changeCircleUserChatStatus = (query) => {
  return request({
    url: "/teacher/chat/changeCircleUserChatStatus",
    method: "POST",
    data: query,
  });
};
// 删除圈子消息
export const delCircleMessage = (query) => {
  return request({
    url: "/teacher/chat/delCircleMessage",
    method: "POST",
    data: query,
  });
};
// 获取圈子列表
export const getCircleList = (query) => {
  return request({
    url: "/teacher/chat/getCircleList",
    method: "POST",
    data: query,
  });
};
