import store from "../store/index";
import * as Service from "../api/chat";
function initWebpack () {
  // var wsurl = 'wss://api.i-vce.com/wss'
  var wsurl = "ws:/106.55.153.181:2000";
  // var wsurl = localStorage.getItem("socketUrl");
  store.state.ws = new WebSocket(wsurl);
  store.state.ws.onopen = onopen;
  store.state.ws.onmessage = onmessage;
  store.state.ws.onclose = onclose;
  store.state.ws.onerror = onerror;
}
function onopen () {
  console.log("连接websocket");
  // store.state.ws.send(
  //   JSON.stringify({
  //     type: "join_room_send",
  //     room_id: 1,
  //     config: {
  //       platform: "pushWeb",
  //     },
  //   })
  // );
  start();
}
function reconnect () {
  //重新连接
  var that = store.state;
  console.log("-------reconnect", that.lockReconnect);
  if (that.lockReconnect) {
    return;
  }
  that.lockReconnect = true;
  //没连接上会一直重连，设置延迟避免请求过多
  that.timeoutnum && clearTimeout(that.timeoutnum);
  that.timeoutnum = setTimeout(function () {
    //新连接
    initWebpack();
    that.lockReconnect = false;
    that.isFirstGet = true;
  }, 3000);
}
function reset () {
  //重置心跳
  // console.log('重置心跳')
  var that = store.state;
  //清除时间
  clearTimeout(that.timeoutObj);
  clearTimeout(that.serverTimeoutObj);
  //重启心跳
  start();
}
function start () {
  //开启心跳
  // console.log('开启心跳');
  var self = store.state;
  self.timeoutObj && clearTimeout(self.timeoutObj);
  self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
  self.timeoutObj = setTimeout(function () {
    // console.log("-------", self.ws.readyState);
    //这里发送一个心跳，后端收到后，返回一个心跳消息，
    if (self.ws.readyState === 1) {
      //如果连接正常
      self.ws.send(
        JSON.stringify({
          action: "heart",
        })
      ); //心跳包格式需要自己确定
    } else {
      //否则重连
      reconnect();
    }
    self.serverTimeoutObj = setTimeout(function () {
      console.error("重连准备");
      //超时关闭
      self.ws.close();
      reconnect();
    }, self.timeout);
  }, self.timeout);
}
function onmessage (e) {
  window.onmessageSocket(e);
  // console.log("接收数据", e);
  // const redata = JSON.parse(e.data);
  // if (redata.type == "get_client_id") {
  //   //---------绑定客服
  //   const res = Service.bindUser({
  //     client_id: redata.data.client_id,
  //   });
  //   // console.log("axios", res);
  // }
  // //处理数据的地方
  reset();
}
function onclose (e) {
  console.log("websocket 断开: ", e);
}
function onerror (e) {
  console.log("出现错误");
  //重连
  reconnect();
}

export default {
  initWebpack,
  onmessage,
  onclose,
  onopen,
  onerror,
};
