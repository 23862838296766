<template>
  <div class="bg">
    <img @click="toPage('/index')"
         class="logo"
         src="@/assets/image/logo.png"
         alt="" />
    <div class="content">
      <div class="pr flex jc_c at_c tac">
        {{ info.title }}
        <div class="zhibu">{{ info.branchName }}</div>
        <div class="time">{{ currentDate }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
export default {
  data() {
    return {
      currentDate: "",
      info: "",
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getInfo();
    this.showRealTime();
  },
  methods: {
    onmessage() {},
    async getInfo() {
      const res = await Service.getApi({
        do: "evaluate",
        op: "there_lesson",
        data: {},
      });
      console.log("res", res);
      this.info = res.data;
    },
    toPage(e) {
      this.$router.push(e);
    },
    openUrl(e) {
      window.open(e);
    },
    //当前日期时间
    showRealTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      var date = d.getDate();
      var days = new Array("日", "一", "二", "三", "四", "五", "六");
      var day = d.getDay();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "年" + month + "月" + date + "日";
      // var cori_time = hour + ":" + min;
      this.currentDate = cori_date;
      // this.currentTime = cori_time;
      // this.day = days[day];
      setTimeout(() => {
        this.showRealTime();
      }, 1000);
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.logo {
  width: 111px;
  height: 111px;
  position: fixed;
  left: 40px;
  top: 30px;
  cursor: pointer;
  z-index: 1;
}

.content {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  font-size: 100px;
  color: #cd0d0d;
}

.zhibu {
  position: absolute;
  font-size: 48px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #cd0d0d;
  top: 222px;
}

.time {
  position: absolute;
  font-size: 48px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #cd0d0d;
  top: 300px;
}
</style>
