<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huixuexi')"
           class="can">慧学习--></div>
      <div>{{ pageName }}</div>
    </div>

    <div class="page_body flex at_c jc_c">
      <template v-if="type == 1 || type == 5">
        <div class="bookshelf_body">
          <div @click="showVideo(item.url)"
               v-for="(item, index) in list"
               :key="index"
               class="book_cell1">
            <div class="info1 at_c jc_c">
              <img class="cover_img1"
                   :src="item.image"
                   alt="" />
              <img class="icon_play"
                   src="@/assets/image/icon_play.png"
                   alt="" />
              <!-- <div>
                <img class="qrcode1"
                     :src="item.qrcode"
                     alt="" />
              </div> -->
            </div>
            <div class="w100 tac"
                 style="margin-top: 80px">
              {{ item.title }}
            </div>
          </div>
        </div>
      </template>
      <template v-if="type == 4">
        <div class="bookshelf_body">
          <div v-for="(item, index) in list"
               :key="index"
               class="book_cell">
            <div class="info">
              <img class="cover_img"
                   :src="item.image"
                   alt="" />
              <div>
                <div class="name hang1">{{ item.title }}</div>
                <div class="flex jc_e">
                  <img class="book_qrcode"
                       :src="item.qrcode"
                       alt="" />
                </div>
              </div>
            </div>
            <div class="w100 tac"
                 style="margin-top: 110px">
              {{ item.title }}
            </div>
            <div class="hang1 w100"
                 style="margin-top: 20px; -webkit-line-clamp: 3; color: #f10000">
              {{ item.desc }}
              <!-- 这是简介这是简介这是简介这是简介这是简介这是简介这是简介这是简介这是简介这是简介这是简介这是简介这是简介这是简介 -->
            </div>
          </div>
        </div>
        <img class="biaoyu"
             src="@/assets/image/biaoyu.png"
             alt="" />
      </template>
    </div>

    <div class="flex jc_c w100 mt30">
      <el-pagination v-model:currentPage="currentPage"
                     v-model:page-size="pageSize"
                     :background="background"
                     layout="total, prev, pager, next, jumper"
                     :total="total"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange" />
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>

    <div @click="videoShow = false"
         v-if="videoShow"
         class="zhezhaoceng3 flex at_c jc_c">
      <video controls
             @click.stop=""
             :src="videoSrc"></video>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 8,
      background: "#c30515",
      total: 0,
      list: [],
      isShipin: true,
      pageName: "",
      type: null, //1 学习视频 2学习材料 3 有声读物 4 红色数据 5经典红色电影
      videoShow: false,
      videoSrc: "",
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.type = this.$route.query.type;
    if (this.type == 1) {
      this.pageName = "学习视频";
    } else if (this.type == 4) {
      this.pageName = "电子红书馆";
    } else if (this.type == 5) {
      this.pageName = "红色经典电影";
    }
    this.getList(1);
  },
  methods: {
    onmessage() {},
    toPage(e) {
      this.$router.push(e);
    },
    async getList(page) {
      const res = await Service.getApi({
        do: "study",
        op: "educhapter",
        data: {
          count: this.pageSize,
          cateid: this.type,
          page: this.currentPage,
        },
      });
      this.list = res.data;
      this.total = res.count;
    },
    handleSizeChange(e) {
      this.getList(e);
    },
    handleCurrentChange(e) {
      this.getList(e);
    },
    showVideo(url) {
      this.videoSrc = url;
      this.videoShow = true;
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

.page_body {
  width: 1678px;
  height: 728px;
  margin-top: 20px;
}

.page_bottom {
  margin-top: 27px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}

.iframe {
  width: 975px;
  height: 650px;
}

.lists {
  width: 350px;
  height: 650px;
  margin-left: 30px;
  background-color: #fce085;
  box-sizing: border-box;
  padding: 30px;
}

.active {
  color: red;
}

.shujia {
  width: 1300px;
}

.shujia2 {
  width: 1300px;
  margin-top: 50px;
}

.biaoyu {
  width: 149px;
}

.bookshelf_body {
  width: 1300px;
  height: 540px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.book_cell {
  width: 220px;
  height: 140px;
  background-image: url("../assets/image/shujia_cell.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 40px;
  position: relative;
}

.book_cell1 {
  width: 220px;
  height: 140px;
  background-image: url("../assets/image/shujia2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-bottom: 40px;
  position: relative;
  margin-top: 50px;
}

.book_cell:not(:nth-child(4n)) {
  margin-right: 140px;
}

.book_cell1:not(:nth-child(4n)) {
  margin-right: 120px;
}

.info {
  position: absolute;
  top: 5px;
  left: 62px;
  display: flex;
}

.info1 {
  position: absolute;
  top: -78px;
  left: 0;
  display: flex;
}

.icon_play {
  width: 70px;
  height: 70px;
  position: absolute;
}

.cover_img {
  /* width: calc(600px 0.2);
  height: calc(850px * 0.2); */
  width: 50px;
  height: 70px;
  object-fit: cover;
  margin-right: 5px;
}

.cover_img1 {
  width: 220px;
  height: 140px;
  object-fit: cover;
}

.name {
  max-width: 100px;
  word-break: break-all;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #c30515;
}

.qrcode {
  width: 44px;
  height: 44px;
  object-fit: cover;
  margin-top: 3px;
}
.book_qrcode {
  width: 120px;
  height: 120px;
  object-fit: cover;
  margin-top: -70px;
  margin-left: 0;
}
.qrcode1 {
  width: 120px;
  height: 120px;
  object-fit: cover;
  position: absolute;
  bottom: 0;
  right: 0;
}

video {
  width: 80%;
}
</style>
