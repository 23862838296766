<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huihudong')"
           class="can">慧互动--></div>
      <div>入党宣誓</div>
    </div>

    <div class="page_body overflow_y">
      <div class="flex at_c">
        <img class="dangqi"
             src="@/assets/image/dangqi.png"
             alt="" />
        <div class="flex fd_c at_c">
          <div class="title">入党誓词</div>
          <div class="shici"
               v-html="content"></div>
        </div>
      </div>
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
export default {
  data() {
    return {
      content: "",
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getInfo();
  },
  methods: {
    onmessage() {},
    toPage(e) {
      this.$router.push(e);
    },
    async getInfo() {
      const res = await Service.getApi({
        do: "evaluate",
        op: "join_part",
        data: {},
      });
      console.log("res", res);
      this.content = res.data.content;
    },
  },
};
</script>

<style scoped>
@font-face {
  font-family: "方正大标宋简体";
  src: url("../assets/font/方正大标宋简体.TTF");
}

@font-face {
  font-family: "方正兰亭特黑简体";
  src: url("../assets/font/方正兰亭特黑简体.TTF");
}

.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

.page_body {
  width: 1678px;
  height: 728px;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.page_bottom {
  margin-top: 27px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}

.shici span {
  text-indent: 80px;
  color: #6e6e44;
  font-size: 40px !important;
  line-height: 40px;
  font-family: "方正大标宋简体";
  /* font-family: "方正兰亭特黑简体"; */
}

.title {
  font-size: 90px;
  color: #e02810;
  font-weight: bolder;
  margin-bottom: 30px;
}

.dangqi {
  width: 767px;
  margin-right: 87px;
}
</style>
