<template>
  <div class="login">
    <div class="login_form">
      <img class="logo" src="../assets/img/logo.png" alt="" />
      <div class="name">研究员管理系统</div>
      <div class="form">
        <div class="form_cell">
          <div>账号</div>
          <div>
            <input
              class="input"
              type="text"
              v-model="form.account"
              placeholder="请输入账号"
            />
          </div>
        </div>
        <div class="form_cell">
          <div>密码</div>
          <div>
            <input
              class="input"
              type="password"
              v-model="form.password"
              placeholder="请输入密码"
              @keyup.enter="login"
            />
          </div>
        </div>
        <div class="form_cell">
          <el-button
            :disabled="disabled"
            class="w100"
            @click="login"
            type="primary"
            >登录</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/common";
import socket from "../utils/socket";
import { useStore } from "vuex";
export default {
  data() {
    return {
      form: {
        account: "",
        password: "",
      },
      disabled: false,
    };
  },
  mounted() {
    const store = useStore();
    console.log("store", store.state.ws);
    if (store.state.ws) {
      store.state.timeoutObj && clearTimeout(store.state.timeoutObj);
      store.state.ws.close();
      console.log("login关闭socket");
    }
  },
  methods: {
    async login() {
      if (this.disabled) {
        return false;
      }
      var form = this.form;
      if (!form.account) {
        this.$message.error("账号不可为空");
        return false;
      }
      if (!form.password) {
        this.$message.error("密码不可为空");
        return false;
      }
      this.disabled = true;
      setTimeout(() => {
        this.disabled = false;
      }, 800);
      const res = await Service.login(this.form);
      const { code, data, msg } = res;
      if (code == 1) {
        this.disabled = true;
        this.$message.success(msg);
        localStorage.setItem("vce-token-push", data.userInfo.token);
        setTimeout(() => {
          socket.initWebpack();
          console.log("登录后重新连接");
          this.$router.push({ path: "/chat" });
          this.disabled = false;
        }, 800);
      }
      console.log("res", res);
    },
  },
};
</script>

<style scoped>
.login {
  width: 100%;
  height: 100vh;
  background-image: url("../assets/img/login_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_form {
  background-color: #fff;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 40px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logo {
  width: 142px;
  height: 142px;
  position: absolute;
  top: -70px;
}

.name {
  font-size: 24px;
  font-weight: 500;
  color: #434343;
  line-height: 33px;
  margin: 34px 0 20px;
}

.form {
  width: 306px;
}

.form_cell {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #111111;
  line-height: 20px;
}

.form_cell:not(:last-child) {
  box-sizing: border-box;
  padding: 24px 0 16px;
  border-bottom: 1px solid #efefef;
}

.form_cell:last-child {
  margin-top: 48px;
}

.form_cell > div:first-child {
  margin-right: 16px;
}

.form_cell > div:last-child {
  width: calc(100% - 50px);
}

.input {
  width: 100%;
  border: 0;
  background: transparent !important;
  outline: none;
}

input:-webkit-autofill {
  background-color: transparent !important;
  /* -webkit-text-fill-color: #acfff2 !important; 浏览器记住密码的字的颜色 */
  transition: background-color 5000s ease-in-out 0s; /*通过延时渲染背景色变相去除背景颜色*/
  /* caret-color: #acfff2; 光标颜色 */
}

.input:focus {
  border: 0;
  background-color: transparent;
}

/* ::selection {
  background: transparent;
} */

.mt48 {
  margin-top: 48px;
}
</style>
