import request from "../utils/request";

// export const getApi = (query) => {
//   return request({
//     url: "/app/index.php",
//     method: "POST",
//     data: {
//       i: 2,
//       c: "entry",
//       m: "vlinke_cparty",
//       do: query.do,
//       op: query.op,
//     },
//   });
// };

export const getApi = (query) => {
  query.data.i = 2;
  query.data.c = "entry";
  query.data.m = "vlinke_cparty";
  query.data.do = query.do;
  query.data.op = query.op;
  var wxapp = "";
  if (query.wxapp) {
    wxapp = "&v=3&a=wxapp&type=pc";
  }
  return request({
    url:
      "https://fishlion.ryze.club/app/index.php?i=" +
      query.data.i +
      "&c=" +
      query.data.c +
      "&m=" +
      query.data.m +
      "&do=" +
      query.do +
      "&op=" +
      query.op +
      wxapp,
    method: "POST",

    data: query.data,
  });
};
