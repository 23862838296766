<template>
  <div>
    <pageTitle :title="title"></pageTitle>
    <div class="bb p30">
      <el-input
        size="large"
        v-model="content"
        placeholder="请输入文案"
        class="input-with-select"
      >
        <template #prepend>
          <el-select
            v-model="title1"
            placeholder="请选择类别"
            style="width: 115px"
          >
            <el-option label="开盘提醒" value="开盘提醒" />
            <el-option label="夜盘提醒" value="夜盘提醒" />
            <el-option label="课程提醒" value="课程提醒" />
          </el-select>
        </template>
      </el-input>
      <div class="mt20">
        <el-button type="primary" @click="send">推送</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import pageTitle from "../components/pageTitle.vue";
import * as Service from "../api/pushMessage";
export default {
  components: {
    pageTitle,
  },
  data() {
    return {
      title: "推送消息",
      content: "",
      title1: "",
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
  },
  methods: {
    onmessage() {},
    async send() {
      if (!this.title1) {
        this.$message.error("请选择类别");
        return false;
      }
      if (!this.content) {
        this.$message.error("请填写内容");
        return false;
      }
      const res = await Service.jPush({
        title: this.title1,
        content: this.content,
      });
      this.$message.success(res.msg);
    },
  },
};
</script>

<style scoped></style>
