<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huishuju')"
           class="can">慧数据--></div>
      <div>党员评价</div>
    </div>

    <div class="page_body">
      <div class="flex at_c mb30">
        <div>选择评价月份：</div>
        <div>
          <el-select @change="changeTimeId"
                     v-model="time_id"
                     class="m-2"
                     placeholder="请选择评价月份"
                     size="large">
            <el-option v-for="item in monthList"
                       :key="item.id"
                       :label="item.title"
                       :value="item.id" />
          </el-select>
        </div>
      </div>
      <div style="width: 1678px">
        <el-table height="600"
                  border
                  :data="tableData"
                  style="width: 100%">
          <el-table-column prop="userid"
                           label="党员id"
                           width="80"
                           :align="align" />
          <el-table-column label="党员信息"
                           width="200"
                           :align="align">
            <template #default="scope">
              <div class="flex at_c">
                <img class="avatar"
                     :src="scope.row.headpic"
                     alt="" />
                <div>{{ scope.row.realname }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="branchName"
                           label="支部名称"
                           width="200"
                           :align="align" />
          <!-- <el-table-column prop="title"
                           label="评论月份标题"
                           width="200" /> -->
          <el-table-column prop="answer"
                           label="答题得分"
                           width="140"
                           :align="align" />
          <el-table-column prop="selection"
                           label="民主评选"
                           width="140"
                           :align="align" />
          <el-table-column prop="month"
                           label="月度考核得分"
                           width="140"
                           :align="align" />
          <el-table-column prop="addtime"
                           label="添加时间"
                           width="200"
                           :align="align" />
          <el-table-column label="评价标准"
                           width=""
                           :align="align">
            <template #default="scope">
              <div v-html="scope.row.content"></div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="flex jc_c w100 mt30">
        <el-pagination v-model:currentPage="currentPage"
                       v-model:page-size="pageSize"
                       :background="background"
                       layout="total, prev, pager, next, jumper"
                       :total="total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange" />
      </div>
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
export default {
  data() {
    return {
      type: null, // 1党内法规2控股制度3公司制度4星级考评
      pageName: "",
      title: "",
      currentPage: 1,
      pageSize: 10,
      background: "#c30515",
      total: 0,
      list: [],
      info: {},
      tableData: [],
      monthList: [],
      time_id: "",
      align: "center",
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getList(1);
    this.getMonthList();
  },
  methods: {
    onmessage() {},
    changeTimeId(e) {
      this.tableData = [];
      this.total = 0;
      this.getList(1);
    },
    async getList(page) {
      const res = await Service.getApi({
        do: "evaluate",
        op: "commentList",
        data: {
          page,
          time_id: this.time_id,
          count: this.pageSize,
        },
      });
      console.log("res", res);
      this.tableData = res.data;
      this.total = res.count;
    },
    async getMonthList() {
      const res = await Service.getApi({
        do: "evaluate",
        op: "timeList",
        data: {
          page: 1,
          count: 100,
        },
      });
      console.log("评价月份列表", res);
      this.monthList = res.data;
      this.time_id = res.data[0].id;
    },
    viewDetail(id) {
      this.$router.push({
        path: "/liuyanbanDetail",
        query: { type: this.type, id },
      });
    },
    toPage(e) {
      this.$router.push(e);
    },
    handleSizeChange(e) {
      this.getList(e);
    },
    handleCurrentChange(e) {
      this.getList(e);
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

.page_body {
  width: 1678px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.lists {
  margin-top: 30px;
  width: 1518px;
  height: 600px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.list {
  width: 270px;
  height: 280px;
  font-size: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
}

.list:not(:nth-child(5n)) {
  margin-right: 40px;
}

.cover {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.info {
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  padding: 10px;
}

.info .title {
  height: 52px;
  margin-bottom: 5px;
}

.info .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  object-fit: cover;
  margin-right: 10px;
}

.info .name {
  max-width: 800px;
  font-size: 14px;
  max-width: 165px;
}

.time {
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #c30515;
}

.page_bottom {
  margin-top: 47px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

/* /deep/.el-table {
  background-color: #c30515;
}

/deep/.el-table tr {
  background-color: #02134b;
  color: #95b3e8;
  font-size: 16px;
}

/deep/.el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid #01386a;
}

/deep/.el-table--border .el-table__cell,
.el-table__body-wrapper .el-table--border.is-scrolling-left ~ .el-table__fixed {
  border-right: 1px solid #01386a;
}

/deep/.el-table--border,
.el-table--group {
  border: 1px solid #01386a !important;
}

/deep/.el-table th {
  background-color: #0f318d !important;
}

/deep/.el-table--border::after,
.el-table--group::after {
  width: 0;
}

/deep/.el-table--border::after,
.el-table--group::after,
.el-table::before {
  content: none;
}

/deep/.el-table--small .el-table__cell {
  padding: 16px 0;
} */
</style>
