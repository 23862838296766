div<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huihudong')"
           class="can">慧互动--></div>
      <div>互动答题</div>
    </div>

    <template v-if="type">
      <div class="page_body"
           v-if="isStart">
        <div class="flex at_c mb30">
          <div @click="currentUserId = item.userid"
               class="user_group_cell"
               :class="{ user_group_cell_active: currentUserId == item.userid }"
               v-for="(item, index) in userGroup"
               :key="index">
            <img :src="item.headpic"
                 alt="" />
            <div class="name">{{ item.realname }}</div>
            <div class="score">{{ item.score }}</div>
          </div>
          <div v-if="type != 1 && !isEnd"
               @click="handleAnswer"
               class="dati_btn">
            确定答题
          </div>
        </div>
        <!-- 作答表格 -->
        <div class="sheet"
             v-if="type != 2">
          <div class="sheet_cell"
               v-for="(item, index) in topicList"
               :key="index"
               :class="{
              red: (isEnd || type == 3) && item.answer != item.myanswer,
              green: (isEnd || type == 3) && item.answer == item.myanswer,
            }">
            <div class="fb fz20">{{ index + 1 }}</div>
            <div>{{ item.myanswer }}</div>
          </div>
        </div>
        <div class="mt30 fz20"
             v-if="!isEnd">
          <div>当前题目：</div>
          <div class="mt10"
               style="color: #8f0b0b">
            {{ now }}、{{ topicList[now - 1].title }}
          </div>
          <div>
            <div class="mt10 can pr"
                 @click="chooseAnswer('A')">
              A、{{ topicList[now - 1].itema }}
              <img v-if="currentTopicAnswer.indexOf('A') > -1"
                   class="icon_dui"
                   src="@/assets/image/icon_dui.png"
                   alt="" />
            </div>
            <div class="mt10 can pr"
                 @click="chooseAnswer('B')">
              B、{{ topicList[now - 1].itemb }}
              <img v-if="currentTopicAnswer.indexOf('B') > -1"
                   class="icon_dui"
                   src="@/assets/image/icon_dui.png"
                   alt="" />
            </div>
            <div class="mt10 can pr"
                 @click="chooseAnswer('C')">
              C、{{ topicList[now - 1].itemc }}
              <img v-if="currentTopicAnswer.indexOf('C') > -1"
                   class="icon_dui"
                   src="@/assets/image/icon_dui.png"
                   alt="" />
            </div>
            <div class="mt10 can pr"
                 @click="chooseAnswer('D')">
              D、{{ topicList[now - 1].itemd }}
              <img v-if="currentTopicAnswer.indexOf('D') > -1"
                   class="icon_dui"
                   src="@/assets/image/icon_dui.png"
                   alt="" />
            </div>
            <div class="mt10 can pr"
                 @click="chooseAnswer('E')">
              E、{{ topicList[now - 1].iteme }}
              <img v-if="currentTopicAnswer.indexOf('E') > -1"
                   class="icon_dui"
                   src="@/assets/image/icon_dui.png"
                   alt="" />
            </div>
            <div class="mt10 can pr"
                 @click="chooseAnswer('F')">
              F、{{ topicList[now - 1].itemf }}
              <img v-if="currentTopicAnswer.indexOf('F') > -1"
                   class="icon_dui"
                   src="@/assets/image/icon_dui.png"
                   alt="" />
            </div>
          </div>
        </div>
        <div class="mt30"
             v-if="isEnd">答题结束</div>
      </div>

      <div class="h600 w100"
           style="justify-content: flex-start"
           v-if="!isStart">
        <div class="flex at_c mr100">
          <img v-if="type == 1"
               class="answer_entry_img"
               src="http://fishlion.ryze.club/attachment/qrcodev/1661126113.jpg"
               alt="" />
          <img v-if="type == 2"
               class="answer_entry_img"
               src="http://fishlion.ryze.club/attachment/qrcodev/1661127656.jpg"
               alt="" />
          <div v-if="!userGroup.length">
            <h1>等待答题...</h1>
          </div>

          <!-- <div v-if="!userInfo.userid">
            <h1>等待答题...</h1>
          </div> -->
        </div>
        <div class="flex at_c"
             v-if="userGroup.length">
          <div class="user_info"
               v-for="(item, index) in userGroup"
               :key="index">
            <img class="avatar"
                 :src="item.headpic"
                 alt="" />
            <div class="mt20 fz24">{{ item.realname }}</div>
            <div class="mt20">{{ item.branchName }}</div>
            <img class="daikaishi"
                 src="@/assets/image/daikaishi.png"
                 alt="" />
          </div>
        </div>
        <div v-if="type != 1"
             @click="beginAnswer"
             class="dati_btn">
          开始答题
        </div>
      </div>
    </template>

    <template v-if="!type">
      <div class="choose_body">
        <div class="title">请选择答题模式</div>
        <div class="flex fd_c at_c">
          <div class="btn1 mb30"
               @click="selectMode(1)">单人答题</div>
          <div class="btn2 mb30"
               @click="selectMode(2)">多人答题</div>
          <div class="btn2"
               @click="selectMode(3)">本地答题</div>
        </div>
      </div>
    </template>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
import "@/utils/taizhou.js";
export default {
  data() {
    return {
      topicList: [],
      // topicList: [
      //   {
      //     title: "这是一道测试测试测试测试测试测试测试题1",
      //     itema: "A选项",
      //     itemb: "B选项",
      //     itemc: "C选项",
      //     itemd: "D选项",
      //     iteme: "E选项",
      //     itemf: "F选项",
      //     answer: "A",
      //     myanswer: "",
      //     score: 10,
      //   },
      //   {
      //     title: "这是一道测试测试测试测试测试测试测试题2",
      //     itema: "A选项",
      //     itemb: "B选项",
      //     itemc: "C选项",
      //     itemd: "D选项",
      //     iteme: "E选项",
      //     itemf: "F选项",
      //     answer: "B",
      //     myanswer: "",
      //     score: 20,
      //   },
      //   {
      //     title: "这是一道测试测试测试测试测试测试测试题3",
      //     itema: "A选项",
      //     itemb: "B选项",
      //     itemc: "C选项",
      //     itemd: "D选项",
      //     iteme: "E选项",
      //     itemf: "F选项",
      //     answer: "C",
      //     myanswer: "",
      //     score: 30,
      //   },
      // ], // 题目
      // userGroup: [
      //   {
      //     branchName: "AAA党总支",
      //     headpic:
      //       "https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132",
      //     realname: "测试答题用户1",
      //     userid: "1",
      //     score: 0,
      //   },
      //   {
      //     branchName: "AAA党总支",
      //     headpic:
      //       "https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132",
      //     realname: "测试答题用户2",
      //     userid: "2",
      //     score: 0,
      //   },
      //   {
      //     branchName: "AAA党总支",
      //     headpic:
      //       "https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132",
      //     realname: "测试答题用户3",
      //     userid: "3",
      //     score: 0,
      //   },
      //   {
      //     branchName: "AAA党总支",
      //     headpic:
      //       "https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132",
      //     realname: "测试答题用户4",
      //     userid: "4",
      //     score: 0,
      //   },
      // ], // 多人答题的用户列表
      userGroup: [],
      currentUserId: null, // 多人答题-当前答题的用户
      currentTopicAnswer: [], // 多人答题-当前选择的答案
      topic: {}, // 当前题目的数据
      isStart: false, // 是否开始答题
      isEnd: false, // 是否结束答题
      userInfo: {
        branchName: "AAA党总支",
        headpic:
          "https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132",
        realname: "张三",
        userid: "38",
      },
      now: 1, // 当前是第几题
      type: null, // 1单人答题2多人答题
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    // this.getBranchList();
  },
  methods: {
    onmessage(e) {
      const redata = JSON.parse(e.data);
      if (redata.action != "heartTest") {
        console.log("redata", redata);
      }
      switch (redata.action) {
        // 单人答题用户连接成功
        case "userMessage":
          this.userGroup.push(redata.data);
          console.log("userGroup", this.userGroup);
          break;
        // 单人答题开始
        case "singleTopic":
          // var count = redata.data.count;
          // for (let index = 0; index < count; index++) {
          //   topicArr.push({
          //     myanswer: "",
          //   });
          // }
          this.topicList = redata.data.topicList;
          this.now = redata.data.now;
          this.isEnd = redata.data.isEnd;
          this.isStart = true;
          break;
        // 多人答题加入
        case "join":
          this.userGroup.push(redata.data);
          break;
      }
    },
    // 开始多人答题
    beginAnswer() {
      if (this.userGroup.length) {
        this.isStart = true;
      } else {
        this.$message.error("答题人员不足无法开始");
        return false;
      }
    },
    // 多人答题-选择答案
    chooseAnswer(option) {
      if (this.type == 1) {
        return false;
      }
      if (!this.currentUserId) {
        this.$message.error("请先选择答题人员");
        return false;
      }
      if (this.currentTopicAnswer.indexOf(option) > -1) {
        this.currentTopicAnswer.splice(
          this.currentTopicAnswer.indexOf(option),
          1
        );
      } else {
        this.currentTopicAnswer.push(option);
      }
    },
    // 多人答题-确定答题
    handleAnswer() {
      if (!this.currentTopicAnswer.length) {
        this.$message.error("请至少选择一个答案");
        return false;
      }
      // 拼接答案
      var str = "";
      for (const item of this.currentTopicAnswer.sort()) {
        str += item;
      }
      //判断对错
      if (this.topicList[this.now - 1].answer != str) {
        this.$message.error(
          "回答错误，正确答案为：" + this.topicList[this.now - 1].answer
        );
      } else {
        this.$message.success(
          "回答正确，加" + this.topicList[this.now - 1].score + "分"
        );
        //正确的话加分
        for (const item of this.userGroup) {
          if (item.userid == this.currentUserId) {
            item.score += this.topicList[this.now - 1].score;
          }
        }
      }
      this.topicList[this.now - 1].myanswer = str;
      this.currentTopicAnswer = [];

      var now = this.now;
      if (now == this.topicList.length) {
        this.isEnd = true;
        this.answerEnd();
      } else {
        this.now++;
      }
    },
    // 答题结束后提交返回
    async answerEnd() {
      let that = this;
      if (this.type == 1) {
        return false;
      }
      const res = await Service.getApi({
        do: "answer",
        op: "answerResult",
        data: {
          result: this.userGroup,
        },
      });
      console.log("answerEnd", res);
    },
    toPage(e) {
      this.$router.push(e);
    },
    async getBranchList() {
      const res = await Service.getApi({
        do: "intell",
        op: "branchList",
        data: {
          page: this.page,
          count: this.pageSize,
        },
      });
      this.list = res.data;
    },
    async getTopicList() {
      let that = this;
      const res = await Service.getApi({
        do: "answer",
        op: "multi",
        data: {},
      });
      that.topicList = res.data;
    },
    selectMode(e) {
      this.type = e;
      if (e != 1) {
        this.getTopicList();
      }
      if (e == 3) {
        this.userGroup.push({
          branchName: "",
          headpic:
            "https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132",
          realname: "本地答题用户",
          userid: "999999999",
          score: 0,
        });
        this.currentUserId = "999999999";
      }
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

.page_body {
  width: 1678px;
  height: 728px;
  margin-top: 20px;
}

.sheet {
  box-sizing: border-box;
  /* border-top: 1px solid #000; */
  border-left: 1px solid #000;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.sheet_cell {
  width: calc((1678px / 10) - 0.1px);
  height: 70px;
  box-sizing: border-box;
  border-right: 1px solid #000;
  border-top: 1px solid #000;
}

.sheet_cell > div {
  width: 100%;
  height: 35px;
  box-sizing: border-box;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.red {
  color: red;
}

.green {
  color: green;
}

/* .sheet_cell > div:first-child {
  width: 100%;
  height: 35px;
  box-sizing: border-box;
  border-bottom: 1px solid #000;
}

.sheet_cell > div:last-child {
  width: 100%;
  height: 35px;
  box-sizing: border-box;
  border-bottom: 1px solid #000;
} */

.page_bottom {
  margin-top: 27px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}

.h600 {
  height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 30px;
}

.daikaishi {
  position: absolute;
  width: 50px;
  right: -10px;
  top: 100px;
}

.choose_body {
  width: 1678px;
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.choose_body .title {
  font-size: 40px;
  width: 100%;
  text-align: center;
  margin-bottom: 50px;
}

.choose_body .btn1,
.choose_body .btn2 {
  font-size: 40px;
  width: 700px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.choose_body .btn1 {
  background-color: #8f0b0b;
  color: #fff;
}

.choose_body .btn2 {
  background-color: #8f0b0b;
  color: #fff;
}

.user_group_cell {
  display: flex;
  align-items: center;
  margin-right: 20px;
  box-sizing: border-box;
  padding: 10px;
  color: #fff;
  border-radius: 3px;
  cursor: pointer;
  border: 2px solid transparent;
  background-color: #d1a626;
}

.user_group_cell_active {
  border: 2px solid #000;
}

/* .user_group_cell:nth-child(1) {
  background-color: #c30515;
}

.user_group_cell:nth-child(2) {
  background-color: #6733c7;
}

.user_group_cell:nth-child(3) {
  background-color: #0557c3;
}

.user_group_cell:nth-child(4) {
  background-color: #05c325;
} */

.user_group_cell img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.user_group_cell .name {
  font-size: 12px;
  margin-right: 10px;
}

.user_group_cell .score {
  font-size: 24px;
  font-weight: bold;
}

.dati_btn {
  width: 115px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #a5211e;
  cursor: pointer;
}

.icon_dui {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 0;
}

.answer_entry_img {
  width: 300px;
  height: 300px;
  display: block;
  margin-right: 30px;
}

.mr100 {
  margin-right: 100px;
}
</style>
