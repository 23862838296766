import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../common/Home";
import Login from "../views/Login";
import BusinessDynamic from "../views/BusinessDynamic";
import Chat from "../views/Chat";
import MarketEditor from "../views/MarketEditor";
import EnterpriseWechat from "../views/EnterpriseWechat";
import ArticleTemplate from "../views/ArticleTemplate";
import Dummy from "../views/Dummy";
import CircleManage from "../views/CircleManage";
import Statistics from "../views/Statistics";
import PushMessage from "../views/PushMessage";

import TaizhouHome from "../views/TaizhouHome";
import Index from "../views/Index";
import Huidangwu from "../views/Huidangwu";
import Huishuju from "../views/Huishuju";
import Huihudong from "../views/Huihudong";
import Huixuexi from "../views/Huixuexi";
import Huihuodong from "../views/Huihuodong";
import Zhidu from "../views/Zhidu";
import ZhiduDetail from "../views/ZhiduDetail";
import PartyMemberInformation from "../views/PartyMemberInformation";
import PartyMap from "../views/PartyMap";
import PartyMap1 from "../views/PartyMap1";
import PartyMap2 from "../views/PartyMap2";
import Answer from "../views/Answer";
import Huodong from "../views/Huodong";
import HuodongDetail from "../views/HuodongDetail";
import TrueToLike from "../views/TrueToLike";
import Yunyouzhanting from "../views/Yunyouzhanting";
import Library from "../views/Library";
import Xuanshi from "../views/Xuanshi";
import Liuyanban from "../views/Liuyanban";
import LiuyanbanDetail from "../views/LiuyanbanDetail";
import Dangyuanpingjia from "../views/Dangyuanpingjia";
import Sanhuiyike from "../views/Sanhuiyike";
import Xingjikaoping from "../views/Xingjikaoping";

const routes = [
  {
    path: "/",
    redirect: "/taizhouHome",
  },
  {
    path: "/",
    component: Home,
    children: [
      {
        path: "/taizhouHome",
        component: TaizhouHome,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/index",
        component: Index,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/huidangwu",
        component: Huidangwu,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/huishuju",
        component: Huishuju,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/huihudong",
        component: Huihudong,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/huixuexi",
        component: Huixuexi,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/huihuodong",
        component: Huihuodong,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/zhidu",
        component: Zhidu,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/zhiduDetail",
        component: ZhiduDetail,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/partyMemberInformation",
        component: PartyMemberInformation,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/partyMap",
        component: PartyMap,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/partyMap1",
        component: PartyMap1,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/partyMap2",
        component: PartyMap2,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/answer",
        component: Answer,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/huodong",
        component: Huodong,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/huodongDetail",
        component: HuodongDetail,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/trueToLike",
        component: TrueToLike,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/yunyouzhanting",
        component: Yunyouzhanting,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/library",
        component: Library,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/xuanshi",
        component: Xuanshi,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/liuyanban",
        component: Liuyanban,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/liuyanbanDetail",
        component: LiuyanbanDetail,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/dangyuanpingjia",
        component: Dangyuanpingjia,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/sanhuiyike",
        component: Sanhuiyike,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/xingjikaoping",
        component: Xingjikaoping,
        meta: { title: "泰州大桥智慧党建" },
      },
      {
        path: "/businessDynamic",
        component: BusinessDynamic,
        meta: { title: "交易动态" },
      },
      {
        path: "/articleTemplate",
        component: ArticleTemplate,
        meta: { title: "文章模板" },
      },
      {
        path: "/chat",
        component: Chat,
        meta: { title: "聊天" },
      },
      {
        path: "/marketEditor",
        component: MarketEditor,
        meta: { title: "行情编辑" },
      },
      {
        path: "/enterpriseWechat",
        component: EnterpriseWechat,
        meta: { title: "企业微信" },
      },
      {
        path: "/dummy",
        component: Dummy,
        meta: { title: "假人管理" },
      },
      {
        path: "/circleManage",
        component: CircleManage,
        meta: { title: "圈子管理" },
      },
      {
        path: "/statistics",
        component: Statistics,
        meta: { title: "统计数据" },
      },
      {
        path: "/pushMessage",
        component: PushMessage,
        meta: { title: "推送消息" },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { title: "泰州大桥智慧党建" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
