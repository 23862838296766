<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huishuju')"
           class="can">慧数据--></div>
      <div>党建地图</div>
    </div>

    <div class="page_body">
      <div id="container"></div>
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
import * as echarts from "echarts";
import "@/utils/taizhou.js";
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
  data() {
    return {
      list: [],
      pageSize: 100,
      page: 1,
      info: {}, // 当前支部的信息
      map: null,
      dataList: [],
    };
  },
  mounted() {
    let that = this;
    window.onmessageSocket = this.onmessage;
    this.getBranchList();
    this.getLine();
    this.$nextTick(() => {
      // this.getEchartMap();
    });
  },
  methods: {
    onmessage() {},
    async getLine() {
      let that = this;
      const res = await Service.getApi({
        do: "start",
        op: "get_line",
        data: {},
      });
      this.lineList = res.data;
      this.getPoint();
    },
    async getPoint() {
      let that = this;
      const res = await Service.getApi({
        do: "intell",
        op: "branchList",
        data: {
          page: 1,
          count: 100,
        },
      });
      var data = res.data,
        dataList = [];
      // for (const [index, item] of data.entries()) {
      //   dataList.push({
      //     name: item.name,
      //     coord: [item.lng * 1, item.lat * 1],
      //     data: item,
      //   });
      // }
      that.dataList = data;

      this.mapInit();
    },
    toPage(e) {
      this.$router.push(e);
    },
    async getEchartMap() {
      let that = this;
      const res = await Service.getApi({
        do: "intell",
        op: "branchList",
        data: {
          page: this.page,
          count: this.pageSize,
        },
      });
      var data = res.data,
        dataList = [];
      for (const [index, item] of data.entries()) {
        dataList.push({
          name: item.name,
          coord: [item.lng * 1, item.lat * 1],
          data: item,
        });
      }
      console.log("dataList", dataList);
      let myChart = echarts.init(this.$refs.myChart);

      myChart.setOption({
        title: {
          text: "",
          left: "center",
        },
        series: [
          {
            type: "map",
            // silent: true,
            map: "taizhou",
            zoom: 1.2,
            roam: true, // 是否开启缩放
            label: {
              // 默认文本标签样式
              // normal: {
              //   show: true,
              //   textStyle: {
              //     color: "#fff",
              //     textShadowOffsetX: 0,
              //     textShadowOffsetY: 0,
              //     textShadowBlur: 5,
              //     textShadowColor: "#000",
              //   },
              // },
              // // 高亮文本标签样式
              // emphasis: {
              //   color: "yellow",
              //   fontSize: 22,
              //   fontWeight: "bold",
              // },
              normal: {
                show: true,
                textStyle: {
                  color: "yellow",
                  fontWeight: "bold",
                  fontSize: 18,
                  textShadowOffsetX: 0,
                  textShadowOffsetY: 0,
                  textShadowBlur: 5,
                  textShadowColor: "#000",
                },
              },
              emphasis: {
                color: "yellow",
                fontSize: 22,
                fontWeight: "bold",
              },
            },
            itemStyle: {
              // 默认区域样式
              // normal: {
              //   areaColor: "#FB8225",
              //   borderColor: "#000",
              //   borderWidth: 1,
              // },
              // // 高亮区域样式
              // emphasis: {
              //   // 高亮区域背景色
              //   areaColor: "#D51F1C",
              // },
              normal: {
                areaColor: "#D51F1C",
                borderColor: "#000",
                borderWidth: 1,
              },
              emphasis: {
                areaColor: "#D51F1C",
                borderColor: "#000",
                borderWidth: 1,
              },
            },
            markPoint: {
              symbol:
                "image://" + require("../assets/image/map_point_select.png"), // 自定义图片路径
              symbolSize: [50, 50], // 图片大小
              label: {
                position: "top",
                color: "#fff",
              },
              // coord数组存放地址坐标
              data: dataList,
            },
          },
        ],
      });
      myChart.on("click", function (params) {
        console.log("params", params.data.data);
        that.info = params.data.data;
        return false;
      });
      myChart.on("mouseover", function (params) {
        myChart.dispatchAction({
          type: "map",
        });
      });
      // 添加窗口大小改变监听事件，当窗口大小改变时，图表会重新绘制，自适应窗口大小
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    mapInit() {
      let that = this;
      var lineList = that.lineList,
        dataList = that.dataList;
      AMapLoader.load({
        key: "81626d98c84990fe40f42452d80ddb0d", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Scale", "AMap.ToolBar"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          // 创建地图，设置中心点
          that.map = new AMap.Map("container", {
            center: [lineList[0][0], lineList[0][1]],
            zoom: 10, //初始地图级别
            mapStyle: "amap://styles/whitesmoke",
            // mapStyle: "amap://styles/a732c0a2e2c92a58ce6c59a2afdc8892",
          });
          // that.map.setFeatures(["road", "point"]); // 多个种类要素显示
          // 创建一个icon
          var startIcon = new AMap.Icon({
            // 图标尺寸
            size: new AMap.Size(28, 45),
            // 图标的取图地址
            image:
              "//fishlion.ryze.club/addons/vlinke_cparty/template/static/target.png",
            // 图标所用图片大小
            imageSize: new AMap.Size(28, 45),
            // 图标取图偏移量
            // imageOffset: new AMap.Pixel(-9, -3),
          });
          // 增加地图控件
          that.map.addControl(new AMap.Scale());
          that.map.addControl(new AMap.ToolBar());
          var arr = [],
            path = [];
          // 线
          for (const item of lineList) {
            var point = new AMap.LngLat(item[0], item[1]);
            path.push(point);
          }
          // 点
          for (const [index, item] of dataList.entries()) {
            var marker = new AMap.Marker({
              position: new AMap.LngLat(item.lng, item.lat),
              offset: new AMap.Pixel(-15, -40),
              icon: startIcon, // 添加 Icon 图标 URL
            });
            marker.setLabel({
              direction: "right",
              offset: new AMap.Pixel(10, 0), //设置文本标注偏移量
              content:
                "<div class='info' data-index=" +
                index +
                ">" +
                item.name +
                "</div>", //设置文本标注内容
            });
            marker.on("click", (mapEvent) => {
              var id = mapEvent.target.dom
                .getElementsByClassName("info")[0]
                .getAttribute("data-id");
              this.$router.push("/partyMap1?index=" + index);
              // console.log(id);
              // console.log(mapEvent.target.dom);
            });
            arr.push(marker);
          }
          that.map.add(arr);
          function showInfoM(e) {}
          // 折线的节点坐标数组，每个元素为 AMap.LngLat 对象
          // var path = [
          //   new AMap.LngLat(116.368904, 39.913423),
          //   new AMap.LngLat(116.382122, 39.901176),
          //   new AMap.LngLat(116.387271, 39.912501),
          //   new AMap.LngLat(116.398258, 39.9046),
          // ];

          // 创建折线实例
          var polyline = new AMap.Polyline({
            path: path,
            borderWeight: 2, // 线条宽度，默认为 1
            strokeWeight: 10,
            strokeColor: "#c41b1e", // 线条颜色
            lineJoin: "round", // 折线拐点连接处样式
          });

          // 将折线添加至地图实例
          that.map.add(polyline);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    async getBranchList() {
      const res = await Service.getApi({
        do: "intell",
        op: "branchList",
        data: {
          page: this.page,
          count: this.pageSize,
        },
      });
      this.list = res.data;
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

.page_body {
  width: 1678px;
  height: 728px;
  background: rgba(255, 255, 255, 0.6);
  margin-top: 20px;
}

.page_bottom {
  margin-top: 27px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}

.slash {
  width: 711px;
  height: 749px;
}

#map {
  width: 730px;
  height: 800px;
}

.data_body {
  width: calc(100% - 730px);
}

.data {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  padding-top: 120px;
}

.data > div {
  margin-top: 20px;
  font-weight: bold;
}

.data > div > text {
  max-width: 300px;
}

.data > div > text > span {
  font-size: 20px;
  color: #d51f1c;
}

.cell1 {
  padding-left: calc(640px - 140px);
}
.cell2 {
  padding-right: calc(460px + 140px);
}
.cell3 {
  padding-left: calc(510px - 120px);
}
.cell4 {
  padding-right: calc(580px + 140px);
}
.cell5 {
  padding-left: calc(405px - 140px);
}
.cell6 {
  padding-right: calc(660px + 140px);
}
.cell7 {
  padding-left: calc(320px - 10px);
}

.cell8 {
  margin-top: 100px !important;
  padding-left: 220px;
}

.cell8 > div {
  background-color: #fff;
  box-sizing: border-box;
  padding: 5px;
}

.page_body_cell {
  display: flex;
}

.page_body_cell > div:first-child {
  width: 120px;
  flex-shrink: 0;
  font-size: 30px;
  color: #c12227;
  padding: 0 45px;
}

.page_body_cell > div:last-child {
  font-size: 30px;
  border-left: 1px solid;
  box-sizing: border-box;
  padding: 0 45px 30px 45px;
}

#container {
  width: 100%;
  height: 100%;
}

/deep/.amap-marker-label {
  color: #c41b1e;
  border: 0;
  background-color: transparent;
  font-weight: bold;
  font-size: 18px;
}
</style>
