<template>
  <div @click="toPage">
    <img class="bg"
         src="@/assets/image/home.png"
         alt="" />
    <img class="name"
         src="@/assets/image/name.png"
         alt="" />
  </div>
</template>

<script>
export default {
  methods: {
    toPage() {
      this.$router.push("/index");
    },
  },
};
</script>

<style scoped>
.bg {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.name {
  width: 1500px;
  position: fixed;
  bottom: 12%;
  left: 50%;
  transform: translate(-50%);
}
</style>
