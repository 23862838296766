<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huishuju')"
           class="can">慧数据--></div>
      <div>留言板</div>
    </div>
    <div class="page_body">
      <div class="lists overflow_y">
        <div @click="viewDetail(item.id)"
             class="list can"
             v-for="(item, index) in list"
             :key="index">
          <img class="cover"
               :src="item.image[0]"
               alt="" />
          <div class="info">
            <div class="title hang2">
              {{ item.title }}{{index}}
            </div>
            <div class="flex at_c">
              <img class="avatar"
                   :src="item.headpic"
                   alt="" />
              <div class="name hang1">{{ item.realname }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex jc_c w100 mt30">
        <el-pagination v-model:currentPage="currentPage"
                       v-model:page-size="pageSize"
                       :background="background"
                       layout="total, prev, pager, next, jumper"
                       :total="total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange" />
      </div>
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
export default {
  data() {
    return {
      title: "",
      currentPage: 1,
      pageSize: 10,
      background: "#c30515",
      total: 0,
      list: [],
      info: {},
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getList(1);
  },
  methods: {
    onmessage() {},
    async getList(page) {
      const res = await Service.getApi({
        wxapp: 1,
        do: "message_board",
        op: "list",
        data: {
          userid: 1,
          page,
          count: this.pageSize,
        },
      });
      console.log("res", res);
      this.list = res.data.data;
      this.total = res.data.count;
    },
    viewDetail(id) {
      this.$router.push({
        path: "/liuyanbanDetail",
        query: { type: this.type, id },
      });
    },
    toPage(e) {
      this.$router.push(e);
    },
    handleSizeChange(e) {
      this.getList(e);
    },
    handleCurrentChange(e) {
      this.getList(e);
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

.page_body {
  width: 1678px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.lists {
  margin-top: 30px;
  width: 1518px;
  height: 600px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
}

.list {
  width: 270px;
  height: 280px;
  font-size: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 5px;
}

.list:not(:nth-child(5n)) {
  margin-right: 40px;
}

.cover {
  width: 100%;
  height: 160px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}

.info {
  width: 100%;
  height: 120px;
  box-sizing: border-box;
  padding: 10px;
}

.info .title {
  height: 52px;
  margin-bottom: 5px;
}

.info .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  flex-shrink: 0;
  object-fit: cover;
  margin-right: 10px;
}

.info .name {
  max-width: 800px;
  font-size: 14px;
  max-width: 165px;
}

.time {
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #c30515;
}

.page_bottom {
  margin-top: 47px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}
</style>
