<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huidangwu')"
           class="can">慧活动--></div>
      <div @click="toPage('/huodong?type=' + type)"
           class="can">
        {{ pageName }}-->
      </div>
      <div>详情</div>
    </div>

    <h1>{{ info.title }}</h1>
    <div class="tar w1678">
      <span>活动状态：
        <span class="status1 pr fb"
              v-if="info.status == 1"
              style="color: #919191">尚未开始</span>
        <span class="status2 pr fb"
              v-if="info.status == 2"
              style="color: #209e1b">进行中</span>
        <span class="status3 pr fb"
              v-if="info.status == 3"
              style="color: red">已结束</span>
      </span>
      <span class="time">时间：{{ info.stime }}-{{ info.etime }}</span>
    </div>
    <div class="cori_swiper">
      <el-carousel :interval="4000"
                   type="card"
                   height="220px">
        <el-carousel-item v-for="item in info.activityImg"
                          :key="item">
          <img class="cover_img"
               :src="item"
               alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="page_body overflow_y">
      <div class="mt10">活动类型：{{ info.summarycate }}</div>
      <div class="mt10">活动支部：{{ info.branchName }}</div>
      <div class="mt10">组织地址：{{ info.address }}</div>
      <div class="mt10">发起人姓名：{{ info.userName }}</div>
      <div class="mt10">活动记录人：{{ info.summaryuser }}</div>
      <div v-html="info.summary"></div>
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
export default {
  data() {
    return {
      type: null, // 1主题党日2政治生日
      pageName: "",
      title: "",
      info: {},
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
    if (this.type == 1) {
      this.pageName = "主题党日";
    } else if (this.type == 2) {
      this.pageName = "政治生日";
    }
    this.getDetail();
  },
  methods: {
    onmessage() {},
    async getDetail() {
      const res = await Service.getApi({
        do: "birthday",
        op: "list",
        data: {
          id: this.id,
        },
      });
      console.log("详情", res);
      this.info = res.data[0];
    },
    toPage(e) {
      this.$router.push(e);
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

h1 {
  color: #c30515;
}

.w1678 {
  width: 1678px;
}

.page_body {
  width: 1678px;
  height: 500px;
  margin-top: 20px;
  overflow-x: hidden;
}

.status1,
.status2,
.status3 {
  box-sizing: border-box;
  padding-left: 20px;
  display: inline-flex;
  align-items: center;
}

.status1::after,
.status2::after,
.status3::after {
  content: "";
  position: absolute;
  left: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.status1::after {
  background-color: #919191;
}

.status2::after {
  background-color: #209e1b;
}

.status3::after {
  background-color: red;
}

.time {
  margin-left: 30px;
}

.cori_swiper {
  margin-top: 20px;
  width: 700px;
}

.cover_img {
  width: 360px;
  height: 200px;
  object-fit: cover;
}

.page_bottom {
  margin-top: 47px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}
</style>
