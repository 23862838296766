<template>
  <div class="body flex at_c">
    <div class="w40">
      <div class="plate_title">
        <div class="plate_title_name">行情编辑</div>
      </div>
      <div class="overflow_y">
        <div class="bb pl20 pr20">
          <div class="choose_cell">
            <div>类型：</div>
            <div class="flex">
              <div :class="{ active: currentTypeId == item.id }"
                   v-for="(item, index) in typeArr"
                   :key="index"
                   @click="selectNode(1, item.id, index, item.name)">
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div class="choose_cell">
            <div>品种：</div>
            <div class="flex">
              <div :class="{ active: currentVarietiesId == item.id }"
                   v-for="(item, index) in varietiesArr"
                   :key="index"
                   @click="selectNode(2, item.id, index, item.name)">
                <span v-if="!currentTypeId || currentTypeId == item.type">{{
                  item.name
                }}</span>
              </div>
            </div>
          </div>
          <div class="choose_cell">
            <div>标题：</div>
            <div class="flex">
              <div :class="{ active: currentTitleId == item.id }"
                   v-for="(item, index) in titleArr"
                   :key="index"
                   @click="selectNode(3, item.id, index, item.name)">
                <span>{{ item.name }}</span>
              </div>
            </div>
          </div>
          <div class="choose_cell">
            <div>研究员：</div>
            <div class="flex">
              <div :class="{ active: currentResearcherId == item.id }"
                   v-for="(item, index) in researcherArr"
                   :key="index"
                   @click="selectNode(4, item.id, index, item.nickname)">
                <span>{{ item.nickname }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="bb p20">
          <div class="flex">
            <div class="w50">
              <el-input class="h300"
                        type="textarea"
                        v-model="copytext"></el-input>
              <el-button class="mt_btn"
                         type="primary"
                         @click="copyText">复制</el-button>
            </div>
            <div class="w50">
              <el-form ref="form"
                       :model="form"
                       label-width="80px">
                <el-form-item label="标题">
                  <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="类别">
                  <el-radio-group v-model="form.type">
                    <el-radio-button label="国内期货" />
                    <el-radio-button label="国际期货" />
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="趋势"
                              v-if="form.type == '国际期货'">
                  <el-radio-group v-model="form.trend">
                    <el-radio-button label="看涨" />
                    <el-radio-button label="看跌" />
                  </el-radio-group>
                </el-form-item>
                <el-form-item label="交易所"
                              v-if="form.type == '国际期货'">
                  <el-select v-model="form.exchange_id"
                             class="w100"
                             placeholder="请选择交易所">
                    <el-option v-for="item in exchangeArr"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id" />
                  </el-select>
                </el-form-item>
                <el-form-item label="内容">
                  <el-input type="textarea"
                            v-model="form.content"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                  <el-upload class="avatar-uploader"
                             action="https://api.i-vce.com/teacher/common/upload"
                             :show-file-list="false"
                             :on-success="handleAvatarSuccess">
                    <div class="avatar-uploader_div"
                         v-if="form.images">
                      <img :src="form.images"
                           class="avatar" />
                      <div class="zhezhao">
                        <el-icon @click.stop="handleRemove"
                                 :size="24">
                          <Delete color="#ffffff" />
                        </el-icon>
                      </div>
                    </div>
                    <el-icon v-else
                             class="avatar-uploader-icon">
                      <Plus />
                    </el-icon>
                  </el-upload>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary"
                             @click="onSubmit">添加</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="w60">
      <div class="plate_title">
        <div class="plate_title_name">行情</div>
      </div>
      <div class="quotation_body">
        <div class="flex at_c bb pt10 pb10">
          类别
          <div class="ml10">
            <el-select v-model="viewType"
                       class="m-2"
                       size="large">
              <el-option v-for="item in viewTypeArr"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value" />
            </el-select>
          </div>
          <div class="ml10">
            <el-button @click="searchView"
                       type="primary">搜索</el-button>
          </div>
        </div>
        <div v-loading="quotationListloading"
             class="quotation_group overflow_y"
             v-infinite-scroll="load"
             :infinite-scroll-immediate="false">
          <div class="quotation"
               v-for="(item, index) in quotationList"
               :key="index">
            <div class="info">
              <div class="time">{{ item.createtime }}</div>
              <div>
                <img class="icon_guonei"
                     v-if="item.type == 1"
                     src="@/assets/img/icon_guonei.png"
                     alt="" />
                <img class="icon_guoji"
                     v-if="item.type == 2"
                     src="@/assets/img/icon_guoji.png"
                     alt="" />
              </div>
              <div v-if="item.trend == 1"
                   class="quotation_trend_zhang">
                看涨
                <img class="icon_zhang"
                     src="@/assets/img/icon_zhang.png"
                     alt="" />
              </div>
              <div v-if="item.trend == 2"
                   class="quotation_trend_die">
                看跌
                <img class="icon_die"
                     src="@/assets/img/icon_die.png"
                     alt="" />
              </div>
            </div>
            <img class="quotation_cover"
                 v-if="item.images.length"
                 :src="item.images[0]"
                 alt="" />
            <div>
              <div class="title hang1">{{ item.title }}</div>
              <div class="desc hang1">{{ item.content }}</div>
              <div class="quotation_bottom">
                <div class="quotation_jiaoyisuo"
                     v-if="item.exchange_id">
                  <img src="@/assets/img/icon_jiaoyisuo.png"
                       alt="" />
                  {{ item.exchange_name }}
                </div>
                <div class="operation"
                     @click="delConfirm(item.id)">删除</div>
              </div>
            </div>
          </div>
          <div class="is_end"
               v-if="isEnd">已全部加载</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/marketEditor.js";
import * as ServiceCommon from "../api/common.js";
import data from "../utils/data";
// import { Delete, Plus } from "@element-plus/icons-vue";
const OSS = require("ali-oss");
export default {
  // components: {
  //   Plus,
  //   Delete,
  // },
  data() {
    return {
      typeArr: [],
      varietiesArr: [],
      titleArr: [],
      researcherArr: [],

      currentTypeIndex: null,
      currentVarietiesIndex: null,
      currentTitleIndex: null,
      currentResearcherIndex: null,

      currentTypeId: null,
      currentVarietiesId: null,
      currentTitleId: null,
      currentResearcherId: null,

      quotationList: [],
      page: 1,
      quotationListloading: false, //客户列表加载状态
      isEnd: false,

      form: {
        title: "",
        content: "",
        type: "国内期货",
        trend: "看涨",
        exchange_id: null,
        images: "",
        tag: "",
        teacher_id: null,
      },
      copytext: "",
      exchangeArr: [], // 交易所数组

      viewTypeArr: [
        {
          label: "全部",
          value: "all",
        },
        {
          label: "国内期货",
          value: "internal",
        },
        {
          label: "国际期货",
          value: "international",
        },
      ], // 检索类型条件
      viewType: "all",
    };
  },
  mounted() {
    let that = this;
    window.onmessageSocket = this.onmessage;
    this.typeArr = data.typeArr;
    this.varietiesArr = data.varietiesArr;
    this.titleArr = data.titleArr;
    this.dataInit();
    this.getExchangeArr();
    this.getViewpointList();

    this.copytext =
      "★内部技术指标课程研究\n【品种技术】：\n【技术名称】：\n【技术显示】：\n【空间预期】：\n【研究时间】：";
    this.showRealTime();
    // 创建粘贴事件
    document.addEventListener("paste", function (e) {
      if (!(e.clipboardData && e.clipboardData.items)) {
        return;
      }
      for (var i = 0, len = e.clipboardData.items.length; i < len; i++) {
        var item = e.clipboardData.items[i];

        if (item.kind === "string") {
          item.getAsString(function (str) {
            console.log("str", str);
            // str 是获取到的字符串
          });
        } else if (item.kind === "file") {
          var pasteFile = item.getAsFile();
          // console.log("pasteFile", pasteFile);

          let client = new OSS({
            // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
            region: "oss-cn-shanghai",
            // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
            accessKeyId: "LTAI4G1V6Q8mGL5MpDqpreko",
            accessKeySecret: "sPsj1NZnkJ8uhrhTQoHTyioADohcEu",
            bucket: "i-vce",
          });
          var name = that.getFileName(pasteFile);
          client
            .put(name, pasteFile)
            .then(function (res) {
              // 上传图片，返回结果，将图片插入到编辑器中
              console.log("res", res);
              that.form.images = res.url;
            })
            .catch(function (err) {
              console.log(err);
            });
          // that.uploadImg(pasteFile);
          // pasteFile就是获取到的文件
        }
      }
    });
  },
  methods: {
    onmessage(e) {},
    // 获取交易所列表
    async getExchangeArr() {
      const res = await Service.getExchangeArr();
      this.exchangeArr = res.data.exchangeArr;
    },
    // 获取图片名称
    getFileName(file) {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var type = file.type.substr(
        file.type.lastIndexOf("/") + 1,
        file.type.length
      );
      return (
        "push" + year + month + date + "/" + Math.round(new Date()) + "." + type
      );
    },
    async uploadImg(pasteFile) {
      const res = await ServiceCommon.upload({
        file: pasteFile,
      });
      console.log("res", res);
    },
    showRealTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month = d.getMonth() + 1;
      var date = d.getDate();
      var days = new Array("日", "一", "二", "三", "四", "五", "六");
      var day = d.getDay();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "年" + month + "月" + date + "日";
      var cori_time = hour + ":" + min + ":" + sec;
      this.copytext += cori_date;
      1;
      // this.currentTime = cori_time;
      // this.currentWeek = "星期" + days[day];
    },
    copyText() {
      this.$copyText(this.copytext)
        .then((message) => {
          this.$message.success("复制完毕");
        })
        .catch((err) => {
          console.log("copy.err", err);
          this.$message.error("复制失败");
        });
    },
    handleRemove() {
      console.log("@删除图片");
      this.form.images = "";
    },
    handleAvatarSuccess(res, file) {
      this.form.images = res.data.fullurl;
    },
    async onSubmit() {
      let that = this;
      var form = that.form;
      console.log("form", form);
      if (!form.title) {
        that.$message.error("标题不可为空");
        return false;
      }
      if (!form.content) {
        that.$message.error("内容不可为空");
        return false;
      }
      if (!form.teacher_id) {
        that.$message.error("请选择研究员");
        return false;
      }
      if (form.type == "国内期货") {
        form.type = 1;
        form.trend = 0;
      }
      if (form.type == "国际期货") {
        if (!form.exchange_id) {
          that.$message.error("请选择交易所");
          return false;
        }

        form.type = 2;
        form.trend = form.trend == "看涨" ? 1 : 2;
      }
      const res = await Service.addViewpoint(form);
      that.$message.success("操作成功");
      that.form = {
        title: "",
        content: "",
        type: "国内期货",
        trend: "看涨",
        exchange_id: null,
        images: "",
        tag: "",
        teacher_id: null,
      };
      that.currentTypeIndex = null;
      that.currentVarietiesIndex = null;
      that.currentTitleIndex = null;
      that.currentResearcherIndex = null;

      that.currentTypeId = null;
      that.currentVarietiesId = null;
      that.currentTitleId = null;
      that.currentResearcherId = null;
      that.quotationList = [];
      that.page = 1;
      that.isEnd = false;
      that.getViewpointList(1);
    },
    async dataInit() {
      const res = await Service.baseInfo();
      this.researcherArr = res.data.teacherList;
    },
    //选择节点
    selectNode(type, id, index, text) {
      var str = "";
      switch (type) {
        //选择类型
        case 1:
          this.currentTypeId = id;
          this.currentTypeIndex = index;
          break;
        //选择品种
        case 2:
          var copytext = this.copytext,
            last_index = copytext.indexOf("\n【技术名称】："),
            last_text = copytext.substr(last_index + 1, copytext.length); //04
          console.log("last_text", last_text);
          //拼接需要复制的文本
          this.copytext =
            "★内部技术指标课程研究\n【品种技术】：" + text + "\n" + last_text;

          if (this.currentTitleIndex != null) {
            str = "【" + this.titleArr[this.currentTitleIndex].name + "】";
          } else {
            str = "";
          }
          this.form.title = "【" + this.varietiesArr[index].name + "】" + str;

          this.currentVarietiesId = id;
          this.currentVarietiesIndex = index;
          break;
        //选择标题
        case 3:
          if (this.currentVarietiesIndex != null) {
            str =
              "【" + this.varietiesArr[this.currentVarietiesIndex].name + "】";
          } else {
            str = "";
          }
          this.form.title = str + "【" + this.titleArr[index].name + "】";

          this.currentTitleId = id;
          this.currentTitleIndex = index;
          break;
        //选择研究员
        case 4:
          this.form.teacher_id = id;

          this.currentResearcherId = id;
          this.currentResearcherIndex = index;
          break;
      }
    },
    //获取行情
    async getViewpointList(page) {
      if (!this.isEnd) {
        this.quotationListloading = true;
        const res = await Service.viewpointList({
          page,
          type: this.viewType,
        });
        console.log("全部客户", res);
        var list_new = res.data.list.data,
          quotationList = this.quotationList;
        if (list_new.length) {
          this.quotationList = quotationList.concat(list_new);
          this.page++;
        } else {
          this.isEnd = true;
        }
        this.quotationListloading = false;
      }
    },
    searchView() {
      this.quotationList = [];
      this.isEnd = false;
      this.getViewpointList(1);
    },
    load() {
      this.getViewpointList(this.page);
    },
    //删除行情-弹框
    delConfirm(id) {
      this.$confirm("确认删除该行情吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.del(id);
        })
        .catch(() => {});
    },
    //删除行情
    async del(id) {
      const res = await Service.delViewpoint({ id });
      this.$message({
        type: "success",
        message: "删除成功!",
      });
      this.quotationList = [];
      this.page = 1;
      this.isEnd = false;
      this.getViewpointList(1);
    },
  },
};
</script>

<style scoped>
.body {
  height: 100vh;
}

.w40 {
  height: 100%;
  border-right: 1px solid #ebeced;
}

.w60 {
  height: 100%;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 18px 0;
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.choose_cell {
  font-size: 14px;
  display: flex;
  box-sizing: border-box;
  padding: 20px 0 10px;
  border-bottom: 1px dashed #dedede;
}

.choose_cell > div:first-child {
  font-weight: 600;
  flex-shrink: 0;
}

.choose_cell > div:last-child {
  color: #666;
  flex-wrap: wrap;
}

.choose_cell > div:last-child > div > span {
  cursor: pointer;
  margin-right: 15px;
  margin-bottom: 10px;
  display: block;
}

.choose_cell > div:last-child > div > span:hover {
  color: red;
}

.active {
  color: red;
}

.h300 {
  height: 300px !important;
}

.h300 /deep/.el-textarea__inner {
  height: 100%;
}

.mt_btn {
  margin-top: 31px;
}

.avatar-uploader_div {
  width: 148px;
  height: 148px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  text-align: center;
}

.overflow_y {
  overflow-y: auto;
}

.overflow_y::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.overflow_y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  background: rgba(0, 0, 0, 0.2);
}

.overflow_y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.quotation_body {
  box-sizing: border-box;
  padding: 0 20px;
  height: calc(100% - 54px);
}

.quotation_group {
  height: calc(100% - 60px);
}

.quotation {
  display: flex;
  box-sizing: border-box;
  padding: 10px 0;
  margin-bottom: 10px;
  border-bottom: 1px dashed #dedede;
}

.quotation .info {
  flex-shrink: 0;
  width: 92px;
  margin-right: 15px;
}

.quotation .time {
  font-size: 14px;
  color: #666666;
}

.icon_guonei,
.icon_guoji {
  width: 80px;
  margin-top: 5px;
}

.quotation_trend_zhang,
.quotation_trend_die {
  margin-top: 5px;
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  line-height: 17px;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 4px 10px;
  display: inline-flex;
  align-items: center;
}

.quotation_trend_zhang {
  color: #ff3f30;
  background: #ffeceb;
}

.quotation_trend_die {
  color: #09c28e;
  background: #edfaf4;
}

.icon_zhang,
.icon_die {
  width: 10px;
  margin-left: 5px;
}

.quotation_cover {
  width: 150px;
  height: 90px;
  object-fit: cover;
  margin-right: 10px;
}

.quotation .title {
}

.quotation .desc {
  margin-top: 10px;
  color: #111;
}

.quotation_bottom {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.quotation_jiaoyisuo {
  font-size: 12px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #8b30ff;
  line-height: 17px;
  box-sizing: border-box;
  padding: 4px 10px;
  background: #f3eaff;
  border-radius: 13px 0px 13px 0px;
}

.quotation_jiaoyisuo img {
  width: 11px;
  height: 11px;
  margin-right: 5px;
}

.quotation .operation {
  color: red;
  cursor: pointer;
  margin-left: 10px;
}

.is_end {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 12px 0;
  font-size: 12px;
  color: #ccc;
}
</style>
