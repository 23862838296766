<template>
  <div class="bb p30">
    <div class="head flex fw_w">
      <div>
        <div>关键词</div>
        <div class="w300">
          <el-input
            clearable
            size="large"
            v-model="search"
            placeholder="请输入昵称/手机号/真实姓名"
          ></el-input>
        </div>
      </div>
      <div>
        <div>登入时间</div>
        <div>
          <el-date-picker
            format="YYYY-MM-DD"
            value-format="x"
            size="large"
            v-model="time_range"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
      <div>
        <div>所属</div>
        <div>
          <el-radio-group v-model="belong" size="large">
            <el-radio-button label="全部" />
            <el-radio-button label="自己客户" />
            <el-radio-button label="他人客户" />
          </el-radio-group>
        </div>
      </div>
      <div>
        <el-button @click="getUserList(1)" type="primary">搜 索</el-button>
      </div>
    </div>
    <div v-loading="userListLoading">
      <div class="mb20">
        <el-table :data="userList" height="60vh" style="width: 100%">
          <el-table-column
            show-overflow-tooltip
            label="昵称"
            width="120"
            prop="user.nickname"
          >
          </el-table-column>
          <el-table-column label="手机号" width="120" prop="user.mobile">
          </el-table-column>
          <el-table-column label="加入圈子时间" width="170" prop="createtime">
          </el-table-column>
          <el-table-column label="登入时间" width="170" prop="user.logintime">
          </el-table-column>
          <el-table-column label="研究员" width="140">
            <template #default="scope">
              {{ scope.row.teacher.id ? scope.row.teacher.teacher_name : "-" }}
            </template>
          </el-table-column>
          <el-table-column label="实盘信息" width="130">
            <template #default="scope">
              {{ scope.row.offerInfo.info.name }}<br />{{
                scope.row.offerInfo.info.phone
              }}
            </template>
          </el-table-column>
          <el-table-column label="期末权益" width="130">
            <template #default="scope">
              <div
                v-for="(item, index) in scope.row.offerInfo.funds"
                :key="index"
              >
                <div>天数：{{ item.dormancy_days }}</div>
                <div>权益：{{ item.last_money }}</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-size="15"
          layout="total, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/circleManage";
import * as ServiceC from "../api/chat";
export default {
  data() {
    return {
      userListLoading: false,
      userList: [],
      page: 1,
      total: 0,
      search: "", // 关键词
      belong: "全部", // 0全部1自己客户2他人客户
      time_range: [], // 登入时间范围
      userInfo: [],
    };
  },
  mounted() {
    this.getTeacherInfo();
  },
  methods: {
    // 获取用户信息
    async getTeacherInfo() {
      const res = await ServiceC.getTeacherInfo();
      // console.log("获取研究员信息A", res);
      this.userInfo = res.data.userinfo;
    },
    // 获取圈子人员
    async getUserList(page) {
      this.userListLoading = true;
      var belongVal = null;
      switch (this.belong) {
        case "全部":
          belongVal = 0;
          break;
        case "自己客户":
          belongVal = 1;
          break;
        case "他人客户":
          belongVal = 2;
          break;
      }
      var time_range0 = this.time_range ? [...this.time_range] : [];
      if (time_range0.length) {
        time_range0[0] = time_range0[0] / 1000;
        time_range0[1] = time_range0[1] / 1000;
      }
      console.log("@", time_range0);
      const res = await Service.getCircleUserList({
        page,
        search: this.search,
        is_teacher: 1,
        belong: belongVal,
        login_time_range: time_range0,
        uid: this.userInfo.id,
        search_offer: 1,
      });
      this.page = page;
      this.total = res.data.total;
      this.userList = res.data.data;
      this.userListLoading = false;
    },
    handleCurrentChange(val) {
      this.getUserList(val);
    },
  },
};
</script>

<style scoped>
.head > div {
  margin: 0 30px 20px 0;
  display: flex;
  align-items: center;
}

.head > div > div:first-child {
  margin-right: 10px;
  /* margin-bottom: 10px; */
}

.w300 {
  width: 300px;
}
</style>
