(function (root, factory) {
  if (typeof define === "function" && define.amd) {
    // AMD. Register as an anonymous module.
    define(["exports", "echarts"], factory);
  } else if (
    typeof exports === "object" &&
    typeof exports.nodeName !== "string"
  ) {
    // CommonJS
    factory(exports, require("echarts"));
  } else {
    // Browser globals
    factory({}, root.echarts);
  }
})(this, function (exports, echarts) {
  var log = function (msg) {
    if (typeof console !== "undefined") {
      console && console.error && console.error(msg);
    }
  };
  if (!echarts) {
    log("ECharts is not Loaded");
    return;
  }
  if (!echarts.registerMap) {
    log("ECharts Map is not loaded");
    return;
  }
  echarts.registerMap("taizhou", {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {
          adcode: 321202,
          name: "海陵区",
          center: [119.920187, 32.488406],
          centroid: [119.916381, 32.509368],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 321200 },
          subFeatureIndex: 0,
          acroutes: [100000, 320000, 321200],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.856201, 32.379729],
                [119.8535, 32.383651],
                [119.851294, 32.384942],
                [119.851138, 32.387664],
                [119.84964, 32.391097],
                [119.846194, 32.389819],
                [119.844577, 32.395961],
                [119.847481, 32.396167],
                [119.847132, 32.39818],
                [119.849548, 32.400051],
                [119.849264, 32.401276],
                [119.853885, 32.401676],
                [119.854069, 32.40414],
                [119.851744, 32.411171],
                [119.85349, 32.411481],
                [119.855889, 32.413764],
                [119.856118, 32.415621],
                [119.861025, 32.417105],
                [119.8638, 32.417118],
                [119.864333, 32.422445],
                [119.867016, 32.422277],
                [119.866382, 32.426559],
                [119.864857, 32.427346],
                [119.866483, 32.434646],
                [119.868496, 32.438721],
                [119.866676, 32.440836],
                [119.86267, 32.442087],
                [119.859252, 32.444472],
                [119.856752, 32.450494],
                [119.856311, 32.453499],
                [119.853389, 32.462756],
                [119.85066, 32.465399],
                [119.840543, 32.46447],
                [119.829673, 32.460345],
                [119.81985, 32.465502],
                [119.814539, 32.468854],
                [119.817727, 32.477903],
                [119.817562, 32.489375],
                [119.818187, 32.491656],
                [119.824546, 32.496322],
                [119.825244, 32.501348],
                [119.825143, 32.50618],
                [119.824417, 32.508719],
                [119.825042, 32.511231],
                [119.824343, 32.514698],
                [119.824095, 32.523652],
                [119.822938, 32.529192],
                [119.823406, 32.535376],
                [119.830417, 32.543002],
                [119.832898, 32.544779],
                [119.835508, 32.549764],
                [119.835388, 32.554581],
                [119.834368, 32.556448],
                [119.827174, 32.559153],
                [119.824077, 32.563596],
                [119.819777, 32.57243],
                [119.81566, 32.578739],
                [119.816377, 32.584005],
                [119.818123, 32.587636],
                [119.820172, 32.594086],
                [119.818848, 32.59424],
                [119.818729, 32.596995],
                [119.816312, 32.597716],
                [119.817011, 32.598965],
                [119.814962, 32.600291],
                [119.815081, 32.602878],
                [119.813684, 32.604346],
                [119.81577, 32.605659],
                [119.821118, 32.605092],
                [119.821771, 32.607268],
                [119.817994, 32.615402],
                [119.816119, 32.614887],
                [119.815871, 32.620035],
                [119.814263, 32.62369],
                [119.814383, 32.627075],
                [119.825538, 32.628413],
                [119.828726, 32.629366],
                [119.828359, 32.632518],
                [119.830123, 32.6323],
                [119.83299, 32.640895],
                [119.844614, 32.640342],
                [119.844513, 32.638592],
                [119.846755, 32.638772],
                [119.846737, 32.636739],
                [119.853279, 32.63661],
                [119.855668, 32.63441],
                [119.855034, 32.631888],
                [119.860051, 32.631463],
                [119.861099, 32.629533],
                [119.864159, 32.629147],
                [119.866603, 32.627615],
                [119.867081, 32.63091],
                [119.870324, 32.630125],
                [119.869286, 32.625183],
                [119.868018, 32.623575],
                [119.870379, 32.623086],
                [119.869534, 32.619379],
                [119.875525, 32.619495],
                [119.876655, 32.615968],
                [119.881581, 32.617127],
                [119.881489, 32.618954],
                [119.887342, 32.619315],
                [119.890549, 32.620344],
                [119.89405, 32.619945],
                [119.893976, 32.618375],
                [119.898644, 32.616509],
                [119.898791, 32.619443],
                [119.900629, 32.623961],
                [119.902917, 32.623768],
                [119.911867, 32.625145],
                [119.913935, 32.619932],
                [119.907438, 32.619855],
                [119.906629, 32.616458],
                [119.908329, 32.615685],
                [119.908293, 32.613639],
                [119.912464, 32.612043],
                [119.916572, 32.612455],
                [119.919549, 32.604075],
                [119.924373, 32.605285],
                [119.925145, 32.601411],
                [119.927589, 32.601565],
                [119.928242, 32.600304],
                [119.931954, 32.600432],
                [119.928885, 32.597433],
                [119.932101, 32.597523],
                [119.930925, 32.593571],
                [119.932533, 32.593378],
                [119.930612, 32.590636],
                [119.933158, 32.590288],
                [119.932818, 32.591627],
                [119.936723, 32.592593],
                [119.939388, 32.590855],
                [119.939351, 32.589039],
                [119.947235, 32.590584],
                [119.947639, 32.585692],
                [119.949247, 32.585512],
                [119.949578, 32.58013],
                [119.948108, 32.575803],
                [119.947602, 32.571516],
                [119.948595, 32.570769],
                [119.948677, 32.563841],
                [119.949725, 32.561226],
                [119.959603, 32.559385],
                [119.961965, 32.546788],
                [119.966164, 32.542808],
                [119.977733, 32.557376],
                [119.981757, 32.564085],
                [119.987133, 32.569339],
                [119.990156, 32.569133],
                [119.997093, 32.560891],
                [120.000787, 32.559565],
                [120.005713, 32.564716],
                [120.006714, 32.56773],
                [120.006383, 32.571528],
                [120.007504, 32.573537],
                [120.014322, 32.574117],
                [120.025294, 32.572803],
                [120.030329, 32.568129],
                [120.032562, 32.56178],
                [120.029769, 32.5544],
                [120.03111, 32.551606],
                [120.035025, 32.549725],
                [120.036146, 32.54626],
                [120.034464, 32.540902],
                [120.03519, 32.534268],
                [120.044361, 32.517867],
                [120.045087, 32.513357],
                [120.041953, 32.506979],
                [120.042008, 32.505974],
                [120.037754, 32.502868],
                [120.026626, 32.496979],
                [120.026773, 32.495162],
                [120.033362, 32.493718],
                [120.03361, 32.490174],
                [120.039105, 32.489749],
                [120.04392, 32.482479],
                [120.037451, 32.48119],
                [120.03519, 32.479411],
                [120.036449, 32.476537],
                [120.025956, 32.458604],
                [120.022932, 32.456129],
                [120.016105, 32.453099],
                [120.012301, 32.450636],
                [120.009315, 32.446213],
                [120.002405, 32.439456],
                [120.007082, 32.434439],
                [120.009912, 32.433188],
                [119.987261, 32.43284],
                [119.986875, 32.43186],
                [119.981923, 32.43186],
                [119.981868, 32.430596],
                [119.979037, 32.430699],
                [119.977374, 32.4291],
                [119.97232, 32.432711],
                [119.962341, 32.432453],
                [119.962801, 32.42781],
                [119.958427, 32.42603],
                [119.947143, 32.41971],
                [119.948834, 32.412513],
                [119.940132, 32.410861],
                [119.94015, 32.408656],
                [119.932285, 32.407688],
                [119.931742, 32.413648],
                [119.928315, 32.41348],
                [119.92724, 32.416847],
                [119.922627, 32.414835],
                [119.924548, 32.410965],
                [119.920697, 32.408385],
                [119.915451, 32.407791],
                [119.90458, 32.405456],
                [119.905049, 32.402154],
                [119.909561, 32.398825],
                [119.910185, 32.39329],
                [119.907052, 32.385522],
                [119.902715, 32.379367],
                [119.901392, 32.373663],
                [119.901677, 32.371366],
                [119.897753, 32.372979],
                [119.893535, 32.373844],
                [119.88635, 32.372644],
                [119.882518, 32.373147],
                [119.883446, 32.38378],
                [119.884806, 32.388013],
                [119.881709, 32.387716],
                [119.876508, 32.381884],
                [119.875057, 32.375664],
                [119.875635, 32.374476],
                [119.871188, 32.373728],
                [119.867108, 32.373947],
                [119.865463, 32.372218],
                [119.861328, 32.373483],
                [119.856201, 32.379729],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 321203,
          name: "高港区",
          center: [119.88166, 32.315701],
          centroid: [119.944702, 32.32073],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 321200 },
          subFeatureIndex: 1,
          acroutes: [100000, 320000, 321200],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.856201, 32.379729],
                [119.861328, 32.373483],
                [119.865463, 32.372218],
                [119.867108, 32.373947],
                [119.871188, 32.373728],
                [119.875635, 32.374476],
                [119.875057, 32.375664],
                [119.876508, 32.381884],
                [119.881709, 32.387716],
                [119.884806, 32.388013],
                [119.883446, 32.38378],
                [119.882518, 32.373147],
                [119.88635, 32.372644],
                [119.893535, 32.373844],
                [119.897753, 32.372979],
                [119.901677, 32.371366],
                [119.901392, 32.373663],
                [119.902715, 32.379367],
                [119.907052, 32.385522],
                [119.910185, 32.39329],
                [119.909561, 32.398825],
                [119.905049, 32.402154],
                [119.90458, 32.405456],
                [119.915451, 32.407791],
                [119.920697, 32.408385],
                [119.924548, 32.410965],
                [119.922627, 32.414835],
                [119.92724, 32.416847],
                [119.928315, 32.41348],
                [119.931742, 32.413648],
                [119.932285, 32.407688],
                [119.94015, 32.408656],
                [119.940132, 32.410861],
                [119.948834, 32.412513],
                [119.947143, 32.41971],
                [119.958427, 32.42603],
                [119.962801, 32.42781],
                [119.962341, 32.432453],
                [119.97232, 32.432711],
                [119.977374, 32.4291],
                [119.979037, 32.430699],
                [119.981868, 32.430596],
                [119.981923, 32.43186],
                [119.986875, 32.43186],
                [119.987261, 32.43284],
                [120.009912, 32.433188],
                [120.012567, 32.431344],
                [120.014947, 32.428043],
                [120.022473, 32.421232],
                [120.021701, 32.418691],
                [120.004968, 32.417131],
                [120.007256, 32.409352],
                [120.015223, 32.400528],
                [120.018053, 32.39867],
                [120.024853, 32.397509],
                [120.029668, 32.392658],
                [120.033555, 32.387768],
                [120.03803, 32.3836],
                [120.038994, 32.381961],
                [120.038195, 32.379922],
                [120.033132, 32.374115],
                [120.024623, 32.368501],
                [120.021628, 32.365997],
                [120.021646, 32.361119],
                [120.02273, 32.357956],
                [120.025055, 32.357388],
                [120.031735, 32.358886],
                [120.047586, 32.351038],
                [120.053917, 32.348972],
                [120.061764, 32.349269],
                [120.074133, 32.350934],
                [120.071422, 32.347281],
                [120.073315, 32.342479],
                [120.071137, 32.336101],
                [120.06839, 32.329723],
                [120.0702, 32.323719],
                [120.077716, 32.315571],
                [120.07779, 32.310251],
                [120.080537, 32.3071],
                [120.08187, 32.303354],
                [120.079848, 32.30028],
                [120.073875, 32.292931],
                [120.065394, 32.287351],
                [120.051372, 32.283566],
                [120.047411, 32.278476],
                [120.042394, 32.274833],
                [120.039491, 32.275802],
                [120.035852, 32.282403],
                [120.030918, 32.286266],
                [120.022096, 32.287286],
                [120.015949, 32.288642],
                [120.013201, 32.292505],
                [120.013477, 32.296263],
                [120.012264, 32.303845],
                [120.007008, 32.308107],
                [119.9994, 32.31171],
                [119.99135, 32.313182],
                [119.98512, 32.312601],
                [119.979138, 32.309592],
                [119.97493, 32.306118],
                [119.969757, 32.306183],
                [119.958114, 32.312046],
                [119.952693, 32.31357],
                [119.946555, 32.313879],
                [119.943706, 32.311374],
                [119.945388, 32.308468],
                [119.944441, 32.303277],
                [119.945406, 32.297],
                [119.944524, 32.294442],
                [119.942843, 32.294403],
                [119.943568, 32.289792],
                [119.952188, 32.28686],
                [119.951848, 32.28261],
                [119.9508, 32.282584],
                [119.948347, 32.278088],
                [119.946729, 32.276512],
                [119.948632, 32.276202],
                [119.947189, 32.272624],
                [119.954062, 32.271848],
                [119.95329, 32.268515],
                [119.950635, 32.267779],
                [119.947161, 32.268089],
                [119.94672, 32.264755],
                [119.947777, 32.264548],
                [119.946252, 32.261745],
                [119.950782, 32.25319],
                [119.950938, 32.244984],
                [119.953658, 32.241417],
                [119.955413, 32.241198],
                [119.954834, 32.237462],
                [119.952932, 32.237075],
                [119.948034, 32.231336],
                [119.949569, 32.230535],
                [119.946555, 32.22994],
                [119.946978, 32.228104],
                [119.942328, 32.214816],
                [119.939856, 32.205869],
                [119.93777, 32.201564],
                [119.93483, 32.197387],
                [119.93188, 32.194905],
                [119.92894, 32.190611],
                [119.925981, 32.181921],
                [119.919374, 32.173799],
                [119.919255, 32.172325],
                [119.921368, 32.171031],
                [119.905168, 32.152262],
                [119.901888, 32.159765],
                [119.90096, 32.16393],
                [119.89745, 32.171807],
                [119.894068, 32.182904],
                [119.893499, 32.190042],
                [119.89258, 32.194646],
                [119.891992, 32.201085],
                [119.887489, 32.211532],
                [119.886717, 32.220491],
                [119.885559, 32.226036],
                [119.882858, 32.233675],
                [119.878475, 32.247543],
                [119.870821, 32.260129],
                [119.869552, 32.262688],
                [119.866695, 32.26575],
                [119.8527, 32.281938],
                [119.845092, 32.287867],
                [119.843006, 32.290218],
                [119.839836, 32.291898],
                [119.834571, 32.295708],
                [119.826337, 32.298911],
                [119.819179, 32.302528],
                [119.822588, 32.306118],
                [119.826448, 32.308546],
                [119.831428, 32.312549],
                [119.833606, 32.313182],
                [119.84386, 32.321873],
                [119.846406, 32.325475],
                [119.847315, 32.328432],
                [119.843759, 32.334952],
                [119.841931, 32.336837],
                [119.842004, 32.33889],
                [119.844807, 32.34204],
                [119.844347, 32.34457],
                [119.84477, 32.349385],
                [119.84443, 32.355362],
                [119.84273, 32.36068],
                [119.846433, 32.363468],
                [119.853711, 32.361506],
                [119.856201, 32.379729],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 321204,
          name: "姜堰区",
          center: [120.148208, 32.508483],
          centroid: [120.115832, 32.515906],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 321200 },
          subFeatureIndex: 2,
          acroutes: [100000, 320000, 321200],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.911867, 32.625145],
                [119.904112, 32.635517],
                [119.902853, 32.638064],
                [119.897174, 32.646248],
                [119.897064, 32.651072],
                [119.899159, 32.65699],
                [119.899333, 32.661801],
                [119.898148, 32.667114],
                [119.894877, 32.674317],
                [119.897652, 32.676324],
                [119.912308, 32.676877],
                [119.924254, 32.676825],
                [119.932625, 32.675706],
                [119.934536, 32.678047],
                [119.938946, 32.6777],
                [119.940169, 32.678896],
                [119.943642, 32.678626],
                [119.942696, 32.672877],
                [119.950304, 32.671745],
                [119.950478, 32.669584],
                [119.954779, 32.670407],
                [119.957591, 32.674176],
                [119.957738, 32.676041],
                [119.960311, 32.675513],
                [119.970473, 32.675436],
                [119.973607, 32.674613],
                [119.975463, 32.671436],
                [119.983246, 32.672838],
                [119.983604, 32.674472],
                [119.987353, 32.674407],
                [119.988667, 32.672208],
                [119.991231, 32.67177],
                [119.991598, 32.668851],
                [119.990358, 32.667076],
                [119.992508, 32.667063],
                [119.992756, 32.664966],
                [119.996496, 32.665841],
                [119.996919, 32.663834],
                [119.999188, 32.666111],
                [120.00415, 32.66723],
                [120.009783, 32.667024],
                [120.028777, 32.674381],
                [120.033803, 32.677327],
                [120.030348, 32.686831],
                [120.030706, 32.690741],
                [120.033463, 32.695666],
                [120.039454, 32.701388],
                [120.049286, 32.706338],
                [120.064016, 32.711468],
                [120.077523, 32.713319],
                [120.081906, 32.712792],
                [120.084773, 32.70945],
                [120.084773, 32.703715],
                [120.090856, 32.701632],
                [120.094357, 32.702211],
                [120.098998, 32.695949],
                [120.09784, 32.686047],
                [120.098345, 32.684503],
                [120.104263, 32.686137],
                [120.108021, 32.686227],
                [120.113617, 32.685507],
                [120.119066, 32.685545],
                [120.124754, 32.684336],
                [120.121676, 32.679192],
                [120.122539, 32.671295],
                [120.122144, 32.664902],
                [120.119075, 32.657428],
                [120.119415, 32.655292],
                [120.121767, 32.653517],
                [120.122797, 32.647959],
                [120.122429, 32.641757],
                [120.120821, 32.64195],
                [120.120251, 32.637189],
                [120.120959, 32.633252],
                [120.114646, 32.630704],
                [120.113415, 32.633471],
                [120.109105, 32.630498],
                [120.107828, 32.62804],
                [120.109657, 32.62741],
                [120.111715, 32.623253],
                [120.113194, 32.613806],
                [120.122686, 32.610267],
                [120.126573, 32.610859],
                [120.1316, 32.614038],
                [120.1376, 32.614424],
                [120.140843, 32.616277],
                [120.146292, 32.615634],
                [120.15436, 32.61602],
                [120.161592, 32.617912],
                [120.17634, 32.621232],
                [120.176, 32.629121],
                [120.184867, 32.629507],
                [120.186585, 32.623935],
                [120.192227, 32.622764],
                [120.193303, 32.621052],
                [120.197245, 32.619263],
                [120.197392, 32.614656],
                [120.195425, 32.606688],
                [120.196013, 32.603033],
                [120.201618, 32.601282],
                [120.203594, 32.599724],
                [120.205276, 32.595425],
                [120.207012, 32.594176],
                [120.207655, 32.59155],
                [120.210605, 32.58967],
                [120.216302, 32.588511],
                [120.221025, 32.583001],
                [120.224609, 32.581829],
                [120.226731, 32.582692],
                [120.230582, 32.586825],
                [120.237538, 32.587932],
                [120.240101, 32.591215],
                [120.245072, 32.593352],
                [120.248803, 32.593816],
                [120.25291, 32.597575],
                [120.25575, 32.597613],
                [120.259095, 32.59209],
                [120.259609, 32.589928],
                [120.258938, 32.584057],
                [120.262017, 32.578057],
                [120.262844, 32.574876],
                [120.260666, 32.570627],
                [120.265049, 32.56357],
                [120.262311, 32.562025],
                [120.266501, 32.554826],
                [120.26809, 32.551039],
                [120.270084, 32.548695],
                [120.269974, 32.54532],
                [120.267034, 32.535118],
                [120.264856, 32.522583],
                [120.264801, 32.519246],
                [120.26368, 32.514569],
                [120.265646, 32.512919],
                [120.264847, 32.50752],
                [120.267953, 32.505858],
                [120.270121, 32.501799],
                [120.267631, 32.499595],
                [120.258341, 32.498938],
                [120.257542, 32.492945],
                [120.258663, 32.490818],
                [120.261723, 32.490767],
                [120.264323, 32.48748],
                [120.269156, 32.47606],
                [120.269818, 32.473469],
                [120.271371, 32.472296],
                [120.271316, 32.465102],
                [120.272841, 32.461583],
                [120.271913, 32.459017],
                [120.275083, 32.442293],
                [120.275166, 32.438902],
                [120.27874, 32.436232],
                [120.280624, 32.431009],
                [120.279916, 32.421439],
                [120.281644, 32.413764],
                [120.281671, 32.410371],
                [120.283307, 32.404837],
                [120.283574, 32.40018],
                [120.285384, 32.396722],
                [120.289363, 32.386129],
                [120.287837, 32.382555],
                [120.284557, 32.379871],
                [120.286165, 32.373921],
                [120.292579, 32.369534],
                [120.296052, 32.369611],
                [120.299755, 32.371831],
                [120.302539, 32.372502],
                [120.304717, 32.367533],
                [120.310102, 32.361545],
                [120.297118, 32.361945],
                [120.294582, 32.361557],
                [120.293773, 32.35988],
                [120.287929, 32.361648],
                [120.287883, 32.36046],
                [120.283528, 32.361506],
                [120.283013, 32.359092],
                [120.280054, 32.359234],
                [120.278529, 32.353697],
                [120.273631, 32.353452],
                [120.270397, 32.354084],
                [120.269818, 32.351296],
                [120.272997, 32.350341],
                [120.274311, 32.33982],
                [120.269423, 32.339626],
                [120.268789, 32.340801],
                [120.269313, 32.345306],
                [120.267263, 32.345435],
                [120.266271, 32.34],
                [120.259425, 32.339458],
                [120.257257, 32.338309],
                [120.248362, 32.338722],
                [120.246772, 32.336605],
                [120.244732, 32.336592],
                [120.243997, 32.340026],
                [120.235047, 32.341653],
                [120.233743, 32.34297],
                [120.228238, 32.345087],
                [120.22731, 32.349334],
                [120.221338, 32.34865],
                [120.216486, 32.352548],
                [120.214584, 32.35482],
                [120.211937, 32.355672],
                [120.211515, 32.353606],
                [120.209705, 32.352638],
                [120.209695, 32.357427],
                [120.206746, 32.364087],
                [120.203769, 32.362603],
                [120.198329, 32.361583],
                [120.196813, 32.362809],
                [120.193633, 32.362848],
                [120.189195, 32.361777],
                [120.184086, 32.359376],
                [120.183296, 32.360318],
                [120.180649, 32.35846],
                [120.177773, 32.358072],
                [120.179078, 32.355956],
                [120.17634, 32.354755],
                [120.174061, 32.356123],
                [120.174217, 32.352535],
                [120.166168, 32.352522],
                [120.166048, 32.350728],
                [120.162152, 32.350018],
                [120.158973, 32.350457],
                [120.157944, 32.351851],
                [120.159221, 32.354316],
                [120.150813, 32.354381],
                [120.145769, 32.353516],
                [120.145144, 32.349631],
                [120.141285, 32.350173],
                [120.141239, 32.347978],
                [120.138804, 32.348262],
                [120.139309, 32.350431],
                [120.136451, 32.350818],
                [120.136212, 32.349114],
                [120.131324, 32.349876],
                [120.131305, 32.351167],
                [120.126536, 32.352316],
                [120.124138, 32.348391],
                [120.120297, 32.349928],
                [120.117072, 32.35331],
                [120.119084, 32.363158],
                [120.113718, 32.363687],
                [120.110612, 32.361273],
                [120.098952, 32.362938],
                [120.099521, 32.364449],
                [120.094247, 32.365507],
                [120.092492, 32.362951],
                [120.088044, 32.365675],
                [120.084589, 32.362371],
                [120.081695, 32.363894],
                [120.078479, 32.361648],
                [120.077082, 32.357453],
                [120.074133, 32.350934],
                [120.061764, 32.349269],
                [120.053917, 32.348972],
                [120.047586, 32.351038],
                [120.031735, 32.358886],
                [120.025055, 32.357388],
                [120.02273, 32.357956],
                [120.021646, 32.361119],
                [120.021628, 32.365997],
                [120.024623, 32.368501],
                [120.033132, 32.374115],
                [120.038195, 32.379922],
                [120.038994, 32.381961],
                [120.03803, 32.3836],
                [120.033555, 32.387768],
                [120.029668, 32.392658],
                [120.024853, 32.397509],
                [120.018053, 32.39867],
                [120.015223, 32.400528],
                [120.007256, 32.409352],
                [120.004968, 32.417131],
                [120.021701, 32.418691],
                [120.022473, 32.421232],
                [120.014947, 32.428043],
                [120.012567, 32.431344],
                [120.009912, 32.433188],
                [120.007082, 32.434439],
                [120.002405, 32.439456],
                [120.009315, 32.446213],
                [120.012301, 32.450636],
                [120.016105, 32.453099],
                [120.022932, 32.456129],
                [120.025956, 32.458604],
                [120.036449, 32.476537],
                [120.03519, 32.479411],
                [120.037451, 32.48119],
                [120.04392, 32.482479],
                [120.039105, 32.489749],
                [120.03361, 32.490174],
                [120.033362, 32.493718],
                [120.026773, 32.495162],
                [120.026626, 32.496979],
                [120.037754, 32.502868],
                [120.042008, 32.505974],
                [120.041953, 32.506979],
                [120.045087, 32.513357],
                [120.044361, 32.517867],
                [120.03519, 32.534268],
                [120.034464, 32.540902],
                [120.036146, 32.54626],
                [120.035025, 32.549725],
                [120.03111, 32.551606],
                [120.029769, 32.5544],
                [120.032562, 32.56178],
                [120.030329, 32.568129],
                [120.025294, 32.572803],
                [120.014322, 32.574117],
                [120.007504, 32.573537],
                [120.006383, 32.571528],
                [120.006714, 32.56773],
                [120.005713, 32.564716],
                [120.000787, 32.559565],
                [119.997093, 32.560891],
                [119.990156, 32.569133],
                [119.987133, 32.569339],
                [119.981757, 32.564085],
                [119.977733, 32.557376],
                [119.966164, 32.542808],
                [119.961965, 32.546788],
                [119.959603, 32.559385],
                [119.949725, 32.561226],
                [119.948677, 32.563841],
                [119.948595, 32.570769],
                [119.947602, 32.571516],
                [119.948108, 32.575803],
                [119.949578, 32.58013],
                [119.949247, 32.585512],
                [119.947639, 32.585692],
                [119.947235, 32.590584],
                [119.939351, 32.589039],
                [119.939388, 32.590855],
                [119.936723, 32.592593],
                [119.932818, 32.591627],
                [119.933158, 32.590288],
                [119.930612, 32.590636],
                [119.932533, 32.593378],
                [119.930925, 32.593571],
                [119.932101, 32.597523],
                [119.928885, 32.597433],
                [119.931954, 32.600432],
                [119.928242, 32.600304],
                [119.927589, 32.601565],
                [119.925145, 32.601411],
                [119.924373, 32.605285],
                [119.919549, 32.604075],
                [119.916572, 32.612455],
                [119.912464, 32.612043],
                [119.908293, 32.613639],
                [119.908329, 32.615685],
                [119.906629, 32.616458],
                [119.907438, 32.619855],
                [119.913935, 32.619932],
                [119.911867, 32.625145],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 321281,
          name: "兴化市",
          center: [119.840162, 32.938065],
          centroid: [119.989627, 32.955393],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 321200 },
          subFeatureIndex: 3,
          acroutes: [100000, 320000, 321200],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [120.124754, 32.684336],
                [120.119066, 32.685545],
                [120.113617, 32.685507],
                [120.108021, 32.686227],
                [120.104263, 32.686137],
                [120.098345, 32.684503],
                [120.09784, 32.686047],
                [120.098998, 32.695949],
                [120.094357, 32.702211],
                [120.090856, 32.701632],
                [120.084773, 32.703715],
                [120.084773, 32.70945],
                [120.081906, 32.712792],
                [120.077523, 32.713319],
                [120.064016, 32.711468],
                [120.049286, 32.706338],
                [120.039454, 32.701388],
                [120.033463, 32.695666],
                [120.030706, 32.690741],
                [120.030348, 32.686831],
                [120.033803, 32.677327],
                [120.028777, 32.674381],
                [120.009783, 32.667024],
                [120.00415, 32.66723],
                [119.999188, 32.666111],
                [119.996919, 32.663834],
                [119.996496, 32.665841],
                [119.992756, 32.664966],
                [119.992508, 32.667063],
                [119.990358, 32.667076],
                [119.991598, 32.668851],
                [119.991231, 32.67177],
                [119.988667, 32.672208],
                [119.987353, 32.674407],
                [119.983604, 32.674472],
                [119.983246, 32.672838],
                [119.975463, 32.671436],
                [119.973607, 32.674613],
                [119.970473, 32.675436],
                [119.960311, 32.675513],
                [119.957738, 32.676041],
                [119.957591, 32.674176],
                [119.954779, 32.670407],
                [119.950478, 32.669584],
                [119.950304, 32.671745],
                [119.942696, 32.672877],
                [119.943642, 32.678626],
                [119.940169, 32.678896],
                [119.938946, 32.6777],
                [119.934536, 32.678047],
                [119.932625, 32.675706],
                [119.924254, 32.676825],
                [119.912308, 32.676877],
                [119.897652, 32.676324],
                [119.894877, 32.674317],
                [119.883483, 32.673906],
                [119.874891, 32.675964],
                [119.870655, 32.679063],
                [119.870471, 32.681365],
                [119.871473, 32.687075],
                [119.873586, 32.693454],
                [119.875792, 32.694547],
                [119.87728, 32.698405],
                [119.874138, 32.701696],
                [119.868845, 32.705284],
                [119.863194, 32.706351],
                [119.858471, 32.710594],
                [119.858462, 32.718629],
                [119.858002, 32.721174],
                [119.853371, 32.727949],
                [119.847021, 32.732474],
                [119.84364, 32.736702],
                [119.842648, 32.739479],
                [119.841784, 32.74593],
                [119.842142, 32.748449],
                [119.845266, 32.75296],
                [119.851331, 32.756019],
                [119.863038, 32.766067],
                [119.866915, 32.768482],
                [119.867007, 32.771014],
                [119.863093, 32.775703],
                [119.855834, 32.777502],
                [119.843704, 32.778953],
                [119.833514, 32.781972],
                [119.827192, 32.787419],
                [119.826163, 32.789051],
                [119.825777, 32.793649],
                [119.824922, 32.794921],
                [119.826797, 32.799596],
                [119.829563, 32.802075],
                [119.830279, 32.806866],
                [119.825703, 32.808124],
                [119.823544, 32.807957],
                [119.812233, 32.801805],
                [119.80649, 32.800572],
                [119.794673, 32.803848],
                [119.790712, 32.807161],
                [119.788645, 32.810205],
                [119.790868, 32.820016],
                [119.794535, 32.824292],
                [119.800903, 32.828272],
                [119.802051, 32.830083],
                [119.802446, 32.833742],
                [119.801491, 32.837671],
                [119.795959, 32.841458],
                [119.796308, 32.843717],
                [119.798605, 32.846336],
                [119.805754, 32.851946],
                [119.797337, 32.857093],
                [119.801206, 32.860276],
                [119.802667, 32.862741],
                [119.801601, 32.866617],
                [119.797852, 32.869979],
                [119.794765, 32.871545],
                [119.795987, 32.877205],
                [119.795445, 32.879168],
                [119.789352, 32.881157],
                [119.787735, 32.889472],
                [119.785741, 32.895028],
                [119.784583, 32.901032],
                [119.785879, 32.907421],
                [119.78542, 32.90973],
                [119.782589, 32.914848],
                [119.77885, 32.916554],
                [119.775293, 32.915951],
                [119.768622, 32.911051],
                [119.765498, 32.907228],
                [119.761869, 32.904085],
                [119.757991, 32.90211],
                [119.751145, 32.900442],
                [119.746192, 32.89871],
                [119.743473, 32.898543],
                [119.739429, 32.899775],
                [119.730213, 32.900673],
                [119.725067, 32.901699],
                [119.721603, 32.903842],
                [119.719508, 32.906189],
                [119.71518, 32.915695],
                [119.714454, 32.918465],
                [119.712387, 32.921954],
                [119.71167, 32.924956],
                [119.712038, 32.927931],
                [119.714757, 32.93637],
                [119.709694, 32.939922],
                [119.707011, 32.941128],
                [119.703042, 32.944885],
                [119.69943, 32.951168],
                [119.697786, 32.959463],
                [119.696747, 32.961335],
                [119.697979, 32.965438],
                [119.70147, 32.972258],
                [119.701406, 32.978923],
                [119.702601, 32.982346],
                [119.705458, 32.986409],
                [119.706653, 32.9896],
                [119.706901, 32.996957],
                [119.708197, 33.003813],
                [119.707994, 33.005658],
                [119.698429, 33.013666],
                [119.695884, 33.019458],
                [119.695332, 33.027516],
                [119.694367, 33.031667],
                [119.690315, 33.041633],
                [119.688147, 33.050176],
                [119.688101, 33.057297],
                [119.685702, 33.067682],
                [119.683938, 33.072535],
                [119.679546, 33.080205],
                [119.669741, 33.08982],
                [119.656886, 33.097437],
                [119.654699, 33.099369],
                [119.653918, 33.097757],
                [119.651952, 33.100777],
                [119.65027, 33.100585],
                [119.64948, 33.104042],
                [119.647743, 33.104118],
                [119.648837, 33.107728],
                [119.648258, 33.112271],
                [119.643719, 33.111273],
                [119.642643, 33.115625],
                [119.640484, 33.117007],
                [119.6403, 33.119234],
                [119.644665, 33.120245],
                [119.649241, 33.120245],
                [119.647265, 33.122983],
                [119.646383, 33.126566],
                [119.649103, 33.128473],
                [119.646154, 33.130111],
                [119.653661, 33.156569],
                [119.658072, 33.156761],
                [119.657263, 33.162351],
                [119.661232, 33.166112],
                [119.667352, 33.168964],
                [119.670173, 33.171612],
                [119.676909, 33.175551],
                [119.683038, 33.181344],
                [119.687733, 33.183544],
                [119.692061, 33.186933],
                [119.698815, 33.199413],
                [119.701856, 33.201804],
                [119.705348, 33.19839],
                [119.70816, 33.198339],
                [119.709097, 33.199745],
                [119.709823, 33.205064],
                [119.711165, 33.207787],
                [119.712635, 33.219088],
                [119.715401, 33.218206],
                [119.722853, 33.217017],
                [119.731472, 33.211648],
                [119.733512, 33.205345],
                [119.740201, 33.204489],
                [119.739632, 33.203082],
                [119.732418, 33.203491],
                [119.730369, 33.193685],
                [119.728798, 33.18871],
                [119.728871, 33.184375],
                [119.728136, 33.178275],
                [119.729046, 33.174489],
                [119.730323, 33.172852],
                [119.738125, 33.169783],
                [119.746459, 33.169719],
                [119.748637, 33.171497],
                [119.751265, 33.17078],
                [119.748021, 33.165933],
                [119.749748, 33.163131],
                [119.753066, 33.160637],
                [119.751384, 33.158744],
                [119.74848, 33.158949],
                [119.745751, 33.156493],
                [119.744382, 33.153563],
                [119.745384, 33.147973],
                [119.739806, 33.147422],
                [119.739742, 33.143124],
                [119.74305, 33.140475],
                [119.746404, 33.133847],
                [119.749252, 33.131007],
                [119.754325, 33.131301],
                [119.757366, 33.132171],
                [119.759985, 33.131032],
                [119.772279, 33.130904],
                [119.780118, 33.136573],
                [119.787331, 33.139106],
                [119.820898, 33.147294],
                [119.844393, 33.153678],
                [119.855402, 33.155316],
                [119.868468, 33.154471],
                [119.869626, 33.151427],
                [119.869856, 33.14796],
                [119.867549, 33.143405],
                [119.874321, 33.140885],
                [119.876417, 33.144032],
                [119.877262, 33.146821],
                [119.88759, 33.14567],
                [119.890255, 33.144301],
                [119.891449, 33.146245],
                [119.891633, 33.149853],
                [119.894417, 33.151068],
                [119.898782, 33.148855],
                [119.903275, 33.148356],
                [119.91659, 33.149469],
                [119.930272, 33.153167],
                [119.936429, 33.155354],
                [119.954246, 33.152437],
                [119.968342, 33.149879],
                [119.972201, 33.149789],
                [119.97833, 33.152169],
                [119.988759, 33.151708],
                [120.001394, 33.15822],
                [120.003434, 33.156032],
                [120.019569, 33.157196],
                [120.023998, 33.158053],
                [120.041852, 33.162633],
                [120.048174, 33.164027],
                [120.078111, 33.180769],
                [120.08084, 33.181792],
                [120.094072, 33.178646],
                [120.120453, 33.169079],
                [120.124101, 33.167327],
                [120.131884, 33.164641],
                [120.145135, 33.160803],
                [120.153395, 33.157209],
                [120.16433, 33.154433],
                [120.174557, 33.151337],
                [120.182212, 33.148561],
                [120.196702, 33.142868],
                [120.214161, 33.137404],
                [120.238815, 33.134884],
                [120.24589, 33.134526],
                [120.247893, 33.134948],
                [120.25699, 33.131199],
                [120.262357, 33.132478],
                [120.267245, 33.132593],
                [120.288866, 33.131557],
                [120.296787, 33.129586],
                [120.29505, 33.126362],
                [120.293654, 33.117992],
                [120.291467, 33.112668],
                [120.289712, 33.104233],
                [120.291733, 33.102749],
                [120.293672, 33.096694],
                [120.293516, 33.088002],
                [120.291494, 33.084737],
                [120.291991, 33.08114],
                [120.295115, 33.077951],
                [120.296668, 33.073944],
                [120.301979, 33.071204],
                [120.305967, 33.067593],
                [120.305177, 33.063738],
                [120.309385, 33.062266],
                [120.309615, 33.060127],
                [120.311921, 33.05421],
                [120.3116, 33.050727],
                [120.313033, 33.048063],
                [120.310773, 33.037905],
                [120.311085, 33.032986],
                [120.310488, 33.028374],
                [120.308898, 33.024403],
                [120.308981, 33.013538],
                [120.310524, 32.999648],
                [120.31239, 32.996123],
                [120.312353, 32.992586],
                [120.309808, 32.984448],
                [120.308099, 32.980628],
                [120.30151, 32.969232],
                [120.299084, 32.966617],
                [120.301225, 32.961617],
                [120.301666, 32.957604],
                [120.300362, 32.954155],
                [120.295308, 32.952681],
                [120.29143, 32.955014],
                [120.287415, 32.955207],
                [120.286376, 32.949373],
                [120.28916, 32.94668],
                [120.295078, 32.945449],
                [120.294398, 32.943077],
                [120.291981, 32.94073],
                [120.292018, 32.933625],
                [120.288122, 32.930445],
                [120.282848, 32.927149],
                [120.280753, 32.924943],
                [120.2673, 32.925841],
                [120.253159, 32.927033],
                [120.238833, 32.928008],
                [120.238438, 32.921146],
                [120.235323, 32.9203],
                [120.234055, 32.918825],
                [120.225068, 32.918106],
                [120.220437, 32.917029],
                [120.216734, 32.919453],
                [120.209594, 32.919312],
                [120.204991, 32.920582],
                [120.204072, 32.922903],
                [120.203309, 32.92956],
                [120.201085, 32.930535],
                [120.176386, 32.93251],
                [120.177057, 32.930996],
                [120.17747, 32.909063],
                [120.181155, 32.905561],
                [120.181853, 32.903919],
                [120.179372, 32.885058],
                [120.177902, 32.882171],
                [120.177066, 32.877756],
                [120.186898, 32.877898],
                [120.188644, 32.876922],
                [120.194479, 32.876088],
                [120.197235, 32.874394],
                [120.200249, 32.874497],
                [120.199753, 32.868978],
                [120.198779, 32.866925],
                [120.208437, 32.86432],
                [120.208933, 32.858826],
                [120.2059, 32.852164],
                [120.203971, 32.851291],
                [120.19526, 32.851343],
                [120.196169, 32.848416],
                [120.194901, 32.842806],
                [120.189462, 32.837658],
                [120.18484, 32.83414],
                [120.18314, 32.831546],
                [120.181035, 32.825268],
                [120.193394, 32.826077],
                [120.193183, 32.822764],
                [120.189462, 32.816908],
                [120.187017, 32.812183],
                [120.180061, 32.812504],
                [120.172242, 32.813403],
                [120.167252, 32.815008],
                [120.165966, 32.812606],
                [120.161096, 32.809935],
                [120.160406, 32.804695],
                [120.163338, 32.804348],
                [120.165552, 32.802961],
                [120.169182, 32.79731],
                [120.168153, 32.793046],
                [120.162832, 32.788485],
                [120.15923, 32.787895],
                [120.158771, 32.78327],
                [120.162474, 32.780958],
                [120.174199, 32.77966],
                [120.174447, 32.778118],
                [120.182956, 32.772928],
                [120.189563, 32.775099],
                [120.197033, 32.781497],
                [120.19934, 32.786957],
                [120.212884, 32.788755],
                [120.222422, 32.792326],
                [120.227531, 32.784323],
                [120.228165, 32.778542],
                [120.223782, 32.773673],
                [120.214639, 32.767429],
                [120.206608, 32.756571],
                [120.205266, 32.753243],
                [120.202574, 32.742974],
                [120.199726, 32.743],
                [120.199496, 32.740957],
                [120.196702, 32.742473],
                [120.196133, 32.739337],
                [120.193872, 32.739029],
                [120.193698, 32.740417],
                [120.189498, 32.739761],
                [120.187559, 32.74273],
                [120.180852, 32.741843],
                [120.180631, 32.73854],
                [120.181706, 32.72318],
                [120.175495, 32.718372],
                [120.173234, 32.715672],
                [120.168943, 32.717613],
                [120.164799, 32.720339],
                [120.155389, 32.714181],
                [120.156226, 32.711378],
                [120.159322, 32.707058],
                [120.159019, 32.704551],
                [120.157586, 32.703252],
                [120.147239, 32.705811],
                [120.14507, 32.705875],
                [120.143821, 32.698405],
                [120.141174, 32.691988],
                [120.138096, 32.687166],
                [120.131618, 32.68912],
                [120.129504, 32.689069],
                [120.124754, 32.684336],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 321282,
          name: "靖江市",
          center: [120.26825, 32.018168],
          centroid: [120.266754, 32.033953],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 321200 },
          subFeatureIndex: 4,
          acroutes: [100000, 320000, 321200],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [120.022399, 31.967753],
                [120.025202, 31.97566],
                [120.02953, 31.985303],
                [120.034225, 31.996565],
                [120.038158, 31.996047],
                [120.038627, 32.000815],
                [120.040024, 32.002668],
                [120.04686, 32.007165],
                [120.050958, 32.011207],
                [120.059798, 32.027518],
                [120.061397, 32.032648],
                [120.058236, 32.033581],
                [120.05592, 32.036236],
                [120.052364, 32.037001],
                [120.05218, 32.040433],
                [120.05411, 32.044643],
                [120.056867, 32.048658],
                [120.066625, 32.057995],
                [120.072129, 32.061207],
                [120.07677, 32.065286],
                [120.081493, 32.067772],
                [120.086795, 32.069818],
                [120.090195, 32.071786],
                [120.095478, 32.073832],
                [120.106174, 32.075618],
                [120.111017, 32.075813],
                [120.122438, 32.078998],
                [120.129321, 32.081328],
                [120.134549, 32.08428],
                [120.146008, 32.086533],
                [120.148084, 32.083167],
                [120.150308, 32.081885],
                [120.155592, 32.083684],
                [120.159653, 32.083374],
                [120.165791, 32.084293],
                [120.172683, 32.086377],
                [120.179391, 32.086856],
                [120.189021, 32.088125],
                [120.196794, 32.08863],
                [120.200387, 32.086934],
                [120.206194, 32.089212],
                [120.212617, 32.089899],
                [120.216927, 32.089834],
                [120.22494, 32.091271],
                [120.226493, 32.092475],
                [120.229222, 32.091892],
                [120.235746, 32.096035],
                [120.243428, 32.098611],
                [120.246726, 32.102403],
                [120.252828, 32.104694],
                [120.257689, 32.107788],
                [120.26152, 32.114246],
                [120.281745, 32.120639],
                [120.285586, 32.1206],
                [120.286422, 32.122632],
                [120.289408, 32.122257],
                [120.288637, 32.12346],
                [120.291549, 32.124716],
                [120.298901, 32.126255],
                [120.296208, 32.129866],
                [120.296796, 32.132596],
                [120.301869, 32.135896],
                [120.302953, 32.137629],
                [120.30027, 32.14111],
                [120.308549, 32.144901],
                [120.310506, 32.142624],
                [120.313355, 32.143542],
                [120.314007, 32.142054],
                [120.316433, 32.143465],
                [120.318096, 32.140541],
                [120.318978, 32.140916],
                [120.321174, 32.137293],
                [120.316552, 32.135611],
                [120.317848, 32.133877],
                [120.324629, 32.134201],
                [120.339451, 32.130422],
                [120.353105, 32.130306],
                [120.356193, 32.130746],
                [120.360714, 32.130759],
                [120.374414, 32.12808],
                [120.376316, 32.129361],
                [120.387894, 32.129672],
                [120.40958, 32.126268],
                [120.417032, 32.124858],
                [120.427866, 32.123745],
                [120.435189, 32.123926],
                [120.440758, 32.11985],
                [120.482254, 32.102921],
                [120.486748, 32.099154],
                [120.510565, 32.092656],
                [120.512835, 32.09175],
                [120.515683, 32.080422],
                [120.516253, 32.079671],
                [120.518458, 32.065894],
                [120.520783, 32.062398],
                [120.523834, 32.059199],
                [120.529384, 32.056428],
                [120.537139, 32.055703],
                [120.539611, 32.05604],
                [120.550491, 32.050937],
                [120.550077, 32.044578],
                [120.547918, 32.041068],
                [120.558503, 32.033736],
                [120.559101, 32.032791],
                [120.557805, 32.028568],
                [120.553128, 32.021145],
                [120.524633, 32.030446],
                [120.503793, 32.041016],
                [120.465669, 32.045821],
                [120.403763, 32.016221],
                [120.387858, 32.006659],
                [120.380516, 31.998289],
                [120.370702, 31.990824],
                [120.35306, 31.980637],
                [120.294159, 31.954893],
                [120.262991, 31.941837],
                [120.2366, 31.932902],
                [120.205873, 31.931515],
                [120.175246, 31.933823],
                [120.134806, 31.939386],
                [120.064907, 31.955463],
                [120.022399, 31.967753],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 321283,
          name: "泰兴市",
          center: [120.020228, 32.168784],
          centroid: [120.134134, 32.199953],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 321200 },
          subFeatureIndex: 5,
          acroutes: [100000, 320000, 321200],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.905168, 32.152262],
                [119.921368, 32.171031],
                [119.919255, 32.172325],
                [119.919374, 32.173799],
                [119.925981, 32.181921],
                [119.92894, 32.190611],
                [119.93188, 32.194905],
                [119.93483, 32.197387],
                [119.93777, 32.201564],
                [119.939856, 32.205869],
                [119.942328, 32.214816],
                [119.946978, 32.228104],
                [119.946555, 32.22994],
                [119.949569, 32.230535],
                [119.948034, 32.231336],
                [119.952932, 32.237075],
                [119.954834, 32.237462],
                [119.955413, 32.241198],
                [119.953658, 32.241417],
                [119.950938, 32.244984],
                [119.950782, 32.25319],
                [119.946252, 32.261745],
                [119.947777, 32.264548],
                [119.94672, 32.264755],
                [119.947161, 32.268089],
                [119.950635, 32.267779],
                [119.95329, 32.268515],
                [119.954062, 32.271848],
                [119.947189, 32.272624],
                [119.948632, 32.276202],
                [119.946729, 32.276512],
                [119.948347, 32.278088],
                [119.9508, 32.282584],
                [119.951848, 32.28261],
                [119.952188, 32.28686],
                [119.943568, 32.289792],
                [119.942843, 32.294403],
                [119.944524, 32.294442],
                [119.945406, 32.297],
                [119.944441, 32.303277],
                [119.945388, 32.308468],
                [119.943706, 32.311374],
                [119.946555, 32.313879],
                [119.952693, 32.31357],
                [119.958114, 32.312046],
                [119.969757, 32.306183],
                [119.97493, 32.306118],
                [119.979138, 32.309592],
                [119.98512, 32.312601],
                [119.99135, 32.313182],
                [119.9994, 32.31171],
                [120.007008, 32.308107],
                [120.012264, 32.303845],
                [120.013477, 32.296263],
                [120.013201, 32.292505],
                [120.015949, 32.288642],
                [120.022096, 32.287286],
                [120.030918, 32.286266],
                [120.035852, 32.282403],
                [120.039491, 32.275802],
                [120.042394, 32.274833],
                [120.047411, 32.278476],
                [120.051372, 32.283566],
                [120.065394, 32.287351],
                [120.073875, 32.292931],
                [120.079848, 32.30028],
                [120.08187, 32.303354],
                [120.080537, 32.3071],
                [120.07779, 32.310251],
                [120.077716, 32.315571],
                [120.0702, 32.323719],
                [120.06839, 32.329723],
                [120.071137, 32.336101],
                [120.073315, 32.342479],
                [120.071422, 32.347281],
                [120.074133, 32.350934],
                [120.077082, 32.357453],
                [120.078479, 32.361648],
                [120.081695, 32.363894],
                [120.084589, 32.362371],
                [120.088044, 32.365675],
                [120.092492, 32.362951],
                [120.094247, 32.365507],
                [120.099521, 32.364449],
                [120.098952, 32.362938],
                [120.110612, 32.361273],
                [120.113718, 32.363687],
                [120.119084, 32.363158],
                [120.117072, 32.35331],
                [120.120297, 32.349928],
                [120.124138, 32.348391],
                [120.126536, 32.352316],
                [120.131305, 32.351167],
                [120.131324, 32.349876],
                [120.136212, 32.349114],
                [120.136451, 32.350818],
                [120.139309, 32.350431],
                [120.138804, 32.348262],
                [120.141239, 32.347978],
                [120.141285, 32.350173],
                [120.145144, 32.349631],
                [120.145769, 32.353516],
                [120.150813, 32.354381],
                [120.159221, 32.354316],
                [120.157944, 32.351851],
                [120.158973, 32.350457],
                [120.162152, 32.350018],
                [120.166048, 32.350728],
                [120.166168, 32.352522],
                [120.174217, 32.352535],
                [120.174061, 32.356123],
                [120.17634, 32.354755],
                [120.179078, 32.355956],
                [120.177773, 32.358072],
                [120.180649, 32.35846],
                [120.183296, 32.360318],
                [120.184086, 32.359376],
                [120.189195, 32.361777],
                [120.193633, 32.362848],
                [120.196813, 32.362809],
                [120.198329, 32.361583],
                [120.203769, 32.362603],
                [120.206746, 32.364087],
                [120.209695, 32.357427],
                [120.209705, 32.352638],
                [120.211515, 32.353606],
                [120.211937, 32.355672],
                [120.214584, 32.35482],
                [120.216486, 32.352548],
                [120.221338, 32.34865],
                [120.22731, 32.349334],
                [120.228238, 32.345087],
                [120.233743, 32.34297],
                [120.235047, 32.341653],
                [120.243997, 32.340026],
                [120.244732, 32.336592],
                [120.246772, 32.336605],
                [120.248362, 32.338722],
                [120.257257, 32.338309],
                [120.259425, 32.339458],
                [120.266271, 32.34],
                [120.267263, 32.345435],
                [120.269313, 32.345306],
                [120.268789, 32.340801],
                [120.269423, 32.339626],
                [120.274311, 32.33982],
                [120.272997, 32.350341],
                [120.269818, 32.351296],
                [120.270397, 32.354084],
                [120.273631, 32.353452],
                [120.278529, 32.353697],
                [120.280054, 32.359234],
                [120.283013, 32.359092],
                [120.283528, 32.361506],
                [120.287883, 32.36046],
                [120.287929, 32.361648],
                [120.293773, 32.35988],
                [120.294582, 32.361557],
                [120.297118, 32.361945],
                [120.310102, 32.361545],
                [120.314016, 32.361583],
                [120.314623, 32.364732],
                [120.317094, 32.366539],
                [120.32303, 32.368449],
                [120.327965, 32.368385],
                [120.336308, 32.373263],
                [120.342272, 32.374567],
                [120.345212, 32.376606],
                [120.351635, 32.377909],
                [120.352361, 32.368927],
                [120.351461, 32.36774],
                [120.356726, 32.364965],
                [120.359703, 32.357995],
                [120.35373, 32.355039],
                [120.351157, 32.351309],
                [120.35011, 32.347759],
                [120.348787, 32.347294],
                [120.346214, 32.343976],
                [120.346186, 32.340039],
                [120.349917, 32.337302],
                [120.353115, 32.335959],
                [120.353868, 32.333016],
                [120.354263, 32.324933],
                [120.362156, 32.316798],
                [120.36461, 32.31233],
                [120.365216, 32.308817],
                [120.352306, 32.304465],
                [120.3526, 32.292944],
                [120.352131, 32.2912],
                [120.35238, 32.278657],
                [120.350229, 32.277145],
                [120.348208, 32.272998],
                [120.347234, 32.266499],
                [120.350661, 32.257674],
                [120.350854, 32.255982],
                [120.345911, 32.249895],
                [120.351525, 32.243937],
                [120.357029, 32.238729],
                [120.361394, 32.239621],
                [120.364601, 32.237824],
                [120.369949, 32.226734],
                [120.371621, 32.217556],
                [120.37073, 32.215682],
                [120.366687, 32.214247],
                [120.366778, 32.211571],
                [120.365336, 32.207007],
                [120.36144, 32.200516],
                [120.358031, 32.193366],
                [120.355568, 32.191698],
                [120.355008, 32.189551],
                [120.354576, 32.182076],
                [120.352425, 32.180693],
                [120.34649, 32.179218],
                [120.346048, 32.173334],
                [120.343218, 32.170074],
                [120.347436, 32.150541],
                [120.349963, 32.150852],
                [120.351038, 32.149494],
                [120.355642, 32.138535],
                [120.356193, 32.130746],
                [120.353105, 32.130306],
                [120.339451, 32.130422],
                [120.324629, 32.134201],
                [120.317848, 32.133877],
                [120.316552, 32.135611],
                [120.321174, 32.137293],
                [120.318978, 32.140916],
                [120.318096, 32.140541],
                [120.316433, 32.143465],
                [120.314007, 32.142054],
                [120.313355, 32.143542],
                [120.310506, 32.142624],
                [120.308549, 32.144901],
                [120.30027, 32.14111],
                [120.302953, 32.137629],
                [120.301869, 32.135896],
                [120.296796, 32.132596],
                [120.296208, 32.129866],
                [120.298901, 32.126255],
                [120.291549, 32.124716],
                [120.288637, 32.12346],
                [120.289408, 32.122257],
                [120.286422, 32.122632],
                [120.285586, 32.1206],
                [120.281745, 32.120639],
                [120.26152, 32.114246],
                [120.257689, 32.107788],
                [120.252828, 32.104694],
                [120.246726, 32.102403],
                [120.243428, 32.098611],
                [120.235746, 32.096035],
                [120.229222, 32.091892],
                [120.226493, 32.092475],
                [120.22494, 32.091271],
                [120.216927, 32.089834],
                [120.212617, 32.089899],
                [120.206194, 32.089212],
                [120.200387, 32.086934],
                [120.196794, 32.08863],
                [120.189021, 32.088125],
                [120.179391, 32.086856],
                [120.172683, 32.086377],
                [120.165791, 32.084293],
                [120.159653, 32.083374],
                [120.155592, 32.083684],
                [120.150308, 32.081885],
                [120.148084, 32.083167],
                [120.146008, 32.086533],
                [120.134549, 32.08428],
                [120.129321, 32.081328],
                [120.122438, 32.078998],
                [120.111017, 32.075813],
                [120.106174, 32.075618],
                [120.095478, 32.073832],
                [120.090195, 32.071786],
                [120.086795, 32.069818],
                [120.081493, 32.067772],
                [120.07677, 32.065286],
                [120.072129, 32.061207],
                [120.066625, 32.057995],
                [120.056867, 32.048658],
                [120.05411, 32.044643],
                [120.05218, 32.040433],
                [120.052364, 32.037001],
                [120.05592, 32.036236],
                [120.058236, 32.033581],
                [120.061397, 32.032648],
                [120.059798, 32.027518],
                [120.050958, 32.011207],
                [120.04686, 32.007165],
                [120.040024, 32.002668],
                [120.038627, 32.000815],
                [120.038158, 31.996047],
                [120.034225, 31.996565],
                [120.02953, 31.985303],
                [120.025202, 31.97566],
                [120.022399, 31.967753],
                [120.016234, 31.970475],
                [120.005464, 31.979341],
                [119.998564, 31.984227],
                [119.980122, 31.998042],
                [119.971778, 32.01398],
                [119.948705, 32.047466],
                [119.930594, 32.078959],
                [119.915037, 32.123059],
                [119.909239, 32.139221],
                [119.907962, 32.144836],
                [119.905168, 32.152262],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 320402,
          name: "天宁区",
          center: [119.963783, 31.779632],
          centroid: [120.060044, 31.801986],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 320400 },
          subFeatureIndex: 0,
          acroutes: [100000, 320000, 320400],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.92134, 31.754397],
                [119.922405, 31.754252],
                [119.924674, 31.750828],
                [119.933824, 31.750058],
                [119.94176, 31.748101],
                [119.945765, 31.74541],
                [119.950133, 31.747349],
                [119.952061, 31.744296],
                [119.955832, 31.741804],
                [119.96022, 31.74291],
                [119.967006, 31.741532],
                [119.975112, 31.736504],
                [119.97982, 31.735616],
                [119.989428, 31.734791],
                [119.995702, 31.734855],
                [119.998909, 31.731593],
                [119.999282, 31.727697],
                [120.000922, 31.72641],
                [120.002349, 31.728032],
                [120.00807, 31.731883],
                [120.009987, 31.731928],
                [120.01184, 31.729908],
                [120.018285, 31.727905],
                [120.028341, 31.728068],
                [120.03323, 31.726772],
                [120.035733, 31.724344],
                [120.037629, 31.7238],
                [120.036873, 31.728775],
                [120.039888, 31.73037],
                [120.040378, 31.733043],
                [120.013662, 31.74791],
                [120.003969, 31.753138],
                [120.006845, 31.760484],
                [120.006482, 31.762577],
                [120.010168, 31.763084],
                [120.015377, 31.765294],
                [120.024101, 31.765955],
                [120.025955, 31.766725],
                [120.030194, 31.773219],
                [120.039973, 31.772957],
                [120.042721, 31.773817],
                [120.044255, 31.777838],
                [120.046684, 31.778092],
                [120.052904, 31.77561],
                [120.062012, 31.77417],
                [120.07047, 31.780691],
                [120.073271, 31.780763],
                [120.080653, 31.772332],
                [120.081623, 31.774224],
                [120.084083, 31.774089],
                [120.083764, 31.776235],
                [120.085404, 31.778065],
                [120.088504, 31.777974],
                [120.086501, 31.780836],
                [120.087513, 31.783725],
                [120.091891, 31.783634],
                [120.092477, 31.78569],
                [120.09089, 31.788017],
                [120.094032, 31.790254],
                [120.096951, 31.794492],
                [120.099603, 31.795406],
                [120.100424, 31.793079],
                [120.102746, 31.793586],
                [120.105068, 31.792355],
                [120.10576, 31.789203],
                [120.113685, 31.791666],
                [120.112865, 31.792581],
                [120.119022, 31.793079],
                [120.119661, 31.791657],
                [120.125222, 31.794166],
                [120.127363, 31.796629],
                [120.132188, 31.795895],
                [120.130867, 31.797127],
                [120.134233, 31.798132],
                [120.133733, 31.801047],
                [120.138377, 31.806018],
                [120.141519, 31.807249],
                [120.151224, 31.807322],
                [120.153375, 31.807675],
                [120.155676, 31.805991],
                [120.154771, 31.804171],
                [120.158723, 31.802659],
                [120.160906, 31.800323],
                [120.16537, 31.801636],
                [120.173209, 31.802396],
                [120.174243, 31.801029],
                [120.176192, 31.807267],
                [120.179239, 31.812853],
                [120.177886, 31.814139],
                [120.172219, 31.814944],
                [120.170493, 31.817253],
                [120.172038, 31.82301],
                [120.165497, 31.825563],
                [120.163921, 31.830288],
                [120.164091, 31.832704],
                [120.167085, 31.835483],
                [120.169993, 31.836071],
                [120.173188, 31.838769],
                [120.172517, 31.840868],
                [120.176799, 31.844497],
                [120.180496, 31.849004],
                [120.184245, 31.856126],
                [120.185182, 31.860415],
                [120.182711, 31.864849],
                [120.175883, 31.870214],
                [120.1688, 31.87016],
                [120.158115, 31.867834],
                [120.15166, 31.864188],
                [120.146164, 31.862487],
                [120.144225, 31.858922],
                [120.122814, 31.859338],
                [120.117392, 31.855004],
                [120.11409, 31.855339],
                [120.098666, 31.855556],
                [120.085468, 31.853058],
                [120.080291, 31.847484],
                [120.060489, 31.834515],
                [120.056398, 31.833347],
                [120.056697, 31.831971],
                [120.051882, 31.829156],
                [120.047972, 31.825717],
                [120.044979, 31.821752],
                [120.032016, 31.82607],
                [120.030247, 31.831265],
                [120.028767, 31.832116],
                [120.025273, 31.831673],
                [120.022791, 31.828668],
                [120.022226, 31.826649],
                [120.019286, 31.822802],
                [120.014557, 31.824947],
                [120.011329, 31.823852],
                [120.009817, 31.826984],
                [120.006291, 31.825318],
                [120.008624, 31.819027],
                [120.01167, 31.818068],
                [120.012256, 31.813577],
                [120.008624, 31.809259],
                [120.005055, 31.809096],
                [119.993434, 31.807258],
                [119.986893, 31.803284],
                [119.980896, 31.796918],
                [119.97639, 31.797788],
                [119.975176, 31.799327],
                [119.974164, 31.803329],
                [119.970532, 31.804189],
                [119.968944, 31.807294],
                [119.964225, 31.808987],
                [119.961413, 31.808381],
                [119.954181, 31.809838],
                [119.950111, 31.808779],
                [119.95058, 31.804017],
                [119.94797, 31.804823],
                [119.94715, 31.80293],
                [119.960593, 31.798014],
                [119.956865, 31.79479],
                [119.958804, 31.789846],
                [119.956812, 31.778436],
                [119.958686, 31.777702],
                [119.955501, 31.766798],
                [119.952348, 31.768727],
                [119.948503, 31.767142],
                [119.942772, 31.766336],
                [119.940333, 31.763491],
                [119.937457, 31.762504],
                [119.938714, 31.761272],
                [119.945648, 31.762948],
                [119.94797, 31.762441],
                [119.947353, 31.760892],
                [119.943635, 31.760231],
                [119.945989, 31.759479],
                [119.945158, 31.755067],
                [119.940514, 31.755946],
                [119.940684, 31.759252],
                [119.938458, 31.758736],
                [119.938181, 31.757187],
                [119.933941, 31.758057],
                [119.927188, 31.757024],
                [119.921361, 31.757187],
                [119.92134, 31.754397],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 320404,
          name: "钟楼区",
          center: [119.948388, 31.78096],
          centroid: [119.858011, 31.787817],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 320400 },
          subFeatureIndex: 1,
          acroutes: [100000, 320000, 320400],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.94715, 31.80293],
                [119.923992, 31.810997],
                [119.925122, 31.816954],
                [119.926879, 31.822567],
                [119.925697, 31.825889],
                [119.922618, 31.82635],
                [119.918411, 31.830442],
                [119.916227, 31.831537],
                [119.912935, 31.831365],
                [119.910017, 31.833003],
                [119.911348, 31.835085],
                [119.907759, 31.842923],
                [119.903093, 31.844289],
                [119.900515, 31.84352],
                [119.893005, 31.844135],
                [119.891812, 31.844932],
                [119.891077, 31.848154],
                [119.888095, 31.848905],
                [119.873427, 31.824268],
                [119.857747, 31.832831],
                [119.855084, 31.830089],
                [119.857715, 31.827835],
                [119.858365, 31.824793],
                [119.854615, 31.823445],
                [119.852932, 31.825382],
                [119.84832, 31.818765],
                [119.850471, 31.81814],
                [119.847723, 31.813387],
                [119.845465, 31.810825],
                [119.841715, 31.813089],
                [119.840831, 31.811939],
                [119.837753, 31.813605],
                [119.836805, 31.815334],
                [119.831117, 31.816049],
                [119.826515, 31.813306],
                [119.825897, 31.815651],
                [119.821572, 31.813098],
                [119.821689, 31.811106],
                [119.820081, 31.808833],
                [119.815959, 31.811441],
                [119.81141, 31.803419],
                [119.809525, 31.805203],
                [119.80847, 31.803673],
                [119.804199, 31.804605],
                [119.802516, 31.807557],
                [119.80226, 31.810445],
                [119.800385, 31.808191],
                [119.798212, 31.81126],
                [119.796913, 31.809965],
                [119.791874, 31.808236],
                [119.790447, 31.806987],
                [119.784929, 31.808354],
                [119.782884, 31.807466],
                [119.777611, 31.807403],
                [119.763955, 31.808136],
                [119.763017, 31.798512],
                [119.759002, 31.793912],
                [119.759875, 31.791884],
                [119.758586, 31.78694],
                [119.757329, 31.786242],
                [119.748381, 31.785744],
                [119.748754, 31.78032],
                [119.747178, 31.776489],
                [119.743364, 31.773292],
                [119.736302, 31.762115],
                [119.739796, 31.76158],
                [119.743226, 31.762205],
                [119.747156, 31.763935],
                [119.747199, 31.761961],
                [119.753218, 31.762803],
                [119.756466, 31.761816],
                [119.758064, 31.759298],
                [119.753388, 31.758355],
                [119.753399, 31.755901],
                [119.757361, 31.753382],
                [119.760301, 31.755556],
                [119.765191, 31.756036],
                [119.769494, 31.753264],
                [119.775204, 31.752494],
                [119.775342, 31.754632],
                [119.77858, 31.755457],
                [119.782085, 31.754379],
                [119.781382, 31.752522],
                [119.783043, 31.75186],
                [119.77939, 31.747621],
                [119.787624, 31.750202],
                [119.787581, 31.747648],
                [119.790628, 31.748291],
                [119.791704, 31.745093],
                [119.795858, 31.746162],
                [119.795613, 31.749731],
                [119.800396, 31.748998],
                [119.800588, 31.751072],
                [119.803602, 31.750003],
                [119.804561, 31.751235],
                [119.807469, 31.750655],
                [119.805871, 31.752141],
                [119.806585, 31.755946],
                [119.811186, 31.755547],
                [119.813072, 31.752603],
                [119.818174, 31.75186],
                [119.822158, 31.752893],
                [119.82954, 31.752784],
                [119.829785, 31.754741],
                [119.827442, 31.759479],
                [119.830137, 31.759397],
                [119.830531, 31.757531],
                [119.834696, 31.759714],
                [119.832874, 31.762694],
                [119.833119, 31.764633],
                [119.839319, 31.766725],
                [119.840267, 31.768283],
                [119.843952, 31.769424],
                [119.84441, 31.767721],
                [119.849896, 31.765611],
                [119.851483, 31.76601],
                [119.854828, 31.763193],
                [119.856469, 31.76004],
                [119.858876, 31.761046],
                [119.859824, 31.765439],
                [119.861017, 31.766345],
                [119.863701, 31.765385],
                [119.864607, 31.763818],
                [119.874801, 31.759098],
                [119.878636, 31.7561],
                [119.886209, 31.748282],
                [119.892324, 31.743326],
                [119.896414, 31.740663],
                [119.897671, 31.742366],
                [119.903253, 31.7458],
                [119.908717, 31.752386],
                [119.912456, 31.755756],
                [119.92134, 31.754397],
                [119.921361, 31.757187],
                [119.927188, 31.757024],
                [119.933941, 31.758057],
                [119.938181, 31.757187],
                [119.938458, 31.758736],
                [119.940684, 31.759252],
                [119.940514, 31.755946],
                [119.945158, 31.755067],
                [119.945989, 31.759479],
                [119.943635, 31.760231],
                [119.947353, 31.760892],
                [119.94797, 31.762441],
                [119.945648, 31.762948],
                [119.938714, 31.761272],
                [119.937457, 31.762504],
                [119.940333, 31.763491],
                [119.942772, 31.766336],
                [119.948503, 31.767142],
                [119.952348, 31.768727],
                [119.955501, 31.766798],
                [119.958686, 31.777702],
                [119.956812, 31.778436],
                [119.958804, 31.789846],
                [119.956865, 31.79479],
                [119.960593, 31.798014],
                [119.94715, 31.80293],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 320411,
          name: "新北区",
          center: [119.974654, 31.824664],
          centroid: [119.886314, 31.924334],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 320400 },
          subFeatureIndex: 2,
          acroutes: [100000, 320000, 320400],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.791874, 31.808236],
                [119.796913, 31.809965],
                [119.798212, 31.81126],
                [119.800385, 31.808191],
                [119.80226, 31.810445],
                [119.802516, 31.807557],
                [119.804199, 31.804605],
                [119.80847, 31.803673],
                [119.809525, 31.805203],
                [119.81141, 31.803419],
                [119.815959, 31.811441],
                [119.820081, 31.808833],
                [119.821689, 31.811106],
                [119.821572, 31.813098],
                [119.825897, 31.815651],
                [119.826515, 31.813306],
                [119.831117, 31.816049],
                [119.836805, 31.815334],
                [119.837753, 31.813605],
                [119.840831, 31.811939],
                [119.841715, 31.813089],
                [119.845465, 31.810825],
                [119.847723, 31.813387],
                [119.850471, 31.81814],
                [119.84832, 31.818765],
                [119.852932, 31.825382],
                [119.854615, 31.823445],
                [119.858365, 31.824793],
                [119.857715, 31.827835],
                [119.855084, 31.830089],
                [119.857747, 31.832831],
                [119.873427, 31.824268],
                [119.888095, 31.848905],
                [119.891077, 31.848154],
                [119.891812, 31.844932],
                [119.893005, 31.844135],
                [119.900515, 31.84352],
                [119.903093, 31.844289],
                [119.907759, 31.842923],
                [119.911348, 31.835085],
                [119.910017, 31.833003],
                [119.912935, 31.831365],
                [119.916227, 31.831537],
                [119.918411, 31.830442],
                [119.922618, 31.82635],
                [119.925697, 31.825889],
                [119.926879, 31.822567],
                [119.925122, 31.816954],
                [119.923992, 31.810997],
                [119.94715, 31.80293],
                [119.94797, 31.804823],
                [119.95058, 31.804017],
                [119.950111, 31.808779],
                [119.954181, 31.809838],
                [119.961413, 31.808381],
                [119.964225, 31.808987],
                [119.968944, 31.807294],
                [119.970532, 31.804189],
                [119.974164, 31.803329],
                [119.975176, 31.799327],
                [119.97639, 31.797788],
                [119.980896, 31.796918],
                [119.986893, 31.803284],
                [119.993434, 31.807258],
                [120.005055, 31.809096],
                [120.008624, 31.809259],
                [120.012256, 31.813577],
                [120.01167, 31.818068],
                [120.008624, 31.819027],
                [120.006291, 31.825318],
                [120.003351, 31.828242],
                [120.003692, 31.838606],
                [120.000698, 31.837854],
                [120.001188, 31.840561],
                [120.000464, 31.845611],
                [119.997343, 31.845945],
                [119.99681, 31.848986],
                [119.992912, 31.849448],
                [119.990153, 31.852805],
                [119.990217, 31.854868],
                [119.995031, 31.855936],
                [120.003266, 31.859157],
                [120.005098, 31.862541],
                [120.007995, 31.863799],
                [120.007995, 31.866884],
                [120.010498, 31.867807],
                [120.013204, 31.871689],
                [120.010221, 31.873815],
                [120.01069, 31.875543],
                [120.01478, 31.881785],
                [120.009018, 31.88287],
                [120.008517, 31.885494],
                [120.006323, 31.88913],
                [120.002562, 31.889094],
                [120.000187, 31.892531],
                [119.997833, 31.894349],
                [120.001913, 31.901486],
                [120.00089, 31.905565],
                [120.005481, 31.911886],
                [120.014993, 31.914753],
                [120.022418, 31.91969],
                [120.01919, 31.92177],
                [120.014397, 31.927069],
                [120.01135, 31.929329],
                [120.007399, 31.935929],
                [120.008187, 31.940332],
                [120.007644, 31.947998],
                [120.008783, 31.951298],
                [120.010839, 31.953802],
                [120.014844, 31.955149],
                [120.022397, 31.967748],
                [120.016229, 31.970478],
                [120.00547, 31.979343],
                [119.998568, 31.984223],
                [119.980129, 31.998038],
                [119.975506, 32.006865],
                [119.963448, 32.006287],
                [119.954244, 32.004164],
                [119.951677, 32.00233],
                [119.933792, 32.00411],
                [119.9197, 32.003658],
                [119.913979, 32.005871],
                [119.908675, 32.011066],
                [119.905905, 32.015357],
                [119.899045, 32.032392],
                [119.896648, 32.037106],
                [119.895999, 32.040168],
                [119.896936, 32.045441],
                [119.893996, 32.047085],
                [119.890843, 32.046155],
                [119.885911, 32.040493],
                [119.883408, 32.039364],
                [119.878625, 32.04219],
                [119.872149, 32.054372],
                [119.869613, 32.05811],
                [119.859962, 32.051546],
                [119.858535, 32.052024],
                [119.849981, 32.05857],
                [119.843814, 32.059825],
                [119.839905, 32.054525],
                [119.834855, 32.052918],
                [119.829007, 32.051916],
                [119.821817, 32.05588],
                [119.804742, 32.056105],
                [119.801056, 32.054977],
                [119.796316, 32.049017],
                [119.788465, 32.034821],
                [119.783715, 32.027298],
                [119.779347, 32.017371],
                [119.777462, 32.014264],
                [119.78479, 32.012312],
                [119.782245, 32.010732],
                [119.779869, 32.006558],
                [119.779273, 32.003351],
                [119.780892, 32.001598],
                [119.778985, 31.997415],
                [119.778378, 31.992943],
                [119.77613, 31.991298],
                [119.77564, 31.986455],
                [119.778144, 31.984087],
                [119.767641, 31.949291],
                [119.768535, 31.947293],
                [119.777132, 31.944627],
                [119.780466, 31.940197],
                [119.782798, 31.934203],
                [119.789456, 31.926915],
                [119.789403, 31.925116],
                [119.785217, 31.921653],
                [119.783874, 31.919338],
                [119.760493, 31.926626],
                [119.759364, 31.924157],
                [119.761058, 31.923353],
                [119.759651, 31.921119],
                [119.781755, 31.913876],
                [119.780551, 31.906967],
                [119.781787, 31.903132],
                [119.780476, 31.90164],
                [119.771827, 31.900835],
                [119.763145, 31.890379],
                [119.758309, 31.885213],
                [119.753697, 31.887593],
                [119.75522, 31.880319],
                [119.764061, 31.872584],
                [119.764338, 31.86636],
                [119.763859, 31.858044],
                [119.763337, 31.856886],
                [119.769941, 31.847611],
                [119.771422, 31.847493],
                [119.778207, 31.849303],
                [119.782255, 31.852063],
                [119.799597, 31.853447],
                [119.811794, 31.852235],
                [119.800492, 31.833311],
                [119.796763, 31.830324],
                [119.790319, 31.826568],
                [119.790894, 31.821761],
                [119.787613, 31.819543],
                [119.785483, 31.816746],
                [119.786505, 31.813369],
                [119.791874, 31.808236],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 320412,
          name: "武进区",
          center: [119.958773, 31.718566],
          centroid: [119.940922, 31.638712],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 320400 },
          subFeatureIndex: 3,
          acroutes: [100000, 320000, 320400],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.673028, 31.609324],
                [119.674988, 31.604225],
                [119.677886, 31.603317],
                [119.684948, 31.604025],
                [119.690498, 31.594969],
                [119.69412, 31.587964],
                [119.694567, 31.584188],
                [119.698018, 31.581411],
                [119.699786, 31.576546],
                [119.707318, 31.577472],
                [119.709959, 31.576002],
                [119.710119, 31.568123],
                [119.712697, 31.560326],
                [119.712899, 31.558302],
                [119.715243, 31.555987],
                [119.721197, 31.556867],
                [119.725618, 31.561034],
                [119.727759, 31.562123],
                [119.733266, 31.563158],
                [119.73725, 31.561479],
                [119.746113, 31.560271],
                [119.747945, 31.558774],
                [119.755976, 31.557031],
                [119.763912, 31.554443],
                [119.768557, 31.55379],
                [119.77891, 31.554688],
                [119.792172, 31.553381],
                [119.804103, 31.549886],
                [119.807405, 31.548506],
                [119.820688, 31.537247],
                [119.832352, 31.529193],
                [119.841918, 31.528476],
                [119.847798, 31.529801],
                [119.852953, 31.534287],
                [119.856202, 31.538827],
                [119.860069, 31.543058],
                [119.86188, 31.546263],
                [119.864, 31.546018],
                [119.877613, 31.546926],
                [119.890119, 31.546545],
                [119.897596, 31.546745],
                [119.902475, 31.547743],
                [119.911061, 31.548252],
                [119.921361, 31.549868],
                [119.935635, 31.552718],
                [119.941568, 31.54768],
                [119.942484, 31.546327],
                [119.948364, 31.543376],
                [119.959294, 31.540117],
                [119.966164, 31.537193],
                [119.971714, 31.535967],
                [119.973791, 31.528358],
                [119.973567, 31.515861],
                [119.981546, 31.511466],
                [119.989929, 31.503727],
                [119.996107, 31.497496],
                [119.996917, 31.501338],
                [119.997151, 31.508123],
                [120.005556, 31.503318],
                [120.009092, 31.504454],
                [120.015803, 31.505444],
                [120.018487, 31.504635],
                [120.022077, 31.501738],
                [120.030982, 31.500212],
                [120.036202, 31.497877],
                [120.037629, 31.494752],
                [120.04548, 31.490256],
                [120.04336, 31.486095],
                [120.046194, 31.47978],
                [120.044244, 31.469695],
                [120.037608, 31.425887],
                [120.034529, 31.418596],
                [120.027851, 31.40905],
                [120.021065, 31.383005],
                [120.020937, 31.374201],
                [120.023696, 31.36495],
                [120.032079, 31.353378],
                [120.041762, 31.345881],
                [120.060659, 31.339148],
                [120.068744, 31.336882],
                [120.08959, 31.332442],
                [120.100147, 31.335335],
                [120.100434, 31.339384],
                [120.096141, 31.352486],
                [120.093659, 31.353132],
                [120.05725, 31.356126],
                [120.044851, 31.358809],
                [120.041911, 31.36102],
                [120.040154, 31.364495],
                [120.039706, 31.378121],
                [120.044574, 31.406222],
                [120.05479, 31.434287],
                [120.060968, 31.440422],
                [120.078757, 31.450002],
                [120.08779, 31.45341],
                [120.089995, 31.454737],
                [120.091348, 31.453183],
                [120.110074, 31.461535],
                [120.105686, 31.470494],
                [120.108743, 31.480998],
                [120.111193, 31.485141],
                [120.117605, 31.493235],
                [120.123581, 31.499203],
                [120.127501, 31.501474],
                [120.129738, 31.504708],
                [120.123198, 31.511338],
                [120.122058, 31.508477],
                [120.121983, 31.505353],
                [120.11883, 31.505498],
                [120.118383, 31.511929],
                [120.120258, 31.51498],
                [120.116253, 31.516225],
                [120.117541, 31.518032],
                [120.115773, 31.519104],
                [120.111022, 31.514036],
                [120.107433, 31.511983],
                [120.107102, 31.513064],
                [120.103236, 31.513001],
                [120.100509, 31.516833],
                [120.103406, 31.518559],
                [120.103949, 31.521011],
                [120.102384, 31.523154],
                [120.102362, 31.527386],
                [120.103864, 31.528149],
                [120.101585, 31.529965],
                [120.102053, 31.533524],
                [120.100722, 31.533797],
                [120.101116, 31.536839],
                [120.10315, 31.538782],
                [120.101712, 31.542005],
                [120.099475, 31.542341],
                [120.096791, 31.541143],
                [120.094171, 31.541933],
                [120.095044, 31.544175],
                [120.096727, 31.543576],
                [120.099049, 31.5463],
                [120.096951, 31.547253],
                [120.097324, 31.548742],
                [120.08534, 31.550903],
                [120.082326, 31.550285],
                [120.080195, 31.551457],
                [120.081537, 31.553417],
                [120.079556, 31.555033],
                [120.076978, 31.554207],
                [120.071823, 31.554371],
                [120.069191, 31.552491],
                [120.063897, 31.555578],
                [120.058209, 31.559527],
                [120.056153, 31.563403],
                [120.05937, 31.56904],
                [120.059455, 31.572625],
                [120.05578, 31.576664],
                [120.057059, 31.580395],
                [120.06181, 31.583072],
                [120.064696, 31.586956],
                [120.068531, 31.590133],
                [120.070619, 31.590958],
                [120.07489, 31.59536],
                [120.075583, 31.60711],
                [120.084243, 31.61181],
                [120.093958, 31.618079],
                [120.102373, 31.625881],
                [120.104482, 31.628774],
                [120.116423, 31.629945],
                [120.11948, 31.630897],
                [120.12111, 31.638144],
                [120.124487, 31.648239],
                [120.125882, 31.657843],
                [120.126031, 31.673847],
                [120.126702, 31.678725],
                [120.128534, 31.684636],
                [120.143277, 31.676077],
                [120.151042, 31.682396],
                [120.151032, 31.684826],
                [120.14937, 31.686213],
                [120.143, 31.688325],
                [120.143863, 31.689966],
                [120.14528, 31.697064],
                [120.147698, 31.698206],
                [120.153588, 31.699149],
                [120.153162, 31.702122],
                [120.156837, 31.703826],
                [120.155985, 31.712599],
                [120.158914, 31.71394],
                [120.159479, 31.717203],
                [120.161407, 31.718073],
                [120.158083, 31.722395],
                [120.158105, 31.725921],
                [120.155175, 31.727089],
                [120.155974, 31.729572],
                [120.157679, 31.730696],
                [120.156123, 31.737945],
                [120.154749, 31.741424],
                [120.155964, 31.742049],
                [120.156081, 31.751507],
                [120.155239, 31.752286],
                [120.155623, 31.756843],
                [120.15999, 31.75908],
                [120.169023, 31.760964],
                [120.172389, 31.758047],
                [120.178653, 31.758401],
                [120.183116, 31.753292],
                [120.183936, 31.749913],
                [120.202812, 31.753826],
                [120.205443, 31.75706],
                [120.203696, 31.759198],
                [120.20082, 31.758555],
                [120.200617, 31.759823],
                [120.203398, 31.761037],
                [120.201629, 31.764008],
                [120.198104, 31.762459],
                [120.196559, 31.764769],
                [120.192522, 31.76735],
                [120.186109, 31.783634],
                [120.185097, 31.787682],
                [120.181241, 31.793994],
                [120.174243, 31.801029],
                [120.173209, 31.802396],
                [120.16537, 31.801636],
                [120.160906, 31.800323],
                [120.158723, 31.802659],
                [120.154771, 31.804171],
                [120.155676, 31.805991],
                [120.153375, 31.807675],
                [120.151224, 31.807322],
                [120.141519, 31.807249],
                [120.138377, 31.806018],
                [120.133733, 31.801047],
                [120.134233, 31.798132],
                [120.130867, 31.797127],
                [120.132188, 31.795895],
                [120.127363, 31.796629],
                [120.125222, 31.794166],
                [120.119661, 31.791657],
                [120.119022, 31.793079],
                [120.112865, 31.792581],
                [120.113685, 31.791666],
                [120.10576, 31.789203],
                [120.105068, 31.792355],
                [120.102746, 31.793586],
                [120.100424, 31.793079],
                [120.099603, 31.795406],
                [120.096951, 31.794492],
                [120.094032, 31.790254],
                [120.09089, 31.788017],
                [120.092477, 31.78569],
                [120.091891, 31.783634],
                [120.087513, 31.783725],
                [120.086501, 31.780836],
                [120.088504, 31.777974],
                [120.085404, 31.778065],
                [120.083764, 31.776235],
                [120.084083, 31.774089],
                [120.081623, 31.774224],
                [120.080653, 31.772332],
                [120.073271, 31.780763],
                [120.07047, 31.780691],
                [120.062012, 31.77417],
                [120.052904, 31.77561],
                [120.046684, 31.778092],
                [120.044255, 31.777838],
                [120.042721, 31.773817],
                [120.039973, 31.772957],
                [120.030194, 31.773219],
                [120.025955, 31.766725],
                [120.024101, 31.765955],
                [120.015377, 31.765294],
                [120.010168, 31.763084],
                [120.006482, 31.762577],
                [120.006845, 31.760484],
                [120.003969, 31.753138],
                [120.013662, 31.74791],
                [120.040378, 31.733043],
                [120.039888, 31.73037],
                [120.036873, 31.728775],
                [120.037629, 31.7238],
                [120.035733, 31.724344],
                [120.03323, 31.726772],
                [120.028341, 31.728068],
                [120.018285, 31.727905],
                [120.01184, 31.729908],
                [120.009987, 31.731928],
                [120.00807, 31.731883],
                [120.002349, 31.728032],
                [120.000922, 31.72641],
                [119.999282, 31.727697],
                [119.998909, 31.731593],
                [119.995702, 31.734855],
                [119.989428, 31.734791],
                [119.97982, 31.735616],
                [119.975112, 31.736504],
                [119.967006, 31.741532],
                [119.96022, 31.74291],
                [119.955832, 31.741804],
                [119.952061, 31.744296],
                [119.950133, 31.747349],
                [119.945765, 31.74541],
                [119.94176, 31.748101],
                [119.933824, 31.750058],
                [119.924674, 31.750828],
                [119.922405, 31.754252],
                [119.92134, 31.754397],
                [119.912456, 31.755756],
                [119.908717, 31.752386],
                [119.903253, 31.7458],
                [119.897671, 31.742366],
                [119.896414, 31.740663],
                [119.892324, 31.743326],
                [119.886209, 31.748282],
                [119.878636, 31.7561],
                [119.874801, 31.759098],
                [119.864607, 31.763818],
                [119.863701, 31.765385],
                [119.861017, 31.766345],
                [119.859824, 31.765439],
                [119.858876, 31.761046],
                [119.856469, 31.76004],
                [119.854828, 31.763193],
                [119.851483, 31.76601],
                [119.849896, 31.765611],
                [119.84441, 31.767721],
                [119.843952, 31.769424],
                [119.840267, 31.768283],
                [119.839319, 31.766725],
                [119.833119, 31.764633],
                [119.832874, 31.762694],
                [119.834696, 31.759714],
                [119.830531, 31.757531],
                [119.830137, 31.759397],
                [119.827442, 31.759479],
                [119.829785, 31.754741],
                [119.82954, 31.752784],
                [119.822158, 31.752893],
                [119.818174, 31.75186],
                [119.813072, 31.752603],
                [119.811186, 31.755547],
                [119.806585, 31.755946],
                [119.805871, 31.752141],
                [119.807469, 31.750655],
                [119.804561, 31.751235],
                [119.803602, 31.750003],
                [119.800588, 31.751072],
                [119.800396, 31.748998],
                [119.795613, 31.749731],
                [119.795858, 31.746162],
                [119.791704, 31.745093],
                [119.790628, 31.748291],
                [119.787581, 31.747648],
                [119.787624, 31.750202],
                [119.77939, 31.747621],
                [119.783043, 31.75186],
                [119.781382, 31.752522],
                [119.782085, 31.754379],
                [119.77858, 31.755457],
                [119.775342, 31.754632],
                [119.775204, 31.752494],
                [119.769494, 31.753264],
                [119.765191, 31.756036],
                [119.760301, 31.755556],
                [119.757361, 31.753382],
                [119.753399, 31.755901],
                [119.753388, 31.758355],
                [119.758064, 31.759298],
                [119.756466, 31.761816],
                [119.753218, 31.762803],
                [119.747199, 31.761961],
                [119.747156, 31.763935],
                [119.743226, 31.762205],
                [119.739796, 31.76158],
                [119.736302, 31.762115],
                [119.737229, 31.751724],
                [119.734587, 31.747557],
                [119.734704, 31.743716],
                [119.738997, 31.737709],
                [119.741053, 31.73297],
                [119.740552, 31.731221],
                [119.745005, 31.728875],
                [119.747125, 31.726292],
                [119.752344, 31.724163],
                [119.753676, 31.722685],
                [119.751641, 31.718046],
                [119.747604, 31.710904],
                [119.748179, 31.707388],
                [119.745505, 31.702584],
                [119.737793, 31.695849],
                [119.733788, 31.696674],
                [119.728238, 31.696248],
                [119.724808, 31.69817],
                [119.716095, 31.701088],
                [119.70638, 31.703155],
                [119.701821, 31.703091],
                [119.699478, 31.700554],
                [119.698636, 31.696538],
                [119.692948, 31.691389],
                [119.692394, 31.689685],
                [119.699051, 31.68323],
                [119.696697, 31.677002],
                [119.696847, 31.67527],
                [119.700894, 31.667627],
                [119.705539, 31.663927],
                [119.704772, 31.661842],
                [119.699637, 31.656727],
                [119.699478, 31.655195],
                [119.697102, 31.655113],
                [119.691148, 31.653109],
                [119.689124, 31.649046],
                [119.690317, 31.642498],
                [119.690583, 31.633945],
                [119.687963, 31.630244],
                [119.680581, 31.627232],
                [119.674988, 31.622306],
                [119.673199, 31.61856],
                [119.671942, 31.612345],
                [119.673028, 31.609324],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 320413,
          name: "金坛区",
          center: [119.573395, 31.744399],
          centroid: [119.509004, 31.717248],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 320400 },
          subFeatureIndex: 4,
          acroutes: [100000, 320000, 320400],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.321998, 31.684173],
                [119.326845, 31.682415],
                [119.329093, 31.679269],
                [119.344751, 31.675361],
                [119.352772, 31.677664],
                [119.354327, 31.676585],
                [119.35355, 31.673439],
                [119.353124, 31.667001],
                [119.354477, 31.662322],
                [119.352911, 31.661579],
                [119.349012, 31.662912],
                [119.341151, 31.668407],
                [119.337369, 31.670265],
                [119.333194, 31.669558],
                [119.330669, 31.667373],
                [119.331053, 31.665786],
                [119.335857, 31.65914],
                [119.342461, 31.653417],
                [119.344336, 31.649445],
                [119.34588, 31.643396],
                [119.350951, 31.640167],
                [119.35273, 31.637618],
                [119.360346, 31.631078],
                [119.367067, 31.628901],
                [119.372255, 31.626697],
                [119.37838, 31.62843],
                [119.381586, 31.63497],
                [119.386018, 31.633963],
                [119.388105, 31.632122],
                [119.390566, 31.627768],
                [119.393367, 31.626906],
                [119.400142, 31.626035],
                [119.402848, 31.624048],
                [119.409836, 31.616881],
                [119.410762, 31.613135],
                [119.412872, 31.608671],
                [119.420477, 31.602129],
                [119.422096, 31.595895],
                [119.428392, 31.589606],
                [119.430458, 31.58898],
                [119.439651, 31.589044],
                [119.443422, 31.587764],
                [119.445329, 31.585541],
                [119.445712, 31.580041],
                [119.448055, 31.577808],
                [119.452732, 31.57691],
                [119.463554, 31.579696],
                [119.475623, 31.58005],
                [119.478382, 31.576093],
                [119.481919, 31.573978],
                [119.490227, 31.572952],
                [119.496469, 31.572707],
                [119.504853, 31.575357],
                [119.530972, 31.570329],
                [119.54124, 31.569285],
                [119.545565, 31.569176],
                [119.551743, 31.568132],
                [119.560627, 31.563449],
                [119.567721, 31.562405],
                [119.580152, 31.564819],
                [119.584552, 31.564166],
                [119.588866, 31.561697],
                [119.591433, 31.562368],
                [119.594927, 31.564583],
                [119.598016, 31.570111],
                [119.603257, 31.569657],
                [119.607401, 31.570184],
                [119.610607, 31.569176],
                [119.61556, 31.563521],
                [119.615699, 31.55871],
                [119.6203, 31.559645],
                [119.627927, 31.559917],
                [119.630782, 31.563676],
                [119.637653, 31.568214],
                [119.640646, 31.569086],
                [119.644449, 31.572952],
                [119.646782, 31.577681],
                [119.64268, 31.582364],
                [119.642489, 31.588671],
                [119.640049, 31.590795],
                [119.641125, 31.592855],
                [119.641242, 31.596176],
                [119.639378, 31.60026],
                [119.644129, 31.604715],
                [119.649817, 31.605159],
                [119.657945, 31.609306],
                [119.661151, 31.610186],
                [119.666776, 31.610603],
                [119.673028, 31.609324],
                [119.671942, 31.612345],
                [119.673199, 31.61856],
                [119.674988, 31.622306],
                [119.680581, 31.627232],
                [119.687963, 31.630244],
                [119.690583, 31.633945],
                [119.690317, 31.642498],
                [119.689124, 31.649046],
                [119.691148, 31.653109],
                [119.697102, 31.655113],
                [119.699478, 31.655195],
                [119.699637, 31.656727],
                [119.704772, 31.661842],
                [119.705539, 31.663927],
                [119.700894, 31.667627],
                [119.696847, 31.67527],
                [119.696697, 31.677002],
                [119.699051, 31.68323],
                [119.692394, 31.689685],
                [119.692948, 31.691389],
                [119.698636, 31.696538],
                [119.699478, 31.700554],
                [119.701821, 31.703091],
                [119.70638, 31.703155],
                [119.716095, 31.701088],
                [119.724808, 31.69817],
                [119.728238, 31.696248],
                [119.733788, 31.696674],
                [119.737793, 31.695849],
                [119.745505, 31.702584],
                [119.748179, 31.707388],
                [119.747604, 31.710904],
                [119.751641, 31.718046],
                [119.753676, 31.722685],
                [119.752344, 31.724163],
                [119.747125, 31.726292],
                [119.745005, 31.728875],
                [119.740552, 31.731221],
                [119.727802, 31.732263],
                [119.723892, 31.732925],
                [119.716968, 31.737002],
                [119.708308, 31.737917],
                [119.707147, 31.740608],
                [119.7067, 31.747131],
                [119.704505, 31.749224],
                [119.700649, 31.751108],
                [119.695739, 31.754515],
                [119.693278, 31.756951],
                [119.692085, 31.765267],
                [119.688186, 31.769415],
                [119.680804, 31.770556],
                [119.674999, 31.770937],
                [119.65656, 31.773618],
                [119.65084, 31.777675],
                [119.648944, 31.783996],
                [119.644651, 31.789086],
                [119.644619, 31.795542],
                [119.637269, 31.797317],
                [119.63304, 31.800766],
                [119.629632, 31.801183],
                [119.625605, 31.796755],
                [119.614985, 31.796176],
                [119.612365, 31.797842],
                [119.611235, 31.802251],
                [119.609765, 31.804026],
                [119.605718, 31.806117],
                [119.60624, 31.808625],
                [119.604929, 31.817787],
                [119.605153, 31.822051],
                [119.603715, 31.823037],
                [119.598645, 31.823535],
                [119.597345, 31.827826],
                [119.589388, 31.826984],
                [119.592083, 31.83532],
                [119.590442, 31.837438],
                [119.588855, 31.845728],
                [119.586959, 31.847439],
                [119.576744, 31.84838],
                [119.570054, 31.854551],
                [119.565229, 31.855719],
                [119.559913, 31.856072],
                [119.558284, 31.858008],
                [119.558539, 31.8616],
                [119.556079, 31.862505],
                [119.550039, 31.856434],
                [119.541379, 31.8559],
                [119.537395, 31.855239],
                [119.536852, 31.853628],
                [119.532953, 31.853049],
                [119.525603, 31.853737],
                [119.517071, 31.852343],
                [119.508219, 31.851846],
                [119.505108, 31.855112],
                [119.500698, 31.856633],
                [119.497854, 31.856805],
                [119.492997, 31.859438],
                [119.491079, 31.862062],
                [119.486435, 31.86493],
                [119.482568, 31.866061],
                [119.476646, 31.872141],
                [119.468827, 31.871652],
                [119.46561, 31.874231],
                [119.465951, 31.87737],
                [119.463938, 31.881215],
                [119.453935, 31.885268],
                [119.448822, 31.885394],
                [119.439449, 31.877669],
                [119.43751, 31.87642],
                [119.434208, 31.877189],
                [119.432961, 31.875344],
                [119.423215, 31.87168],
                [119.424259, 31.869807],
                [119.426623, 31.869038],
                [119.42559, 31.865889],
                [119.421798, 31.862216],
                [119.418634, 31.861057],
                [119.417857, 31.855538],
                [119.41905, 31.85257],
                [119.420456, 31.851918],
                [119.425548, 31.852787],
                [119.428061, 31.849511],
                [119.432056, 31.840361],
                [119.439342, 31.832659],
                [119.443379, 31.828921],
                [119.4429, 31.8274],
                [119.440365, 31.826169],
                [119.432993, 31.825816],
                [119.432184, 31.829582],
                [119.429361, 31.832297],
                [119.4269, 31.832288],
                [119.422331, 31.829392],
                [119.419007, 31.82979],
                [119.412605, 31.831609],
                [119.404084, 31.832116],
                [119.400334, 31.833591],
                [119.399599, 31.835583],
                [119.394497, 31.838823],
                [119.390854, 31.84371],
                [119.38704, 31.846642],
                [119.381469, 31.846371],
                [119.376377, 31.844796],
                [119.372425, 31.845801],
                [119.369549, 31.849891],
                [119.367898, 31.854135],
                [119.364149, 31.857972],
                [119.356341, 31.858171],
                [119.351494, 31.857655],
                [119.34816, 31.858307],
                [119.346274, 31.856886],
                [119.344091, 31.857384],
                [119.342088, 31.859546],
                [119.338946, 31.85837],
                [119.332416, 31.85276],
                [119.329923, 31.849348],
                [119.329497, 31.845764],
                [119.331596, 31.842742],
                [119.334216, 31.841203],
                [119.332629, 31.836352],
                [119.33069, 31.834207],
                [119.325077, 31.830478],
                [119.320006, 31.825427],
                [119.319197, 31.823255],
                [119.314307, 31.815506],
                [119.313807, 31.81384],
                [119.316086, 31.812545],
                [119.316938, 31.810246],
                [119.313487, 31.800857],
                [119.31353, 31.798829],
                [119.315362, 31.796303],
                [119.32006, 31.797063],
                [119.319665, 31.789756],
                [119.317588, 31.787275],
                [119.318685, 31.785409],
                [119.317897, 31.782937],
                [119.314457, 31.780102],
                [119.314659, 31.777313],
                [119.307394, 31.776525],
                [119.30683, 31.770276],
                [119.304614, 31.767368],
                [119.304923, 31.759633],
                [119.306574, 31.751154],
                [119.30618, 31.748282],
                [119.306457, 31.741795],
                [119.305669, 31.733034],
                [119.309056, 31.731711],
                [119.322818, 31.732436],
                [119.324331, 31.734266],
                [119.326834, 31.730814],
                [119.331095, 31.727307],
                [119.32921, 31.723048],
                [119.323607, 31.719777],
                [119.321604, 31.715535],
                [119.324832, 31.710134],
                [119.321082, 31.707632],
                [119.328656, 31.703998],
                [119.328677, 31.700717],
                [119.324427, 31.689177],
                [119.321998, 31.684173],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 320481,
          name: "溧阳市",
          center: [119.487816, 31.427081],
          centroid: [119.376273, 31.419784],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 320400 },
          subFeatureIndex: 5,
          acroutes: [100000, 320000, 320400],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.615699, 31.55871],
                [119.61556, 31.563521],
                [119.610607, 31.569176],
                [119.607401, 31.570184],
                [119.603257, 31.569657],
                [119.598016, 31.570111],
                [119.594927, 31.564583],
                [119.591433, 31.562368],
                [119.588866, 31.561697],
                [119.584552, 31.564166],
                [119.580152, 31.564819],
                [119.567721, 31.562405],
                [119.560627, 31.563449],
                [119.551743, 31.568132],
                [119.545565, 31.569176],
                [119.54124, 31.569285],
                [119.530972, 31.570329],
                [119.504853, 31.575357],
                [119.496469, 31.572707],
                [119.490227, 31.572952],
                [119.481919, 31.573978],
                [119.478382, 31.576093],
                [119.475623, 31.58005],
                [119.463554, 31.579696],
                [119.452732, 31.57691],
                [119.448055, 31.577808],
                [119.445712, 31.580041],
                [119.445329, 31.585541],
                [119.443422, 31.587764],
                [119.439651, 31.589044],
                [119.430458, 31.58898],
                [119.428392, 31.589606],
                [119.422096, 31.595895],
                [119.420477, 31.602129],
                [119.412872, 31.608671],
                [119.410762, 31.613135],
                [119.409836, 31.616881],
                [119.402848, 31.624048],
                [119.400142, 31.626035],
                [119.393367, 31.626906],
                [119.390566, 31.627768],
                [119.388105, 31.632122],
                [119.386018, 31.633963],
                [119.381586, 31.63497],
                [119.37838, 31.62843],
                [119.372255, 31.626697],
                [119.367067, 31.628901],
                [119.360346, 31.631078],
                [119.35273, 31.637618],
                [119.350951, 31.640167],
                [119.34588, 31.643396],
                [119.344336, 31.649445],
                [119.342461, 31.653417],
                [119.335857, 31.65914],
                [119.331053, 31.665786],
                [119.330669, 31.667373],
                [119.333194, 31.669558],
                [119.337369, 31.670265],
                [119.341151, 31.668407],
                [119.349012, 31.662912],
                [119.352911, 31.661579],
                [119.354477, 31.662322],
                [119.353124, 31.667001],
                [119.35355, 31.673439],
                [119.354327, 31.676585],
                [119.352772, 31.677664],
                [119.344751, 31.675361],
                [119.329093, 31.679269],
                [119.326845, 31.682415],
                [119.321998, 31.684173],
                [119.318771, 31.67751],
                [119.316523, 31.671009],
                [119.306989, 31.660772],
                [119.299895, 31.654741],
                [119.288913, 31.648983],
                [119.285185, 31.644602],
                [119.282788, 31.642797],
                [119.276769, 31.641319],
                [119.269526, 31.63692],
                [119.263145, 31.635777],
                [119.258362, 31.632203],
                [119.248147, 31.6297],
                [119.236952, 31.625209],
                [119.234065, 31.627033],
                [119.230156, 31.619276],
                [119.228707, 31.611574],
                [119.221868, 31.594788],
                [119.219844, 31.590777],
                [119.216244, 31.585414],
                [119.216031, 31.580513],
                [119.217906, 31.576465],
                [119.215797, 31.570402],
                [119.206476, 31.563131],
                [119.206785, 31.558283],
                [119.197667, 31.551856],
                [119.189092, 31.551738],
                [119.186865, 31.549641],
                [119.187483, 31.545846],
                [119.181092, 31.53811],
                [119.180176, 31.527649],
                [119.184863, 31.528339],
                [119.18939, 31.529865],
                [119.198625, 31.530028],
                [119.197635, 31.528267],
                [119.199456, 31.524534],
                [119.198199, 31.521737],
                [119.19445, 31.518241],
                [119.193725, 31.516579],
                [119.189102, 31.51103],
                [119.186961, 31.509467],
                [119.186738, 31.505889],
                [119.183542, 31.500194],
                [119.17437, 31.499004],
                [119.171739, 31.497977],
                [119.170142, 31.494607],
                [119.166392, 31.493453],
                [119.153013, 31.493181],
                [119.148624, 31.486949],
                [119.149136, 31.484014],
                [119.156049, 31.480117],
                [119.155942, 31.47561],
                [119.152608, 31.464633],
                [119.149008, 31.457945],
                [119.141306, 31.451493],
                [119.140539, 31.448557],
                [119.140912, 31.444958],
                [119.147058, 31.443704],
                [119.153109, 31.439523],
                [119.155548, 31.438632],
                [119.159958, 31.440813],
                [119.164134, 31.441104],
                [119.168352, 31.428487],
                [119.169225, 31.421496],
                [119.169566, 31.400848],
                [119.169183, 31.394028],
                [119.170344, 31.391872],
                [119.171771, 31.385615],
                [119.174104, 31.380922],
                [119.182498, 31.381723],
                [119.186823, 31.381004],
                [119.190402, 31.378739],
                [119.200095, 31.369098],
                [119.202961, 31.364268],
                [119.204484, 31.357863],
                [119.208052, 31.356771],
                [119.214124, 31.355907],
                [119.216255, 31.35396],
                [119.214433, 31.353487],
                [119.218012, 31.348865],
                [119.211802, 31.343998],
                [119.208244, 31.341787],
                [119.201246, 31.333424],
                [119.198338, 31.330649],
                [119.19723, 31.323723],
                [119.195749, 31.320957],
                [119.193054, 31.318563],
                [119.195781, 31.313494],
                [119.195344, 31.309371],
                [119.190146, 31.30877],
                [119.187963, 31.306203],
                [119.187483, 31.30187],
                [119.181113, 31.300486],
                [119.186088, 31.299285],
                [119.190498, 31.296099],
                [119.197784, 31.295225],
                [119.199467, 31.293604],
                [119.198561, 31.291137],
                [119.199062, 31.287104],
                [119.1974, 31.27799],
                [119.197166, 31.273538],
                [119.198679, 31.270178],
                [119.202609, 31.266344],
                [119.20426, 31.265707],
                [119.205602, 31.268165],
                [119.208276, 31.270633],
                [119.214806, 31.273957],
                [119.215168, 31.271006],
                [119.213922, 31.268439],
                [119.218524, 31.264559],
                [119.230315, 31.2616],
                [119.236281, 31.259341],
                [119.238571, 31.254669],
                [119.241117, 31.254105],
                [119.245963, 31.254806],
                [119.249969, 31.256955],
                [119.249766, 31.262],
                [119.25669, 31.262],
                [119.261398, 31.261345],
                [119.261707, 31.258877],
                [119.263976, 31.252574],
                [119.26698, 31.250443],
                [119.273531, 31.253312],
                [119.279336, 31.257902],
                [119.28331, 31.259359],
                [119.288199, 31.258294],
                [119.292087, 31.260279],
                [119.294484, 31.26313],
                [119.301898, 31.26333],
                [119.307788, 31.26465],
                [119.310909, 31.264231],
                [119.314861, 31.265716],
                [119.321838, 31.265224],
                [119.326003, 31.263849],
                [119.333236, 31.26414],
                [119.33755, 31.258877],
                [119.342205, 31.26251],
                [119.345955, 31.268985],
                [119.345092, 31.275332],
                [119.347521, 31.277043],
                [119.350269, 31.281204],
                [119.350365, 31.298356],
                [119.350141, 31.301024],
                [119.356628, 31.303299],
                [119.358013, 31.301561],
                [119.359227, 31.297173],
                [119.367856, 31.289244],
                [119.367962, 31.284655],
                [119.369858, 31.281596],
                [119.37103, 31.277772],
                [119.371573, 31.271598],
                [119.379701, 31.269395],
                [119.380383, 31.266672],
                [119.380489, 31.261399],
                [119.374662, 31.258348],
                [119.374982, 31.253822],
                [119.373768, 31.248831],
                [119.370519, 31.242565],
                [119.363552, 31.238948],
                [119.362636, 31.235104],
                [119.365193, 31.232645],
                [119.362508, 31.226077],
                [119.362785, 31.218187],
                [119.36008, 31.212146],
                [119.362391, 31.202597],
                [119.365778, 31.195954],
                [119.369155, 31.198278],
                [119.371392, 31.197713],
                [119.371882, 31.192773],
                [119.374716, 31.189502],
                [119.379158, 31.189219],
                [119.383003, 31.192053],
                [119.388681, 31.194696],
                [119.389256, 31.191762],
                [119.391929, 31.194241],
                [119.39325, 31.198524],
                [119.394901, 31.199599],
                [119.398449, 31.198788],
                [119.404201, 31.195717],
                [119.405394, 31.192755],
                [119.395338, 31.193357],
                [119.389309, 31.188344],
                [119.388851, 31.181673],
                [119.391173, 31.174536],
                [119.394848, 31.173004],
                [119.397309, 31.173843],
                [119.400611, 31.178747],
                [119.407886, 31.177972],
                [119.412989, 31.173961],
                [119.415407, 31.172968],
                [119.418602, 31.174135],
                [119.421148, 31.176988],
                [119.420807, 31.179686],
                [119.423193, 31.181837],
                [119.42853, 31.181837],
                [119.431161, 31.180479],
                [119.430458, 31.177334],
                [119.43882, 31.177061],
                [119.447267, 31.171209],
                [119.454777, 31.16491],
                [119.454777, 31.161591],
                [119.457408, 31.157735],
                [119.460582, 31.156377],
                [119.467815, 31.157261],
                [119.475826, 31.158911],
                [119.482494, 31.152529],
                [119.485253, 31.15417],
                [119.485583, 31.1616],
                [119.489109, 31.1647],
                [119.494488, 31.163989],
                [119.507558, 31.157261],
                [119.514386, 31.156076],
                [119.523494, 31.159768],
                [119.532591, 31.159093],
                [119.536628, 31.167881],
                [119.54303, 31.175484],
                [119.546385, 31.17604],
                [119.548963, 31.177945],
                [119.552372, 31.177972],
                [119.553575, 31.179157],
                [119.553181, 31.183167],
                [119.554289, 31.19168],
                [119.552766, 31.201084],
                [119.552968, 31.208638],
                [119.552553, 31.212392],
                [119.550753, 31.216164],
                [119.553852, 31.221048],
                [119.551232, 31.224947],
                [119.544159, 31.22686],
                [119.536117, 31.233492],
                [119.534476, 31.236762],
                [119.530791, 31.237282],
                [119.529065, 31.239304],
                [119.522599, 31.242173],
                [119.528586, 31.247501],
                [119.527116, 31.252319],
                [119.531557, 31.2564],
                [119.532186, 31.258585],
                [119.530482, 31.268967],
                [119.535669, 31.2725],
                [119.535531, 31.275969],
                [119.530961, 31.277663],
                [119.530908, 31.279948],
                [119.533038, 31.285402],
                [119.531398, 31.287869],
                [119.535094, 31.29091],
                [119.533667, 31.294269],
                [119.527179, 31.297947],
                [119.523675, 31.301142],
                [119.522929, 31.310927],
                [119.519776, 31.31313],
                [119.520032, 31.318245],
                [119.526721, 31.327154],
                [119.530514, 31.330876],
                [119.530727, 31.339293],
                [119.528532, 31.344971],
                [119.527595, 31.360765],
                [119.52817, 31.365241],
                [119.53241, 31.374637],
                [119.534838, 31.378039],
                [119.535701, 31.381732],
                [119.540686, 31.390153],
                [119.541741, 31.39471],
                [119.541112, 31.398584],
                [119.539664, 31.40073],
                [119.537363, 31.40143],
                [119.535978, 31.406085],
                [119.536479, 31.408222],
                [119.546641, 31.41325],
                [119.553352, 31.411723],
                [119.553799, 31.415396],
                [119.556025, 31.415141],
                [119.556089, 31.416596],
                [119.553906, 31.41726],
                [119.553, 31.421978],
                [119.554385, 31.422051],
                [119.554811, 31.426142],
                [119.554555, 31.433896],
                [119.55691, 31.433896],
                [119.567508, 31.432369],
                [119.576701, 31.430723],
                [119.578256, 31.432223],
                [119.578342, 31.43455],
                [119.582528, 31.437505],
                [119.582592, 31.444822],
                [119.583327, 31.446058],
                [119.587875, 31.445803],
                [119.589601, 31.447657],
                [119.590027, 31.452247],
                [119.588429, 31.454873],
                [119.589516, 31.458354],
                [119.591433, 31.46088],
                [119.591742, 31.463061],
                [119.588355, 31.464579],
                [119.588333, 31.466687],
                [119.584115, 31.465824],
                [119.565154, 31.464334],
                [119.563578, 31.468504],
                [119.565186, 31.471385],
                [119.571886, 31.471103],
                [119.573005, 31.472039],
                [119.575146, 31.480798],
                [119.573122, 31.482624],
                [119.571652, 31.488566],
                [119.567988, 31.490419],
                [119.566411, 31.492808],
                [119.567029, 31.494798],
                [119.567018, 31.504717],
                [119.568883, 31.506779],
                [119.574262, 31.505807],
                [119.579258, 31.503146],
                [119.583625, 31.504535],
                [119.584786, 31.507315],
                [119.586128, 31.514172],
                [119.588525, 31.520112],
                [119.593074, 31.529275],
                [119.593606, 31.532208],
                [119.596887, 31.536112],
                [119.601712, 31.539917],
                [119.605281, 31.549214],
                [119.607869, 31.553172],
                [119.613313, 31.557902],
                [119.615699, 31.55871],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 321102,
          name: "京口区",
          center: [119.454571, 32.206191],
          centroid: [119.636307, 32.184713],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 321100 },
          subFeatureIndex: 0,
          acroutes: [100000, 320000, 321100],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.663635, 32.098164],
                [119.666975, 32.097152],
                [119.670758, 32.097533],
                [119.673213, 32.098171],
                [119.676221, 32.099487],
                [119.680879, 32.101911],
                [119.683897, 32.103006],
                [119.686634, 32.104551],
                [119.689914, 32.105631],
                [119.69924, 32.110023],
                [119.700115, 32.112073],
                [119.697731, 32.113729],
                [119.695075, 32.114934],
                [119.693515, 32.117033],
                [119.690889, 32.119374],
                [119.690417, 32.121223],
                [119.692077, 32.122795],
                [119.69433, 32.125732],
                [119.697137, 32.12889],
                [119.699944, 32.132498],
                [119.702409, 32.133592],
                [119.706755, 32.134651],
                [119.708908, 32.134374],
                [119.712369, 32.132698],
                [119.714472, 32.13105],
                [119.715769, 32.129416],
                [119.721514, 32.123328],
                [119.727862, 32.117649],
                [119.730196, 32.117905],
                [119.741817, 32.117968],
                [119.746344, 32.117857],
                [119.751575, 32.116513],
                [119.758648, 32.114872],
                [119.766435, 32.113874],
                [119.771516, 32.112108],
                [119.77395, 32.112136],
                [119.776908, 32.113182],
                [119.780148, 32.11269],
                [119.785671, 32.110369],
                [119.786365, 32.110238],
                [119.793538, 32.111491],
                [119.795611, 32.113646],
                [119.792955, 32.118591],
                [119.791738, 32.121936],
                [119.791607, 32.126196],
                [119.79214, 32.131674],
                [119.793428, 32.138093],
                [119.795118, 32.143023],
                [119.800581, 32.15478],
                [119.801798, 32.159716],
                [119.801657, 32.165663],
                [119.798871, 32.173056],
                [119.798166, 32.176094],
                [119.798307, 32.178614],
                [119.799927, 32.181472],
                [119.804796, 32.184974],
                [119.808297, 32.186116],
                [119.81535, 32.186912],
                [119.81868, 32.187141],
                [119.821608, 32.187687],
                [119.824495, 32.189715],
                [119.826386, 32.1924],
                [119.826849, 32.195016],
                [119.826547, 32.197605],
                [119.824938, 32.199957],
                [119.822372, 32.202303],
                [119.818046, 32.205286],
                [119.813609, 32.20705],
                [119.811185, 32.207271],
                [119.799867, 32.205888],
                [119.791687, 32.20514],
                [119.781566, 32.206842],
                [119.771355, 32.211582],
                [119.764946, 32.215471],
                [119.760409, 32.21839],
                [119.753457, 32.222645],
                [119.751123, 32.224416],
                [119.742883, 32.229369],
                [119.740106, 32.232136],
                [119.73571, 32.234979],
                [119.733124, 32.237193],
                [119.729905, 32.239475],
                [119.727188, 32.241834],
                [119.724261, 32.243951],
                [119.722088, 32.245036],
                [119.71755, 32.247762],
                [119.714431, 32.248834],
                [119.710759, 32.249435],
                [119.708365, 32.250148],
                [119.705316, 32.250673],
                [119.70263, 32.251552],
                [119.701745, 32.252236],
                [119.694612, 32.253384],
                [119.682016, 32.231258],
                [119.677941, 32.220113],
                [119.673273, 32.21363],
                [119.660466, 32.203542],
                [119.65113, 32.198566],
                [119.639228, 32.194352],
                [119.62627, 32.193293],
                [119.613161, 32.188774],
                [119.585293, 32.187418],
                [119.572636, 32.189376],
                [119.557415, 32.196006],
                [119.554105, 32.197964],
                [119.553048, 32.200525],
                [119.553501, 32.210171],
                [119.557264, 32.230504],
                [119.570886, 32.23004],
                [119.570413, 32.232676],
                [119.570745, 32.244732],
                [119.570856, 32.27162],
                [119.57154, 32.272747],
                [119.572566, 32.27303],
                [119.577586, 32.272823],
                [119.577858, 32.273023],
                [119.57821, 32.282288],
                [119.576379, 32.284556],
                [119.570061, 32.284722],
                [119.567435, 32.285219],
                [119.560735, 32.285226],
                [119.552173, 32.283816],
                [119.544879, 32.282751],
                [119.54678, 32.279834],
                [119.546428, 32.278852],
                [119.539808, 32.274752],
                [119.537012, 32.272539],
                [119.535935, 32.27097],
                [119.537193, 32.268985],
                [119.5367, 32.267934],
                [119.514767, 32.248923],
                [119.51187, 32.248896],
                [119.508902, 32.252637],
                [119.507101, 32.25391],
                [119.504415, 32.25494],
                [119.478589, 32.261275],
                [119.462482, 32.262499],
                [119.45875, 32.261849],
                [119.465551, 32.237719],
                [119.466265, 32.235872],
                [119.467895, 32.233208],
                [119.467633, 32.231887],
                [119.466054, 32.230767],
                [119.461164, 32.228677],
                [119.456888, 32.225675],
                [119.453931, 32.222714],
                [119.451687, 32.221137],
                [119.447703, 32.220071],
                [119.44215, 32.219615],
                [119.440238, 32.218321],
                [119.440731, 32.215097],
                [119.44216, 32.212267],
                [119.444111, 32.212309],
                [119.446154, 32.211291],
                [119.445973, 32.209299],
                [119.446959, 32.207389],
                [119.446445, 32.202026],
                [119.446556, 32.200975],
                [119.449222, 32.198124],
                [119.454675, 32.197037],
                [119.457593, 32.196034],
                [119.459303, 32.196546],
                [119.460601, 32.196304],
                [119.461456, 32.195522],
                [119.465561, 32.196809],
                [119.468961, 32.193584],
                [119.471014, 32.192075],
                [119.472291, 32.188476],
                [119.47372, 32.187286],
                [119.471114, 32.18395],
                [119.472714, 32.181085],
                [119.475491, 32.178344],
                [119.482563, 32.169041],
                [119.485602, 32.164735],
                [119.499012, 32.147565],
                [119.504425, 32.145287],
                [119.505713, 32.145357],
                [119.505602, 32.146125],
                [119.506598, 32.147392],
                [119.508137, 32.146956],
                [119.509345, 32.148763],
                [119.509918, 32.148514],
                [119.51191, 32.149116],
                [119.514043, 32.147752],
                [119.518248, 32.146665],
                [119.520723, 32.146679],
                [119.523319, 32.147129],
                [119.525633, 32.149026],
                [119.527575, 32.153402],
                [119.528319, 32.156061],
                [119.527816, 32.157002],
                [119.530784, 32.157501],
                [119.529526, 32.158131],
                [119.528883, 32.159155],
                [119.53011, 32.160692],
                [119.535834, 32.162714],
                [119.538279, 32.163994],
                [119.539396, 32.165905],
                [119.540513, 32.165358],
                [119.543591, 32.165143],
                [119.542988, 32.1638],
                [119.543531, 32.162104],
                [119.542635, 32.161883],
                [119.539366, 32.162146],
                [119.538702, 32.161191],
                [119.540482, 32.159183],
                [119.541448, 32.156296],
                [119.541458, 32.153637],
                [119.543199, 32.153492],
                [119.544889, 32.150092],
                [119.54676, 32.148881],
                [119.547746, 32.146492],
                [119.549336, 32.145128],
                [119.550704, 32.145239],
                [119.553581, 32.144761],
                [119.555734, 32.143896],
                [119.554467, 32.140239],
                [119.555141, 32.139374],
                [119.55839, 32.137871],
                [119.558551, 32.136964],
                [119.561207, 32.136708],
                [119.561278, 32.13738],
                [119.563753, 32.137373],
                [119.563542, 32.138481],
                [119.564306, 32.140108],
                [119.565403, 32.140745],
                [119.567556, 32.140426],
                [119.567254, 32.141424],
                [119.56821, 32.141797],
                [119.571268, 32.141984],
                [119.572103, 32.140759],
                [119.574618, 32.141001],
                [119.577234, 32.141756],
                [119.578069, 32.142365],
                [119.577606, 32.143681],
                [119.578985, 32.143632],
                [119.579337, 32.14267],
                [119.581299, 32.142504],
                [119.582003, 32.143535],
                [119.583643, 32.14366],
                [119.583653, 32.142414],
                [119.585806, 32.142379],
                [119.58824, 32.141105],
                [119.589025, 32.13963],
                [119.591057, 32.139422],
                [119.591108, 32.134887],
                [119.594961, 32.135136],
                [119.59643, 32.134333],
                [119.597758, 32.135392],
                [119.597838, 32.136569],
                [119.598583, 32.136957],
                [119.600213, 32.136202],
                [119.600384, 32.136763],
                [119.602235, 32.136812],
                [119.603231, 32.138384],
                [119.60481, 32.138467],
                [119.608986, 32.135406],
                [119.610595, 32.133529],
                [119.612316, 32.133239],
                [119.61462, 32.131978],
                [119.614368, 32.130593],
                [119.615193, 32.131064],
                [119.616994, 32.130455],
                [119.617457, 32.128474],
                [119.619469, 32.128031],
                [119.62135, 32.128294],
                [119.621893, 32.128917],
                [119.620223, 32.131133],
                [119.625012, 32.130621],
                [119.626672, 32.1315],
                [119.628694, 32.131923],
                [119.628322, 32.132782],
                [119.629469, 32.13319],
                [119.628503, 32.13551],
                [119.630666, 32.13783],
                [119.633624, 32.138474],
                [119.63462, 32.137941],
                [119.637326, 32.138231],
                [119.639238, 32.137435],
                [119.638333, 32.135635],
                [119.639318, 32.134963],
                [119.638856, 32.134111],
                [119.639832, 32.134056],
                [119.640013, 32.132712],
                [119.641019, 32.132643],
                [119.643433, 32.133419],
                [119.643846, 32.132823],
                [119.644389, 32.134049],
                [119.645757, 32.132858],
                [119.645466, 32.130344],
                [119.646773, 32.128959],
                [119.646723, 32.127927],
                [119.645938, 32.128398],
                [119.644741, 32.12729],
                [119.643333, 32.125191],
                [119.642387, 32.124852],
                [119.643866, 32.12346],
                [119.645516, 32.123134],
                [119.645667, 32.122213],
                [119.64451, 32.121652],
                [119.645838, 32.118598],
                [119.647598, 32.119221],
                [119.648383, 32.117912],
                [119.649097, 32.118494],
                [119.651804, 32.118127],
                [119.652568, 32.119388],
                [119.652518, 32.118127],
                [119.653474, 32.11742],
                [119.652256, 32.116264],
                [119.654188, 32.116292],
                [119.653605, 32.115627],
                [119.654963, 32.115204],
                [119.654309, 32.114214],
                [119.65443, 32.111055],
                [119.653353, 32.111263],
                [119.654731, 32.110134],
                [119.654168, 32.108416],
                [119.656995, 32.110958],
                [119.656311, 32.109746],
                [119.658031, 32.108651],
                [119.656703, 32.107107],
                [119.655355, 32.10728],
                [119.653635, 32.105742],
                [119.656311, 32.103539],
                [119.658082, 32.103768],
                [119.661784, 32.101766],
                [119.663192, 32.10072],
                [119.664007, 32.098912],
                [119.663635, 32.098164],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 321111,
          name: "润州区",
          center: [119.414877, 32.213501],
          centroid: [119.421007, 32.188365],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 321100 },
          subFeatureIndex: 1,
          acroutes: [100000, 320000, 321100],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.45875, 32.261849],
                [119.43389, 32.257534],
                [119.412672, 32.246627],
                [119.396726, 32.235125],
                [119.388385, 32.225094],
                [119.383124, 32.218764],
                [119.378737, 32.214447],
                [119.373164, 32.206566],
                [119.370447, 32.203992],
                [119.36755, 32.201902],
                [119.36104, 32.19802],
                [119.355326, 32.195508],
                [119.345658, 32.192352],
                [119.342157, 32.191445],
                [119.335627, 32.190144],
                [119.325909, 32.189113],
                [119.319218, 32.188691],
                [119.319208, 32.184234],
                [119.322548, 32.184054],
                [119.325003, 32.183549],
                [119.330235, 32.183694],
                [119.332901, 32.183576],
                [119.342489, 32.18231],
                [119.345879, 32.184829],
                [119.347127, 32.185141],
                [119.347509, 32.183632],
                [119.348927, 32.182102],
                [119.355376, 32.181154],
                [119.357217, 32.181195],
                [119.356986, 32.179064],
                [119.358978, 32.177804],
                [119.358837, 32.174544],
                [119.356533, 32.170917],
                [119.358143, 32.170183],
                [119.357439, 32.168501],
                [119.355487, 32.168715],
                [119.355306, 32.167926],
                [119.353857, 32.166542],
                [119.352449, 32.165981],
                [119.35429, 32.163828],
                [119.355296, 32.163593],
                [119.357429, 32.161225],
                [119.35929, 32.1618],
                [119.362147, 32.163731],
                [119.366262, 32.164867],
                [119.36755, 32.16441],
                [119.368938, 32.164804],
                [119.369481, 32.164022],
                [119.370326, 32.164472],
                [119.371443, 32.163364],
                [119.375045, 32.16281],
                [119.375739, 32.162118],
                [119.375628, 32.160927],
                [119.373848, 32.159702],
                [119.372047, 32.15955],
                [119.370196, 32.15865],
                [119.368737, 32.158823],
                [119.368767, 32.157507],
                [119.372007, 32.15721],
                [119.37425, 32.155998],
                [119.374049, 32.151816],
                [119.372027, 32.151851],
                [119.370216, 32.153257],
                [119.369059, 32.155458],
                [119.363978, 32.15523],
                [119.362922, 32.154918],
                [119.364099, 32.153243],
                [119.363888, 32.152093],
                [119.366212, 32.148168],
                [119.364552, 32.147309],
                [119.366483, 32.146056],
                [119.367952, 32.142677],
                [119.365799, 32.142504],
                [119.366262, 32.13801],
                [119.368234, 32.137747],
                [119.371383, 32.138079],
                [119.372761, 32.137622],
                [119.373053, 32.136833],
                [119.373757, 32.137857],
                [119.372962, 32.139367],
                [119.375135, 32.139762],
                [119.376514, 32.13776],
                [119.376594, 32.139616],
                [119.378063, 32.140489],
                [119.378637, 32.139776],
                [119.378093, 32.138342],
                [119.378938, 32.137359],
                [119.379763, 32.13765],
                [119.380961, 32.137096],
                [119.384914, 32.138017],
                [119.387007, 32.138121],
                [119.386605, 32.139519],
                [119.387208, 32.140087],
                [119.39252, 32.1381],
                [119.396836, 32.137179],
                [119.401273, 32.13704],
                [119.403909, 32.139907],
                [119.406515, 32.140835],
                [119.407953, 32.140101],
                [119.408034, 32.140987],
                [119.410006, 32.140939],
                [119.410841, 32.13972],
                [119.410408, 32.139145],
                [119.411153, 32.137026],
                [119.415438, 32.136923],
                [119.417209, 32.138612],
                [119.418024, 32.141036],
                [119.416404, 32.142663],
                [119.418044, 32.143328],
                [119.418135, 32.146174],
                [119.418859, 32.147718],
                [119.416123, 32.15057],
                [119.414292, 32.150335],
                [119.412682, 32.151173],
                [119.412943, 32.15325],
                [119.412229, 32.153305],
                [119.41076, 32.156981],
                [119.410539, 32.158761],
                [119.411756, 32.159107],
                [119.411585, 32.159951],
                [119.412943, 32.160173],
                [119.41231, 32.162423],
                [119.41403, 32.162997],
                [119.416606, 32.162748],
                [119.418728, 32.164223],
                [119.418598, 32.164832],
                [119.42072, 32.164777],
                [119.421656, 32.163337],
                [119.425328, 32.163004],
                [119.42891, 32.16398],
                [119.43063, 32.162679],
                [119.431797, 32.160775],
                [119.433125, 32.162084],
                [119.434242, 32.1618],
                [119.435198, 32.158504],
                [119.433206, 32.157383],
                [119.433155, 32.159224],
                [119.432501, 32.158705],
                [119.430026, 32.158816],
                [119.430731, 32.156732],
                [119.429896, 32.156019],
                [119.42902, 32.154157],
                [119.429614, 32.15336],
                [119.428045, 32.150709],
                [119.425942, 32.148375],
                [119.425409, 32.147178],
                [119.426636, 32.14582],
                [119.42726, 32.14634],
                [119.429564, 32.146291],
                [119.431395, 32.145329],
                [119.43563, 32.14562],
                [119.437361, 32.145308],
                [119.43898, 32.144159],
                [119.443528, 32.142144],
                [119.448508, 32.139499],
                [119.450309, 32.139907],
                [119.452663, 32.141174],
                [119.454685, 32.14186],
                [119.456848, 32.141714],
                [119.458357, 32.142988],
                [119.456949, 32.144096],
                [119.458005, 32.145855],
                [119.461013, 32.147787],
                [119.465017, 32.146845],
                [119.465339, 32.146028],
                [119.464655, 32.142975],
                [119.465943, 32.142822],
                [119.46709, 32.143736],
                [119.468166, 32.142026],
                [119.469173, 32.142282],
                [119.468519, 32.139983],
                [119.469756, 32.138051],
                [119.470118, 32.136805],
                [119.471255, 32.135171],
                [119.472583, 32.136029],
                [119.47373, 32.136043],
                [119.473982, 32.135226],
                [119.475813, 32.136445],
                [119.477815, 32.135025],
                [119.481416, 32.133426],
                [119.483942, 32.133107],
                [119.491125, 32.135025],
                [119.496165, 32.134194],
                [119.498268, 32.134187],
                [119.498831, 32.135115],
                [119.499908, 32.13463],
                [119.499395, 32.137303],
                [119.501186, 32.13891],
                [119.503731, 32.140066],
                [119.505059, 32.142961],
                [119.505824, 32.143376],
                [119.5053, 32.144498],
                [119.505713, 32.145357],
                [119.504425, 32.145287],
                [119.499012, 32.147565],
                [119.485602, 32.164735],
                [119.482563, 32.169041],
                [119.475491, 32.178344],
                [119.472714, 32.181085],
                [119.471114, 32.18395],
                [119.47372, 32.187286],
                [119.472291, 32.188476],
                [119.471014, 32.192075],
                [119.468961, 32.193584],
                [119.465561, 32.196809],
                [119.461456, 32.195522],
                [119.460601, 32.196304],
                [119.459303, 32.196546],
                [119.457593, 32.196034],
                [119.454675, 32.197037],
                [119.449222, 32.198124],
                [119.446556, 32.200975],
                [119.446445, 32.202026],
                [119.446959, 32.207389],
                [119.445973, 32.209299],
                [119.446154, 32.211291],
                [119.444111, 32.212309],
                [119.44216, 32.212267],
                [119.440731, 32.215097],
                [119.440238, 32.218321],
                [119.44215, 32.219615],
                [119.447703, 32.220071],
                [119.451687, 32.221137],
                [119.453931, 32.222714],
                [119.456888, 32.225675],
                [119.461164, 32.228677],
                [119.466054, 32.230767],
                [119.467633, 32.231887],
                [119.467895, 32.233208],
                [119.466265, 32.235872],
                [119.465551, 32.237719],
                [119.45875, 32.261849],
              ],
            ],
            [
              [
                [119.293413, 32.099036],
                [119.295465, 32.098205],
                [119.299228, 32.093487],
                [119.305294, 32.09576],
                [119.310365, 32.096951],
                [119.313786, 32.09452],
                [119.319228, 32.091298],
                [119.325365, 32.089628],
                [119.327921, 32.087543],
                [119.329349, 32.088728],
                [119.329712, 32.089642],
                [119.333082, 32.089649],
                [119.333565, 32.08782],
                [119.334953, 32.087481],
                [119.335617, 32.088762],
                [119.337941, 32.088035],
                [119.338746, 32.086975],
                [119.33941, 32.087425],
                [119.33936, 32.089074],
                [119.337438, 32.091021],
                [119.33767, 32.091665],
                [119.339702, 32.093314],
                [119.343233, 32.09294],
                [119.343927, 32.091367],
                [119.344742, 32.091153],
                [119.344491, 32.09258],
                [119.345034, 32.093598],
                [119.344561, 32.094984],
                [119.34598, 32.095801],
                [119.347418, 32.095822],
                [119.348706, 32.097076],
                [119.348414, 32.098718],
                [119.347277, 32.09993],
                [119.34766, 32.100491],
                [119.346231, 32.10311],
                [119.346181, 32.104031],
                [119.347418, 32.105791],
                [119.345849, 32.105915],
                [119.340678, 32.105347],
                [119.339309, 32.1041],
                [119.337981, 32.10462],
                [119.338213, 32.105978],
                [119.33615, 32.106449],
                [119.336865, 32.109074],
                [119.336442, 32.109365],
                [119.331895, 32.115052],
                [119.328695, 32.115052],
                [119.326009, 32.114317],
                [119.320416, 32.113327],
                [119.318393, 32.11224],
                [119.316603, 32.111796],
                [119.314148, 32.112226],
                [119.312447, 32.111817],
                [119.31124, 32.109213],
                [119.312639, 32.107515],
                [119.31116, 32.107342],
                [119.310526, 32.105825],
                [119.307659, 32.106054],
                [119.307216, 32.1055],
                [119.305395, 32.105714],
                [119.304077, 32.107606],
                [119.302779, 32.107502],
                [119.301874, 32.105784],
                [119.300626, 32.105617],
                [119.298866, 32.107536],
                [119.295626, 32.108153],
                [119.292618, 32.106677],
                [119.293302, 32.105299],
                [119.292467, 32.103865],
                [119.293453, 32.102285],
                [119.293906, 32.103643],
                [119.294721, 32.104038],
                [119.29633, 32.103574],
                [119.29631, 32.101115],
                [119.295817, 32.100415],
                [119.293413, 32.099036],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 321112,
          name: "丹徒区",
          center: [119.433883, 32.128972],
          centroid: [119.413302, 32.074613],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 321100 },
          subFeatureIndex: 2,
          acroutes: [100000, 320000, 321100],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.33939, 31.85859],
                [119.342086, 31.859541],
                [119.344088, 31.857381],
                [119.346271, 31.856881],
                [119.348163, 31.858312],
                [119.349954, 31.858444],
                [119.351493, 31.857659],
                [119.35426, 31.857708],
                [119.356342, 31.858173],
                [119.359119, 31.858027],
                [119.362127, 31.858166],
                [119.364149, 31.857965],
                [119.366725, 31.855582],
                [119.367902, 31.854138],
                [119.369552, 31.849886],
                [119.372429, 31.845802],
                [119.376383, 31.844795],
                [119.381464, 31.846364],
                [119.385397, 31.846844],
                [119.387047, 31.846642],
                [119.39085, 31.843711],
                [119.394492, 31.83882],
                [119.399603, 31.835583],
                [119.400337, 31.833589],
                [119.40409, 31.832116],
                [119.407722, 31.831546],
                [119.409996, 31.831747],
                [119.412601, 31.831609],
                [119.415056, 31.830747],
                [119.419, 31.829788],
                [119.42233, 31.829392],
                [119.424503, 31.830518],
                [119.426898, 31.832282],
                [119.428095, 31.832699],
                [119.429362, 31.832296],
                [119.43219, 31.82958],
                [119.432994, 31.825814],
                [119.440369, 31.826168],
                [119.442894, 31.827405],
                [119.443387, 31.82892],
                [119.439343, 31.832658],
                [119.432049, 31.840362],
                [119.429071, 31.846941],
                [119.428065, 31.849511],
                [119.425549, 31.852783],
                [119.422833, 31.852151],
                [119.420449, 31.851915],
                [119.41905, 31.852568],
                [119.417853, 31.855534],
                [119.418628, 31.861056],
                [119.419412, 31.861708],
                [119.421797, 31.862215],
                [119.42559, 31.86589],
                [119.426656, 31.86757],
                [119.426626, 31.869036],
                [119.424252, 31.869807],
                [119.423215, 31.871682],
                [119.422461, 31.872883],
                [119.420781, 31.873793],
                [119.415117, 31.8743],
                [119.413859, 31.877425],
                [119.413799, 31.880265],
                [119.413125, 31.882077],
                [119.413688, 31.884341],
                [119.413648, 31.885661],
                [119.412159, 31.887758],
                [119.409191, 31.88925],
                [119.408456, 31.891375],
                [119.408969, 31.89434],
                [119.409714, 31.895958],
                [119.410106, 31.899359],
                [119.409493, 31.900484],
                [119.405157, 31.904802],
                [119.404724, 31.907676],
                [119.405146, 31.911557],
                [119.407491, 31.916312],
                [119.409704, 31.918082],
                [119.417863, 31.927015],
                [119.420127, 31.930166],
                [119.422109, 31.9395],
                [119.420901, 31.950076],
                [119.421304, 31.953504],
                [119.422501, 31.956674],
                [119.424523, 31.960053],
                [119.429171, 31.968857],
                [119.432441, 31.97678],
                [119.433648, 31.980415],
                [119.434071, 31.984528],
                [119.432069, 31.988024],
                [119.429222, 31.991082],
                [119.426948, 31.994814],
                [119.428387, 31.998164],
                [119.430771, 32.002893],
                [119.436053, 32.008975],
                [119.437974, 32.010764],
                [119.44382, 32.017296],
                [119.445258, 32.020471],
                [119.444232, 32.022558],
                [119.442944, 32.024195],
                [119.441174, 32.02859],
                [119.440158, 32.030448],
                [119.439705, 32.033443],
                [119.439836, 32.037797],
                [119.440721, 32.040992],
                [119.444826, 32.043426],
                [119.448598, 32.043793],
                [119.453659, 32.042081],
                [119.457955, 32.042213],
                [119.463176, 32.046219],
                [119.465128, 32.048929],
                [119.46707, 32.050724],
                [119.470169, 32.055923],
                [119.473901, 32.064101],
                [119.479535, 32.073137],
                [119.480692, 32.075868],
                [119.481084, 32.077517],
                [119.482573, 32.077406],
                [119.494294, 32.077766],
                [119.49367, 32.080871],
                [119.496236, 32.082194],
                [119.502685, 32.083725],
                [119.504023, 32.081335],
                [119.504717, 32.081127],
                [119.508278, 32.082118],
                [119.509033, 32.082048],
                [119.513037, 32.079894],
                [119.522665, 32.074031],
                [119.527615, 32.070913],
                [119.528581, 32.070095],
                [119.533551, 32.070539],
                [119.536026, 32.071668],
                [119.537042, 32.073283],
                [119.540442, 32.068765],
                [119.541197, 32.068488],
                [119.545171, 32.06819],
                [119.547968, 32.06751],
                [119.55004, 32.066436],
                [119.55666, 32.06672],
                [119.563129, 32.06749],
                [119.569015, 32.066811],
                [119.56989, 32.069021],
                [119.571339, 32.071377],
                [119.573844, 32.07392],
                [119.575554, 32.076588],
                [119.576158, 32.078716],
                [119.576007, 32.080656],
                [119.576882, 32.081453],
                [119.578612, 32.081356],
                [119.582596, 32.079658],
                [119.584166, 32.078251],
                [119.587073, 32.076346],
                [119.589719, 32.075369],
                [119.593442, 32.073685],
                [119.596058, 32.0711],
                [119.597919, 32.070144],
                [119.600022, 32.068488],
                [119.601299, 32.066402],
                [119.603935, 32.064274],
                [119.606068, 32.06354],
                [119.608986, 32.062105],
                [119.613644, 32.064524],
                [119.617517, 32.068113],
                [119.620264, 32.069666],
                [119.622215, 32.072147],
                [119.625052, 32.076214],
                [119.627226, 32.077087],
                [119.637145, 32.083767],
                [119.63961, 32.084862],
                [119.64294, 32.088007],
                [119.643624, 32.09258],
                [119.644771, 32.094852],
                [119.646069, 32.101939],
                [119.648262, 32.103269],
                [119.653906, 32.102237],
                [119.656562, 32.100803],
                [119.658705, 32.100304],
                [119.661633, 32.098863],
                [119.663635, 32.098164],
                [119.664007, 32.098912],
                [119.663192, 32.10072],
                [119.661784, 32.101766],
                [119.658082, 32.103768],
                [119.656311, 32.103539],
                [119.653635, 32.105742],
                [119.655355, 32.10728],
                [119.656703, 32.107107],
                [119.658031, 32.108651],
                [119.656311, 32.109746],
                [119.656995, 32.110958],
                [119.654168, 32.108416],
                [119.654731, 32.110134],
                [119.653353, 32.111263],
                [119.65443, 32.111055],
                [119.654309, 32.114214],
                [119.654963, 32.115204],
                [119.653605, 32.115627],
                [119.654188, 32.116292],
                [119.652256, 32.116264],
                [119.653474, 32.11742],
                [119.652518, 32.118127],
                [119.652568, 32.119388],
                [119.651804, 32.118127],
                [119.649097, 32.118494],
                [119.648383, 32.117912],
                [119.647598, 32.119221],
                [119.645838, 32.118598],
                [119.64451, 32.121652],
                [119.645667, 32.122213],
                [119.645516, 32.123134],
                [119.643866, 32.12346],
                [119.642387, 32.124852],
                [119.643333, 32.125191],
                [119.644741, 32.12729],
                [119.645938, 32.128398],
                [119.646723, 32.127927],
                [119.646773, 32.128959],
                [119.645466, 32.130344],
                [119.645757, 32.132858],
                [119.644389, 32.134049],
                [119.643846, 32.132823],
                [119.643433, 32.133419],
                [119.641019, 32.132643],
                [119.640013, 32.132712],
                [119.639832, 32.134056],
                [119.638856, 32.134111],
                [119.639318, 32.134963],
                [119.638333, 32.135635],
                [119.639238, 32.137435],
                [119.637326, 32.138231],
                [119.63462, 32.137941],
                [119.633624, 32.138474],
                [119.630666, 32.13783],
                [119.628503, 32.13551],
                [119.629469, 32.13319],
                [119.628322, 32.132782],
                [119.628694, 32.131923],
                [119.626672, 32.1315],
                [119.625012, 32.130621],
                [119.620223, 32.131133],
                [119.621893, 32.128917],
                [119.62135, 32.128294],
                [119.619469, 32.128031],
                [119.617457, 32.128474],
                [119.616994, 32.130455],
                [119.615193, 32.131064],
                [119.614368, 32.130593],
                [119.61462, 32.131978],
                [119.612316, 32.133239],
                [119.610595, 32.133529],
                [119.608986, 32.135406],
                [119.60481, 32.138467],
                [119.603231, 32.138384],
                [119.602235, 32.136812],
                [119.600384, 32.136763],
                [119.600213, 32.136202],
                [119.598583, 32.136957],
                [119.597838, 32.136569],
                [119.597758, 32.135392],
                [119.59643, 32.134333],
                [119.594961, 32.135136],
                [119.591108, 32.134887],
                [119.591057, 32.139422],
                [119.589025, 32.13963],
                [119.58824, 32.141105],
                [119.585806, 32.142379],
                [119.583653, 32.142414],
                [119.583643, 32.14366],
                [119.582003, 32.143535],
                [119.581299, 32.142504],
                [119.579337, 32.14267],
                [119.578985, 32.143632],
                [119.577606, 32.143681],
                [119.578069, 32.142365],
                [119.577234, 32.141756],
                [119.574618, 32.141001],
                [119.572103, 32.140759],
                [119.571268, 32.141984],
                [119.56821, 32.141797],
                [119.567254, 32.141424],
                [119.567556, 32.140426],
                [119.565403, 32.140745],
                [119.564306, 32.140108],
                [119.563542, 32.138481],
                [119.563753, 32.137373],
                [119.561278, 32.13738],
                [119.561207, 32.136708],
                [119.558551, 32.136964],
                [119.55839, 32.137871],
                [119.555141, 32.139374],
                [119.554467, 32.140239],
                [119.555734, 32.143896],
                [119.553581, 32.144761],
                [119.550704, 32.145239],
                [119.549336, 32.145128],
                [119.547746, 32.146492],
                [119.54676, 32.148881],
                [119.544889, 32.150092],
                [119.543199, 32.153492],
                [119.541458, 32.153637],
                [119.541448, 32.156296],
                [119.540482, 32.159183],
                [119.538702, 32.161191],
                [119.539366, 32.162146],
                [119.542635, 32.161883],
                [119.543531, 32.162104],
                [119.542988, 32.1638],
                [119.543591, 32.165143],
                [119.540513, 32.165358],
                [119.539396, 32.165905],
                [119.538279, 32.163994],
                [119.535834, 32.162714],
                [119.53011, 32.160692],
                [119.528883, 32.159155],
                [119.529526, 32.158131],
                [119.530784, 32.157501],
                [119.527816, 32.157002],
                [119.528319, 32.156061],
                [119.527575, 32.153402],
                [119.525633, 32.149026],
                [119.523319, 32.147129],
                [119.520723, 32.146679],
                [119.518248, 32.146665],
                [119.514043, 32.147752],
                [119.51191, 32.149116],
                [119.509918, 32.148514],
                [119.509345, 32.148763],
                [119.508137, 32.146956],
                [119.506598, 32.147392],
                [119.505602, 32.146125],
                [119.505713, 32.145357],
                [119.5053, 32.144498],
                [119.505824, 32.143376],
                [119.505059, 32.142961],
                [119.503731, 32.140066],
                [119.501186, 32.13891],
                [119.499395, 32.137303],
                [119.499908, 32.13463],
                [119.498831, 32.135115],
                [119.498268, 32.134187],
                [119.496165, 32.134194],
                [119.491125, 32.135025],
                [119.483942, 32.133107],
                [119.481416, 32.133426],
                [119.477815, 32.135025],
                [119.475813, 32.136445],
                [119.473982, 32.135226],
                [119.47373, 32.136043],
                [119.472583, 32.136029],
                [119.471255, 32.135171],
                [119.470118, 32.136805],
                [119.469756, 32.138051],
                [119.468519, 32.139983],
                [119.469173, 32.142282],
                [119.468166, 32.142026],
                [119.46709, 32.143736],
                [119.465943, 32.142822],
                [119.464655, 32.142975],
                [119.465339, 32.146028],
                [119.465017, 32.146845],
                [119.461013, 32.147787],
                [119.458005, 32.145855],
                [119.456949, 32.144096],
                [119.458357, 32.142988],
                [119.456848, 32.141714],
                [119.454685, 32.14186],
                [119.452663, 32.141174],
                [119.450309, 32.139907],
                [119.448508, 32.139499],
                [119.443528, 32.142144],
                [119.43898, 32.144159],
                [119.437361, 32.145308],
                [119.43563, 32.14562],
                [119.431395, 32.145329],
                [119.429564, 32.146291],
                [119.42726, 32.14634],
                [119.426636, 32.14582],
                [119.425409, 32.147178],
                [119.425942, 32.148375],
                [119.428045, 32.150709],
                [119.429614, 32.15336],
                [119.42902, 32.154157],
                [119.429896, 32.156019],
                [119.430731, 32.156732],
                [119.430026, 32.158816],
                [119.432501, 32.158705],
                [119.433155, 32.159224],
                [119.433206, 32.157383],
                [119.435198, 32.158504],
                [119.434242, 32.1618],
                [119.433125, 32.162084],
                [119.431797, 32.160775],
                [119.43063, 32.162679],
                [119.42891, 32.16398],
                [119.425328, 32.163004],
                [119.421656, 32.163337],
                [119.42072, 32.164777],
                [119.418598, 32.164832],
                [119.418728, 32.164223],
                [119.416606, 32.162748],
                [119.41403, 32.162997],
                [119.41231, 32.162423],
                [119.412943, 32.160173],
                [119.411585, 32.159951],
                [119.411756, 32.159107],
                [119.410539, 32.158761],
                [119.41076, 32.156981],
                [119.412229, 32.153305],
                [119.412943, 32.15325],
                [119.412682, 32.151173],
                [119.414292, 32.150335],
                [119.416123, 32.15057],
                [119.418859, 32.147718],
                [119.418135, 32.146174],
                [119.418044, 32.143328],
                [119.416404, 32.142663],
                [119.418024, 32.141036],
                [119.417209, 32.138612],
                [119.415438, 32.136923],
                [119.411153, 32.137026],
                [119.410408, 32.139145],
                [119.410841, 32.13972],
                [119.410006, 32.140939],
                [119.408034, 32.140987],
                [119.407953, 32.140101],
                [119.406515, 32.140835],
                [119.403909, 32.139907],
                [119.401273, 32.13704],
                [119.396836, 32.137179],
                [119.39252, 32.1381],
                [119.387208, 32.140087],
                [119.386605, 32.139519],
                [119.387007, 32.138121],
                [119.384914, 32.138017],
                [119.380961, 32.137096],
                [119.379763, 32.13765],
                [119.378938, 32.137359],
                [119.378093, 32.138342],
                [119.378637, 32.139776],
                [119.378063, 32.140489],
                [119.376594, 32.139616],
                [119.376514, 32.13776],
                [119.375135, 32.139762],
                [119.372962, 32.139367],
                [119.373757, 32.137857],
                [119.373053, 32.136833],
                [119.372761, 32.137622],
                [119.371383, 32.138079],
                [119.368234, 32.137747],
                [119.366262, 32.13801],
                [119.365799, 32.142504],
                [119.367952, 32.142677],
                [119.366483, 32.146056],
                [119.364552, 32.147309],
                [119.366212, 32.148168],
                [119.363888, 32.152093],
                [119.364099, 32.153243],
                [119.362922, 32.154918],
                [119.363978, 32.15523],
                [119.369059, 32.155458],
                [119.370216, 32.153257],
                [119.372027, 32.151851],
                [119.374049, 32.151816],
                [119.37425, 32.155998],
                [119.372007, 32.15721],
                [119.368767, 32.157507],
                [119.368737, 32.158823],
                [119.370196, 32.15865],
                [119.372047, 32.15955],
                [119.373848, 32.159702],
                [119.375628, 32.160927],
                [119.375739, 32.162118],
                [119.375045, 32.16281],
                [119.371443, 32.163364],
                [119.370326, 32.164472],
                [119.369481, 32.164022],
                [119.368938, 32.164804],
                [119.36755, 32.16441],
                [119.366262, 32.164867],
                [119.362147, 32.163731],
                [119.35929, 32.1618],
                [119.357429, 32.161225],
                [119.355296, 32.163593],
                [119.35429, 32.163828],
                [119.352449, 32.165981],
                [119.353857, 32.166542],
                [119.355306, 32.167926],
                [119.355487, 32.168715],
                [119.357439, 32.168501],
                [119.358143, 32.170183],
                [119.356533, 32.170917],
                [119.358837, 32.174544],
                [119.358978, 32.177804],
                [119.356986, 32.179064],
                [119.357217, 32.181195],
                [119.355376, 32.181154],
                [119.348927, 32.182102],
                [119.347509, 32.183632],
                [119.347127, 32.185141],
                [119.345879, 32.184829],
                [119.342489, 32.18231],
                [119.332901, 32.183576],
                [119.330235, 32.183694],
                [119.325003, 32.183549],
                [119.322548, 32.184054],
                [119.319208, 32.184234],
                [119.319218, 32.188691],
                [119.325909, 32.189113],
                [119.335627, 32.190144],
                [119.342157, 32.191445],
                [119.345658, 32.192352],
                [119.355326, 32.195508],
                [119.36104, 32.19802],
                [119.36755, 32.201902],
                [119.370447, 32.203992],
                [119.373164, 32.206566],
                [119.378737, 32.214447],
                [119.383124, 32.218764],
                [119.388385, 32.225094],
                [119.381202, 32.22499],
                [119.36759, 32.225516],
                [119.345507, 32.229058],
                [119.336422, 32.230995],
                [119.33108, 32.231175],
                [119.327961, 32.230912],
                [119.324581, 32.231327],
                [119.321271, 32.232067],
                [119.318766, 32.232053],
                [119.315385, 32.232475],
                [119.305968, 32.232344],
                [119.301139, 32.232129],
                [119.292618, 32.232703],
                [119.286129, 32.232863],
                [119.280052, 32.232164],
                [119.27309, 32.231735],
                [119.270132, 32.231285],
                [119.264056, 32.231562],
                [119.260363, 32.232351],
                [119.254981, 32.232219],
                [119.252536, 32.231389],
                [119.248814, 32.230725],
                [119.240494, 32.228152],
                [119.229014, 32.222175],
                [119.241661, 32.216294],
                [119.250434, 32.212226],
                [119.26978, 32.199867],
                [119.270766, 32.197867],
                [119.27148, 32.194186],
                [119.269237, 32.191245],
                [119.266219, 32.189466],
                [119.265364, 32.187646],
                [119.263432, 32.186075],
                [119.260695, 32.184518],
                [119.259035, 32.182947],
                [119.253834, 32.18006],
                [119.250806, 32.177825],
                [119.250494, 32.17599],
                [119.249045, 32.17235],
                [119.248673, 32.168459],
                [119.248884, 32.166389],
                [119.247928, 32.161128],
                [119.247345, 32.159307],
                [119.247204, 32.153804],
                [119.247415, 32.151505],
                [119.246439, 32.145329],
                [119.245816, 32.142358],
                [119.245745, 32.139374],
                [119.246761, 32.137061],
                [119.248069, 32.135434],
                [119.250434, 32.133322],
                [119.254719, 32.132324],
                [119.260343, 32.130614],
                [119.263844, 32.130545],
                [119.265726, 32.1297],
                [119.26647, 32.127179],
                [119.269921, 32.122373],
                [119.273784, 32.119713],
                [119.276531, 32.111949],
                [119.277919, 32.10649],
                [119.281481, 32.105195],
                [119.290123, 32.10038],
                [119.293413, 32.099036],
                [119.295817, 32.100415],
                [119.29631, 32.101115],
                [119.29633, 32.103574],
                [119.294721, 32.104038],
                [119.293906, 32.103643],
                [119.293453, 32.102285],
                [119.292467, 32.103865],
                [119.293302, 32.105299],
                [119.292618, 32.106677],
                [119.295626, 32.108153],
                [119.298866, 32.107536],
                [119.300626, 32.105617],
                [119.301874, 32.105784],
                [119.302779, 32.107502],
                [119.304077, 32.107606],
                [119.305395, 32.105714],
                [119.307216, 32.1055],
                [119.307659, 32.106054],
                [119.310526, 32.105825],
                [119.31116, 32.107342],
                [119.312639, 32.107515],
                [119.31124, 32.109213],
                [119.312447, 32.111817],
                [119.314148, 32.112226],
                [119.316603, 32.111796],
                [119.318393, 32.11224],
                [119.320416, 32.113327],
                [119.326009, 32.114317],
                [119.328695, 32.115052],
                [119.331895, 32.115052],
                [119.336442, 32.109365],
                [119.336865, 32.109074],
                [119.33615, 32.106449],
                [119.338213, 32.105978],
                [119.337981, 32.10462],
                [119.339309, 32.1041],
                [119.340678, 32.105347],
                [119.345849, 32.105915],
                [119.347418, 32.105791],
                [119.346181, 32.104031],
                [119.346231, 32.10311],
                [119.34766, 32.100491],
                [119.347277, 32.09993],
                [119.348414, 32.098718],
                [119.348706, 32.097076],
                [119.347418, 32.095822],
                [119.34598, 32.095801],
                [119.344561, 32.094984],
                [119.345034, 32.093598],
                [119.344491, 32.09258],
                [119.344742, 32.091153],
                [119.343927, 32.091367],
                [119.343233, 32.09294],
                [119.339702, 32.093314],
                [119.33767, 32.091665],
                [119.337438, 32.091021],
                [119.33936, 32.089074],
                [119.33941, 32.087425],
                [119.338746, 32.086975],
                [119.337941, 32.088035],
                [119.335617, 32.088762],
                [119.334953, 32.087481],
                [119.333565, 32.08782],
                [119.333082, 32.089649],
                [119.329712, 32.089642],
                [119.329349, 32.088728],
                [119.327921, 32.087543],
                [119.331341, 32.083594],
                [119.334269, 32.079748],
                [119.33612, 32.078334],
                [119.337126, 32.075791],
                [119.338957, 32.07347],
                [119.339984, 32.071613],
                [119.343173, 32.069499],
                [119.347428, 32.067351],
                [119.351161, 32.065917],
                [119.353324, 32.065646],
                [119.358133, 32.064184],
                [119.360829, 32.063671],
                [119.362399, 32.062271],
                [119.367359, 32.055985],
                [119.368093, 32.053448],
                [119.369119, 32.051598],
                [119.36933, 32.049297],
                [119.370065, 32.046531],
                [119.370256, 32.043772],
                [119.371262, 32.041464],
                [119.371433, 32.038019],
                [119.373727, 32.032701],
                [119.375266, 32.030379],
                [119.376262, 32.027377],
                [119.376675, 32.02278],
                [119.376514, 32.016817],
                [119.373667, 32.011825],
                [119.372017, 32.010251],
                [119.368385, 32.00573],
                [119.366674, 32.002318],
                [119.366544, 32.001319],
                [119.365236, 32.001652],
                [119.365789, 32.000161],
                [119.365367, 31.998753],
                [119.366493, 31.998892],
                [119.36766, 31.997942],
                [119.367992, 31.995944],
                [119.370508, 31.993406],
                [119.37098, 31.992518],
                [119.368556, 31.992379],
                [119.370638, 31.991956],
                [119.370709, 31.99145],
                [119.373938, 31.991714],
                [119.372932, 31.990812],
                [119.373073, 31.989633],
                [119.374099, 31.988044],
                [119.376363, 31.98604],
                [119.380649, 31.985915],
                [119.381836, 31.985547],
                [119.383164, 31.986005],
                [119.383345, 31.983758],
                [119.384623, 31.983626],
                [119.384945, 31.98201],
                [119.383627, 31.978847],
                [119.383254, 31.976787],
                [119.384562, 31.976759],
                [119.384381, 31.975288],
                [119.383244, 31.976197],
                [119.383607, 31.974657],
                [119.3825, 31.974497],
                [119.382963, 31.972756],
                [119.384844, 31.971792],
                [119.385126, 31.97098],
                [119.383788, 31.968989],
                [119.384753, 31.969003],
                [119.384009, 31.96806],
                [119.382148, 31.967886],
                [119.381886, 31.96661],
                [119.379874, 31.96663],
                [119.378888, 31.965978],
                [119.377731, 31.966374],
                [119.376152, 31.965493],
                [119.372791, 31.965382],
                [119.372912, 31.963668],
                [119.374944, 31.96432],
                [119.37254, 31.961531],
                [119.371866, 31.963314],
                [119.370085, 31.964008],
                [119.370548, 31.965257],
                [119.368526, 31.965139],
                [119.36752, 31.963425],
                [119.365538, 31.962489],
                [119.366735, 31.96584],
                [119.365668, 31.966499],
                [119.365326, 31.964265],
                [119.364511, 31.964896],
                [119.363224, 31.964653],
                [119.360869, 31.966193],
                [119.359873, 31.966082],
                [119.360849, 31.964979],
                [119.359038, 31.965458],
                [119.357318, 31.964306],
                [119.355336, 31.964466],
                [119.353575, 31.963418],
                [119.354129, 31.962822],
                [119.35266, 31.961739],
                [119.352962, 31.961261],
                [119.351241, 31.960414],
                [119.350648, 31.960837],
                [119.349672, 31.959769],
                [119.348756, 31.959734],
                [119.348274, 31.95818],
                [119.348364, 31.956064],
                [119.347348, 31.95623],
                [119.347549, 31.955079],
                [119.346644, 31.95349],
                [119.345617, 31.947973],
                [119.344672, 31.944323],
                [119.344259, 31.940673],
                [119.344833, 31.934469],
                [119.345929, 31.927799],
                [119.345789, 31.924135],
                [119.345114, 31.920484],
                [119.343917, 31.917534],
                [119.341654, 31.914611],
                [119.339279, 31.908947],
                [119.338887, 31.905747],
                [119.339249, 31.900922],
                [119.338726, 31.89452],
                [119.339078, 31.889473],
                [119.338837, 31.883293],
                [119.338937, 31.878473],
                [119.338474, 31.874411],
                [119.336583, 31.871466],
                [119.337207, 31.863417],
                [119.338152, 31.86084],
                [119.33939, 31.85859],
              ],
            ],
            [
              [
                [119.570886, 32.23004],
                [119.557264, 32.230504],
                [119.553501, 32.210171],
                [119.553048, 32.200525],
                [119.554105, 32.197964],
                [119.557415, 32.196006],
                [119.572636, 32.189376],
                [119.585293, 32.187418],
                [119.613161, 32.188774],
                [119.62627, 32.193293],
                [119.639228, 32.194352],
                [119.65113, 32.198566],
                [119.660466, 32.203542],
                [119.673273, 32.21363],
                [119.677941, 32.220113],
                [119.682016, 32.231258],
                [119.694612, 32.253384],
                [119.705477, 32.272491],
                [119.708385, 32.27639],
                [119.701312, 32.278188],
                [119.693686, 32.28271],
                [119.689843, 32.283021],
                [119.688586, 32.282489],
                [119.687479, 32.279412],
                [119.684672, 32.275388],
                [119.682338, 32.273168],
                [119.678102, 32.270119],
                [119.674521, 32.268142],
                [119.67105, 32.266503],
                [119.66779, 32.265369],
                [119.661351, 32.263758],
                [119.649168, 32.262132],
                [119.643202, 32.261614],
                [119.638463, 32.260265],
                [119.633906, 32.257762],
                [119.630163, 32.254588],
                [119.628282, 32.251289],
                [119.627568, 32.248751],
                [119.627869, 32.246143],
                [119.62966, 32.238334],
                [119.630173, 32.233119],
                [119.629731, 32.22773],
                [119.629117, 32.226028],
                [119.62792, 32.225025],
                [119.626562, 32.220985],
                [119.624479, 32.218376],
                [119.621732, 32.217429],
                [119.622256, 32.228401],
                [119.622085, 32.237414],
                [119.620716, 32.242802],
                [119.619117, 32.247153],
                [119.615817, 32.250673],
                [119.607617, 32.252886],
                [119.602144, 32.251517],
                [119.599116, 32.248986],
                [119.597959, 32.246261],
                [119.598452, 32.243245],
                [119.600092, 32.238383],
                [119.599428, 32.234564],
                [119.595384, 32.233347],
                [119.588552, 32.231541],
                [119.580443, 32.230566],
                [119.570886, 32.23004],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 321181,
          name: "丹阳市",
          center: [119.581911, 31.991459],
          centroid: [119.637651, 31.954722],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 321100 },
          subFeatureIndex: 3,
          acroutes: [100000, 320000, 321100],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.896932, 32.045436],
                [119.898461, 32.047689],
                [119.898944, 32.049144],
                [119.898964, 32.05245],
                [119.89835, 32.060296],
                [119.897455, 32.062195],
                [119.895654, 32.064364],
                [119.894125, 32.065487],
                [119.880563, 32.079],
                [119.865472, 32.089746],
                [119.861307, 32.091693],
                [119.856297, 32.094443],
                [119.847957, 32.098171],
                [119.842031, 32.100983],
                [119.835179, 32.104966],
                [119.830924, 32.106906],
                [119.823509, 32.109206],
                [119.817603, 32.109469],
                [119.805128, 32.107966],
                [119.802009, 32.108901],
                [119.798297, 32.111187],
                [119.795611, 32.113646],
                [119.793538, 32.111491],
                [119.786365, 32.110238],
                [119.785671, 32.110369],
                [119.780148, 32.11269],
                [119.776908, 32.113182],
                [119.77395, 32.112136],
                [119.771516, 32.112108],
                [119.766435, 32.113874],
                [119.758648, 32.114872],
                [119.751575, 32.116513],
                [119.746344, 32.117857],
                [119.741817, 32.117968],
                [119.730196, 32.117905],
                [119.727862, 32.117649],
                [119.721514, 32.123328],
                [119.715769, 32.129416],
                [119.714472, 32.13105],
                [119.712369, 32.132698],
                [119.708908, 32.134374],
                [119.706755, 32.134651],
                [119.702409, 32.133592],
                [119.699944, 32.132498],
                [119.697137, 32.12889],
                [119.69433, 32.125732],
                [119.692077, 32.122795],
                [119.690417, 32.121223],
                [119.690889, 32.119374],
                [119.693515, 32.117033],
                [119.695075, 32.114934],
                [119.697731, 32.113729],
                [119.700115, 32.112073],
                [119.69924, 32.110023],
                [119.689914, 32.105631],
                [119.686634, 32.104551],
                [119.683897, 32.103006],
                [119.680879, 32.101911],
                [119.676221, 32.099487],
                [119.673213, 32.098171],
                [119.670758, 32.097533],
                [119.666975, 32.097152],
                [119.663635, 32.098164],
                [119.661633, 32.098863],
                [119.658705, 32.100304],
                [119.656562, 32.100803],
                [119.653906, 32.102237],
                [119.648262, 32.103269],
                [119.646069, 32.101939],
                [119.644771, 32.094852],
                [119.643624, 32.09258],
                [119.64294, 32.088007],
                [119.63961, 32.084862],
                [119.637145, 32.083767],
                [119.627226, 32.077087],
                [119.625052, 32.076214],
                [119.622215, 32.072147],
                [119.620264, 32.069666],
                [119.617517, 32.068113],
                [119.613644, 32.064524],
                [119.608986, 32.062105],
                [119.606068, 32.06354],
                [119.603935, 32.064274],
                [119.601299, 32.066402],
                [119.600022, 32.068488],
                [119.597919, 32.070144],
                [119.596058, 32.0711],
                [119.593442, 32.073685],
                [119.589719, 32.075369],
                [119.587073, 32.076346],
                [119.584166, 32.078251],
                [119.582596, 32.079658],
                [119.578612, 32.081356],
                [119.576882, 32.081453],
                [119.576007, 32.080656],
                [119.576158, 32.078716],
                [119.575554, 32.076588],
                [119.573844, 32.07392],
                [119.571339, 32.071377],
                [119.56989, 32.069021],
                [119.569015, 32.066811],
                [119.563129, 32.06749],
                [119.55666, 32.06672],
                [119.55004, 32.066436],
                [119.547968, 32.06751],
                [119.545171, 32.06819],
                [119.541197, 32.068488],
                [119.540442, 32.068765],
                [119.537042, 32.073283],
                [119.536026, 32.071668],
                [119.533551, 32.070539],
                [119.528581, 32.070095],
                [119.527615, 32.070913],
                [119.522665, 32.074031],
                [119.513037, 32.079894],
                [119.509033, 32.082048],
                [119.508278, 32.082118],
                [119.504717, 32.081127],
                [119.504023, 32.081335],
                [119.502685, 32.083725],
                [119.496236, 32.082194],
                [119.49367, 32.080871],
                [119.494294, 32.077766],
                [119.482573, 32.077406],
                [119.481084, 32.077517],
                [119.480692, 32.075868],
                [119.479535, 32.073137],
                [119.473901, 32.064101],
                [119.470169, 32.055923],
                [119.46707, 32.050724],
                [119.465128, 32.048929],
                [119.463176, 32.046219],
                [119.457955, 32.042213],
                [119.453659, 32.042081],
                [119.448598, 32.043793],
                [119.444826, 32.043426],
                [119.440721, 32.040992],
                [119.439836, 32.037797],
                [119.439705, 32.033443],
                [119.440158, 32.030448],
                [119.441174, 32.02859],
                [119.442944, 32.024195],
                [119.444232, 32.022558],
                [119.445258, 32.020471],
                [119.44382, 32.017296],
                [119.437974, 32.010764],
                [119.436053, 32.008975],
                [119.430771, 32.002893],
                [119.428387, 31.998164],
                [119.426948, 31.994814],
                [119.429222, 31.991082],
                [119.432069, 31.988024],
                [119.434071, 31.984528],
                [119.433648, 31.980415],
                [119.432441, 31.97678],
                [119.429171, 31.968857],
                [119.424523, 31.960053],
                [119.422501, 31.956674],
                [119.421304, 31.953504],
                [119.420901, 31.950076],
                [119.422109, 31.9395],
                [119.420127, 31.930166],
                [119.417863, 31.927015],
                [119.409704, 31.918082],
                [119.407491, 31.916312],
                [119.405146, 31.911557],
                [119.404724, 31.907676],
                [119.405157, 31.904802],
                [119.409493, 31.900484],
                [119.410106, 31.899359],
                [119.409714, 31.895958],
                [119.408969, 31.89434],
                [119.408456, 31.891375],
                [119.409191, 31.88925],
                [119.412159, 31.887758],
                [119.413648, 31.885661],
                [119.413688, 31.884341],
                [119.413125, 31.882077],
                [119.413799, 31.880265],
                [119.413859, 31.877425],
                [119.415117, 31.8743],
                [119.420781, 31.873793],
                [119.422461, 31.872883],
                [119.423215, 31.871682],
                [119.431073, 31.874522],
                [119.432964, 31.875342],
                [119.434202, 31.877189],
                [119.437512, 31.876418],
                [119.439443, 31.877675],
                [119.445691, 31.882987],
                [119.44883, 31.885397],
                [119.453931, 31.885272],
                [119.463941, 31.881216],
                [119.465943, 31.877369],
                [119.465611, 31.87423],
                [119.467643, 31.872001],
                [119.46883, 31.871647],
                [119.472975, 31.872126],
                [119.476648, 31.87214],
                [119.477754, 31.87064],
                [119.480611, 31.867681],
                [119.482563, 31.866063],
                [119.486437, 31.864931],
                [119.491075, 31.862063],
                [119.492996, 31.85943],
                [119.497855, 31.856805],
                [119.500693, 31.856631],
                [119.505109, 31.855117],
                [119.508218, 31.851852],
                [119.517071, 31.852345],
                [119.525603, 31.853735],
                [119.532957, 31.853047],
                [119.536851, 31.85363],
                [119.537394, 31.855242],
                [119.541388, 31.855895],
                [119.55003, 31.856437],
                [119.550362, 31.856617],
                [119.556077, 31.862507],
                [119.557415, 31.862667],
                [119.558541, 31.861604],
                [119.55828, 31.858006],
                [119.55991, 31.856075],
                [119.565232, 31.855721],
                [119.570051, 31.854554],
                [119.576741, 31.848386],
                [119.586963, 31.847441],
                [119.588854, 31.845732],
                [119.58974, 31.842578],
                [119.590444, 31.837438],
                [119.592084, 31.835326],
                [119.591178, 31.831928],
                [119.589387, 31.826981],
                [119.58985, 31.826891],
                [119.597345, 31.827829],
                [119.598653, 31.823535],
                [119.603714, 31.823035],
                [119.605152, 31.822055],
                [119.604931, 31.817788],
                [119.606239, 31.808622],
                [119.605716, 31.806114],
                [119.60976, 31.804022],
                [119.611239, 31.802249],
                [119.612366, 31.797843],
                [119.613402, 31.796793],
                [119.614992, 31.796182],
                [119.625606, 31.796752],
                [119.627407, 31.798399],
                [119.628352, 31.800039],
                [119.62963, 31.801179],
                [119.633041, 31.800762],
                [119.635636, 31.798079],
                [119.637266, 31.797322],
                [119.64462, 31.795542],
                [119.645063, 31.792574],
                [119.644651, 31.789085],
                [119.646874, 31.786715],
                [119.648947, 31.783997],
                [119.650848, 31.777671],
                [119.654933, 31.774264],
                [119.656562, 31.773618],
                [119.675004, 31.770934],
                [119.680799, 31.770552],
                [119.688183, 31.769412],
                [119.690467, 31.767201],
                [119.692087, 31.765261],
                [119.692932, 31.761256],
                [119.693274, 31.756952],
                [119.695739, 31.754518],
                [119.700648, 31.751104],
                [119.704502, 31.749219],
                [119.706695, 31.747126],
                [119.707147, 31.74061],
                [119.708314, 31.737919],
                [119.714914, 31.737404],
                [119.716967, 31.737],
                [119.721051, 31.73426],
                [119.723898, 31.732925],
                [119.727802, 31.732257],
                [119.740006, 31.731151],
                [119.740549, 31.731221],
                [119.741052, 31.732967],
                [119.739, 31.73771],
                [119.734704, 31.743719],
                [119.734583, 31.747564],
                [119.737229, 31.751723],
                [119.737219, 31.75529],
                [119.736293, 31.762111],
                [119.743366, 31.773291],
                [119.747179, 31.776489],
                [119.748748, 31.780319],
                [119.748376, 31.785749],
                [119.75733, 31.786242],
                [119.758588, 31.786944],
                [119.759875, 31.791879],
                [119.759, 31.793909],
                [119.760499, 31.795181],
                [119.763014, 31.79851],
                [119.763467, 31.806391],
                [119.76395, 31.808136],
                [119.777612, 31.807399],
                [119.782884, 31.807469],
                [119.784937, 31.808358],
                [119.79045, 31.806989],
                [119.791868, 31.808233],
                [119.790892, 31.809408],
                [119.788689, 31.81111],
                [119.786506, 31.813369],
                [119.78548, 31.816746],
                [119.786204, 31.818205],
                [119.787613, 31.819546],
                [119.790319, 31.820721],
                [119.790892, 31.821763],
                [119.790259, 31.826238],
                [119.792009, 31.827898],
                [119.796768, 31.830323],
                [119.80049, 31.833311],
                [119.807472, 31.844788],
                [119.811798, 31.852234],
                [119.808297, 31.852325],
                [119.799595, 31.85345],
                [119.78226, 31.852068],
                [119.778206, 31.849303],
                [119.771415, 31.84749],
                [119.769936, 31.847615],
                [119.763346, 31.856888],
                [119.763859, 31.858041],
                [119.764342, 31.866362],
                [119.764061, 31.872585],
                [119.755217, 31.880321],
                [119.753698, 31.885647],
                [119.753698, 31.887598],
                [119.757964, 31.885209],
                [119.758306, 31.885209],
                [119.763145, 31.890375],
                [119.766636, 31.894756],
                [119.771827, 31.900831],
                [119.780771, 31.901706],
                [119.781788, 31.903129],
                [119.780973, 31.90474],
                [119.78055, 31.906961],
                [119.780952, 31.910446],
                [119.781757, 31.913876],
                [119.77558, 31.916014],
                [119.759654, 31.921115],
                [119.761063, 31.92335],
                [119.759362, 31.924155],
                [119.760489, 31.926626],
                [119.78388, 31.919338],
                [119.785218, 31.921657],
                [119.789403, 31.925113],
                [119.789454, 31.926911],
                [119.786375, 31.929944],
                [119.782794, 31.934205],
                [119.78047, 31.940194],
                [119.777129, 31.944622],
                [119.774856, 31.945635],
                [119.772129, 31.945878],
                [119.768538, 31.947286],
                [119.767642, 31.949292],
                [119.778136, 31.984084],
                [119.77564, 31.986456],
                [119.776133, 31.991304],
                [119.778377, 31.992941],
                [119.778991, 31.997415],
                [119.780741, 32.000029],
                [119.780892, 32.001596],
                [119.779272, 32.003351],
                [119.779866, 32.006555],
                [119.781888, 32.00919],
                [119.78224, 32.010729],
                [119.784836, 32.011173],
                [119.784786, 32.01231],
                [119.782794, 32.012907],
                [119.779785, 32.013253],
                [119.777461, 32.014259],
                [119.779343, 32.017372],
                [119.783719, 32.027301],
                [119.788458, 32.034823],
                [119.796315, 32.049019],
                [119.801054, 32.054973],
                [119.804746, 32.056103],
                [119.821819, 32.055874],
                [119.825471, 32.053532],
                [119.829002, 32.051917],
                [119.834858, 32.052915],
                [119.839908, 32.05453],
                [119.840944, 32.056595],
                [119.842494, 32.058515],
                [119.843922, 32.059222],
                [119.843812, 32.059825],
                [119.849989, 32.05857],
                [119.85854, 32.052028],
                [119.859959, 32.051542],
                [119.866146, 32.056075],
                [119.869617, 32.058113],
                [119.872142, 32.05437],
                [119.875493, 32.048243],
                [119.878631, 32.042192],
                [119.8834, 32.039363],
                [119.885915, 32.040493],
                [119.889386, 32.044729],
                [119.890835, 32.046157],
                [119.894004, 32.047086],
                [119.896932, 32.045436],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 321182,
          name: "扬中市",
          center: [119.828054, 32.237266],
          centroid: [119.837483, 32.184036],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 321100 },
          subFeatureIndex: 4,
          acroutes: [100000, 320000, 321100],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.896932, 32.045436],
                [119.896077, 32.042441],
                [119.895996, 32.040168],
                [119.89665, 32.03711],
                [119.899045, 32.032396],
                [119.901972, 32.024853],
                [119.905906, 32.015354],
                [119.908683, 32.011069],
                [119.913975, 32.005868],
                [119.916419, 32.00437],
                [119.919699, 32.003663],
                [119.923562, 32.003684],
                [119.927365, 32.00394],
                [119.933794, 32.004114],
                [119.949056, 32.002533],
                [119.951682, 32.002332],
                [119.95309, 32.002928],
                [119.954247, 32.004162],
                [119.963453, 32.006291],
                [119.975506, 32.006867],
                [119.971783, 32.013975],
                [119.948704, 32.047467],
                [119.930595, 32.078958],
                [119.915041, 32.123065],
                [119.909236, 32.139222],
                [119.907968, 32.144837],
                [119.906379, 32.148881],
                [119.903924, 32.156054],
                [119.901892, 32.159771],
                [119.900956, 32.163925],
                [119.899447, 32.166943],
                [119.898471, 32.169954],
                [119.897455, 32.171817],
                [119.896751, 32.174814],
                [119.895765, 32.177361],
                [119.894065, 32.182912],
                [119.89338, 32.186822],
                [119.893501, 32.190041],
                [119.892576, 32.194643],
                [119.892425, 32.198096],
                [119.891982, 32.201092],
                [119.889738, 32.20642],
                [119.888763, 32.209202],
                [119.887495, 32.211527],
                [119.886821, 32.215671],
                [119.88672, 32.220493],
                [119.885563, 32.226028],
                [119.882857, 32.233672],
                [119.879929, 32.24292],
                [119.878933, 32.245244],
                [119.878471, 32.247547],
                [119.877475, 32.249871],
                [119.875915, 32.25151],
                [119.873883, 32.255238],
                [119.870824, 32.260134],
                [119.869557, 32.262693],
                [119.8667, 32.265742],
                [119.858661, 32.275125],
                [119.85686, 32.277469],
                [119.852705, 32.281935],
                [119.847453, 32.286201],
                [119.845089, 32.287867],
                [119.843007, 32.290218],
                [119.839838, 32.291904],
                [119.834576, 32.295713],
                [119.826336, 32.298907],
                [119.823157, 32.300829],
                [119.819183, 32.302529],
                [119.815491, 32.305149],
                [119.809142, 32.308757],
                [119.80703, 32.310187],
                [119.802271, 32.312827],
                [119.799605, 32.313581],
                [119.794293, 32.316014],
                [119.787864, 32.317313],
                [119.786013, 32.317893],
                [119.768638, 32.318916],
                [119.745157, 32.313864],
                [119.730679, 32.303994],
                [119.718617, 32.290128],
                [119.708385, 32.27639],
                [119.705477, 32.272491],
                [119.694612, 32.253384],
                [119.701745, 32.252236],
                [119.70263, 32.251552],
                [119.705316, 32.250673],
                [119.708365, 32.250148],
                [119.710759, 32.249435],
                [119.714431, 32.248834],
                [119.71755, 32.247762],
                [119.722088, 32.245036],
                [119.724261, 32.243951],
                [119.727188, 32.241834],
                [119.729905, 32.239475],
                [119.733124, 32.237193],
                [119.73571, 32.234979],
                [119.740106, 32.232136],
                [119.742883, 32.229369],
                [119.751123, 32.224416],
                [119.753457, 32.222645],
                [119.760409, 32.21839],
                [119.764946, 32.215471],
                [119.771355, 32.211582],
                [119.781566, 32.206842],
                [119.791687, 32.20514],
                [119.799867, 32.205888],
                [119.811185, 32.207271],
                [119.813609, 32.20705],
                [119.818046, 32.205286],
                [119.822372, 32.202303],
                [119.824938, 32.199957],
                [119.826547, 32.197605],
                [119.826849, 32.195016],
                [119.826386, 32.1924],
                [119.824495, 32.189715],
                [119.821608, 32.187687],
                [119.81868, 32.187141],
                [119.81535, 32.186912],
                [119.808297, 32.186116],
                [119.804796, 32.184974],
                [119.799927, 32.181472],
                [119.798307, 32.178614],
                [119.798166, 32.176094],
                [119.798871, 32.173056],
                [119.801657, 32.165663],
                [119.801798, 32.159716],
                [119.800581, 32.15478],
                [119.795118, 32.143023],
                [119.793428, 32.138093],
                [119.79214, 32.131674],
                [119.791607, 32.126196],
                [119.791738, 32.121936],
                [119.792955, 32.118591],
                [119.795611, 32.113646],
                [119.798297, 32.111187],
                [119.802009, 32.108901],
                [119.805128, 32.107966],
                [119.817603, 32.109469],
                [119.823509, 32.109206],
                [119.830924, 32.106906],
                [119.835179, 32.104966],
                [119.842031, 32.100983],
                [119.847957, 32.098171],
                [119.856297, 32.094443],
                [119.861307, 32.091693],
                [119.865472, 32.089746],
                [119.880563, 32.079],
                [119.894125, 32.065487],
                [119.895654, 32.064364],
                [119.897455, 32.062195],
                [119.89835, 32.060296],
                [119.898964, 32.05245],
                [119.898944, 32.049144],
                [119.898461, 32.047689],
                [119.896932, 32.045436],
              ],
            ],
          ],
        },
      },
      {
        type: "Feature",
        properties: {
          adcode: 321183,
          name: "句容市",
          center: [119.167135, 31.947355],
          centroid: [119.20068, 31.92635],
          childrenNum: 0,
          level: "district",
          parent: { adcode: 321100 },
          subFeatureIndex: 5,
          acroutes: [100000, 320000, 321100],
        },
        geometry: {
          type: "MultiPolygon",
          coordinates: [
            [
              [
                [119.33939, 31.85859],
                [119.338152, 31.86084],
                [119.337207, 31.863417],
                [119.336583, 31.871466],
                [119.338474, 31.874411],
                [119.338937, 31.878473],
                [119.338837, 31.883293],
                [119.339078, 31.889473],
                [119.338726, 31.89452],
                [119.339249, 31.900922],
                [119.338887, 31.905747],
                [119.339279, 31.908947],
                [119.341654, 31.914611],
                [119.343917, 31.917534],
                [119.345114, 31.920484],
                [119.345789, 31.924135],
                [119.345929, 31.927799],
                [119.344833, 31.934469],
                [119.344259, 31.940673],
                [119.344672, 31.944323],
                [119.345617, 31.947973],
                [119.346644, 31.95349],
                [119.347549, 31.955079],
                [119.347348, 31.95623],
                [119.348364, 31.956064],
                [119.348274, 31.95818],
                [119.348756, 31.959734],
                [119.349672, 31.959769],
                [119.350648, 31.960837],
                [119.351241, 31.960414],
                [119.352962, 31.961261],
                [119.35266, 31.961739],
                [119.354129, 31.962822],
                [119.353575, 31.963418],
                [119.355336, 31.964466],
                [119.357318, 31.964306],
                [119.359038, 31.965458],
                [119.360849, 31.964979],
                [119.359873, 31.966082],
                [119.360869, 31.966193],
                [119.363224, 31.964653],
                [119.364511, 31.964896],
                [119.365326, 31.964265],
                [119.365668, 31.966499],
                [119.366735, 31.96584],
                [119.365538, 31.962489],
                [119.36752, 31.963425],
                [119.368526, 31.965139],
                [119.370548, 31.965257],
                [119.370085, 31.964008],
                [119.371866, 31.963314],
                [119.37254, 31.961531],
                [119.374944, 31.96432],
                [119.372912, 31.963668],
                [119.372791, 31.965382],
                [119.376152, 31.965493],
                [119.377731, 31.966374],
                [119.378888, 31.965978],
                [119.379874, 31.96663],
                [119.381886, 31.96661],
                [119.382148, 31.967886],
                [119.384009, 31.96806],
                [119.384753, 31.969003],
                [119.383788, 31.968989],
                [119.385126, 31.97098],
                [119.384844, 31.971792],
                [119.382963, 31.972756],
                [119.3825, 31.974497],
                [119.383607, 31.974657],
                [119.383244, 31.976197],
                [119.384381, 31.975288],
                [119.384562, 31.976759],
                [119.383254, 31.976787],
                [119.383627, 31.978847],
                [119.384945, 31.98201],
                [119.384623, 31.983626],
                [119.383345, 31.983758],
                [119.383164, 31.986005],
                [119.381836, 31.985547],
                [119.380649, 31.985915],
                [119.376363, 31.98604],
                [119.374099, 31.988044],
                [119.373073, 31.989633],
                [119.372932, 31.990812],
                [119.373938, 31.991714],
                [119.370709, 31.99145],
                [119.370638, 31.991956],
                [119.368556, 31.992379],
                [119.37098, 31.992518],
                [119.370508, 31.993406],
                [119.367992, 31.995944],
                [119.36766, 31.997942],
                [119.366493, 31.998892],
                [119.365367, 31.998753],
                [119.365789, 32.000161],
                [119.365236, 32.001652],
                [119.366544, 32.001319],
                [119.366674, 32.002318],
                [119.368385, 32.00573],
                [119.372017, 32.010251],
                [119.373667, 32.011825],
                [119.376514, 32.016817],
                [119.376675, 32.02278],
                [119.376262, 32.027377],
                [119.375266, 32.030379],
                [119.373727, 32.032701],
                [119.371433, 32.038019],
                [119.371262, 32.041464],
                [119.370256, 32.043772],
                [119.370065, 32.046531],
                [119.36933, 32.049297],
                [119.369119, 32.051598],
                [119.368093, 32.053448],
                [119.367359, 32.055985],
                [119.362399, 32.062271],
                [119.360829, 32.063671],
                [119.358133, 32.064184],
                [119.353324, 32.065646],
                [119.351161, 32.065917],
                [119.347428, 32.067351],
                [119.343173, 32.069499],
                [119.339984, 32.071613],
                [119.338957, 32.07347],
                [119.337126, 32.075791],
                [119.33612, 32.078334],
                [119.334269, 32.079748],
                [119.331341, 32.083594],
                [119.327921, 32.087543],
                [119.325365, 32.089628],
                [119.319228, 32.091298],
                [119.313786, 32.09452],
                [119.310365, 32.096951],
                [119.305294, 32.09576],
                [119.299228, 32.093487],
                [119.295465, 32.098205],
                [119.293413, 32.099036],
                [119.290123, 32.10038],
                [119.281481, 32.105195],
                [119.277919, 32.10649],
                [119.276531, 32.111949],
                [119.273784, 32.119713],
                [119.269921, 32.122373],
                [119.26647, 32.127179],
                [119.265726, 32.1297],
                [119.263844, 32.130545],
                [119.260343, 32.130614],
                [119.254719, 32.132324],
                [119.250434, 32.133322],
                [119.248069, 32.135434],
                [119.246761, 32.137061],
                [119.245745, 32.139374],
                [119.245816, 32.142358],
                [119.246439, 32.145329],
                [119.247415, 32.151505],
                [119.247204, 32.153804],
                [119.247345, 32.159307],
                [119.247928, 32.161128],
                [119.248884, 32.166389],
                [119.248673, 32.168459],
                [119.249045, 32.17235],
                [119.250494, 32.17599],
                [119.250806, 32.177825],
                [119.253834, 32.18006],
                [119.259035, 32.182947],
                [119.260695, 32.184518],
                [119.263432, 32.186075],
                [119.265364, 32.187646],
                [119.266219, 32.189466],
                [119.269237, 32.191245],
                [119.27148, 32.194186],
                [119.270766, 32.197867],
                [119.26978, 32.199867],
                [119.250434, 32.212226],
                [119.241661, 32.216294],
                [119.236147, 32.210406],
                [119.232274, 32.207624],
                [119.228924, 32.205894],
                [119.224286, 32.202836],
                [119.221398, 32.201106],
                [119.220282, 32.199923],
                [119.219708, 32.198587],
                [119.218159, 32.193072],
                [119.216257, 32.190698],
                [119.214738, 32.190124],
                [119.209517, 32.189445],
                [119.199396, 32.18584],
                [119.196659, 32.185085],
                [119.193812, 32.184836],
                [119.191076, 32.185417],
                [119.188067, 32.18703],
                [119.186347, 32.188691],
                [119.184546, 32.189632],
                [119.18187, 32.189279],
                [119.179184, 32.188435],
                [119.174989, 32.187591],
                [119.172745, 32.18676],
                [119.170592, 32.186746],
                [119.163399, 32.18557],
                [119.160038, 32.185556],
                [119.154455, 32.186172],
                [119.146618, 32.189238],
                [119.141507, 32.191923],
                [119.136718, 32.19339],
                [119.134424, 32.193307],
                [119.13204, 32.192276],
                [119.128257, 32.191376],
                [119.124383, 32.190137],
                [119.123055, 32.189335],
                [119.121315, 32.187604],
                [119.119756, 32.186622],
                [119.118196, 32.186248],
                [119.116345, 32.186379],
                [119.11386, 32.185487],
                [119.108065, 32.183756],
                [119.098206, 32.181604],
                [119.093548, 32.17986],
                [119.086897, 32.17851],
                [119.083547, 32.178392],
                [119.079885, 32.178828],
                [119.078658, 32.178731],
                [119.077491, 32.17788],
                [119.076454, 32.17608],
                [119.079261, 32.174931],
                [119.076958, 32.170654],
                [119.076012, 32.161675],
                [119.075126, 32.161426],
                [119.069824, 32.161329],
                [119.06748, 32.16072],
                [119.066092, 32.162506],
                [119.062712, 32.161945],
                [119.061595, 32.16272],
                [119.058889, 32.162894],
                [119.056333, 32.16207],
                [119.052299, 32.161253],
                [119.049301, 32.163745],
                [119.047872, 32.162845],
                [119.048697, 32.160574],
                [119.051474, 32.159349],
                [119.053687, 32.159854],
                [119.054683, 32.160671],
                [119.054945, 32.159875],
                [119.058949, 32.159169],
                [119.058486, 32.156448],
                [119.057229, 32.156614],
                [119.056796, 32.155479],
                [119.055428, 32.155901],
                [119.056122, 32.157231],
                [119.054693, 32.157708],
                [119.054683, 32.158989],
                [119.053063, 32.158906],
                [119.053245, 32.157632],
                [119.047691, 32.158366],
                [119.047369, 32.15631],
                [119.043858, 32.156067],
                [119.041745, 32.156981],
                [119.041021, 32.156157],
                [119.039351, 32.15703],
                [119.038284, 32.155728],
                [119.035045, 32.152765],
                [119.031755, 32.148659],
                [119.032862, 32.145973],
                [119.027811, 32.141936],
                [119.022378, 32.130898],
                [119.020517, 32.128931],
                [119.01603, 32.128488],
                [119.011241, 32.126016],
                [119.010205, 32.124035],
                [119.009239, 32.124686],
                [119.008927, 32.12319],
                [119.009702, 32.121632],
                [119.008887, 32.115945],
                [119.013193, 32.115446],
                [119.013102, 32.114608],
                [119.01442, 32.114601],
                [119.014622, 32.11528],
                [119.016976, 32.114989],
                [119.018093, 32.114158],
                [119.020769, 32.115266],
                [119.021926, 32.114255],
                [119.026654, 32.115959],
                [119.029159, 32.115537],
                [119.03254, 32.11402],
                [119.037228, 32.114304],
                [119.038928, 32.113597],
                [119.039924, 32.11224],
                [119.041977, 32.11172],
                [119.045377, 32.111533],
                [119.042399, 32.108866],
                [119.044944, 32.110238],
                [119.044824, 32.109233],
                [119.046444, 32.107786],
                [119.046444, 32.109136],
                [119.047953, 32.107709],
                [119.046796, 32.109857],
                [119.047922, 32.110383],
                [119.049271, 32.110245],
                [119.049844, 32.108534],
                [119.051363, 32.1055],
                [119.050146, 32.104426],
                [119.055669, 32.104003],
                [119.057661, 32.102244],
                [119.060559, 32.104045],
                [119.069261, 32.107917],
                [119.075549, 32.108083],
                [119.079785, 32.107322],
                [119.080428, 32.106317],
                [119.081123, 32.100664],
                [119.082954, 32.097007],
                [119.086394, 32.09224],
                [119.089755, 32.089698],
                [119.091324, 32.090176],
                [119.094554, 32.091832],
                [119.096938, 32.092046],
                [119.09887, 32.091042],
                [119.100178, 32.088832],
                [119.100157, 32.085949],
                [119.099141, 32.076297],
                [119.098507, 32.071627],
                [119.092833, 32.060331],
                [119.086616, 32.053206],
                [119.087732, 32.04563],
                [119.089392, 32.042559],
                [119.092672, 32.037617],
                [119.09234, 32.032216],
                [119.094936, 32.027994],
                [119.094312, 32.02224],
                [119.094634, 32.020804],
                [119.096365, 32.018329],
                [119.097904, 32.011236],
                [119.096536, 32.009273],
                [119.093769, 32.00625],
                [119.09236, 32.004121],
                [119.092894, 32.002796],
                [119.097934, 32.002387],
                [119.100872, 32.002325],
                [119.102813, 32.004877],
                [119.107502, 32.003434],
                [119.112482, 32.000452],
                [119.114162, 31.997879],
                [119.114222, 31.98819],
                [119.115842, 31.986394],
                [119.118669, 31.986054],
                [119.121637, 31.984819],
                [119.121657, 31.980983],
                [119.121124, 31.977557],
                [119.118589, 31.977994],
                [119.115993, 31.979901],
                [119.11392, 31.979846],
                [119.112683, 31.978375],
                [119.111747, 31.97499],
                [119.111043, 31.969482],
                [119.109685, 31.967491],
                [119.107944, 31.965798],
                [119.105671, 31.96516],
                [119.102964, 31.965583],
                [119.100248, 31.967088],
                [119.096707, 31.969503],
                [119.095137, 31.971223],
                [119.090117, 31.97413],
                [119.082843, 31.974241],
                [119.080016, 31.973013],
                [119.07733, 31.970599],
                [119.074965, 31.96935],
                [119.073748, 31.969621],
                [119.070418, 31.972361],
                [119.064895, 31.973422],
                [119.061977, 31.972479],
                [119.053104, 31.968767],
                [119.046846, 31.968545],
                [119.043325, 31.965756],
                [119.041886, 31.963855],
                [119.038526, 31.962718],
                [119.034401, 31.96226],
                [119.02924, 31.957091],
                [119.029169, 31.95274],
                [119.02933, 31.950263],
                [119.033023, 31.944559],
                [119.03752, 31.938987],
                [119.040156, 31.93787],
                [119.043616, 31.937328],
                [119.046504, 31.935913],
                [119.051544, 31.93594],
                [119.054623, 31.938161],
                [119.055297, 31.939549],
                [119.058043, 31.939181],
                [119.061545, 31.939271],
                [119.065348, 31.940174],
                [119.067631, 31.940229],
                [119.071112, 31.938591],
                [119.076877, 31.936718],
                [119.082219, 31.936482],
                [119.084956, 31.935913],
                [119.087129, 31.9344],
                [119.088517, 31.932637],
                [119.090429, 31.933192],
                [119.093507, 31.934566],
                [119.094916, 31.934469],
                [119.097723, 31.933005],
                [119.101495, 31.935649],
                [119.102039, 31.935316],
                [119.10226, 31.933185],
                [119.104162, 31.933601],
                [119.104343, 31.931249],
                [119.105771, 31.931221],
                [119.104987, 31.93027],
                [119.108437, 31.922982],
                [119.109383, 31.916152],
                [119.110027, 31.910655],
                [119.111284, 31.908461],
                [119.112683, 31.906823],
                [119.112391, 31.904622],
                [119.111184, 31.901637],
                [119.11215, 31.900456],
                [119.116697, 31.89904],
                [119.117029, 31.892833],
                [119.116466, 31.890479],
                [119.115037, 31.888584],
                [119.11379, 31.887737],
                [119.109061, 31.886042],
                [119.107783, 31.88514],
                [119.10563, 31.880904],
                [119.103759, 31.878869],
                [119.102854, 31.875744],
                [119.102914, 31.873564],
                [119.102512, 31.871835],
                [119.101485, 31.869675],
                [119.100992, 31.866924],
                [119.099745, 31.865438],
                [119.097179, 31.864084],
                [119.094523, 31.861007],
                [119.093568, 31.860375],
                [119.092401, 31.860451],
                [119.086736, 31.861806],
                [119.075408, 31.864181],
                [119.071937, 31.865501],
                [119.069754, 31.868793],
                [119.068949, 31.869161],
                [119.066645, 31.868244],
                [119.061826, 31.86457],
                [119.055931, 31.861125],
                [119.050045, 31.859944],
                [119.04586, 31.858402],
                [119.043365, 31.854422],
                [119.041594, 31.853867],
                [119.03596, 31.854304],
                [119.032811, 31.853755],
                [119.028968, 31.850317],
                [119.027972, 31.8484],
                [119.0259, 31.846364],
                [119.020356, 31.845934],
                [119.014672, 31.84658],
                [119.010748, 31.845878],
                [119.003374, 31.845774],
                [118.996915, 31.842801],
                [118.995516, 31.843836],
                [118.992267, 31.844135],
                [118.979661, 31.842349],
                [118.978856, 31.841377],
                [118.979329, 31.838237],
                [118.97782, 31.836263],
                [118.976592, 31.835805],
                [118.971552, 31.836416],
                [118.969449, 31.834596],
                [118.96954, 31.833707],
                [118.970797, 31.83099],
                [118.97291, 31.829795],
                [118.971743, 31.827509],
                [118.970385, 31.826078],
                [118.971884, 31.824334],
                [118.978011, 31.822784],
                [118.979339, 31.822034],
                [118.980617, 31.820672],
                [118.986412, 31.817872],
                [118.987639, 31.815585],
                [118.988393, 31.81512],
                [118.990023, 31.812806],
                [118.992046, 31.812444],
                [118.992669, 31.808879],
                [118.995013, 31.805377],
                [118.997096, 31.803327],
                [118.998424, 31.801471],
                [119.000738, 31.801408],
                [119.004802, 31.799629],
                [119.006362, 31.798614],
                [119.00606, 31.793846],
                [119.007871, 31.792331],
                [119.005326, 31.787535],
                [119.002931, 31.783434],
                [118.985496, 31.784449],
                [118.982558, 31.782982],
                [118.982025, 31.778046],
                [118.982951, 31.775245],
                [118.982116, 31.772311],
                [118.980456, 31.767959],
                [118.979339, 31.766909],
                [118.980456, 31.764656],
                [118.984128, 31.764065],
                [118.99121, 31.765518],
                [118.995718, 31.768619],
                [118.998897, 31.767208],
                [119.001744, 31.770017],
                [119.005024, 31.777747],
                [119.006442, 31.778818],
                [119.0093, 31.778686],
                [119.012066, 31.777372],
                [119.016644, 31.777316],
                [119.017217, 31.778477],
                [119.021553, 31.778609],
                [119.025608, 31.781856],
                [119.029682, 31.784713],
                [119.032872, 31.783865],
                [119.034964, 31.783656],
                [119.038254, 31.785978],
                [119.041655, 31.788091],
                [119.049562, 31.788772],
                [119.055639, 31.788974],
                [119.057923, 31.787674],
                [119.061243, 31.785067],
                [119.06576, 31.781953],
                [119.068879, 31.781904],
                [119.069714, 31.783865],
                [119.077108, 31.783948],
                [119.078185, 31.781612],
                [119.077139, 31.775822],
                [119.077843, 31.77391],
                [119.085791, 31.773958],
                [119.089896, 31.769634],
                [119.092803, 31.762737],
                [119.09401, 31.756055],
                [119.096767, 31.754094],
                [119.101415, 31.752495],
                [119.105419, 31.751347],
                [119.109242, 31.752557],
                [119.110822, 31.751354],
                [119.10888, 31.746862],
                [119.11055, 31.744574],
                [119.114433, 31.740255],
                [119.117432, 31.739219],
                [119.120581, 31.738767],
                [119.124997, 31.73579],
                [119.126748, 31.731881],
                [119.129273, 31.723597],
                [119.130903, 31.722109],
                [119.134957, 31.722693],
                [119.13868, 31.722109],
                [119.142372, 31.717239],
                [119.148006, 31.712105],
                [119.153549, 31.703576],
                [119.156205, 31.704237],
                [119.157393, 31.69961],
                [119.157704, 31.699388],
                [119.167946, 31.699186],
                [119.169083, 31.697885],
                [119.171216, 31.698226],
                [119.173842, 31.696618],
                [119.17858, 31.69643],
                [119.182786, 31.695171],
                [119.184858, 31.695129],
                [119.186428, 31.69412],
                [119.188822, 31.690398],
                [119.190412, 31.687058],
                [119.1902, 31.683133],
                [119.186619, 31.677663],
                [119.186065, 31.671455],
                [119.187444, 31.66682],
                [119.189255, 31.665087],
                [119.191076, 31.662372],
                [119.192273, 31.659831],
                [119.190392, 31.655968],
                [119.188591, 31.653754],
                [119.186096, 31.651193],
                [119.187323, 31.649153],
                [119.1902, 31.649],
                [119.194436, 31.647816],
                [119.199104, 31.647329],
                [119.200865, 31.646424],
                [119.204738, 31.64664],
                [119.20672, 31.643841],
                [119.206217, 31.637095],
                [119.208581, 31.634971],
                [119.21178, 31.63344],
                [119.212143, 31.630933],
                [119.21164, 31.628726],
                [119.212424, 31.627723],
                [119.216579, 31.627814],
                [119.220231, 31.629659],
                [119.222344, 31.630195],
                [119.227264, 31.630097],
                [119.232505, 31.63227],
                [119.233562, 31.62773],
                [119.234065, 31.627034],
                [119.236952, 31.625209],
                [119.24815, 31.629694],
                [119.25643, 31.632005],
                [119.258361, 31.6322],
                [119.260866, 31.634512],
                [119.26314, 31.635772],
                [119.269529, 31.636921],
                [119.276772, 31.641321],
                [119.282789, 31.642797],
                [119.285183, 31.644607],
                [119.287849, 31.647427],
                [119.288916, 31.648986],
                [119.290656, 31.649745],
                [119.299902, 31.654743],
                [119.306995, 31.660771],
                [119.316522, 31.67101],
                [119.318776, 31.67751],
                [119.32443, 31.68918],
                [119.328675, 31.700716],
                [119.328655, 31.703993],
                [119.321613, 31.707138],
                [119.32108, 31.707632],
                [119.322709, 31.709114],
                [119.324832, 31.710137],
                [119.323353, 31.711716],
                [119.321603, 31.715535],
                [119.323605, 31.719772],
                [119.326905, 31.721531],
                [119.329209, 31.723048],
                [119.32953, 31.724849],
                [119.33109, 31.727312],
                [119.329852, 31.727924],
                [119.326834, 31.730817],
                [119.325939, 31.732396],
                [119.324329, 31.734267],
                [119.323222, 31.733627],
                [119.32282, 31.732438],
                [119.318554, 31.732041],
                [119.309047, 31.731708],
                [119.305667, 31.733036],
                [119.306451, 31.741799],
                [119.30618, 31.748281],
                [119.306572, 31.751153],
                [119.304922, 31.759629],
                [119.304661, 31.765581],
                [119.305214, 31.766366],
                [119.30462, 31.767368],
                [119.305123, 31.769356],
                [119.306834, 31.770274],
                [119.307387, 31.773048],
                [119.306793, 31.77448],
                [119.307387, 31.776524],
                [119.310979, 31.777309],
                [119.314661, 31.777309],
                [119.314912, 31.778213],
                [119.31445, 31.780104],
                [119.314771, 31.781015],
                [119.31614, 31.782294],
                [119.318182, 31.783093],
                [119.31776, 31.784171],
                [119.318685, 31.785408],
                [119.320003, 31.785533],
                [119.318323, 31.786027],
                [119.317588, 31.787271],
                [119.318162, 31.788842],
                [119.319671, 31.789752],
                [119.319651, 31.79295],
                [119.320053, 31.797065],
                [119.318796, 31.796585],
                [119.315355, 31.7963],
                [119.31456, 31.796627],
                [119.313524, 31.798823],
                [119.313484, 31.800852],
                [119.316935, 31.810242],
                [119.316089, 31.812549],
                [119.313806, 31.813841],
                [119.314299, 31.815509],
                [119.319198, 31.82325],
                [119.320013, 31.825425],
                [119.322468, 31.828246],
                [119.325074, 31.830483],
                [119.325285, 31.831407],
                [119.326241, 31.831254],
                [119.330698, 31.834207],
                [119.332629, 31.836354],
                [119.334289, 31.840501],
                [119.334219, 31.84121],
                [119.331603, 31.842745],
                [119.329591, 31.845392],
                [119.329923, 31.849352],
                [119.332207, 31.851943],
                [119.332418, 31.852762],
                [119.338937, 31.858375],
                [119.33939, 31.85859],
              ],
            ],
          ],
        },
      },
    ],
  });
});
