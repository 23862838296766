<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div v-if="type != 6"
           @click="toPage('/huidangwu')"
           class="can">
        慧党务-->
      </div>
      <div v-if="type == 6"
           @click="toPage('/huihuodong')"
           class="can">
        慧活动-->
      </div>
      <div @click="toPage('/zhidu?type=' + type)"
           class="can">
        {{ pageName }}-->
      </div>
      <div>详情</div>
    </div>

    <h1>{{ info.title }}</h1>
    <div class="tar w1678">时间：{{ info.addtime }}</div>
    <div v-if="info.contextType == 1"
         class="page_body overflow_y"
         v-html="info.context"></div>
    <div v-if="info.contextType == 2"
         class="page_body overflow_y"
         v-loading="loading">
      <iframe v-if="suffix == 'pdf'"
              :src="info.url + '#toolbar=0'"
              type="application/x-google-chrome-pdf"
              width="100%"
              frameborder="0"
              scrolling="auto"
              height="100%" />
      <iframe v-else
              :src="`https://view.officeapps.live.com/op/view.aspx?src=${info.url}`"
              width="100%"
              frameborder="1"
              scrolling="auto"
              height="100%" />
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
// import { Search } from "@element-plus/icons-vue";
import * as Service from "../api/index";
export default {
  // components: {
  //   Search,
  // },
  data() {
    return {
      type: null, // 1党内法规2控股制度3公司制度4星级考评
      pageName: "",
      title: "",
      currentPage: 1,
      pageSize: 10,
      background: "#c30515",
      total: 0,
      list: [],
      centerDialogVisible: false,
      info: {},
      loading: false,
      suffix: "", // 文件后缀名
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
    if (this.type == 1) {
      this.pageName = "党内法规";
    } else if (this.type == 2) {
      this.pageName = "控股制度";
    } else if (this.type == 3) {
      this.pageName = "公司制度";
    } else if (this.type == 4) {
      this.pageName = "星级考评";
    } else if (this.type == 6) {
      this.pageName = "精品党课";
    }
    this.getDetail();
  },
  methods: {
    onmessage() {},
    async getDetail() {
      this.loading = true;
      const res = await Service.getApi({
        do: "wisdom",
        op: "list",
        data: {
          id: this.id,
        },
      });
      console.log("详情", res);
      var info = res.data[0];
      this.info = info;
      var suffix = info.url.substring(info.url.lastIndexOf(".") + 1);
      this.loading = false;
      this.suffix = suffix;
    },
    toPage(e) {
      this.$router.push(e);
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

h1 {
  color: #c30515;
}

.w1678 {
  width: 1678px;
}

.page_body {
  width: 1678px;
  height: 600px;
  margin-top: 20px;
  overflow-x: hidden;
}

.search {
  display: flex;
  align-items: center;
}

.search_btn {
  font-size: 20px;
}

.el-button--danger {
  width: 100px;
  height: 55px;
  font-size: 20px;
}

.page_bottom {
  margin-top: 47px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}
</style>
