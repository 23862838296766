<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huishuju')"
           class="can">慧数据--></div>
      <div class="can"
           @click="toPage('/partyMap2')">党建地图</div>
    </div>

    <div class="page_body bb pt30 pb30 overflow_y">
      <div class="page_body_cell">
        <div>支部名称</div>
        <div>{{ info.name }}</div>
      </div>
      <div class="page_body_cell">
        <div>支部地址</div>
        <div>{{ info.address }}</div>
      </div>
      <div class="page_body_cell">
        <div>成立时间</div>
        <div>{{ info.creattime }}</div>
      </div>
      <div class="page_body_cell">
        <div>支部人数</div>
        <div>{{ info.joinNum }}</div>
      </div>
      <div class="page_body_cell">
        <div>支部成员</div>
        <div>
          {{ info.branchMember }}
        </div>
      </div>
      <div class="page_body_cell">
        <div>支部简介</div>
        <div v-html="info.details"></div>
      </div>
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
import * as echarts from "echarts";
import "@/utils/taizhou.js";
export default {
  data() {
    return {
      list: [],
      pageSize: 100,
      page: 1,
      info: {}, // 当前支部的信息
      index: null,
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.index = this.$route.query.index || 0;
    this.getBranchList();
  },
  methods: {
    onmessage() {},
    toPage(e) {
      this.$router.push(e);
    },
    async getBranchList() {
      const res = await Service.getApi({
        do: "intell",
        op: "branchList",
        data: {
          page: this.page,
          count: this.pageSize,
        },
      });
      this.info = res.data[this.index];
      console.log("info", this.info);
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

.page_body {
  width: 1678px;
  height: 728px;
  background: rgba(255, 255, 255, 0.6);
  margin-top: 20px;
}

.page_bottom {
  margin-top: 27px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}

.slash {
  width: 711px;
  height: 749px;
}

#map {
  width: 730px;
  height: 800px;
}

.data_body {
  width: calc(100% - 730px);
}

.data {
  width: 100%;
  position: absolute;
  box-sizing: border-box;
  padding-top: 120px;
}

.data > div {
  margin-top: 20px;
  font-weight: bold;
}

.data > div > text {
  max-width: 300px;
}

.data > div > text > span {
  font-size: 20px;
  color: #d51f1c;
}

.cell1 {
  padding-left: calc(640px - 140px);
}
.cell2 {
  padding-right: calc(460px + 140px);
}
.cell3 {
  padding-left: calc(510px - 120px);
}
.cell4 {
  padding-right: calc(580px + 140px);
}
.cell5 {
  padding-left: calc(405px - 140px);
}
.cell6 {
  padding-right: calc(660px + 140px);
}
.cell7 {
  padding-left: calc(320px - 10px);
}

.cell8 {
  margin-top: 100px !important;
  padding-left: 220px;
}

.cell8 > div {
  background-color: #fff;
  box-sizing: border-box;
  padding: 5px;
}

.page_body_cell {
  display: flex;
}

.page_body_cell > div:first-child {
  width: 120px;
  flex-shrink: 0;
  font-size: 30px;
  color: #c12227;
  padding: 0 45px;
}

.page_body_cell > div:last-child {
  font-size: 30px;
  border-left: 1px solid;
  box-sizing: border-box;
  padding: 0 45px 30px 45px;
}
</style>
