<template>
  <div class="bb p30">
    <div v-loading="circleListLoading">
      <div class="mb20">
        <el-table :data="circleList" height="60vh" style="width: 100%">
          <el-table-column label="头像/名称" width="360">
            <template #default="scope">
              <div class="flex at_c">
                <img class="list_avatar" :src="scope.row.avatar" alt="" />
                <span class="ml10">{{ scope.row.title }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="人数"
            width="120"
            prop="user_total"
          ></el-table-column>
          <el-table-column
            label="最后发言时间"
            width="160"
            prop="time"
          ></el-table-column>
          <el-table-column label="操作" width="300" align="center">
            <template #default="scope">
              <el-button
                type="primary"
                size="large"
                @click="handleEditCircle(scope.$index, scope.row)"
                >编辑</el-button
              >
              <!-- <el-button
                        type="danger"
                        size="large"
                        @click="handleDelete(scope.$index, scope.row)"
                        >删除</el-button
                      > -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- <div>
                <el-pagination
                  @current-change="handleCurrentChange"
                  :current-page="page"
                  :page-size="15"
                  layout="total, prev, pager, next, jumper"
                  :total="total"
                >
                </el-pagination>
              </div> -->
    </div>
    <!-- 修改圈子 -->
    <el-dialog width="620px" v-model="circleInfoFormShow" title="圈子信息">
      <el-form :model="circleInfoForm" ref="circleInfoForm">
        <el-form-item
          class="w520"
          size="large"
          label="头像"
          :label-width="formLabelWidth"
        >
          <img class="list_avatar" :src="circleInfoForm.avatar" alt="" />
        </el-form-item>
        <el-form-item
          class="w520"
          size="large"
          label="名称"
          :label-width="formLabelWidth"
        >
          {{ circleInfoForm.title }}
        </el-form-item>
        <el-form-item
          class="w520"
          size="large"
          label="群公告"
          :label-width="formLabelWidth"
        >
          <el-input
            resize="none"
            class="h340"
            type="textarea"
            v-model="circleInfoForm.notice"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="circleInfoFormShow = false">取消</el-button>
          <el-button type="primary" @click="submitForm('circleInfoForm')"
            >确定</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/circleManage";
export default {
  data() {
    return {
      circleListLoading: false, // 圈子列表加载状态
      circleList: [], // 圈子列表
      circleInfoForm: {
        uid: null,
        avatar: "",
        title: "",
        notice: "",
      }, // 修改圈子表单
      circleInfoFormShow: false, // 修改圈子表单显示状态
      formLabelWidth: "60px",
    };
  },
  methods: {
    // 获取圈子列表
    async getCircleList() {
      this.circleListLoading = true;
      const res = await Service.getCircleList();
      this.circleList = res.data.list;
      this.circleListLoading = false;
    },
    // 圈子展示编辑表单
    async handleEditCircle(index, row) {
      const res = await Service.getCircleConfig({
        uid: row.uid,
      });
      this.circleInfoFormShow = true;
      this.circleInfoForm = {
        uid: row.uid,
        avatar: row.avatar,
        title: row.title,
        notice: res.data.notice,
      };
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "addUserForm":
              this.addUser();
              break;
            case "editForm":
              this.profile();
              break;
            case "circleInfoForm":
              this.changeCircleNotice();
              break;
            case "modifyTopicForm":
              this.modifyTopic();
              break;
          }
        }
      });
    },
    // 修改圈子公告
    async changeCircleNotice() {
      const res = await Service.changeCircleNotice(this.circleInfoForm);
      this.$message.success(res.msg);
      this.circleInfoFormShow = false;
      this.circleInfoForm = {
        uid: null,
        avatar: "",
        title: "",
        notice: "",
      };
    },
  },
};
</script>

<style scoped>
.w520 /deep/ .el-form-item__content {
  width: 520px !important;
  flex: initial !important;
}

.h340 {
  height: 340px !important;
}

.h340 /deep/ .el-textarea__inner {
  height: 100%;
}

.list_avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
</style>
