<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huihuodong')"
           class="can">慧活动--></div>
      <div>{{ pageName }}</div>
    </div>

    <div class="page_body">
      <div class="search">
        <el-input :input-style="{
            width: '680px',
            height: '55px',
            fontSize: '20px',
            border: '1px solid #c30515',
          }"
                  v-model="title"
                  placeholder="请输入标题进行搜索">
        </el-input>
        <el-button @click="search"
                   type="danger"
                   color="#c30515">搜索</el-button>
      </div>

      <div class="lists overflow_y">
        <div @click="viewDetail(item.id)"
             class="list can"
             v-for="(item, index) in list"
             :key="index">
          <div class="flex at_c">
            <img class="cover_img"
                 :src="item.tilpic"
                 alt="" />
            <div class="name hang1">
              {{ item.title }}
            </div>
          </div>
          <div class="time">{{ item.stime }}-{{ item.etime }}</div>
        </div>
      </div>

      <div class="flex jc_c w100 mt30">
        <el-pagination v-model:currentPage="currentPage"
                       v-model:page-size="pageSize"
                       :background="background"
                       layout="total, prev, pager, next, jumper"
                       :total="total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange" />
      </div>
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
export default {
  data() {
    return {
      type: null, // 1党内法规2控股制度3公司制度4星级考评
      pageName: "",
      title: "",
      currentPage: 1,
      pageSize: 10,
      background: "#c30515",
      total: 0,
      list: [],
      info: {},
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.type = this.$route.query.type;
    if (this.type == 1) {
      this.pageName = "主题党日";
    } else if (this.type == 2) {
      this.pageName = "政治生日";
    }
    this.getList(1);
  },
  methods: {
    onmessage() {},
    search() {
      this.getList(1);
    },
    async getList(page) {
      const res = await Service.getApi({
        do: "birthday",
        op: "list",
        data: {
          type: this.type,
          title: this.title,
          page,
          count: this.pageSize,
        },
      });
      console.warn("res", res);
      this.list = res.data;
      this.total = res.count;
    },
    viewDetail(id) {
      this.$router.push({
        path: "/huodongDetail",
        query: { type: this.type, id },
      });
    },
    toPage(e) {
      this.$router.push(e);
    },
    handleSizeChange(e) {
      this.getList(e);
    },
    handleCurrentChange(e) {
      this.getList(e);
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

.page_body {
  width: 1678px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.search {
  display: flex;
  align-items: center;
}

.search_btn {
  font-size: 20px;
}

.el-button--danger {
  width: 100px;
  height: 55px;
  font-size: 20px;
}

.lists {
  margin-top: 30px;
  width: 100%;
  height: 600px;
  overflow-y: auto;
}

.list {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 20px 10px 20px 0;
  border-bottom: 1px solid #b9b9b9;
}

.cover_img {
  width: 170px;
  height: 100px;
  object-fit: cover;
  margin-right: 10px;
}

.name {
  max-width: 800px;
}

.time {
  color: #7c7c7c;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #c30515;
}

.page_bottom {
  margin-top: 47px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}
</style>
