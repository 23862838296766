<template>
  <div>
    <div class="bb p30">
      <div class="mb20">
        <el-button type="primary"
                   @click="addUserFormShow = true">添加假人</el-button>
      </div>
      <div v-loading="userListLoading">
        <div class="mb20">
          <el-table :data="tableData"
                    height="60vh"
                    style="width: 100%">
            <el-table-column show-overflow-tooltip
                             label="昵称"
                             width="220"
                             prop="nickname">
            </el-table-column>
            <el-table-column label="头像"
                             width="120">
              <template #default="scope">
                <img class="list_avatar"
                     :src="scope.row.avatar"
                     alt="" />
              </template>
            </el-table-column>
            <el-table-column label="操作"
                             width="300"
                             align="center">
              <template #default="scope">
                <el-button type="primary"
                           size="large"
                           @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
                <el-button type="danger"
                           size="large"
                           @click="handleDelete(scope.$index, scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div>
          <el-pagination @current-change="handleCurrentChange"
                         :current-page="page"
                         :page-size="15"
                         layout="total, prev, pager, next, jumper"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 添加假人 -->
    <el-dialog width="420px"
               v-model="addUserFormShow"
               title="添加假人">
      <el-form :model="addUserForm"
               ref="addUserForm"
               :rules="addUserRules">
        <el-form-item class="w222"
                      size="large"
                      label="昵称"
                      :label-width="formLabelWidth"
                      prop="nickname">
          <el-input v-model="addUserForm.nickname"
                    autocomplete="off" />
        </el-form-item>
        <el-form-item class="w222"
                      size="large"
                      label="&nbsp;&nbsp;&nbsp;头像"
                      :label-width="formLabelWidth">
          <el-upload class="avatar-uploader"
                     action="https://api.i-vce.com/teacher/common/upload"
                     :show-file-list="false"
                     :on-success="handleAvatarSuccess1">
            <div class="avatar-uploader_div"
                 v-if="addUserForm.avatar">
              <img :src="addUserForm.avatar"
                   class="avatar" />
              <div class="zhezhao">
                <el-icon @click.stop="handleRemove1"
                         :size="24">
                  <Delete color="#ffffff" />
                </el-icon>
              </div>
            </div>
            <el-icon v-else
                     class="avatar-uploader-icon">
              <Plus />
            </el-icon>
          </el-upload>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addUserFormShow = false">取消</el-button>
          <el-button type="primary"
                     @click="submitForm('addUserForm')">确定</el-button>
        </span>
      </template>
    </el-dialog>
    <!-- 编辑资料 -->
    <el-dialog width="680px"
               v-model="editFormShow"
               title="编辑资料">
      <el-form :model="editForm"
               ref="editForm">
        <div>
          <div class="form_cell_title">基础信息</div>
          <div class="form_cell">
            <el-form-item size="large"
                          label="昵称"
                          :label-width="formLabelWidth1">
              <el-input v-model="editForm.nickname"
                        autocomplete="off" />
            </el-form-item>
          </div>
          <div class="form_cell">
            <el-form-item size="large"
                          label="头像"
                          :label-width="formLabelWidth1">
              <el-upload class="avatar-uploader"
                         action="https://api.i-vce.com/teacher/common/upload"
                         :show-file-list="false"
                         :on-success="handleAvatarSuccess">
                <div class="avatar-uploader_div"
                     v-if="editForm.avatar">
                  <img :src="editForm.avatar"
                       class="avatar" />
                  <div class="zhezhao">
                    <el-icon @click.stop="handleRemove"
                             :size="24">
                      <Delete color="#ffffff" />
                    </el-icon>
                  </div>
                </div>
                <el-icon v-else
                         class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
          </div>
        </div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="editFormShow = false">取消</el-button>
          <el-button type="primary"
                     @click="submitForm('editForm')">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/circleManage";
// import { Delete, Plus } from "@element-plus/icons-vue";
export default {
  // components: {
  //   Plus,
  //   Delete,
  // },
  data() {
    return {
      addUserFormShow: false, // 添加假人表单显示状态
      addUserForm: {
        nickname: "",
        avatar: "",
      }, // 添加假人表单
      addUserRules: {
        nickname: [{ required: true, message: "请输入昵称", trigger: "blur" }],
        avatar: [{ required: true, message: "请上传头像", trigger: "blur" }],
      }, // 添加假人表单验证规则
      editFormShow: false, // 编辑资料表单显示状态
      editForm: {
        id: null,
        nickname: "",
        avatar: "",
      }, // 编辑资料表单
      formLabelWidth1: "100px",
      userListLoading: false, // 会员列表加载状态
      tableData: [],
      page: 1,
      total: 0,
    };
  },
  methods: {
    // 获取我的会员列表
    async getUserList(page) {
      this.userListLoading = true;
      const res = await Service.getUserList({
        page,
      });
      // console.log("我的会员列表", res);
      this.page = page;
      this.total = res.data.total;
      this.tableData = res.data.data;
      this.userListLoading = false;
    },
    handleCurrentChange(val) {
      this.getUserList(val);
      // console.log(`当前页: ${val}`);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          switch (formName) {
            case "addUserForm":
              this.addUser();
              break;
            case "editForm":
              this.profile();
              break;
            case "circleInfoForm":
              this.changeCircleNotice();
              break;
            case "modifyTopicForm":
              this.modifyTopic();
              break;
          }
        }
      });
    },
    // 添加假人
    async addUser() {
      var addUserForm = this.addUserForm;
      const res = await Service.fillUser({
        nickname: addUserForm.nickname,
        avatar: addUserForm.avatar,
      });
      // console.log("添加假人", res);
      this.$message.success(res.msg);
      this.addUserFormShow = false;
      this.getUserList(this.page);
      this.addUserForm = {
        nickname: "",
        avatar: "",
      };
      this.$refs["addUserForm"].resetFields();
    },
    // 修改会员信息
    async profile() {
      var editForm = this.editForm;
      var param = {
        id: editForm.id,
        nickname: editForm.nickname,
        avatar: editForm.avatar,
      };
      const res = await Service.profile(param);
      // console.log("修改会员信息", res);
      this.$message.success(res.msg);
      this.editFormShow = false;
      this.$refs["editForm"].resetFields();
      this.getUserList(this.page);
    },
    handleEdit(index, row) {
      this.editFormShow = true;
      console.log("handleEdit", row.service_id);
      this.editForm = {
        id: row.id,
        nickname: row.nickname,
        avatar: row.avatar,
      };
    },
    handleDelete(index, row) {
      this.$confirm("确认删除该假人吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.handleDeleteApi(index, row);
      });
      // console.log(index, row);
    },
    async handleDeleteApi(index, row) {
      const res = await Service.delDummyUser({
        id: row.id,
      });
      this.$message.success(res.msg);
      this.getUserList(this.page);
    },
  },
};
</script>

<style scoped>
.w222 /deep/ .el-form-item__content {
  width: 222px !important;
  flex: initial !important;
}

.form_cell_title {
  font-size: 16px;
  color: #000;
  border-bottom: 1px solid #e0e9f5;
  box-sizing: border-box;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.form_cell {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.list_avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.avatar-uploader_div {
  width: 178px;
  height: 178px;
  display: block;
  position: relative;
}

.avatar-uploader .avatar {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.zhezhao {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
}

.zhezhao:hover {
  opacity: 1;
}

/deep/.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

/deep/.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

/deep/.el-icon.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
</style>
