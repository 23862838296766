<template>
  <router-view />
</template>

<script>
import socket from "./utils/socket";
import * as Service from "./api/index";
export default {
  created() {
    // this.getSocketUrl();
  },
  mounted() {
    // const role = localStorage.getItem("vce-token-push");
    // if (role) {

    // }
    socket.initWebpack();
  },
  methods: {
    async getSocketUrl() {
      const res = await Service.getSocketUrl();
      localStorage.setItem("socketUrl", res.data.socketUrl);
    },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
}

.overflow_y::-webkit-scrollbar {
  display: block;
  width: 8px;
  /*height: 6px;*/
}

.overflow_y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  /* background: rgba(0, 0, 0, 0.5); */
  background: #bd1d2b;
  width: 8px;
}

.overflow_y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}
</style>
