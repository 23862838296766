import request from "../utils/request";
// 登录
export const login = (query) => {
  return request({
    url: "/teacher/user/login",
    method: "POST",
    data: query,
  });
};
// 上传文件
export const upload = (query) => {
  return request({
    url: "/teacher/common/upload",
    method: "POST",
    data: query,
  });
};
