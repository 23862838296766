<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huishuju')"
           class="can">慧数据--></div>
      <div>党员信息</div>
    </div>

    <div class="page_body flex fw_w"
         v-loading="loading">
      <div class="memeber_cell"
           v-for="(item, index) in list"
           :key="index">
        <img class="avatar"
             :src="item.headpic"
             alt="" />
        <div class="flex fd_c at_c">
          <div class="name fb fz18 mb5">{{ item.realname }}</div>
          <div class="time fz14 mb5">{{ item.partyday }}</div>
          <div class="branch_name">{{ item.branchName }}</div>
        </div>
      </div>
    </div>

    <div class="flex jc_c w100 mt30">
      <el-pagination v-model:currentPage="currentPage"
                     v-model:page-size="pageSize"
                     :background="background"
                     layout="total, prev, pager, next, jumper"
                     :total="total"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange" />
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 16,
      background: "#c30515",
      total: 0,
      list: [],
      loading: false,
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getMemberList(1);
  },
  methods: {
    onmessage() {},
    async getMemberList(page) {
      this.loading = true;
      this.list = [];
      const res = await Service.getApi({
        do: "intell",
        op: "userList",
        data: {
          page,
          count: this.pageSize,
        },
      });
      this.list = res.data;
      this.total = res.count;
      this.loading = false;
    },
    toPage(e) {
      this.$router.push(e);
    },
    handleSizeChange(e) {
      this.getMemberList(e);
    },
    handleCurrentChange(e) {
      this.getMemberList(e);
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

.page_body {
  width: 1678px;
  height: 728px;
  margin-top: 20px;
}

.memeber_cell {
  width: 178px;
  margin: 0 36px 20px 0;
}

.memeber_cell:nth-child(8n) {
  margin: 0 0 20px 0;
}

.avatar {
  width: 100%;
  height: 266px;
  object-fit: cover;
}

.name {
}

.time {
}

.branch_name {
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #c30515;
}

.page_bottom {
  margin-top: 27px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}
</style>
