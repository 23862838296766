<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huihudong')"
           class="can">慧互动--></div>
      <div>云游展厅</div>
    </div>

    <div class="page_body flex at_c jc_c">
      <iframe style="width: 1678px; height: 650px"
              allowfullscreen
              src="https://vr.baidu.com/vrcc/depthpano-share.html?shareId=share_020185119"></iframe>
      <!-- <div id="domId"
           style="width: 1200px; height: 650px"></div> -->
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
export default {
  data() {
    return {
      viewToken: "0eb5b93cc10a4c8a945d06a461d5ebe7", // 这里替换成自己的
      viewer3D: "",
      app: "",
      viewAdded: false,
      isMapLoaded: false,
      loaderConfig: null,
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getViewToken();
    // this.getDeviceList();
  },
  methods: {
    onmessage() {},
    async getViewToken() {
      const res = await Service.getApi({
        do: "bim",
        op: "viewToken",
        data: {
          fileId: "10000756213370",
          type: 1,
        },
      });
      console.log("getViewToken", res);
      this.viewToken = res.token;
      var options = new BimfaceSDKLoaderConfig();
      options.viewToken = this.viewToken;
      BimfaceSDKLoader.load(
        options,
        this.successCallback,
        this.failureCallback
      );
    },
    setButtonText(btnId, text) {
      var dom = document.getElementById(btnId);
      if (dom != null && dom.nodeName == "BUTTON") {
        dom.innerText = text;
      }
    },
    // 加载成功回调函数
    successCallback(viewMetaData) {
      var that = this;
      var dom4Show = document.getElementById("domId");
      // 设置WebApplication3D的配置项
      var webAppConfig =
        new Glodon.Bimface.Application.WebApplication3DConfig();
      webAppConfig.domElement = dom4Show;
      webAppConfig.enableLogarithmicDepthBuffer = true;
      // 创建WebApplication3D，用以显示模型
      var app = new Glodon.Bimface.Application.WebApplication3D(webAppConfig);
      app.addView(viewMetaData.viewToken);
      var viewer3D = app.getViewer();
      that.viewer3D = viewer3D;
      // 增加加载完成监听事件
      viewer3D.addEventListener(
        Glodon.Bimface.Viewer.Viewer3DEvent.ViewAdded,
        function () {
          that.viewAdded = true;
          //自适应屏幕大小
          window.onresize = function () {
            viewer3D.resize(
              document.documentElement.clientWidth,
              document.documentElement.clientHeight - 40
            );
          };
          // 渲染场景
          viewer3D.render();
        }
      );
    },
    // 加载失败回调函数
    failureCallback(error) {
      console.log(error);
    },

    // 添加构件嵌套关系
    loadMap() {
      var that = this;
      if (!that.viewAdded) {
        return;
      }
      if (!that.isMapLoaded) {
        // 构造地图配置项
        var mapConfig = new Glodon.Bimface.Plugins.TileMap.MapConfig();
        mapConfig.viewer = that.viewer3D;
        // 设置模型载入的基点
        mapConfig.basePoint = {
          x: -85689.088,
          y: -62066.347,
        };
        mapConfig.modelPosition = [121.63259566511255, 29.837978723638756];
        mapConfig.modelRotationZ = (0 * Math.PI) / 180;
        mapConfig.modelAltitude = 0;
        // 构造地图对象
        var map = new Glodon.Bimface.Plugins.TileMap.Map(mapConfig);
      }
    },
    toPage(e) {
      this.$router.push(e);
    },
    // async getDeviceList() {
    //   const res = await Service.getApi({
    //     do: "device",
    //     op: "list",
    //     data: {},
    //   });
    //   this.list = res.data;
    //   this.currentInfo = this.list[this.currentIndex];
    //   console.log("res", res.data);
    // },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

.page_body {
  width: 1678px;
  height: 728px;
  margin-top: 20px;
}

.page_bottom {
  margin-top: 27px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}

.iframe {
  width: 975px;
  height: 650px;
}

.lists {
  width: 350px;
  height: 650px;
  margin-left: 30px;
  background-color: #fce085;
  box-sizing: border-box;
  padding: 30px;
}

.active {
  color: red;
}
</style>
