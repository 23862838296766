<template>
  <div class="bg">
    <div class="head">
      <div>当前位置：</div>
      <div @click="toPage('/index')"
           class="can">首页--></div>
      <div @click="toPage('/huishuju')"
           class="can">慧数据--></div>
      <div @click="toPage('/liuyanban')"
           class="can">留言板--></div>
      <div>详情</div>
    </div>

    <h1>{{ info.title }}</h1>
    <div class="w1678 flex at_c jc_e">
      <div class="flex at_c">
        <img class="avatar"
             :src="info.headpic"
             alt="" />
        {{ info.realname }}
      </div>
      <div class="ml20">时间：{{ info.addtime }}</div>
    </div>
    <div class="page_body overflow_y">
      <div class="cori_swiper">
        <el-carousel :interval="4000"
                     type="card"
                     height="220px">
          <el-carousel-item v-for="item in info.image"
                            :key="item">
            <img class="cover_img"
                 :src="item"
                 alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="content">{{ info.content }}</div>
      <div class="comment">
        <div class="name">评论（{{ total > 99 ? "99+" : total }}）</div>
        <div class="comment_c"
             v-for="(item, index) in list"
             :key="index">
          <div class="comment_cell">
            <img class="avatar"
                 :src="item.headpic"
                 alt="" />
            <div>
              <div class="name hang1">{{ item.realname }}</div>
              <div class="time">{{ item.addtime }}</div>
            </div>
          </div>
          <div class="comment_cell_content">{{ item.content }}</div>
        </div>
        <div class="empty"
             v-if="!list.length">暂无评论~</div>
        <div class="flex jc_c w100 mt30">
          <el-pagination v-model:currentPage="currentPage"
                         v-model:page-size="pageSize"
                         :background="background"
                         layout="total, prev, pager, next, jumper"
                         :total="total"
                         @size-change="handleSizeChange"
                         @current-change="handleCurrentChange" />
        </div>
      </div>
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
import * as Service from "../api/index";
export default {
  data() {
    return {
      title: "",
      currentPage: 1,
      pageSize: 10,
      background: "#c30515",
      total: 0,
      list: [],
      centerDialogVisible: false,
      info: {},
    };
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
    this.id = this.$route.query.id;
    this.getDetail(this.$route.query.id);
    this.getCommentList(1);
  },
  methods: {
    onmessage() {},
    async getDetail(id) {
      const res = await Service.getApi({
        wxapp: 1,
        do: "message_board",
        op: "list",
        data: {
          userid: 1,
          id,
        },
      });
      this.info = res.data.data[0];
    },
    async getCommentList(page) {
      const res = await Service.getApi({
        wxapp: 1,
        do: "message_board",
        op: "comment_list",
        data: {
          userid: 1,
          boardid: this.id,
          page,
          count: this.pageSize,
        },
      });
      console.log("res", res);
      this.list = res.data.data;
      this.total = res.data.count;
    },
    toPage(e) {
      this.$router.push(e);
    },
    handleSizeChange(e) {
      this.getCommentList(e);
    },
    handleCurrentChange(e) {
      this.getCommentList(e);
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  background-image: url("../assets/image/bg2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 118%;
  width: 1678px;
  height: 55px;
  font-size: 36px;
  line-height: 36px;
  color: #c30515;
  padding-left: 15px;
  box-sizing: border-box;
  margin: 70px 0 15px;
  display: flex;
  align-items: center;
}

h1 {
  color: #c30515;
}

.w1678 {
  width: 1678px;
}

.page_body {
  width: 1678px;
  height: 600px;
  margin-top: 20px;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.search {
  display: flex;
  align-items: center;
}

.search_btn {
  font-size: 20px;
}

.el-button--danger {
  width: 100px;
  height: 55px;
  font-size: 20px;
}

.cori_swiper {
  margin-top: 20px;
  width: 700px;
}

.cover_img {
  width: 360px;
  height: 200px;
  object-fit: cover;
}

.avatar {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.content {
  width: 100%;
  margin-top: 10px;
}

.comment {
  width: 100%;
  box-sizing: border-box;
  padding: 20px 20px;
}

.comment > .name {
  font-size: 24px;
  font-weight: bold;
}

.comment_cell {
  box-sizing: border-box;
  padding: 15px 0;
  display: flex;
  align-items: center;
}

.comment_cell .avatar {
  width: 46px;
  height: 46px;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
}

.comment_cell .name {
  font-size: 18px;
}

.comment_cell .time {
  font-size: 14px;
  color: #bbb;
}

.comment_cell_content {
  box-sizing: border-box;
  padding-left: 55px;
}

.comment_c:not(:last-child) {
  border-bottom: 1px solid #dadada;
  box-sizing: border-box;
  padding-bottom: 15px;
}

.empty {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #dadada;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #c30515;
}

.page_bottom {
  margin-top: 47px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}
</style>
