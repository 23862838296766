<template>
  <div class="body flex at_c">
    <div class="w70">
      <div class="h400">
        <div class="plate_title">
          <div class="plate_title_name">
            <template v-if="formType == 1">入场通知</template>
            <template v-if="formType == 2">离场通知</template>
            <template v-if="formType == 3">特别说明</template>
            <span v-if="formType != 1" class="back" @click="back">返回</span>
          </div>
          <div class="flex at_c">
            <div class="mr10 fz12">当前在麦研究员：</div>
            <el-select
              v-model="teacher_id"
              placeholder="当前在麦"
              @change="changeTeacherId"
            >
              <el-option label="无" :value="0"></el-option>
              <el-option
                v-for="(item, index) in teacherList"
                :key="index"
                :label="item.nickname"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- <div class="plate_push_btn">
              <img
                class="icon_push_video"
                src="@/assets/img/icon_push_video.png"
                alt=""
              />
              视频
            </div> -->
            <div
              class="plate_push_btn"
              :class="{ plate_push_btn_active: live_status == 1 }"
              @click="setLiveStatus(1)"
            >
              <img
                v-if="live_status == 1"
                class="icon_push_speak"
                src="@/assets/img/icon_push_speaks.png"
                alt=""
              />
              <img
                v-else
                class="icon_push_speak"
                src="@/assets/img/icon_push_speak.png"
                alt=""
              />
              开播
            </div>
            <div
              class="plate_push_btn"
              :class="{ plate_push_btn_active: live_status == 0 }"
              @click="setLiveStatus(0)"
            >
              <img
                v-if="live_status == 0"
                class="icon_push_stop"
                src="@/assets/img/icon_push_stops.png"
                alt=""
              />
              <img
                v-else
                class="icon_push_stop"
                src="@/assets/img/icon_push_stop.png"
                alt=""
              />
              停播
            </div>
            <img
              @click="showPost"
              class="can layui-icon-user"
              src="@/assets/img/icon_post.png"
              title="我的海报"
              alt=""
            />
          </div>
        </div>
        <!-- 入场 -->
        <div v-show="formType == 1" class="flex" v-loading="loading">
          <div class="form">
            <el-form
              ref="admission"
              :inline="true"
              :model="admission"
              :rules="rulesAdmission"
              label-position="top"
              class="demo-form-inline"
            >
              <el-form-item label="品种" :size="size" prop="varieties_id">
                <el-select
                  filterable
                  v-model="admission.varieties_id"
                  placeholder="请选择品种"
                  @change="changeVarieties"
                >
                  <el-option
                    v-for="(item, index) in varietiesList"
                    :key="index"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="合约" :size="size" prop="contract">
                <el-input
                  v-model="admission.contract"
                  placeholder="请填写品种合约"
                ></el-input>
              </el-form-item>

              <el-form-item label="参考方向" :size="size" prop="direction_type">
                <el-radio-group v-model="admission.direction_type" size="large">
                  <el-radio-button label="1">买多</el-radio-button>
                  <el-radio-button label="2">卖空</el-radio-button>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="研究员" :size="size" prop="teacher_id">
                <el-select
                  v-model="admission.teacher_id"
                  placeholder="请选择研究员"
                  @change="changeTeacher"
                >
                  <el-option
                    v-for="(item, index) in teacherList"
                    :key="index"
                    :label="item.nickname"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item
                label="入场参考"
                :size="size"
                prop="admission_start"
              >
                <el-input-number
                  v-model="admission.admission_start"
                  :min="0"
                  :controls="false"
                  @change="changeAdmissionStart"
                />
                <el-divider></el-divider>
                <el-input-number
                  v-model="admission.admission_end"
                  :min="0"
                  :controls="false"
                  @change="changeAdmissionEnd"
                />
              </el-form-item>

              <el-form-item
                label="止盈参考"
                :size="size"
                prop="target_profit_start"
              >
                <el-input-number
                  v-model="admission.target_profit_start"
                  :min="0"
                  :controls="false"
                />
                <el-divider></el-divider>
                <el-input-number
                  v-model="admission.target_profit_end"
                  :min="0"
                  :controls="false"
                />
              </el-form-item>

              <el-form-item
                label="止损参考"
                :size="size"
                prop="target_loss_start"
              >
                <el-input-number
                  v-model="admission.target_loss_start"
                  :min="0"
                  :controls="false"
                />
                <el-divider></el-divider>
                <el-input-number
                  v-model="admission.target_loss_end"
                  :min="0"
                  :controls="false"
                />
              </el-form-item>

              <el-form-item
                class="beizhu"
                label="备注说明"
                :size="size"
                prop="admission_remarks"
              >
                <el-input
                  v-model="admission.admission_remarks"
                  placeholder="请填写备注说明"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button
                  class="mt32"
                  type="primary"
                  :loading="loadingAdmission"
                  @click="submitAdmission('admission')"
                  >发布入场通知</el-button
                >
                <el-button class="mt32" @click="resetForm('admission')"
                  >重置</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="preview_table">
            <div class="plate_title_1">
              <img src="@/assets/img/icon_preview.png" alt="" />
              <div>入场推送预览</div>
            </div>
            <div class="preview_body">
              <div class="preview">
                <div class="preview_left">【品种】</div>
                <div class="preview_right">
                  {{
                    varieties_index != null
                      ? varietiesList[varieties_index].name
                      : ""
                  }}
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【研究员】</div>
                <div class="preview_right">
                  {{
                    teacher_index != null
                      ? teacherList[teacher_index].nickname
                      : ""
                  }}
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【参考方向】</div>
                <div class="preview_right">
                  <template v-if="admission.direction_type == 1">买多</template>
                  <template v-if="admission.direction_type == 2">卖空</template>
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【入场参考】</div>
                <div class="preview_right">
                  {{
                    admission.admission_start + "-" + admission.admission_end
                  }}
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【止盈参考】</div>
                <div class="preview_right">
                  {{
                    admission.target_profit_start +
                    "-" +
                    admission.target_profit_end
                  }}
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【止损参考】</div>
                <div class="preview_right">
                  {{
                    admission.target_loss_start +
                    "-" +
                    admission.target_loss_end
                  }}
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【备注说明】</div>
                <div class="preview_right">
                  {{ admission.admission_remarks }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 离场 -->
        <div v-show="formType == 2" class="flex" v-loading="loading">
          <div class="form">
            <el-form
              ref="departure"
              :inline="true"
              :model="departure"
              :rules="rulesDeparture"
              label-position="top"
              class="demo-form-inline"
            >
              <el-form-item label="ID" :size="size">
                <el-input disabled v-model="departure.id"></el-input>
              </el-form-item>

              <el-form-item
                label="离场参考"
                :size="size"
                prop="departure_start"
              >
                <el-input-number
                  v-model="departure.departure_start"
                  :min="0"
                  :controls="false"
                />
                <el-divider></el-divider>
                <el-input-number
                  v-model="departure.departure_end"
                  :min="0"
                  :controls="false"
                />
              </el-form-item>

              <el-form-item label="离场类型" :size="size" prop="departure_type">
                <el-radio-group v-model="departure.departure_type" size="large">
                  <el-radio-button label="1">止盈离场</el-radio-button>
                  <el-radio-button label="2">止损离场</el-radio-button>
                </el-radio-group>
              </el-form-item>

              <el-form-item
                class="beizhu"
                label="备注说明"
                :size="size"
                prop="departure_remarks"
              >
                <el-input
                  v-model="departure.departure_remarks"
                  placeholder="请填写备注说明"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button
                  class="mt32"
                  type="primary"
                  :loading="loadingDeparture"
                  @click="submitDeparture('departure')"
                  >发布离场通知</el-button
                >
                <el-button class="mt32" @click="resetForm('departure')"
                  >重置</el-button
                >
              </el-form-item>
            </el-form>
          </div>
          <div class="preview_table">
            <div class="plate_title_1">
              <img src="@/assets/img/icon_preview.png" alt="" />
              <div>离场推送预览</div>
            </div>
            <div class="preview_body">
              <div class="preview">
                <div class="preview_left">【品种】</div>
                <div class="preview_right">
                  {{ departure.varieties_name }}
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【研究员】</div>
                <div class="preview_right">
                  {{ departure.teacher_name }}
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【参考方向】</div>
                <div class="preview_right">
                  {{ departure.direction_type_text }}
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【入场参考】</div>
                <div class="preview_right">
                  {{
                    departure.admission_start + "-" + departure.admission_end
                  }}
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【止盈参考】</div>
                <div class="preview_right">
                  {{
                    departure.target_profit_start +
                    "-" +
                    departure.target_profit_end
                  }}
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【止损参考】</div>
                <div class="preview_right">
                  {{
                    departure.target_loss_start +
                    "-" +
                    departure.target_loss_end
                  }}
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【离场参考】</div>
                <div class="preview_right">
                  {{
                    departure.departure_start + "-" + departure.departure_end
                  }}
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【离场类型】</div>
                <div class="preview_right">
                  <template v-if="departure.departure_type == 1"
                    >止盈离场</template
                  >
                  <template v-if="departure.departure_type == 2"
                    >止损离场</template
                  >
                </div>
              </div>
              <div class="preview">
                <div class="preview_left">【备注说明】</div>
                <div class="preview_right">
                  {{ departure.departure_remarks }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 特别说明 -->
        <div v-show="formType == 3" class="flex" v-loading="loading">
          <div class="form">
            <el-form
              ref="especially"
              :inline="true"
              :model="especially"
              :rules="rulesEspecially"
              label-position="top"
              class="demo-form-inline"
            >
              <el-form-item label="ID" :size="size">
                <el-input disabled v-model="especially.id"></el-input>
              </el-form-item>

              <el-form-item
                class="beizhu"
                label="备注说明"
                :size="size"
                prop="especially_remarks"
              >
                <el-input
                  v-model="especially.especially_remarks"
                  placeholder="请填写备注说明"
                ></el-input>
              </el-form-item>

              <el-form-item>
                <el-button
                  class="mt32"
                  type="primary"
                  :loading="loadingeEspecially"
                  @click="submitEspecially('especially')"
                  >发布特别说明</el-button
                >
                <el-button class="mt32" @click="resetForm('especially')"
                  >重置</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>

      <div>
        <div class="plate_title">
          <div class="plate_title_name">入场通知列表</div>
        </div>
        <div>
          <el-table
            v-loading="loadingTable"
            height="450"
            :data="tableData"
            style="width: 100%"
          >
            <el-table-column width="80" align="center" prop="id" label="ID" />
            <el-table-column
              align="center"
              prop="varieties_name"
              label="品种"
            />
            <el-table-column
              width="80"
              align="center"
              prop="direction_type_text"
              label="参考方向"
            />
            <el-table-column align="center" label="入场参考">
              <template v-slot="scope">
                {{ scope.row.admission_start + "-" + scope.row.admission_end }}
              </template>
            </el-table-column>
            <el-table-column
              width="80"
              align="center"
              prop="status_text"
              label="状态"
            />
            <el-table-column align="center" label="止盈参考">
              <template v-slot="scope">
                {{
                  scope.row.target_profit_start +
                  "-" +
                  scope.row.target_profit_end
                }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="止损参考">
              <template v-slot="scope">
                {{
                  scope.row.target_loss_start + "-" + scope.row.target_loss_end
                }}
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="teacher_name"
              label="研究员"
            />
            <el-table-column
              width="170"
              align="center"
              prop="createtime"
              label="入场时间"
            />
            <el-table-column align="center" label="操作">
              <template #default="scope">
                <el-button size="small" @click="getEspeciallyInfo(scope.$index)"
                  >特别</el-button
                >
                <el-button
                  size="small"
                  type="danger"
                  @click="getDepartureInfo(scope.$index)"
                  >离场</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="paging">
            <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="page"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="w30">
      <div class="plate_title">
        <div class="plate_title_name">公屏聊天</div>
        <div class="scoll">停止滚动</div>
      </div>
      <div class="chat_box">
        <div class="chat_body" id="chatList">
          <div class="cell" v-for="(item, index) in chatList" :key="index">
            <div class="head">
              <img class="avatar" :src="item.avatar" alt="" />
              <div class="nickname">{{ item.name }}</div>
              <div class="time">{{ item.createtime_text }}</div>
            </div>
            <div class="content" v-html="item.content"></div>
          </div>
        </div>
        <!-- <chat :chat-list="chatList"></chat> -->
      </div>
    </div>

    <!-- 海报 -->
    <el-dialog title="海报" v-model="postStatus">
      <div v-if="postList.length">
        <div class="flex jc_c">
          <img class="post_img" :src="postList[postIndex]" alt="" />
        </div>
        <div class="mt20">
          <img
            @click="switchPost(index)"
            class="post_img_small"
            :class="{ post_img_small_active: postIndex == index }"
            v-for="(item, index) in postList"
            :key="index"
            :src="item"
            alt=""
          />
        </div>
      </div>
      <el-empty v-else description="还没有海报~"></el-empty>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="postStatus = false">关闭</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import * as Service from "../api/index";
import emojiList from "../utils/emoji";
export default {
  data() {
    var price1 = (rule, value, callback) => {
      var admission = this.admission;
      if (admission.admission_start === 0 || admission.admission_end === 0) {
        callback(new Error("入场参考值不可为0"));
      } else {
        callback();
      }
    };
    var price2 = (rule, value, callback) => {
      var admission = this.admission;
      if (
        admission.target_profit_start === 0 ||
        admission.target_profit_end === 0
      ) {
        callback(new Error("止盈参考值不可为0"));
      } else {
        callback();
      }
    };
    var price3 = (rule, value, callback) => {
      var admission = this.admission;
      if (
        admission.target_loss_start === 0 ||
        admission.target_loss_end === 0
      ) {
        callback(new Error("止损参考值不可为0"));
      } else {
        callback();
      }
    };
    var price4 = (rule, value, callback) => {
      var departure = this.departure;
      if (departure.departure_start === 0 || departure.departure_end === 0) {
        callback(new Error("离场参考值不可为0"));
      } else {
        callback();
      }
    };
    return {
      chatList: [],
      room_id: 1,
      formType: 1, //表单状态1入场2离场3特别说明
      loading: false, //切换时的加载状态
      size: "large",
      loadingAdmission: false, //发布入场通知按钮加载状态
      loadingDeparture: false, //发布离场通知按钮加载状态
      loadingeEspecially: false, //发布特别说明按钮加载状态
      varieties_index: null, //品种index
      teacher_index: null, //研究员index
      admission: {
        varieties_id: null, //品种id
        contract: "", //合约
        direction_type: "", //参考方向1买多2卖空
        teacher_id: null, //研究员id
        admission_start: null, //入场开始
        admission_end: null, //入场结束
        target_profit_start: null, //止盈开始
        target_profit_end: null, //止盈结束
        target_loss_start: null, //止损开始
        target_loss_end: null, //止损结束
        admission_remarks: "", //入场备注
      }, //入场表单信息
      rulesAdmission: {
        varieties_id: [
          { required: true, message: "请选择品种", trigger: "change" },
        ],
        contract: [
          { required: true, message: "请填写品种合约", trigger: "blur" },
        ],
        direction_type: [
          { required: true, message: "请选择参考方向", trigger: "change" },
        ],
        teacher_id: [
          { required: true, message: "请选择研究员", trigger: "change" },
        ],
        admission_start: [
          { validator: price1, trigger: "blur" },
          { validator: price1, trigger: "change" },
        ],
        target_profit_start: [
          { validator: price2, trigger: "blur" },
          { validator: price2, trigger: "change" },
        ],
        target_loss_start: [
          { validator: price3, trigger: "blur" },
          { validator: price3, trigger: "change" },
        ],
        admission_remarks: [
          { required: true, message: "请填写备注说明", trigger: "blur" },
        ],
      }, //入场表单验证规则
      departure: {
        id: null, //入场id
        departure_start: 0, //离场开始
        departure_end: 0, //离场结束
        departure_type: null, //离场类型1止盈离场2止损离场
        departure_remarks: "", //离场备注

        varieties_name: "",
        teacher_name: "",
        direction_type_text: "",
        admission_start: "",
        admission_end: "",
        target_profit_start: "",
        target_profit_end: "",
        target_loss_start: "",
        target_loss_end: "",
      }, //离场表单信息
      rulesDeparture: {
        departure_start: [{ validator: price4, trigger: "blur" }],
        departure_type: [
          { required: true, message: "请选择离场类型", trigger: "change" },
        ],
        departure_remarks: [
          { required: true, message: "请填写备注说明", trigger: "blur" },
        ],
      }, //离场表单验证规则
      especially: {
        id: null, //入场id
        especially_remarks: "", //特别说明
      }, //特别说明表单信息
      rulesEspecially: {
        especially_remarks: [
          { required: true, message: "请填写备注说明", trigger: "blur" },
        ],
      }, //特别说明表单验证规则
      varietiesList: [], //品种列表
      teacherList: [], //研究员列表
      tableData: [],
      loadingTable: false,
      page: 1,
      total: 1,
      pageSize: 15,

      repeatCount: 0,

      teacher_id: 0, // 当前在麦研究员类型
      live_status: null,

      postList: [], // 海报列表
      postStatus: false, // 海报显示状态
      postIndex: null,
    };
  },

  mounted() {
    window.onmessageSocket = this.onmessage;
    this.getBaseInfo();
    this.getPolicyList(1);
    this.getRoomInfo();
    this.getRoomChatList(Math.round(new Date() / 1000));
  },
  methods: {
    onmessage(e) {
      const redata = JSON.parse(e.data);
      // console.log("index-redata", redata);
      var chatList = this.chatList;
      switch (redata.type) {
        // 直播间有用户发消息
        case "chat_room_accept":
          chatList.push({
            name: redata.user_info.nickname,
            avatar: redata.user_info.avatar,
            content: this.emojiReplace(redata.content),
            createtime_text: redata.createtime_text,
          });
          this.scoll();
          break;
        //直播间状态变更
        case "live_status":
          this.$notify({
            title: "提示",
            message: "直播间状态已更改",
            duration: 3000,
          });
          this.getRoomInfo();
          break;
        //直播间删除某条信息
        case "room_chat_delete":
          // console.log("删除之前", chatList);
          for (const [index, item] of chatList.entries()) {
            if (item.id == redata.content.id) {
              chatList.splice(index, 1);
            }
          }
          this.chatList = chatList;
          break;
      }
    },
    //获取历史消息
    async getRoomChatList(createtime) {
      let that = this;
      const res = await Service.roomChatList({
        room_id: that.room_id,
        createtime: createtime,
      });
      console.log("getRoomChatList", res);
      var chatList = that.chatList;
      for (const item of res.data.list) {
        chatList.unshift({
          id: item.id,
          name: item.user.nickname,
          avatar: item.user.avatar,
          content: that.emojiReplace(item.content),
          createtime_text: item.createtime_text,
        });
      }
      that.chatList = chatList;
      that.scoll();
    },
    scoll() {
      $("#chatList").animate(
        {
          scrollTop: "99999px",
        },
        1000
      );
    },
    //替换内容中的emoji字符
    emojiReplace(val) {
      // console.log("emojiReplace", val);
      for (const item of emojiList) {
        val = val.replace(
          new RegExp(item.regexp, "g"),
          '<img style="width: 18px;height: 18px;" src="' + item.url + '" />'
        );
      }
      return val;
    },
    // 设置直播间在麦老师
    async changeTeacherId() {
      const res = await Service.setRoomTeacher({
        teacher_id: this.teacher_id,
      });
      console.log("设置直播间在麦老师", res);
      this.$message.success(res.msg);
    },
    // 获取直播间信息
    async getRoomInfo() {
      const res = await Service.getRoomInfo();
      console.log("获取直播间信息", res);
      this.live_status = res.data.roomInfo.live_status;
      this.teacher_id = res.data.roomInfo.teacher_id;
    },
    // 设置直播间状态
    setLiveStatus(live_status) {
      let that = this;
      var text = live_status == 1 ? "开播" : "停播";
      that
        .$confirm("确认" + text + "吗?", "信息", {
          confirmButtonText: "确认",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          that.setLiveStatusApi(live_status);
        })
        .catch(() => {});
    },
    async setLiveStatusApi(live_status) {
      const res = await Service.setLiveStatus({
        live_status,
      });
      console.log("设置直播间播放状态", res);
      this.$message.success(res.msg);
      this.getRoomInfo();
    },
    back() {
      this.formType = 1;
    },
    //更改品种
    changeVarieties(e) {
      var varietiesList = this.varietiesList,
        varieties_index = null;
      for (const [index, item] of varietiesList.entries()) {
        if (item.id == e) {
          varieties_index = index;
        }
      }
      this.varieties_index = varieties_index;
    },
    //更改研究员
    changeTeacher(e) {
      var teacherList = this.teacherList,
        teacher_index = null;
      for (const [index, item] of teacherList.entries()) {
        if (item.id == e) {
          teacher_index = index;
        }
      }
      this.teacher_index = teacher_index;
    },
    //自动计算辅助填写价格
    changeAdmissionStart(e) {
      //若当前为买多，止盈上浮2%，止损下调1%
      //若当前为卖空，止盈下调2%，止损上浮1%
      console.log("changeAdmissionStart", e);
      var target_profit_start = e * 1.02,
        target_loss_start = e * 0.99;
      this.$refs["admission"].clearValidate(["target_profit_start"]);
      this.$refs["admission"].clearValidate(["target_loss_start"]);
      this.admission.target_profit_start = target_profit_start;
      this.admission.target_loss_start = target_loss_start;
    },
    //自动计算辅助填写价格
    changeAdmissionEnd(e) {
      //若当前为买多，止盈上浮2%，止损下调1%
      //若当前为卖空，止盈下调2%，止损上浮1%
      console.log("changeAdmissionEnd", e);
      var target_profit_end = e * 1.02,
        target_loss_end = e * 0.99;
      this.$refs["admission"].clearValidate(["target_profit_start"]);
      this.$refs["admission"].clearValidate(["target_loss_start"]);
      this.admission.target_profit_end = target_profit_end;
      this.admission.target_loss_end = target_loss_end;
    },
    //获取基础信息
    async getBaseInfo() {
      const res = await Service.baseInfo();
      const { code, data } = res;
      if (code == 1) {
        this.teacherList = data.teacherList;
        this.varietiesList = data.varietiesList;
      }
      console.log("getBaseInfo", res);
    },
    //获取策略列表
    async getPolicyList(page) {
      this.loadingTable = true;
      const res = await Service.policyList({ page });
      const { code, data } = res;
      if (code == 1) {
        this.tableData = data.data;
        this.total = data.total;
        this.page = data.current_page;
        this.loadingTable = false;
      }
      console.log("getPolicyList", res);
    },
    resetForm(formName) {
      console.log("清空表格");
      this.$refs[formName].resetFields();
    },
    handleCurrentChange(val) {
      this.getPolicyList(val);
    },
    //入场通知提交
    submitAdmission(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.loadingAdmission = true;
          that.addAdmissionPolicy(formName);
        } else {
          return false;
        }
      });
    },
    //添加入场通知
    async addAdmissionPolicy(formName) {
      let that = this;
      var admission = that.admission;
      const res = await Service.addAdmissionPolicy(admission);
      const { code, msg } = res;
      if (code == 1) {
        that.$message.success(msg);
        that.resetForm(formName);
        that.admission.admission_end = 0;
        that.admission.target_profit_end = 0;
        that.admission.target_loss_end = 0;
        that.getPolicyList(1);
        that.loadingAdmission = false;
      }
      console.log("addAdmissionPolicy", res);
    },
    //获取离场通知信息
    getDepartureInfo(index) {
      this.formType = 2;
      this.loading = true;
      var tableData = this.tableData,
        departure = {
          id: tableData[index].id, //入场id
          departure_start: 0, //离场开始
          departure_end: 0, //离场结束
          departure_type: null, //离场类型1止盈离场2止损离场
          departure_remarks: "", //离场备注

          varieties_name: tableData[index].varieties_name,
          teacher_name: tableData[index].teacher_name,
          direction_type_text: tableData[index].direction_type_text,
          admission_start: tableData[index].admission_start,
          admission_end: tableData[index].admission_end,
          target_profit_start: tableData[index].target_profit_start,
          target_profit_end: tableData[index].target_profit_end,
          target_loss_start: tableData[index].target_loss_start,
          target_loss_end: tableData[index].target_loss_end,
        };
      this.departure = departure;
      setTimeout(() => {
        this.loading = false;
      }, 500);
      console.log("index", index);
    },
    //离场通知提交
    submitDeparture(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.loadingDeparture = true;
          that.addDeparturePolicy(formName);
        } else {
          return false;
        }
      });
    },
    //添加离场通知
    async addDeparturePolicy(formName) {
      let that = this;
      var departure = that.departure;
      const res = await Service.addDeparturePolicy(departure);
      const { code, msg } = res;
      if (code == 1) {
        that.$message.success(msg);
        that.resetForm(formName);
        that.getPolicyList(1);
        that.loadingDeparture = true;
      }
      console.log("addDeparturePolicy", res);
    },
    //获取特别说明信息
    getEspeciallyInfo(index) {
      this.formType = 3;
      this.loading = true;
      var tableData = this.tableData,
        especially = {
          id: tableData[index].id, //入场id
          especially_remarks: "",
        };
      this.especially = especially;
      setTimeout(() => {
        this.loading = false;
      }, 500);
      console.log("index", index);
    },
    //特别说明提交
    submitEspecially(formName) {
      let that = this;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.loadingeEspecially = true;
          that.addEspeciallyRemarks(formName);
        } else {
          return false;
        }
      });
    },
    //添加特别说明
    async addEspeciallyRemarks(formName) {
      let that = this;
      var especially = that.especially;
      const res = await Service.addEspeciallyRemarks(especially);
      const { code, msg } = res;
      if (code == 1) {
        that.$message.success(msg);
        that.resetForm(formName);
        that.getPolicyList(1);
        that.loadingeEspecially = false;
      }
      console.log("addEspeciallyRemarks", res);
    },
    // 我的海报
    async showPost() {
      const res = await Service.getUserPoster({
        type: "pc",
      });
      this.postList = res.data.poster_url;
      this.postStatus = true;
      this.postIndex = 0;
    },
    switchPost(index) {
      this.postIndex = index;
    },
  },
};
</script>

<style scoped>
.body {
  height: 100vh;
}
.w70 {
  height: 100%;
  border-right: 1px solid #ebeced;
}
.w70 > div:nth-child(2) {
  height: calc(100% - 400px);
}
.h400 {
  height: 400px;
}

.w30 {
  height: 100%;
}

.plate_push_btn {
  width: 70px;
  height: 30px;
  background-color: #e2e8f3;
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #a3adbf;
  border-radius: 3px;
}

.plate_push_btn_active {
  background-color: #5389f5;
  color: #fff;
}

.icon_push_video {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.icon_push_speak {
  width: 9px;
  height: 16px;
  margin-right: 5px;
}

.icon_push_stop {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.plate_title {
  background-color: #f8fcff;
  font-size: 16px;
  font-weight: 500;
  box-sizing: border-box;
  /* padding: 18px 0; */
  border-top: 1px solid #ebeced;
  border-bottom: 1px solid #ebeced;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 54px;
  padding-right: 25px;
}

.back {
  line-height: 16px;
  color: #5389f5;
  margin-left: 30px;
  cursor: pointer;
}

.plate_title_name {
  position: relative;
  line-height: 16px;
  padding-left: 30px;
}

.plate_title_name::before {
  content: "";
  position: absolute;
  left: 13px;
  width: 4px;
  height: 16px;
  background-color: #5389f5;
  border-radius: 5px;
}

.plate_title_1 {
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  box-sizing: border-box;
  padding: 13px 0;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.plate_title_1 > img {
  width: 24px;
  height: 24px;
  margin: 0 10px;
}

.preview_table {
  width: 300px;
  height: 346px;
  background-color: #f8fcff;
  box-sizing: border-box;
  border-left: 1px solid #ebeced;
}

.form {
  width: calc(100% - 300px);
  box-sizing: border-box;
  padding: 20px;
}

.mt32 {
  margin-top: 30px;
}

/deep/.el-input-number--large {
  width: 112px;
}

/deep/.el-divider--horizontal {
  width: 30px;
  margin: 0 8px;
}

.beizhu {
  width: calc(100% - 351px);
  min-width: 222px;
}

.preview_body {
  height: 297px;
  overflow-y: auto;
}

.preview_body::-webkit-scrollbar {
  display: block;
  width: 4px;
  /*height: 6px;*/
}

.preview_body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  background: rgba(0, 0, 0, 0.6);
}

.preview_body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.preview {
  display: flex;
  font-size: 14px;
  margin: 5px 0;
}

.preview_left {
  width: 90px;
  color: #a3adbf;
  text-align: right;
}

.preview_right {
  width: calc(100% - 100px);
  margin-left: 10px;
  color: #303656;
  box-sizing: border-box;
  padding-right: 5px;
}

.chat_box {
  width: 100%;
  height: calc(100% - 54px);
}

.scoll {
  line-height: 16px;
  margin-right: 30px;
  cursor: pointer;
}

.paging {
  height: 64px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 30px;
}

.layui-icon-user {
  width: 24px;
  height: 24px;
  margin-left: 20px;
}

.post_img {
  width: 280px;
}

.post_img_small {
  width: 70px;
  height: 70px;
  object-fit: contain;
  cursor: pointer;
  box-sizing: border-box;
  border: 2px solid transparent;
}

.post_img_small_active {
  border: 2px solid #5188f4;
}

/* ********************************** */
.chat_body {
  box-sizing: border-box;
  padding: 15px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.chat_body::-webkit-scrollbar {
  display: block;
  width: 4px;
  /*height: 6px;*/
}

.chat_body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  background: rgba(0, 0, 0, 0.6);
}

.chat_body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.cell {
  margin-bottom: 20px;
}

.head {
  display: flex;
  align-items: center;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.nickname {
  margin: 0 10px;
  font-size: 14px;
}

.time {
  font-size: 12px;
  color: #c9c9c9;
}

.content {
  box-sizing: border-box;
  padding: 5px 0 0 40px;
}

.content >>> img {
  width: 200px;
}

#elementID {
  position: fixed;
  right: 50px;
  bottom: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: #fff;
  cursor: pointer;
}
</style>
