import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import jquery from "jquery";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import locale from "element-plus/lib/locale/lang/zh-cn";
import "./assets/css/BaoUi.css";
import VueClipboard from "vue-clipboard2";
import store from "./store/index";
const app = createApp(App);
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title}`;
  // const role = localStorage.getItem("vce-token-push");
  const role = 1;
  if (!role && to.path !== "/login") {
    next("/login");
  } else {
    next();
  }
});

app
  .use(router)
  .use(jquery)
  .use(store)
  .use(ElementPlus, { locale })
  .use(VueClipboard)
  .mount("#app");
