<template>
  <div class="bg">
    <div class="head">
      <img class="img1"
           src="@/assets/image/img1.png"
           alt="" />
      <img class="head_img"
           src="@/assets/image/head_huishuju.png"
           alt="" />
    </div>

    <div class="page_body">
      <div @click="toPage('/partyMap2')">
        <img src="@/assets/image/shuju1.png"
             alt="" />
        <div>党建地图</div>
      </div>
      <div @click="toPage('/partyMemberInformation')">
        <img src="@/assets/image/shuju2.png"
             alt="" />
        <div>党员信息</div>
      </div>
      <div @click="toPage('/dangyuanpingjia')">
        <img src="@/assets/image/shuju3.png"
             alt="" />
        <div>党员评价</div>
      </div>
      <div @click="toPage('/liuyanban')">
        <img src="@/assets/image/shuju4.png"
             alt="" />
        <div>留言板</div>
      </div>
    </div>

    <div class="page_bottom">
      <img @click="toPage('index')"
           class="btn1"
           src="@/assets/image/btn1.png"
           alt="" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    window.onmessageSocket = this.onmessage;
  },
  methods: {
    onmessage() {},
    toPage(e) {
      this.$router.push(e);
    },
  },
};
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background-image: url("../assets/image/page_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 120px;
}

.head {
  margin: 162px 0 23px;
  display: flex;
  align-items: center;
}

.img1 {
  width: 84px;
  height: 167px;
}

.head_img {
  width: 781px;
  height: 199px;
}

.page_body {
  width: 1552px;
  height: 416px;
  background-image: url("../assets/image/shuju_bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: space-between;
}

.page_body > div {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 364px;
  margin-top: 70px;
  cursor: pointer;
}

.page_body > div > img {
  width: 143px;
  height: 143px;
  margin-bottom: 24px;
}

.page_body > div > div {
  font-size: 32px;
  font-weight: 400;
  color: #fff;
  line-height: 32px;
}

.page_bottom {
  margin-top: 47px;
  display: flex;
  justify-content: center;
}

.btn1 {
  width: 216px;
  height: 93px;
  cursor: pointer;
}
</style>
