<template>
  <div class="flex" @click="privateEmojiStatus = false">
    <div class="side_left">
      <!-- 游客消息列表 -->
      <div class="left_one" v-loading="customerListloading">
        <div class="list_head">
          <div
            @click="changeTouristNewsStatus(1)"
            :class="{ list_head_cell_active: customerStatus == 1 }"
            class="list_head_cell"
          >
            全部客户
          </div>
          <div
            @click="changeTouristNewsStatus(2)"
            :class="{ list_head_cell_active: customerStatus == 2 }"
            class="list_head_cell"
          >
            在线客户
          </div>
        </div>
        <div class="retrieval" v-if="customerStatus == 1">
          <el-input
            v-model="searchVal1"
            placeholder="请输入昵称或手机号"
          ></el-input>
          <el-button @click="search1" class="ml10" type="primary" size="small"
            >搜索</el-button
          >
        </div>
        <div
          class="tourist_news_list overflow_y overflow_y1"
          v-infinite-scroll="load"
          :infinite-scroll-immediate="false"
        >
          <!-- 圈子 -->
          <!-- <div class="tourist bb pl10 pr10" @click="circleChat">
            <div class="tourist_news_cell">
              <div class="tourist_avatar_cell">
                <img
                  class="tourist_avatar_img"
                  :src="circleInfo.cover"
                  alt=""
                />
              </div>
              <div class="h40 flex fd_c jc_b">
                <div class="flex at_c">
                  <div class="fz14">{{ circleInfo.name }}</div>
                  <img
                    v-if="circleInfo.is_new"
                    class="icon_new"
                    src="../assets/img/icon_new.png"
                    alt=""
                  />
                </div>
                <div class="tourist_time">{{ circleInfo.createtime }}</div>
              </div>
              <div
                v-if="circleInfo.is_new_message"
                class="tourist_news_tips"
              ></div>
            </div>
          </div> -->

          <div
            class="tourist bb pl10 pr10"
            @click="circleChat(item.uid, item.title, index)"
            v-for="(item, index) in circleList"
            :key="index"
          >
            <div class="tourist_news_cell">
              <div class="tourist_avatar_cell">
                <img class="tourist_avatar_img" :src="item.avatar" alt="" />
              </div>
              <div class="h40 flex fd_c jc_b">
                <div class="flex at_c">
                  <div class="fz14">{{ item.title }}</div>
                  <!-- <img
                    v-if="item.unread_num"
                    class="icon_new"
                    src="../assets/img/icon_new.png"
                    alt=""
                  /> -->
                </div>
                <div class="tourist_time">{{ item.time }}</div>
              </div>
              <div v-if="item.unread_num" class="tourist_news_tips"></div>
            </div>
          </div>
          <div
            @click="privateChat(item.type, item.id, item.nickname, index)"
            class="tourist bb pl10 pr10"
            v-for="(item, index) in customerList"
            :key="index"
          >
            <div class="tourist_news_cell">
              <div class="tourist_avatar_cell">
                <img class="tourist_avatar_img" :src="item.avatar" alt="" />
              </div>
              <div class="h40 flex fd_c jc_b">
                <div class="flex at_c">
                  <div
                    class="tourist_identity color_huiyuan"
                    v-if="item.type == 1"
                  >
                    会员
                  </div>
                  <div
                    class="tourist_identity color_youke"
                    v-if="item.type == 2"
                  >
                    游客
                  </div>
                  <div
                    class="tourist_identity color_jiangshi"
                    v-if="item.type == 3"
                  >
                    讲师
                  </div>
                  <div
                    class="tourist_identity color_guanli"
                    v-if="item.type == 4"
                  >
                    管理
                  </div>
                  <div
                    class="tourist_identity color_kefu"
                    v-if="item.type == 5"
                  >
                    客服
                  </div>
                  <div class="fz14">{{ item.nickname }}</div>
                  <img
                    v-if="item.is_new"
                    class="icon_new"
                    src="../assets/img/icon_new.png"
                    alt=""
                  />
                </div>
                <div class="tourist_time">{{ item.time }}</div>
              </div>
              <div v-if="item.is_new_message" class="tourist_news_tips"></div>
            </div>
          </div>
          <div class="is_end" v-if="isEnd">已全部加载</div>
        </div>
      </div>
      <div class="left_two can">
        <el-tooltip placement="top">
          <template #content>
            <div v-for="(item, index) in userData" :key="index">{{ item }}</div>
          </template>
          <div class="left_two_div">
            <img class="icon_drqf" src="../assets/img/icon_drqf.png" alt="" />
            统计数据
          </div>
        </el-tooltip>
        <div class="left_two_div" @click="groupSendShow = true">
          <img class="icon_drqf" src="../assets/img/icon_drqf.png" alt="" />
          多人群发
        </div>
      </div>
      <el-dialog v-model="groupSendShow" title="多人群发">
        <div class="flex jc_b">
          <div>
            <div>
              <div class="mb10 fz16">请选择发送对象</div>
              <div class="h500" v-loading="tableLoading">
                <div class="flex">
                  <el-input
                    type="text"
                    placeholder="请输入昵称或手机号"
                    v-model="searchVal2"
                  ></el-input>
                  <el-button
                    @click="search2"
                    class="ml10"
                    type="primary"
                    size="small"
                    >搜索</el-button
                  >
                </div>
                <el-table
                  row-key="id"
                  :data="memberList"
                  @selection-change="handleSelectionChange"
                  height="468px"
                  ref="multipleTable"
                >
                  <el-table-column
                    type="selection"
                    width="40"
                    reserve-selection
                  />
                  <el-table-column label="头像" width="80">
                    <template #default="scope">
                      <div class="flex at_c">
                        <img class="avatar" :src="scope.row.avatar" alt="" />
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="nickname"
                    label="昵称"
                    width="140"
                    show-overflow-tooltip
                  />
                  <el-table-column prop="mobile" label="手机号" width="140" />
                </el-table>
              </div>
            </div>
            <div class="mt10">
              <el-pagination
                @current-change="handleCurrentChange"
                v-model:current-page="currentPage"
                layout="prev, pager, next, jumper"
                :page-size="15"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
          <div class="group_send">
            <div class="mb10 fz16">发送内容</div>
            <div>
              <el-input
                rows="10"
                type="textarea"
                resize="none"
                v-model="content"
              ></el-input>
            </div>
            <div class="mt10 flex jc_e">
              <el-button type="primary" @click="groupSend">发 送</el-button>
            </div>
            <div>
              当前已选择：{{ multipleSelection.length }}人
              <!-- <span v-for="(item, index) in multipleSelection" :key="index">{{
                item.nickname
              }}</span> -->
            </div>
          </div>
        </div>
      </el-dialog>
    </div>
    <!-- 聊天 -->
    <div
      class="private_chat"
      v-loading="messageListLoading"
      v-show="chatBoxStatus"
    >
      <div class="private_chat_head">
        <div>
          <span
            >{{ currentTalkMember.nickname }}
            <span v-if="chat_type == 4">({{ userTotal }})</span></span
          >
          <span v-if="chat_type == 4 && circleStatus == 0" class="estoppel"
            >已禁言</span
          >
          <span>{{
            memberInfo.keywords ? "关键词：" + memberInfo.keywords : ""
          }}</span>
        </div>
        <div class="flex at_c" v-if="chat_type == 4">
          <!-- <img
            class="icon_operation"
            src="../assets/img/icon_ban_say.png"
            alt=""
            title="屏蔽用户观看时长"
          />
          <img
            class="icon_operation"
            src="../assets/img/icon_shield.png"
            alt=""
            title="禁言该用户"
          />
          <img
            class="icon_operation"
            src="../assets/img/icon_seal_ip.png"
            alt=""
            title="封禁该用户"
          /> -->
          <img
            @click="showDrawer"
            class="icon_shezhi"
            src="../assets/img/icon_shezhi.png"
            alt=""
          />
        </div>
      </div>
      <div class="private_chat_body">
        <el-drawer
          v-model="drawer"
          :direction="direction"
          :size="377"
          v-loading="circleUserListLoading"
        >
          <template #title>
            <div class="flex at_c">
              <h4>圈子成员</h4>
              <div class="ml10" v-if="circleIsEnd">已全部加载</div>
              <div v-if="circleUserListLoading">
                <img
                  class="loading"
                  src="@/assets/img/loading.gif"
                  alt=""
                />加载中...
              </div>
            </div>
          </template>
          <template #default>
            <div
              class="circle_users"
              v-infinite-scroll="circleLoad"
              :infinite-scroll-delay="1000"
              :infinite-scroll-immediate="false"
            >
              <div
                class="circle_user_cell"
                v-for="(item, index) in circleUserList"
                :key="index"
              >
                <el-popconfirm
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  icon-color="#626AEF"
                  :title="
                    item.status == 0
                      ? '确认解除禁言该用户吗?'
                      : '确认禁言该用户吗?'
                  "
                  @confirm="
                    changeCircleUserChatStatus(item.user_id, item.status, index)
                  "
                >
                  <template #reference>
                    <div>
                      <div
                        class="pr can bb"
                        :class="{ biankuang: item.user.is_firm_offer }"
                      >
                        <img class="avatar" :src="item.user.avatar" alt="" />
                        <img
                          v-if="item.status == 0"
                          class="icon_jinyan"
                          src="../assets/img/icon_jinyan.png"
                          alt=""
                        />
                        <div
                          class="firm_offer_name"
                          v-if="item.user.firm_offer_name"
                        >
                          {{ item.user.firm_offer_name }}
                        </div>
                        <!-- <div
                          class="is_firm_offer"
                          v-if="item.user.is_firm_offer"
                        >
                          <div>实盘</div>
                        </div> -->
                      </div>
                      <div class="hang1 tac">{{ item.user.nickname }}</div>
                    </div>
                  </template>
                </el-popconfirm>
              </div>
            </div>
          </template>
          <template #footer>
            <div class="w100">
              <el-button @click="changeCircleChatStatus" class="w100">{{
                circleStatus == 1 ? "全员禁言" : "解除禁言"
              }}</el-button>
            </div>
          </template>
        </el-drawer>

        <div
          class="private_chat_left"
          :class="{ max_width: !chatBoxRightStatus }"
        >
          <div
            class="private_chat_list overflow_y overflow_y1"
            id="privateChat"
          >
            <div class="is_end" v-if="isEndMessage">已无更多消息</div>
            <div v-for="(item, index) in messageList" :key="index">
              <!-- 讲师说话右侧 -->
              <div v-if="item.direction == 1" class="flex fd_c at_e">
                <div class="flex at_c fz12" style="color: #858fa2">
                  <div class="mr6">{{ item.name }}</div>
                  <div>{{ item.time }}</div>
                </div>
                <el-popconfirm
                  v-if="chat_type == 4"
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  icon-color="#626AEF"
                  title="确认删除该发言吗?"
                  @confirm="delConfirm(item.id)"
                >
                  <template #reference>
                    <div
                      class="cori_chat_box_list_cell_content can"
                      style="background-color: #347eff; color: #fff"
                      v-html="item.content"
                    ></div>
                  </template>
                </el-popconfirm>
                <div
                  v-if="chat_type == 2"
                  class="cori_chat_box_list_cell_content"
                  style="background-color: #347eff; color: #fff"
                  v-html="item.content"
                ></div>
                <div class="topic_about" v-if="item.topic_id">
                  <div class="flex at_c topic_about_cell">
                    <img
                      class="icon_topic"
                      src="@/assets/img/icon_topic.png"
                      alt=""
                    />
                    {{ item.topic_info.name }}
                  </div>
                  <div class="flex at_c">
                    已点赞{{ item.zan_num }}次
                    <img
                      class="icon_zan"
                      src="@/assets/img/icon_zan.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <!-- 来访者说话左侧 -->
              <div v-if="item.direction == 2" class="cori_chat_box_list_cell">
                <el-popconfirm
                  v-if="chat_type == 4"
                  confirm-button-text="确认"
                  cancel-button-text="取消"
                  icon-color="#626AEF"
                  title="确认禁言该用户吗?"
                  @confirm="banConfirm(item.user_id)"
                >
                  <template #reference>
                    <img
                      class="cori_chat_box_list_cell_avatar can"
                      :src="item.avatar"
                    />
                  </template>
                </el-popconfirm>
                <img
                  v-if="chat_type == 2"
                  class="cori_chat_box_list_cell_avatar"
                  :src="item.avatar"
                />
                <div class="cori_chat_box_list_cell_info">
                  <div class="flex at_c fz12" style="color: #858fa2">
                    <div class="mr6">{{ item.name }}</div>
                    <div>{{ item.time }}</div>
                  </div>
                  <el-popconfirm
                    v-if="chat_type == 4"
                    confirm-button-text="确认"
                    cancel-button-text="取消"
                    icon-color="#626AEF"
                    title="确认删除该发言吗?"
                    @confirm="delConfirm(item.id)"
                  >
                    <template #reference>
                      <div
                        :class="{ dummy_text: item.is_dummy }"
                        class="cori_chat_box_list_cell_content can"
                        v-html="item.content"
                      ></div>
                    </template>
                  </el-popconfirm>
                  <div
                    v-if="chat_type == 2"
                    class="cori_chat_box_list_cell_content"
                    v-html="item.content"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div class="private_chat_bottom">
            <div class="private_chat_bottom_more pr">
              <div>
                <emoji
                  :id="emojiId"
                  v-if="privateEmojiStatus"
                  @changeTextarea="changeTextarea"
                ></emoji>
                <img
                  @click.stop="changeEmojiStatus('private')"
                  class="private_chat_bottom_more_icon"
                  src="../assets/img/icon_emoji.png"
                  alt=""
                />
              </div>
              <div class="flex at_c">
                <!-- <div class="switch_identity">
                  <img src="../assets/img/icon_gz.png" alt="" />
                  切换身份
                </div> -->
                <div class="flex at_c" v-if="chat_type == 4">
                  <div class="mr10">
                    <el-select
                      @change="dummy_id = null"
                      v-model="topic_id"
                      placeholder="请选择话题"
                      clearable
                    >
                      <el-option
                        class="flex at_c"
                        v-for="item in topicList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </el-option>
                    </el-select>
                  </div>
                  <div>
                    <el-select
                      @change="topic_id = null"
                      v-model="dummy_id"
                      placeholder="请选择身份"
                      clearable
                    >
                      <el-option
                        class="flex at_c"
                        v-for="item in dummyList"
                        :key="item.id"
                        :label="item.nickname"
                        :value="item.id"
                      >
                        <img
                          class="switch_identity_avatar"
                          :src="item.avatar"
                          alt=""
                        />
                        {{ item.nickname }}
                      </el-option>
                    </el-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="private_chat_input_body">
              <div id="editor"></div>
              <!-- <textarea
                v-model="textarea"
                name=""
                id=""
                cols="30"
                rows="10"
                @keyup.enter="privateChatSend"
              ></textarea> -->
              <div class="flex at_c jc_e">
                <div @click="privateChatSend" class="chat_btn">发送</div>
              </div>
            </div>
          </div>
        </div>
        <div class="private_chat_right" v-if="chatBoxRightStatus">
          <div class="private_chat_right_title">
            <img src="../assets/img/icon_history.png" alt="" />
            历史轨迹
          </div>
          <div class="fz14">
            <div class="bb p10 border-b">
              首次访问 {{ memberInfo.first_time }}
            </div>
            <div class="bb p10 border-b">
              本次访问 {{ memberInfo.last_time }}
            </div>
            <div class="bb p10 border-b">
              本月访问次数 {{ memberInfo.visit_count }}次
            </div>
          </div>
          <div class="private_chat_right_title">
            <img src="../assets/img/icon_infor.png" alt="" />
            客户信息
          </div>
          <div class="customer_info overflow_y">
            <div class="customer_cell">
              <div>昵称</div>
              <div>{{ memberInfo.nickname }}</div>
            </div>
            <div class="customer_cell">
              <div>IP</div>
              <div>{{ memberInfo.ip }}</div>
            </div>
            <div class="customer_cell">
              <div>着陆页</div>
              <div>{{ memberInfo.url }}</div>
            </div>
            <div class="customer_cell">
              <div>省市</div>
              <div>
                {{ memberInfo.province }}{{ memberInfo.city
                }}{{ memberInfo.county }}
              </div>
            </div>
            <div class="customer_cell">
              <div>运营商</div>
              <div>{{ memberInfo.lsp }}</div>
            </div>
            <div class="customer_cell">
              <div>关键词</div>
              <div>{{ memberInfo.keywords }}</div>
            </div>
            <div class="customer_cell">
              <div>用户组</div>
              <div>{{ memberInfo.type }}</div>
            </div>
            <div class="customer_cell">
              <div>观看视频</div>
              <div>{{ memberInfo.video_status == 1 ? "正常" : "封禁" }}</div>
            </div>
            <div class="customer_cell">
              <div>发言</div>
              <div>{{ memberInfo.chat_status == 1 ? "正常" : "封禁" }}</div>
            </div>
            <div class="customer_cell">
              <div>归属</div>
              <div>{{ memberInfo.service_name }}</div>
            </div>
            <div class="cori_divider cori_divider_small">
              <div>实盘数据</div>
            </div>
            <div class="customer_cell">
              <div>姓名</div>
              <div>{{ memberInfo.offerInfo.info.name }}</div>
            </div>
            <div class="customer_cell">
              <div>手机号</div>
              <div>{{ memberInfo.offerInfo.info.phone }}</div>
            </div>

            <div class="customer_cell">
              <div>期末权益</div>
              <div>
                <div
                  class="mb15"
                  v-for="(item, index) in memberInfo.offerInfo.funds"
                  :key="index"
                >
                  <div class="mb5">资产账号：{{ item.asset }}</div>
                  <div class="mb5">开户时间：{{ item.opentime }}</div>
                  <div class="mb5">天数：{{ item.dormancy_days }}</div>
                  <div>权益：{{ item.last_money }}</div>
                </div>
              </div>
            </div>
            <!-- <div class="cori_divider cori_divider_small">
              <div>实盘数据</div>
            </div>
            <div class="customer_cell">
              <div>姓名</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>休眠天数</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>期末权益</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>开户时间</div>
              <div>{{memberInfo.nickname}}</div>
            </div>
            <div class="customer_cell">
              <div>出金</div>
              <div>{{memberInfo.nickname}}</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as Service from "../api/chat";
import Emoji from "../components/emoji.vue";
import emojiList from "../utils/emoji";
import * as ServiceC from "../api/circleManage";
import E from "wangeditor";
const OSS = require("ali-oss");
export default {
  components: {
    Emoji,
  },
  data() {
    return {
      room_id: 1,
      userInfo: {}, // 研究员信息
      userName: "", //  当前用户名称
      userId: null, // 当前用户id
      customerStatus: 1, //1全部客户2在线客户
      page: 1,
      customerList: [], //客户列表
      customerListloading: false, //客户列表加载状态
      isEnd: false,
      currentTalkMember: {
        id: null,
        type: null,
        nickname: "",
      }, //当前聊天对象用户信息
      messageList: [], //当前聊天消息列表
      messageListLoading: false, // 当前聊天消息列表加载状态
      privateEmojiStatus: false, // 私聊emoji输入状态
      emojiId: "", // 当前激活的emoji id
      textarea: "", // 私聊待发送内容
      memberInfo: {
        offerInfo: {
          info: {},
          funds: [],
        },
      }, // 私聊人员相关信息

      memberList: [],
      groupSendShow: false, // 多人群发弹出层
      currentPage: 1, // 多人群发用户列表当前页码
      total: null, // 多人群发用户列表总人数
      tableLoading: false, // 多人群发数据表加载状态
      searchVal1: "", // 会员列表检索
      searchVal2: "", // 多人群发会员检索
      multipleSelection: [],
      content: "", // 多人群发待发送内容
      // groupInfo: {
      //   cover: require("../assets/img/group_chat_cover.png"),
      //   name: "我的实盘",
      //   is_new: false,
      //   is_new_message: false,
      //   createtime: "",
      // },
      circleInfo: {
        cover: require("../assets/img/group_chat_cover.png"),
        name: "我的圈子",
        is_new: false,
        is_new_message: false,
        createtime: "",
      },
      chatBoxStatus: false,
      chatBoxRightStatus: true,
      chat_type: null, //当前聊天模式1群聊2私聊4圈子
      userData: [],
      dummy_id: null, // 假人id
      dummyList: [],
      drawer: false,
      direction: "rtl",
      circleUserList: [], // 圈子人员列表
      circleUserListLoading: false, // 圈子人员加载状态
      circleStatus: 1, // 0 禁言 1 发言
      userTotal: 0, // 当前圈子总人数
      circleUserPage: 1,
      circleIsEnd: false,
      limit: 50,
      circleList: [], // 圈子列表

      editor: null,
      topic_id: null, // 话题id
      isEndMessage: false, // 历史消息是否查看到头
    };
  },
  created() {},
  mounted() {
    let that = this;
    window.onmessageSocket = this.onmessage;
    if (this.customerStatus == 1) {
      this.getCustomerList1(1);
    } else {
      this.getCustomerList2();
    }

    this.getMemberAll(1);
    this.getUserData();
    this.getTeacherInfo();
    this.getDummyList();
    this.getCircleList();
    this.getTopicList();

    this.createEditor();

    $("#privateChat").scroll(function () {
      var top = $("#privateChat").scrollTop();
      if (top == 0) {
        that.getHistoryChatList();
      }
    });
  },
  methods: {
    // 获取话题列表
    async getTopicList() {
      const res = await ServiceC.topicList({
        is_paginate: 0,
      });
      this.topicList = res.data;
    },
    // 获取图片名称
    getFileName(file) {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var type = file.type.substr(
        file.type.lastIndexOf("/") + 1,
        file.type.length
      );
      return (
        "push" + year + month + date + "/" + Math.round(new Date()) + "." + type
      );
    },
    // 创建富文本编辑器
    createEditor() {
      let that = this;
      var height = 220;
      const editor = new E("#editor");
      // 关闭粘贴样式的过滤
      editor.config.pasteFilterStyle = false;
      // 自定义处理粘贴的文本内容
      editor.config.pasteTextHandle = function (content) {
        // content 即粘贴过来的内容（html 或 纯文本），可进行自定义处理然后返回
        if (that.content != content) {
          that.content = content;
          var str = content;
          str = str.replace(/<xml>[\s\S]*?<\/xml>/gi, "");
          str = str.replace(/<style>[\s\S]*?<\/style>/gi, "");
          str = str.replace(/<\/?[^>]*>/g, "");
          str = str.replace(/[ | ]*\n/g, "\n");
          str = str.replace(/&nbsp;/gi, "");
          console.log(str);
          return str;
        }
      };

      editor.config.uploadImgServer = true;
      let client = new OSS({
        // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
        region: "oss-cn-shanghai",
        // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
        accessKeyId: "LTAI4G1V6Q8mGL5MpDqpreko",
        accessKeySecret: "sPsj1NZnkJ8uhrhTQoHTyioADohcEu",
        bucket: "i-vce",
      });

      editor.config.customUploadImg = function (resultFiles, insertImgFn) {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        var name = that.getFileName(resultFiles[0]);
        client
          .put(name, resultFiles[0])
          .then(function (res) {
            // 上传图片，返回结果，将图片插入到编辑器中
            insertImgFn(res.url);
          })
          .catch(function (err) {
            console.log(err);
          });
      };

      editor.config.height = height;
      editor.config.zIndex = 500;
      // 配置行高
      editor.config.lineHeights = [
        "0",
        "0.5",
        "1.0",
        "1.5",
        "2.0",
        "2.5",
        "3.0",
      ];
      // 配置菜单栏，设置不需要的菜单
      editor.config.excludeMenus = [
        "link",
        "list",
        "todo",
        "justify",
        "quote",
        "video",
        "table",
        "code",
        "splitLine",
      ];
      // 取消自动 focus
      // editor.config.focus = false;
      // 配置 onchange 回调函数
      editor.config.onchange = function (newHtml) {
        // var reult = newHtml.charAt(newHtml.length-1)
        that.textarea = newHtml;
      };
      console.log("@", editor.config);
      // 配置触发 onchange 的时间频率，默认为 200ms
      editor.config.onchangeTimeout = 500; // 修改为 500ms
      editor.create();
      editor.txt.html("");
      this.editor = editor;
    },
    // 获取圈子列表
    async getCircleList() {
      const res = await Service.getCircleList();
      this.circleList = res.data.list;
    },
    // 全员禁言&解禁
    async changeCircleChatStatus() {
      const res = await Service.changeCircleChatStatus({
        uid: this.currentTalkMember.id,
        status: this.circleStatus == 1 ? 0 : 1,
      });
      this.getCircleConfig();
      this.$message.success(res.msg);
    },
    // 修改圈子用户发言状态
    async changeCircleUserChatStatus(user_id, status, index) {
      const res = await Service.changeCircleUserChatStatus({
        uid: this.currentTalkMember.id,
        user_id,
        status: status == 1 ? 0 : 1,
      });
      this.$message.success(res.msg);
      this.circleUserList[index].status = status == 1 ? 0 : 1;
    },
    // 获取假人列表
    async getDummyList() {
      const res = await ServiceC.getUserList({
        is_paginate: 0,
      });
      // console.log("假人列表", res);
      this.dummyList = res.data;
    },
    // 显示圈子成员
    showDrawer() {
      this.circleIsEnd = false;
      this.circleUserList = [];
      this.circleUserPage = 1;
      this.getCircleUserList(1);
      this.drawer = true;
    },
    // 禁言
    async banConfirm(user_id) {
      const res = await Service.changeCircleUserChatStatus({
        uid: this.currentTalkMember.id,
        user_id,
        status: 0,
      });
      this.getCircleConfig();
      this.$message.success(res.msg);
    },
    // 删除发言
    async delConfirm(id) {
      const res = await Service.delCircleMessage({
        uid: this.currentTalkMember.id,
        id,
      });
      var messageList = this.messageList;
      for (const [index, item] of messageList.entries()) {
        if (item.id == id) {
          messageList.splice(index, 1);
        }
      }
      this.$message.success(res.msg);
    },
    onmessage(e) {
      //数据接收
      const redata = JSON.parse(e.data);
      var customerList = this.customerList;
      var circleList = this.circleList;
      switch (redata.type) {
        //---------来访者打开聊天框
        case "user_join_teacher_group_accept":
          for (const [index, item] of customerList.entries()) {
            if (
              item.type == redata.user_info.type &&
              item.id == redata.user_info.id
            ) {
              customerList.splice(index, 1);
            }
          }
          customerList.unshift({
            id: redata.user_info.id,
            avatar: redata.user_info.avatar,
            type: redata.user_info.type,
            nickname: redata.user_info.nickname,
            time: redata.createtime_text,
            is_new: redata.is_new,
            is_new_message: redata.is_new_message,
          });
          break;
        //---------用户发送消息给讲师
        case "chat_teacher_accept":
          var currentTalkMember = this.currentTalkMember,
            messageList = this.messageList;
          if (redata.chat_type != 4) {
            for (const [index, item] of customerList.entries()) {
              if (
                item.type == redata.user_info.type &&
                item.id == redata.user_info.id
              ) {
                customerList.splice(index, 1);
              }
            }
            customerList.unshift({
              id: redata.user_info.id,
              avatar: redata.user_info.avatar,
              type: redata.user_info.type,
              nickname: redata.user_info.nickname,
              time: redata.createtime_text,
              is_new: redata.is_new,
              is_new_message: redata.is_new_message,
            });

            if (
              redata.chat_type == this.chat_type &&
              redata.user_id == currentTalkMember.id
            ) {
              messageList.push({
                id: redata.chat_id,
                user_id: redata.user_id,
                name: redata.user_info.nickname,
                avatar: redata.user_info.avatar,
                time: redata.createtime_text,
                createtime: redata.createtime,
                content: this.emojiReplace(redata.content),
                is_dummy: redata.user_info.is_dummy,
                direction: this.userInfo.id == redata.user_info.id ? 1 : 2,
                topic_id: redata.topic_id,
                topic_info: redata.topic_info,
                zan_num: redata.zan_num,
              });
              this.scoll1();
            }
          } else {
            for (const item of circleList) {
              if (item.uid == redata.teacher_id) {
                item.unread_num++;
                item.time = redata.createtime_text;
              }
            }

            if (
              redata.chat_type == this.chat_type &&
              redata.teacher_id == currentTalkMember.id
            ) {
              messageList.push({
                id: redata.chat_id,
                user_id: redata.user_id,
                name: redata.user_info.nickname,
                avatar: redata.user_info.avatar,
                time: redata.createtime_text,
                createtime: redata.createtime,
                content: this.emojiReplace(redata.content),
                is_dummy: redata.user_info.is_dummy,
                direction: this.userInfo.id == redata.user_info.id ? 1 : 2,
                topic_id: redata.topic_id,
                topic_info: redata.topic_info,
                zan_num: redata.zan_num,
              });
              this.scoll1();
            }
          }

          break;
        //---------用户发送消息给讲师
        case "user_drop_line":
          if (this.customerStatus == 2) {
            for (const [index, item] of customerList.entries()) {
              if (item.id == redata.content.user_id) {
                customerList.splice(index, 1);
              }
            }
          }
          // console.log("会员断线", redata);
          break;
        //---------websocket出现错误进行提示
        case "error":
          this.$message.error(redata.content);
          break;
      }
    },
    async getUserData() {
      const res = await Service.getUserData();
      // console.log("获取弹窗统计信息", res);
      this.userData = res.data;
    },
    //会员列表检索
    search1() {
      this.page = 1;
      this.isEnd = false;
      this.customerList = [];
      this.getCustomerList1(1);
    },
    //多人群发会员检索
    search2() {
      this.getMemberAll(1);
    },
    chatInit() {
      this.isEndMessage = false;
      this.privateEmojiStatus = false;
      this.textarea = "";
      this.editor.txt.html("");
      this.circleIsEnd = false;
      this.circleUserList = [];
      this.circleUserPage = 1;
    },
    // 圈子
    async circleChat(uid, title, index) {
      let that = this;
      this.circleList[index].unread_num = 0;
      this.currentTalkMember = {
        id: uid,
        type: 4,
        nickname: title,
      };
      this.chatInit();

      // this.getCircleUserList(1);
      this.getCircleConfig();
      this.messageListLoading = true;
      this.chatBoxStatus = true;
      this.chatBoxRightStatus = false;
      this.chat_type = 4;
      this.circleUserList = []; // 圈子人员列表
      this.circleUserPage = 1;
      this.circleIsEnd = false;
      this.messageList = [];
      const res = await Service.getChatList({
        uid,
        createtime: Math.round(new Date() / 1000),
        chat_type: 4,
      });
      // console.log("群聊信息", res);
      var list = res.data.list,
        messageList = this.messageList;
      for (const item of list) {
        messageList.unshift({
          id: item.id,
          user_id: item.user_id,
          name: item.user.nickname,
          avatar: item.user.avatar,
          time: item.createtime_text,
          createtime: item.createtime,
          content: this.emojiReplace(item.content),
          direction: item.user_id == this.userInfo.id ? 1 : 2,
          status: item.status,
          is_dummy: item.user.is_dummy,
          topic_id: item.topic_id,
          topic_info: item.topic_info,
          zan_num: item.zan_num,
        });
      }
      this.messageListLoading = false;
      this.scoll1();
    },
    // 获取历史消息
    async getHistoryChatList() {
      if (this.isEndMessage) {
        return false;
      }
      var messageList = this.messageList;
      // console.log("createtime", messageList);
      // console.log("createtime", messageList[0].createtime);
      this.messageListLoading = true;
      const res = await Service.getChatList({
        uid: this.currentTalkMember.id,
        createtime: messageList[0].createtime,
        chat_type: this.chat_type,
      });
      var list = res.data.list;
      if (list.length) {
        for (const item of list) {
          messageList.unshift({
            id: item.id,
            user_id: item.user_id,
            name: item.user.nickname,
            avatar: item.user.avatar,
            time: item.createtime_text,
            createtime: item.createtime,
            content: this.emojiReplace(item.content),
            direction: item.user_id == this.userInfo.id ? 1 : 2,
            status: item.status,
            is_dummy: item.user.is_dummy,
            topic_id: item.topic_id,
            topic_info: item.topic_info,
            zan_num: item.zan_num,
          });
        }
      } else {
        this.isEndMessage = true;
      }

      this.messageListLoading = false;
    },
    circleLoad() {
      if (!this.circleIsEnd) {
        this.getCircleUserList(this.circleUserPage);
      }
    },
    // 获取圈子人员列表
    async getCircleUserList(page) {
      this.circleUserListLoading = true;
      const res = await Service.getCircleUserList({
        uid: this.currentTalkMember.id,
        page,
        limit: this.limit,
        is_teacher: 1,
      });
      const listNew = res.data.data;
      const circleUserList = this.circleUserList;
      if (listNew.length) {
        this.circleUserList = circleUserList.concat(listNew);
        this.circleUserPage = page + 1;
      } else {
        this.circleIsEnd = true;
      }
      this.circleUserListLoading = false;
      // console.log("圈子人员列表", res);
    },
    // 获取圈子配置
    async getCircleConfig() {
      const res = await Service.getCircleConfig({
        uid: this.currentTalkMember.id,
      });
      // console.log("获取圈子配置", res);
      this.circleStatus = res.data.status;
      this.userTotal = res.data.user_total;
    },
    // 多人群发
    async groupSend() {
      var content = this.content,
        multipleSelection = this.multipleSelection,
        ids = [];

      if (!multipleSelection.length) {
        this.$message.error("请至少选择一个发送对象");
        return false;
      }
      if (!content) {
        this.$message.error("发送消息不可为空");
        return false;
      }

      for (const item of multipleSelection) {
        ids.push({
          id: item.id,
        });
      }

      const res = await Service.sendMassMessage({
        ids,
        content,
      });
      this.$message.success(res.msg);
      console.log("多人群发", res);
      this.multipleSelection = [];
      this.$refs.multipleTable.clearSelection();
      this.content = "";
      this.searchVal2 = "";
    },
    // 获取研究员信息
    async getTeacherInfo() {
      const res = await Service.getTeacherInfo();
      // console.log("获取研究员信息", res);
      this.userInfo = res.data.userinfo;
    },
    load() {
      if (this.customerStatus == 1) {
        this.getCustomerList1(this.page);
      }
    },
    // 全部客户
    async getCustomerList1(page) {
      if (!this.isEnd) {
        this.customerListloading = true;
        const res = await Service.getCustomerList({
          page,
          search: this.searchVal1,
        });
        // console.log("全部客户", res);
        var list_new = res.data.data,
          customerList = this.customerList;
        if (list_new.length) {
          this.customerList = customerList.concat(list_new);
          this.page++;
        } else {
          this.isEnd = true;
        }
        this.customerListloading = false;
      }
    },
    // 在线客户
    async getCustomerList2() {
      this.customerListloading = true;
      const res = await Service.getOnLineCustomerList();
      console.log("在线客户", res);

      this.customerList = res.data;
      this.customerListloading = false;
    },
    // 多人群发获取全部会员
    async getMemberAll(page) {
      this.tableLoading = true;
      const res = await Service.getCustomerList({
        page,
        search: this.searchVal2,
      });
      // console.log("多人群发获取全部会员", res);
      this.memberList = res.data.data;
      this.total = res.data.total;
      this.currentPage = page;
      this.tableLoading = false;
    },
    // 获取当前选中的全部会员
    handleSelectionChange(val) {
      this.multipleSelection = val;
      console.log("handleSelectionChange", val);
    },
    handleCurrentChange(val) {
      this.getMemberAll(val);
      console.log(`当前页: ${val}`);
    },
    //私聊滚动
    scoll1() {
      $("#privateChat").animate(
        {
          scrollTop: "99999px",
        },
        1200
      );
    },
    changeTouristNewsStatus(e) {
      this.customerStatus = e;
      this.customerList = [];
      this.page = 1;
      this.isEnd = false;
      if (e == 1) {
        this.getCustomerList1(1);
      } else {
        this.getCustomerList2();
      }
    },
    //私聊表情输入
    changeTextarea(e) {
      this.textarea += e;
      // this.editor.txt.html(this.editor.txt.html() + e);
      this.editor.txt.append(e);
    },
    //emoji选择列表展示状态更改
    changeEmojiStatus(e) {
      this.privateEmojiStatus = !this.privateEmojiStatus;
      this.emojiId = "private";
    },
    //选择私聊人员获取聊天记录
    async privateChat(type, id, nickname, index) {
      this.chatInit();
      this.messageListLoading = true;
      this.chatBoxStatus = true;
      this.chatBoxRightStatus = true;
      this.chat_type = 2;
      this.messageList = [];
      this.currentTalkMember = {
        id,
        type,
        nickname,
      };
      const res = await Service.getChatList({
        uid: id,
        createtime: Math.round(new Date() / 1000),
        chat_type: 2,
      });
      console.log("privateChat", res);
      var list = res.data.list,
        messageList = this.messageList;
      this.customerList[index].is_new_message = false;
      for (const item of list) {
        messageList.unshift({
          id: item.id,
          user_id: item.user_id,
          name: item.user.nickname,
          avatar: item.user.avatar,
          time: item.createtime_text,
          createtime: item.createtime,
          content: this.emojiReplace(item.content),
          direction: item.teacher_id == item.to_user_id ? 2 : 1,
          is_dummy: item.user.is_dummy,
          topic_id: item.topic_id,
          topic_info: item.topic_info,
          zan_num: item.zan_num,
        });
      }
      this.messageListLoading = false;
      this.getUserInfo(type, id);
      this.scoll1();
    },
    //获取私聊人员相关信息
    async getUserInfo(type, id) {
      this.messageListLoading = true;
      const res = await Service.getUserInfo({
        type,
        id,
      });
      console.log("getUserInfo", res);
      this.messageListLoading = false;
      this.memberInfo = res.data;
    },
    //私聊发送
    privateChatSend() {
      if (!this.textarea) {
        this.$message.error("不能发送空消息");
        return false;
      }
      var chatBoxRightStatus = this.chatBoxRightStatus;
      var dummy_id = this.dummy_id;
      var topic_id = this.topic_id;
      this.$store.state.ws.send(
        JSON.stringify({
          type: "chat_teacher_send",
          to_user_id: this.currentTalkMember.id,
          teacher_id:
            this.chat_type == 4 ? this.currentTalkMember.id : this.userInfo.id,
          content: this.textarea,
          chat_type: this.chat_type,
          dummy_id,
          topic_id,
          config: {
            platform: "pushWeb",
          },
        })
      );
      if (this.chat_type != 4) {
        this.messageList.push({
          id: null,
          user_id: null,
          name: this.userInfo.nickname,
          avatar: "",
          time: this.getSendTime(),
          createtime: Math.round(new Date() / 1000),
          content: this.emojiReplace(this.textarea),
          // is_dummy: dummy_id ? 1 : 0,
          direction: 1,
        });
      }
      this.textarea = "";
      this.editor.txt.html("");
      this.privateEmojiStatus = false;
      var customerList = this.customerList;
      for (const [index, item] of customerList.entries()) {
        if (item.id == this.currentTalkMember.id) {
          item.is_new_message = false;
          customerList.splice(index, 1);
          customerList.unshift(item);
        }
      }
      this.scoll1();
    },
    //替换内容中的emoji字符
    emojiReplace(val) {
      // console.log("emojiReplace", val);
      for (const item of emojiList) {
        val = val.replace(
          new RegExp(item.regexp, "g"),
          '<img style="width: 18px;height: 18px;" src="' + item.url + '" />'
        );
      }
      return val;
    },
    getSendTime() {
      var d = new Date();
      var year = d.getFullYear();
      var month =
        d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
      var date = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
      var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
      var min = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
      var sec = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
      var cori_date = year + "-" + month + "-" + date;
      var cori_time = hour + ":" + min + ":" + sec;
      this.currentDate = cori_date;
      this.currentTime = cori_time;

      return cori_date + " " + cori_time;
    },
  },
};
</script>

<style scoped>
.side_left {
  /* width: 18%; */
  width: 334px;
  height: 100vh;
  border-right: 1px solid #ebeced;
  box-sizing: border-box;
}

.private_chat {
  /* width: 58%; */
  width: calc(100% - 334px);
  height: 100vh;
  border-right: 1px solid #ebeced;
}

.left_one {
  /* height: 580px; */
  height: calc(100% - 50px);
}

.left_two {
  /* height: calc(100% - 580px); */
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 400;
  color: #626a78;
  line-height: 20px;
  background: #f7f8fc;
  box-sizing: border-box;
  border-top: 1px solid #e7e9ef;
}

.left_two_div {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon_drqf {
  width: 14px;
  height: 14px;
  margin-right: 6px;
}

.list_head {
  display: flex;
  background-color: #f8fcff;
  border-bottom: 1px solid #ebeced;
}

.list_head_cell {
  flex: 1;
  display: flex;
  justify-content: center;
  cursor: pointer;
  box-sizing: border-box;
  padding: 11px 0;
  font-size: 16px;
  line-height: 22px;
}

.list_head_cell_active {
  position: relative;
  color: #4571e7;
}

.list_head_cell_active::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  border: 1px solid #0092fd;
}

.offline_un {
  margin-right: 20px !important;
}

.tourist_news_list {
  height: calc(100% - 90px);
}

.retrieval {
  height: 45px;
  box-sizing: border-box;
  padding: 0 10px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeced;
}

.overflow_y {
  overflow-y: auto;
}

.overflow_y::-webkit-scrollbar {
  display: block;
  width: 4px;
}

.overflow_y1::-webkit-scrollbar {
  display: block;
  width: 10px;
}

.overflow_y::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px #aaa;
  background: rgba(0, 0, 0, 0.2);
}

.overflow_y::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px #aaa;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.1);
}

.tourist_news_cell {
  height: 66px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeced;
  cursor: pointer;
  position: relative;
}

.tourist_avatar_cell {
  width: 40px;
  height: 40px;
  margin-right: 6px;
}

.tourist_avatar_img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.tourist_identity {
  color: #fff;
  font-size: 12px;
  line-height: 12px;
  background-color: #4571e7;
  box-sizing: border-box;
  padding: 3px 4px;
  display: inline-flex;
  margin-right: 4px;
  flex-shrink: 0;
}

.tourist_time {
  color: #8691a3;
  font-size: 12px;
  line-height: 12px;
}

.h40 {
  height: 40px;
}

.icon_new {
  width: 18px;
  height: 18px;
}

.tourist {
  /* background-image: url("../assets/img/tourist_bg.png"); */
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.tourist_news_tips {
  position: absolute;
  right: -4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #ff3f30;
}

.cori_divider > div {
  font-size: 12px;
  font-weight: 400;
  color: #a3adbf;
  position: relative;
  text-align: center;
  margin: 10px 0;
}

.cori_divider > div::before {
  content: "";
  display: block;
  width: 30%;
  position: absolute;
  left: 45px;
  top: 10px;
  height: 1px;
  background: linear-gradient(90deg, #ffffff 0%, #e6e9f0 49%, #ffffff 100%);
}

.cori_divider > div::after {
  content: "";
  display: block;
  width: 30%;
  position: absolute;
  right: 45px;
  top: 10px;
  height: 1px;
  background: linear-gradient(90deg, #ffffff 0%, #e6e9f0 49%, #ffffff 100%);
}

.chat_list {
  background-color: #fff;
  box-sizing: border-box;
  padding: 10px;
  height: calc(100% - 174px);
}

.chat_btn {
  font-size: 12px;
  line-height: 12px;
  background-color: #5188f4;
  color: #fff;
  width: 60px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
}

.chat_input {
  width: calc(100% - 70px);
}

.private_chat_head {
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 15px;
  background-color: #f8fcff;
  border-bottom: 1px solid #ebeced;
}

.private_chat_head > div > span:first-child {
  font-size: 16px;
  font-weight: 400;
  color: #3b5496;
  line-height: 22px;
  margin-right: 38px;
}

.private_chat_head > div > span:last-child {
  font-size: 14px;
  font-weight: 400;
  color: #94a3c3;
  line-height: 20px;
}

.estoppel {
  font-size: 12px;
  font-weight: 400;
  color: #959595;
  line-height: 17px;
  border-radius: 4px;
  border: 1px solid #d0d1d4;
  box-sizing: border-box;
  padding: 2px 6px;
}

.icon_shezhi {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.icon_operation {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  cursor: pointer;
}

.private_chat_body {
  display: flex;
  height: calc(100% - 45px);
}

.circle_users {
  display: flex;
  flex-wrap: wrap;
}

.circle_user_cell {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 400;
  color: #6a6a6a;
  line-height: 17px;
  margin-right: 16px;
  margin-bottom: 14px;
  width: 48px;
}

.circle_user_cell .avatar {
  width: 46px;
  height: 46px;
  border-radius: 8px;
  display: flex;
}

.circle_user_cell .icon_jinyan {
  width: 16px;
  height: 16px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.private_chat_left {
  width: calc(100% - 250px);
}

.max_width {
  width: 100% !important;
}

.private_chat_right {
  width: 250px;
  height: 100%;
  box-sizing: border-box;
  border-left: 1px solid #ebeced;
  background-color: #f7f8fc;
}

.private_chat_list {
  box-sizing: border-box;
  padding: 20px;
  height: calc(100% - 340px);
}

.private_chat_bottom {
  width: 100%;
  height: 340px;
  background-color: #f7f8fa;
}

.private_chat_bottom_more {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 24px 0 16px;
}

.private_chat_bottom_more_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.switch_identity {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #7c7f86;
  cursor: pointer;
  line-height: 20px;
}

.switch_identity img {
  width: 22px;
  height: 22px;
}

.switch_identity_avatar {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  margin-right: 6px;
}

.private_chat_input_body {
  height: calc(100% - 40px);
  box-sizing: border-box;
  padding: 0 10px;
}

.private_chat_input_body textarea {
  width: 100%;
  box-sizing: border-box;
  height: calc(100% - 40px);
  border: 1px solid #cccccc;
  resize: none;
  outline: none;
  padding: 10px;
}

.private_chat_right_title {
  height: 45px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ebeced;
  box-sizing: border-box;
  padding: 0 10px;
  line-height: 14px;
  font-size: 14px;
}

.private_chat_right_title img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.border-b {
  border-bottom: 1px solid #ebeced;
}

.customer_info {
  box-sizing: border-box;
  padding: 10px;
  height: calc(100% - 210px);
}

.customer_cell {
  display: flex;
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 14px;
  color: #94a3c3;
}

.customer_cell > div:first-child {
  width: 60px;
}

.customer_cell > div:last-child {
  width: calc(100% - 60px);
}

.cori_divider_small > div {
  color: #5288f5;
}

.cori_divider_small > div::before {
  width: 30%;
  left: 15px;
}

.cori_divider_small > div::after {
  width: 30%;
  right: 15px;
}

.cori_chat_box_list_cell {
  display: flex;
}

.cori_chat_box_list_cell_avatar {
  width: 32px;
  height: 32px;
  margin-right: 6px;
  border-radius: 8px;
}

.cori_chat_box_list_cell_info {
  width: calc(100% - 38px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.mr6 {
  margin-right: 6px;
}

.cori_chat_box_list_cell_content {
  margin: 6px 0 20px;
  font-size: 14px;
  max-width: 65%;
  background-color: #f7f8fc;
  box-sizing: border-box;
  padding: 6px 10px;
  border-radius: 5px;
}

.topic_about {
  width: 270px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-bottom: 20px;
}

.topic_about_cell {
  background: #e6ebf8;
  border-radius: 15px;
  box-sizing: border-box;
  padding: 3px 11px;
  color: #4571e7;
  line-height: 18px;
}

.icon_topic {
  width: 14px;
  height: 14px;
  margin-right: 4px;
}

.icon_zan {
  width: 17px;
  height: 16px;
  margin-left: 7px;
}

.color_huiyuan {
  background-color: #4571e7;
}

.color_youke {
  background-color: #4571e7;
}

.color_jiangshi {
  background-color: #ff3f30;
}

.color_guanli {
  background-color: #ffbc01;
}

.color_kefu {
  background-color: #ffbc01;
}

.avatar {
  width: 30px;
  height: 30px;
  object-fit: cover;
}

.group_send {
  width: calc(100% - 420px);
}

.h500 {
  height: 500px;
}

.h260 {
  height: 260px;
}

.is_end {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 12px 0;
  font-size: 12px;
  color: #ccc;
}

.dummy_text {
  background-color: #ff7220 !important;
  color: #fff !important;
}

.loading {
  width: 18px;
  height: 18px;
  margin: 0 10px;
}

.firm_offer_name {
  position: absolute;
  bottom: 0;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  width: 100%;
  text-align: center;
  border-radius: 0 0 8px 8px;
}

.is_firm_offer {
  position: absolute;
  background-color: #4571e7;
  color: #fff;
  transform: rotate(45deg);
  top: -6px;
  right: -14px;
  text-align: center;
  width: 40px;
  height: 24px;
  line-height: 32px;
}

.is_firm_offer div {
  font-size: 12px;
}

.biankuang {
  border: 1px solid #ff0000;
  border-radius: 8px;
}
</style>
