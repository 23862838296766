const typeArr = [
  {
    id: 1,
    name: "农产品",
  },
  {
    id: 2,
    name: "金属",
  },
  {
    id: 3,
    name: "黑色系",
  },
  {
    id: 4,
    name: "能源化工",
  },
  {
    id: 5,
    name: "金融期货",
  },
];
const varietiesArr = [
  {
    id: 1,
    type: 1,
    name: "花生",
  },
  {
    id: 2,
    type: 1,
    name: "生猪",
  },
  {
    id: 3,
    type: 1,
    name: "红枣",
  },
  {
    id: 4,
    type: 1,
    name: "苹果",
  },
  {
    id: 5,
    type: 1,
    name: "鸡蛋",
  },
  {
    id: 6,
    type: 1,
    name: "棉纱",
  },
  {
    id: 7,
    type: 1,
    name: "白糖",
  },
  {
    id: 8,
    type: 1,
    name: "郑棉",
  },
  {
    id: 9,
    type: 1,
    name: "棕榈油",
  },
  {
    id: 10,
    type: 1,
    name: "郑油",
  },
  {
    id: 11,
    type: 1,
    name: "菜粕",
  },
  {
    id: 12,
    type: 1,
    name: "菜籽",
  },
  {
    id: 13,
    type: 1,
    name: "豆油",
  },
  {
    id: 14,
    type: 1,
    name: "豆粕",
  },
  {
    id: 15,
    type: 1,
    name: "豆二",
  },
  {
    id: 16,
    type: 1,
    name: "晚稻",
  },
  {
    id: 17,
    type: 1,
    name: "粳稻",
  },
  {
    id: 18,
    type: 1,
    name: "早鲜稻",
  },
  {
    id: 19,
    type: 1,
    name: "粳米",
  },
  {
    id: 20,
    type: 1,
    name: "郑麦",
  },
  {
    id: 21,
    type: 1,
    name: "淀粉",
  },
  {
    id: 22,
    type: 1,
    name: "豆一",
  },
  {
    id: 23,
    type: 1,
    name: "玉米",
  },
  {
    id: 24,
    type: 1,
    name: "纤板",
  },

  {
    id: 25,
    type: 2,
    name: "国际铜",
  },
  {
    id: 26,
    type: 2,
    name: "沪金",
  },
  {
    id: 27,
    type: 2,
    name: "沪银",
  },
  {
    id: 28,
    type: 2,
    name: "沪铜",
  },
  {
    id: 29,
    type: 2,
    name: "沪铝",
  },
  {
    id: 30,
    type: 2,
    name: "沪锌",
  },
  {
    id: 31,
    type: 2,
    name: "沪铅",
  },
  {
    id: 32,
    type: 2,
    name: "沪镍",
  },
  {
    id: 33,
    type: 2,
    name: "沪锡",
  },

  {
    id: 34,
    type: 3,
    name: "热卷",
  },
  {
    id: 35,
    type: 3,
    name: "不锈钢",
  },
  {
    id: 36,
    type: 3,
    name: "线材",
  },
  {
    id: 37,
    type: 3,
    name: "螺纹",
  },
  {
    id: 38,
    type: 3,
    name: "硅铁",
  },
  {
    id: 39,
    type: 3,
    name: "锰硅",
  },
  {
    id: 40,
    type: 3,
    name: "焦煤",
  },
  {
    id: 41,
    type: 3,
    name: "郑煤",
  },
  {
    id: 42,
    type: 3,
    name: "焦炭",
  },

  {
    id: 43,
    type: 4,
    name: "短纤",
  },
  {
    id: 44,
    type: 4,
    name: "低硫燃料油",
  },
  {
    id: 45,
    type: 4,
    name: "胶板",
  },
  {
    id: 46,
    type: 4,
    name: "玻璃",
  },
  {
    id: 47,
    type: 4,
    name: "纸浆",
  },
  {
    id: 48,
    type: 4,
    name: "纯碱",
  },
  {
    id: 49,
    type: 4,
    name: "尿素",
  },
  {
    id: 50,
    type: 4,
    name: "苯乙烯",
  },
  {
    id: 51,
    type: 4,
    name: "PP",
  },
  {
    id: 52,
    type: 4,
    name: "PVC",
  },
  {
    id: 53,
    type: 4,
    name: "PTA",
  },
  {
    id: 54,
    type: 4,
    name: "乙二醇",
  },
  {
    id: 55,
    type: 4,
    name: "20号胶",
  },
  {
    id: 56,
    type: 4,
    name: "LPG",
  },
  {
    id: 57,
    type: 4,
    name: "橡胶",
  },
  {
    id: 58,
    type: 4,
    name: "甲醇",
  },
  {
    id: 59,
    type: 4,
    name: "塑料",
  },
  {
    id: 60,
    type: 4,
    name: "沥青",
  },
  {
    id: 61,
    type: 4,
    name: "燃油",
  },
  {
    id: 62,
    type: 4,
    name: "原油",
  },
  {
    id: 63,
    type: 4,
    name: "铁矿",
  },

  {
    id: 64,
    type: 5,
    name: "IH",
  },
  {
    id: 65,
    type: 5,
    name: "IC",
  },
  {
    id: 66,
    type: 5,
    name: "IF",
  },
];

const titleArr = [
  {
    id: 1,
    name: "早盘",
  },
  {
    id: 2,
    name: "午盘",
  },
  {
    id: 3,
    name: "晚盘",
  },
  {
    id: 4,
    name: "消息",
  },
];
export default { typeArr, varietiesArr, titleArr };
