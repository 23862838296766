import request from "../utils/request";

//我的会员列表
export const getUserList = (query) => {
  return request({
    url: "/teacher/info/getUserList",
    method: "POST",
    data: query,
  });
};
//添加假人
export const fillUser = (query) => {
  return request({
    url: "/teacher/info/fillUser",
    method: "POST",
    data: query,
  });
};
//删除假人
export const delDummyUser = (query) => {
  return request({
    url: "/teacher/info/delDummyUser",
    method: "POST",
    data: query,
  });
};
//修改会员个人信息
export const profile = (query) => {
  return request({
    url: "/teacher/info/profile",
    method: "POST",
    data: query,
  });
};
