import request from "../utils/request";

//我的会员列表
export const getUserList = (query) => {
  return request({
    url: "/teacher/info/getUserList",
    method: "POST",
    data: query,
  });
};
//添加假人
export const fillUser = (query) => {
  return request({
    url: "/teacher/info/fillUser",
    method: "POST",
    data: query,
  });
};
//删除假人
export const delDummyUser = (query) => {
  return request({
    url: "/teacher/info/delDummyUser",
    method: "POST",
    data: query,
  });
};
//修改会员个人信息
export const profile = (query) => {
  return request({
    url: "/teacher/info/profile",
    method: "POST",
    data: query,
  });
};
// 获取圈子列表
export const getCircleList = (query) => {
  return request({
    url: "/teacher/chat/getCircleList",
    method: "POST",
    data: query,
  });
};
// 获取圈子配置
export const getCircleConfig = (query) => {
  return request({
    url: "/teacher/chat/getCircleConfig",
    method: "POST",
    data: query,
  });
};
// 修改圈子公告
export const changeCircleNotice = (query) => {
  return request({
    url: "/teacher/chat/changeCircleNotice",
    method: "POST",
    data: query,
  });
};
// 我的话题列表
export const topicList = (query) => {
  return request({
    url: "/teacher/info/topicList",
    method: "POST",
    data: query,
  });
};
// 添加话题
export const addTopic = (query) => {
  return request({
    url: "/teacher/info/addTopic",
    method: "POST",
    data: query,
  });
};
// 修改话题
export const editTopic = (query) => {
  return request({
    url: "/teacher/info/editTopic",
    method: "POST",
    data: query,
  });
};
// 删除话题
export const deleteTopic = (query) => {
  return request({
    url: "/teacher/info/deleteTopic",
    method: "POST",
    data: query,
  });
};
// 成员列表
export const getCircleUserList = (query) => {
  return request({
    url: "/api/chat/getCircleUserList",
    method: "POST",
    data: query,
  });
};
// 修改置顶话题
export const topTopic = (query) => {
  return request({
    url: "/teacher/info/topTopic",
    method: "POST",
    data: query,
  });
};
